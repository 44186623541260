export enum ParserFlags {
	ALLOW_SPOILERS = 1 << 0,
	ALLOW_HEADINGS = 1 << 1,
	ALLOW_LISTS = 1 << 2,
	ALLOW_CODE_BLOCKS = 1 << 3,
	ALLOW_MASKED_LINKS = 1 << 4,
	ALLOW_COMMAND_MENTIONS = 1 << 5,
	ALLOW_GUILD_NAVIGATIONS = 1 << 6,
	ALLOW_USER_MENTIONS = 1 << 7,
	ALLOW_ROLE_MENTIONS = 1 << 8,
	ALLOW_CHANNEL_MENTIONS = 1 << 9,
	ALLOW_EVERYONE_MENTIONS = 1 << 10,
	ALLOW_BLOCKQUOTES = 1 << 11,
	ALLOW_MULTILINE_BLOCKQUOTES = 1 << 12,
	ALLOW_SUBTEXT = 1 << 13,
	ALLOW_TABLES = 1 << 14,
	ALLOW_ALERTS = 1 << 15,
	ALLOW_AUTOLINKS = 1 << 16,
}

export enum NodeType {
	Text = 'Text',
	Blockquote = 'Blockquote',
	Strong = 'Strong',
	Emphasis = 'Emphasis',
	Underline = 'Underline',
	Strikethrough = 'Strikethrough',
	Spoiler = 'Spoiler',
	Heading = 'Heading',
	Subtext = 'Subtext',
	List = 'List',
	CodeBlock = 'CodeBlock',
	InlineCode = 'InlineCode',
	Sequence = 'Sequence',
	Link = 'Link',
	Mention = 'Mention',
	Timestamp = 'Timestamp',
	Emoji = 'Emoji',
	TripleAsterisk = 'TripleAsterisk',
	TripleUnderscore = 'TripleUnderscore',
	Table = 'Table',
	TableRow = 'TableRow',
	TableCell = 'TableCell',
	Alert = 'Alert',
}

export enum AlertType {
	Note = 'Note',
	Tip = 'Tip',
	Important = 'Important',
	Warning = 'Warning',
	Caution = 'Caution',
}

export enum TableAlignment {
	Left = 'Left',
	Center = 'Center',
	Right = 'Right',
	None = 'None',
}

export enum TimestampStyle {
	ShortTime = 'ShortTime',
	LongTime = 'LongTime',
	ShortDate = 'ShortDate',
	LongDate = 'LongDate',
	ShortDateTime = 'ShortDateTime',
	LongDateTime = 'LongDateTime',
	RelativeTime = 'RelativeTime',
}

export enum GuildNavKind {
	Customize = 'Customize',
	Browse = 'Browse',
	Guide = 'Guide',
	LinkedRoles = 'LinkedRoles',
}

export enum MentionKind {
	User = 'User',
	Channel = 'Channel',
	Role = 'Role',
	Command = 'Command',
	GuildNavigation = 'GuildNavigation',
	Everyone = 'Everyone',
	Here = 'Here',
}

export enum EmojiKind {
	Standard = 'Standard',
	Custom = 'Custom',
}
