/**
 * Utility functions for string manipulation and checks
 */

/**
 * Checks if a character at the given position is an underscore inside a word
 * Used to prevent formatting _inside_words_like_this
 *
 * @param chars - Array of characters
 * @param pos - Position to check
 * @returns Whether this is a word-internal underscore
 */
export function isWordUnderscore(chars: Array<string>, pos: number): boolean {
	if (chars[pos] !== '_') return false;

	// Check the characters before and after the underscore
	const prevChar = pos > 0 ? chars[pos - 1] : '\0';
	const nextChar = pos + 1 < chars.length ? chars[pos + 1] : '\0';

	// Word-internal underscore has word characters on both sides
	return isWordCharacter(prevChar) && isWordCharacter(nextChar);
}

/**
 * Checks if a character needs escaping in Markdown
 *
 * @param char - Character to check
 * @returns Whether the character is escapable
 */
export function isEscapableCharacter(char: string): boolean {
	return /^[[\]()\\*_~`@!#$%^&+={}|:;"'<>,.?/]$/.test(char);
}

/**
 * Checks if a character is a word character (letter, digit, or underscore)
 *
 * @param char - Character to check
 * @returns Whether the character is a word character
 */
export function isWordCharacter(char: string): boolean {
	return /^[a-zA-Z0-9_]$/.test(char);
}

/**
 * Checks if a character would terminate a URL in plain text
 *
 * @param char - Character to check
 * @returns Whether the character would terminate a URL
 */
export function isUrlTerminationChar(char: string): boolean {
	return /\s|\)|\]|"|'/.test(char);
}

/**
 * Checks if text starts with a URL
 * Only checks for http and https schemes
 *
 * @param text - Text to check
 * @returns Whether the text starts with a URL
 */
export function startsWithUrl(text: string): boolean {
	// Check if this is a URL and not inside quoted text
	// Only allow http and https schemes for autolinks, not mailto
	if (text.startsWith('http://') || text.startsWith('https://')) {
		// Check if we're inside a quoted context by looking backwards
		// for opening quotes or angle brackets
		const prevQuotePos = Math.max(text.lastIndexOf('"', 7), text.lastIndexOf("'", 7));
		return prevQuotePos === -1;
	}
	return false;
}

/**
 * Checks if a sequence of characters matches a given marker
 *
 * @param chars - Array of characters to check
 * @param pos - Position to start checking from
 * @param marker - Marker to match
 * @returns Whether the characters match the marker
 */
export function matchMarker(chars: Array<string>, pos: number, marker: string): boolean {
	if (pos + marker.length > chars.length) return false;

	// Check each character matches the marker
	for (let i = 0; i < marker.length; i++) {
		if (chars[pos + i] !== marker[i]) return false;
	}

	return true;
}
