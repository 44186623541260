import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {Logger} from '~/lib/Logger';
import type {GuildReadyData} from '~/records/GuildRecord';

const logger = new Logger('ChannelStateStore');

export type ChannelState = {
	isLoading: boolean;
	hasMoreMessages: boolean;
	failedToLoadMessages: boolean;
	scrollPosition: number;
	hasLoadedFirstPage: boolean;
	hasSeenNewMessages: boolean;
	lastKnownScrollHeight?: number;
};

const defaultChannelState: ChannelState = {
	isLoading: false,
	hasMoreMessages: true,
	failedToLoadMessages: false,
	scrollPosition: 0,
	hasLoadedFirstPage: false,
	hasSeenNewMessages: false,
};

type State = {
	channelStates: Record<string, ChannelState>;
	lastConnectionReset: number;
	currentChannelId: string | null;
	channelSelectionTimestamp: number;
};

const initialState: State = {
	channelStates: {},
	lastConnectionReset: 0,
	currentChannelId: null,
	channelSelectionTimestamp: 0,
};

class ChannelStateStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		try {
			switch (action.type) {
				case 'CONNECTION_OPEN':
					// Track connection reset time
					this.setState((prevState) => ({
						...prevState,
						lastConnectionReset: Date.now(),
					}));
					return this.handleConnectionOpen(action.guilds);

				case 'GUILD_CREATE':
					return this.handleGuildCreate(action.guild);

				case 'CHANNEL_CREATE':
					return this.handleChannelCreate(action.channel.id);

				case 'CHANNEL_DELETE':
					return this.handleChannelDelete(action.channel.id);

				case 'CHANNEL_SELECT':
					return this.handleChannelSelect(action.channelId);

				case 'CHANNEL_DESELECT':
					return this.handleChannelDeselect();

				case 'MESSAGES_FETCH_START':
					return this.handleMessagesFetchStart(action.channelId);

				case 'MESSAGES_FETCH_SUCCESS':
					return this.handleMessagesFetchSuccess(action.channelId, action.messages.length > 0, action.hasMore);

				case 'MESSAGES_FETCH_ERROR':
					return this.handleMessagesFetchError(action.channelId);

				case 'CHANNEL_SCROLL_POSITION_UPDATE':
					return this.handleScrollPositionUpdate(action.channelId, action.scrollPosition, action.scrollHeight);

				default:
					return false;
			}
		} catch (error) {
			logger.error('Error handling action:', error);
			return false;
		}
	}

	private handleConnectionOpen(guilds: ReadonlyArray<GuildReadyData>): boolean {
		// Clear all channel states on connection open
		this.setState({
			channelStates: {},
			lastConnectionReset: Date.now(),
			currentChannelId: null,
			channelSelectionTimestamp: 0,
		});

		// Initialize channel states from guilds
		const newChannelStates: Record<string, ChannelState> = {};
		guilds
			.filter((guild) => !guild.unavailable)
			.flatMap((guild) => guild.channels)
			.forEach((channel) => {
				newChannelStates[channel.id] = {...defaultChannelState};
			});

		this.setState((prevState) => ({
			...prevState,
			channelStates: {
				...prevState.channelStates,
				...newChannelStates,
			},
		}));
		return true;
	}

	private handleGuildCreate(guild: GuildReadyData): boolean {
		if (guild.unavailable) return false;

		const newStates: Record<string, ChannelState> = {};
		guild.channels.forEach((channel) => {
			newStates[channel.id] = {...defaultChannelState};
		});

		this.setState((prevState) => ({
			...prevState,
			channelStates: {
				...prevState.channelStates,
				...newStates,
			},
		}));

		return true;
	}

	private handleChannelCreate(channelId: string): boolean {
		// Initialize the channel state for a newly created channel
		this.setState((prevState) => ({
			...prevState,
			channelStates: {
				...prevState.channelStates,
				[channelId]: {...defaultChannelState},
			},
		}));
		return true;
	}

	private handleChannelDelete(channelId: string): boolean {
		this.setState((prevState) => {
			const {[channelId]: _, ...remaining} = prevState.channelStates;
			return {
				...prevState,
				channelStates: remaining,
				currentChannelId: prevState.currentChannelId === channelId ? null : prevState.currentChannelId,
			};
		});
		return true;
	}

	private handleChannelSelect(channelId: string): boolean {
		this.setState((prevState) => ({
			...prevState,
			currentChannelId: channelId,
			channelSelectionTimestamp: Date.now(),
		}));
		return true;
	}

	private handleChannelDeselect(): boolean {
		this.setState((prevState) => ({
			...prevState,
			currentChannelId: null,
		}));
		return true;
	}

	private handleMessagesFetchStart(channelId: string): boolean {
		this.setState((prevState) => {
			const channelState = prevState.channelStates[channelId] ?? {...defaultChannelState};
			return {
				...prevState,
				channelStates: {
					...prevState.channelStates,
					[channelId]: {
						...channelState,
						isLoading: true,
						failedToLoadMessages: false,
					},
				},
			};
		});
		return true;
	}

	private handleMessagesFetchSuccess(channelId: string, hasMessages: boolean, hasMore: boolean | undefined): boolean {
		this.setState((prevState) => {
			const channelState = prevState.channelStates[channelId] ?? {...defaultChannelState};
			return {
				...prevState,
				channelStates: {
					...prevState.channelStates,
					[channelId]: {
						...channelState,
						isLoading: false,
						failedToLoadMessages: false,
						hasMoreMessages: hasMore ?? hasMessages,
						hasLoadedFirstPage: true,
					},
				},
			};
		});
		return true;
	}

	private handleMessagesFetchError(channelId: string): boolean {
		this.setState((prevState) => {
			const channelState = prevState.channelStates[channelId] ?? {...defaultChannelState};
			return {
				...prevState,
				channelStates: {
					...prevState.channelStates,
					[channelId]: {
						...channelState,
						isLoading: false,
						failedToLoadMessages: true,
						hasLoadedFirstPage: true,
					},
				},
			};
		});
		return true;
	}

	private handleScrollPositionUpdate(channelId: string, scrollPosition: number, scrollHeight?: number): boolean {
		this.setState((prevState) => {
			const channelState = prevState.channelStates[channelId] ?? {...defaultChannelState};
			return {
				...prevState,
				channelStates: {
					...prevState.channelStates,
					[channelId]: {
						...channelState,
						scrollPosition,
						lastKnownScrollHeight: scrollHeight,
					},
				},
			};
		});
		return true;
	}

	useChannelState(channelId: string): ChannelState {
		const {channelStates} = this.useStore();
		return channelStates[channelId] ?? {...defaultChannelState};
	}

	useLastConnectionReset(): number {
		const {lastConnectionReset} = this.useStore();
		return lastConnectionReset;
	}

	useCurrentChannelId(): string | null {
		const {currentChannelId} = this.useStore();
		return currentChannelId;
	}

	useChannelSelectionTimestamp(): number {
		const {channelSelectionTimestamp} = this.useStore();
		return channelSelectionTimestamp;
	}
}

export default new ChannelStateStore();
