import clsx from 'clsx';
import type React from 'react';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import markupStyles from '~/styles/Markup.module.css';
import type {EmojiNode} from '../parser';
import type {RendererProps} from '../types';
import {getEmojiRenderData} from '../utils/emoji-detector';

/**
 * Renders an emoji node
 */
export function EmojiRenderer({node, key, options}: RendererProps<EmojiNode>): React.ReactElement {
	const {shouldJumboEmojis, guildId, messageId, disableAnimatedEmoji} = options;
	const emojiData = getEmojiRenderData(node, guildId, disableAnimatedEmoji);

	const className = clsx(markupStyles.emoji, shouldJumboEmojis && markupStyles.jumboable);

	const tooltipDelay = 750;
	const size = shouldJumboEmojis ? 240 : 96;
	const qualitySuffix = `?size=${size}&quality=lossless`;

	return (
		<Tooltip key={key} text={emojiData.name} delay={tooltipDelay} position="top">
			<img
				draggable={false}
				className={className}
				alt={emojiData.name}
				src={`${emojiData.url}${emojiData.id ? qualitySuffix : ''}`}
				data-message-id={messageId}
				data-emoji-id={emojiData.id}
				data-animated={emojiData.isAnimated}
			/>
		</Tooltip>
	);
}
