import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';
import type {ExpressionPickerTabType} from '~/stores/ExpressionPickerStore';

// Create a logger for expression picker operations
const logger = new Logger('ExpressionPicker');

/**
 * Selects a tab in the expression picker
 */
export const selectTab = (tab: ExpressionPickerTabType): void => {
	logger.debug(`Selecting expression picker tab: ${tab}`);
	Dispatcher.dispatch({type: 'EXPRESSION_PICKER_TAB_SELECT', tab});
};

/**
 * Closes the expression picker
 */
export const close = (): void => {
	logger.debug('Closing expression picker');
	Dispatcher.dispatch({type: 'POPOUT_CLOSE', key: 'expression-picker'});
};
