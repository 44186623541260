import {Camera, House, Link, Plus} from '@phosphor-icons/react';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as InviteActionCreators from '~/actions/InviteActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as FormUtils from '~/utils/FormUtils';
import * as InviteUtils from '~/utils/InviteUtils';
import * as RouterUtils from '~/utils/RouterUtils';

export type AddGuildModalView = 'landing' | 'create_guild' | 'join_guild';

type GuildCreateFormInputs = {
	icon?: string | null;
	name: string;
};

type GuildJoinFormInputs = {
	code: string;
};

const ActionButton = ({onClick, icon, label}: {onClick: () => void; icon: React.ReactNode; label: string}) => (
	<button
		type="button"
		className="translate flex flex-1 transform-gpu cursor-pointer flex-row items-center justify-center gap-2 rounded-md bg-brand-primary px-2 py-4 text-brand-primary-fill transition duration-300 ease-in-out hover:bg-brand-secondary active:translate-y-px"
		onClick={onClick}
	>
		{icon}
		<span className="font-semibold text-lg">{label}</span>
	</button>
);

export const AddGuildModal = () => {
	const [view, setView] = useState<AddGuildModalView>('landing');

	const getTitle = () => {
		switch (view) {
			case 'landing':
				return i18n.Messages.ADD_A_GUILD;
			case 'create_guild':
				return i18n.Messages.CREATE_GUILD_MODAL_TITLE;
			case 'join_guild':
				return i18n.Messages.JOIN_GUILD_MODAL_TITLE;
		}
	};

	return (
		<Modal.Root label={getTitle()} size="small">
			<Modal.Header title={getTitle()} />

			<Modal.Content className={styles.content}>
				{view === 'landing' && <LandingView onViewChange={setView} />}
				{view === 'create_guild' && <GuildCreateForm />}
				{view === 'join_guild' && <GuildJoinForm />}
			</Modal.Content>
		</Modal.Root>
	);
};

const LandingView = ({onViewChange}: {onViewChange: (view: AddGuildModalView) => void}) => (
	<div className="space-y-6">
		<p>{i18n.Messages.ADD_A_GUILD_DESCRIPTION}</p>

		<div className="flex w-full flex-col gap-3">
			<ActionButton
				onClick={() => onViewChange('create_guild')}
				icon={<House className="h-6 w-6" />}
				label={i18n.Messages.CREATE_GUILD}
			/>
			<ActionButton
				onClick={() => onViewChange('join_guild')}
				icon={<Link className="h-6 w-6" weight="regular" />}
				label={i18n.Messages.JOIN_GUILD}
			/>
		</div>
	</div>
);

const GuildCreateForm = () => {
	const [previewIconUrl, setPreviewIconUrl] = React.useState<string | null>(null);
	const form = useForm<GuildCreateFormInputs>({defaultValues: {name: ''}});
	const inputRef = React.useRef<HTMLInputElement>(null);

	const handleFileChange = React.useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			try {
				const file = event.target.files?.[0];
				if (!file) {
					return;
				}
				const base64 = await AvatarUtils.fileToBase64(file);
				event.target.value = '';
				form.setValue('icon', base64);
				setPreviewIconUrl(base64);
			} catch {
				form.setError('icon', {message: i18n.Messages.INVALID_IMAGE});
			}
		},
		[form],
	);

	const onSubmit = async (data: GuildCreateFormInputs) => {
		try {
			const guild = await GuildActionCreators.create({
				icon: data.icon,
				name: data.name,
			});
			ModalActionCreators.pop();
			RouterUtils.transitionTo(`/channels/${guild.id}/${guild.id}`);
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'name');
		}
	};

	return (
		<Form form={form} onSubmit={onSubmit} className="flex h-full flex-col">
			<div className="space-y-6">
				<p>{i18n.Messages.CREATE_GUILD_MODAL_DESCRIPTION}</p>

				<div className="mt-2 flex flex-col items-center gap-4">
					<div
						className="active:translate-z-0 relative inline-block h-20 w-20 transform-gpu cursor-pointer rounded-full bg-center bg-cover active:translate-y-px"
						style={{
							backgroundImage: previewIconUrl ? `url(${previewIconUrl})` : undefined,
						}}
					>
						{!previewIconUrl ? (
							<div className="absolute inset-0 flex items-center justify-center rounded-full border-4 border-text-tertiary border-dashed">
								<Plus weight="regular" className="h-8 w-8 text-text-tertiary" />
							</div>
						) : (
							<div className="absolute inset-0 flex items-center justify-center rounded-full bg-black/50 opacity-0 transition-opacity hover:opacity-100">
								<Camera weight="bold" className="h-8 w-8 text-white" />
							</div>
						)}
						<input
							accept="image/jpeg,image/png,image/gif,image/webp"
							onChange={handleFileChange}
							ref={inputRef}
							style={{display: 'none'}}
							type="file"
						/>
						<div
							aria-label={i18n.Messages.CHANGE_ICON}
							className="absolute inset-0 flex items-center justify-center"
							onClick={() => inputRef.current?.click()}
							onKeyDown={(event) => event.key === 'Enter' && inputRef.current?.click()}
							role="button"
							tabIndex={0}
						/>
					</div>

					<div className="flex flex-col items-center gap-1 text-center text-sm text-text-primary-muted">
						<p>{i18n.Messages.SUPPORTED_FORMATS}</p>
						<p>{i18n.Messages.RECOMMENDED_SIZE}</p>
					</div>

					{form.formState.errors.icon?.message && (
						<p className="text-sm text-text-danger">{form.formState.errors.icon.message}</p>
					)}
				</div>

				<Input
					{...form.register('name')}
					autoFocus={true}
					error={form.formState.errors.name?.message}
					label={i18n.Messages.NAME}
					minLength={1}
					maxLength={100}
					name="name"
					placeholder={i18n.Messages.GUILD_NAME_PLACEHOLDER}
					required={true}
					type="text"
				/>
			</div>

			<Modal.Footer>
				<div className={styles.footer}>
					<Button onClick={ModalActionCreators.pop} variant="ghost">
						{i18n.Messages.CANCEL}
					</Button>
					<Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
						{i18n.Messages.CREATE_GUILD}
					</Button>
				</div>
			</Modal.Footer>
		</Form>
	);
};

const GuildJoinForm = () => {
	const form = useForm<GuildJoinFormInputs>({defaultValues: {code: ''}});

	const onSubmit = async (data: GuildJoinFormInputs) => {
		try {
			const parsedCode = InviteUtils.findInvite(data.code) ?? data.code;
			const invite = await InviteActionCreators.fetch(parsedCode);
			await InviteActionCreators.acceptAndTransitionToChannel(invite.code);
			ModalActionCreators.pop();
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'code');
		}
	};

	return (
		<Form form={form} onSubmit={onSubmit} className="flex h-full flex-col">
			<div className="space-y-6">
				<p>{i18n.Messages.JOIN_GUILD_MODAL_DESCRIPTION}</p>

				<Input
					{...form.register('code')}
					autoFocus={true}
					error={form.formState.errors.code?.message}
					label={i18n.Messages.INVITE_LINK}
					minLength={1}
					maxLength={100}
					name="code"
					placeholder={`${location.protocol}//${location.host}/invite/${i18n.Messages.INVITE_LINK_PLACEHOLDER}`}
					required={true}
					type="text"
				/>
			</div>

			<Modal.Footer>
				<div className="flex justify-end gap-3">
					<Button onClick={ModalActionCreators.pop} variant="ghost">
						{i18n.Messages.CANCEL}
					</Button>
					<Button type="submit" submitting={form.formState.isSubmitting} variant="brand">
						{i18n.Messages.JOIN_GUILD}
					</Button>
				</div>
			</Modal.Footer>
		</Form>
	);
};
