import type React from 'react';
import * as InviteActionCreators from '~/actions/InviteActionCreators';
import markupStyles from '~/styles/Markup.module.css';
import * as InviteUtils from '~/utils/InviteUtils';
import * as RouterUtils from '~/utils/RouterUtils';
import type {LinkNode} from '../parser';
import type {RendererProps} from '../types';

/**
 * Renders a link node
 */
export function LinkRenderer({node, key, renderChildren}: RendererProps<LinkNode>): React.ReactElement {
	const {url, text} = node;

	// Check if this is an invite link
	const inviteCode = InviteUtils.findInvite(url);

	// Define click handler based on link type
	let handleClick: ((e: React.MouseEvent) => void) | undefined;

	if (inviteCode) {
		// Handle Discord invite links
		handleClick = (e) => {
			e.preventDefault();
			InviteActionCreators.acceptAndTransitionToChannel(inviteCode);
		};
	} else {
		// Check if this is an internal Discord link
		try {
			const parsed = new URL(url);
			if (parsed.host === location.host) {
				handleClick = (e) => {
					e.preventDefault();
					RouterUtils.transitionTo(parsed.pathname);
				};
			}
		} catch {
			// Not a valid URL, ignore
		}
	}

	// Use text node if provided, otherwise use the URL
	const content = text ? renderChildren([text]) : url;

	return (
		<a
			key={key}
			href={url}
			target="_blank"
			rel="noopener noreferrer"
			onClick={handleClick}
			className={markupStyles.link}
		>
			{content}
		</a>
	);
}
