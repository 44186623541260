import {SmileySad} from '@phosphor-icons/react';
import {useParams} from 'react-router-dom';
import {i18n} from '~/i18n';
import ChannelStore from '~/stores/ChannelStore';
import GuildStore from '~/stores/GuildStore';

export const ChannelLayout = ({children}: {children: React.ReactNode}) => {
	const {guildId, channelId} = useParams<{guildId: string; channelId: string}>();
	const guild = GuildStore.useGuild(guildId);
	const channel = ChannelStore.useChannel(channelId);

	if (guild && !channel) {
		return (
			<div className="flex h-full min-h-0 w-full min-w-0 flex-col items-center justify-center gap-4 px-8">
				<div className="flex flex-col items-center gap-1 text-center">
					<SmileySad className="h-16 w-16 text-text-tertiary" />
					<h1 className="font-medium text-2xl text-text-primary">{i18n.Messages.CHANNEL_NOT_FOUND_TITLE}</h1>
					<p className="text-text-tertiary">{i18n.Messages.CHANNEL_NOT_FOUND_DESCRIPTION}</p>
				</div>
			</div>
		);
	}

	return <div className="grid h-full min-h-0 w-full min-w-0 bg-background-tertiary">{children}</div>;
};
