import {Endpoints} from '~/Endpoints';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';

// Create a logger for notes operations
const logger = new Logger('Notes');

/**
 * Update or clear a note for a user
 */
export const update = async (userId: string, note: string | null): Promise<void> => {
	try {
		await http.put({
			url: Endpoints.USER_NOTE(userId),
			body: {note},
		});

		logger.debug(`Updated note for user ${userId} to ${note ? 'new value' : 'null'}`);
	} catch (error) {
		logger.error(`Failed to update note for user ${userId}:`, error);
		throw error;
	}
};
