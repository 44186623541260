import type {ToastProps} from '~/components/uikit/Toast';
import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';

// Create a logger for toast operations
const logger = new Logger('Toast');

/**
 * Creates a new toast notification
 * @returns The ID of the created toast
 */
export const createToast = (data: ToastProps): string => {
	const id = crypto.randomUUID();
	logger.debug(`Creating toast: ${id}, type: ${data.type}`);
	Dispatcher.dispatch({type: 'TOAST_SET', id, data});
	return id;
};

/**
 * Updates an existing toast notification
 */
export const updateToast = (id: string, data: ToastProps): void => {
	logger.debug(`Updating toast: ${id}, type: ${data.type}`);
	Dispatcher.dispatch({type: 'TOAST_SET', id, data});
};

/**
 * Destroys a toast notification
 */
export const destroyToast = (id: string): void => {
	logger.debug(`Destroying toast: ${id}`);
	Dispatcher.dispatch({type: 'TOAST_DELETE', id});
};

/**
 * Creates a success toast
 */
export const success = (message: string): string => {
	return createToast({
		type: 'success',
		children: message,
		timeout: 3000,
	});
};

/**
 * Creates an error toast
 */
export const error = (message: string): string => {
	return createToast({
		type: 'error',
		children: message,
		timeout: 5000,
	});
};
