import {Endpoints} from '~/Endpoints';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {AttachmentUploadFailedModal} from '~/components/alerts/AttachmentUploadFailedModal';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import type {UploadAttachment} from '~/stores/UploadAttachmentStore';
import UploadAttachmentStore from '~/stores/UploadAttachmentStore';

// File name patterns
const FILENAME_SPOILER_REGEX = /^SPOILER_/;

// Unique ID generator for attachments
let nextAttachmentId = 1;

// Create a dedicated logger
const logger = new Logger('Attachments');

// File type utilities
const isImage = (file: File) => file.type.startsWith('image/');
const isVideo = (file: File) => file.type.startsWith('video/');
const isMedia = (file: File) =>
	file.type.startsWith('image/') || file.type.startsWith('video/') || file.type.startsWith('audio/');

/**
 * Get dimensions for image files
 */
const getImageDimensions = async (file: File): Promise<{width: number; height: number}> => {
	return new Promise((resolve, reject) => {
		const url = URL.createObjectURL(file);
		const img = new Image();

		img.onload = () => {
			const {naturalWidth: width, naturalHeight: height} = img;
			URL.revokeObjectURL(url);
			resolve({width, height});
		};

		img.onerror = (error) => {
			URL.revokeObjectURL(url);
			reject(error);
		};

		img.src = url;
	});
};

/**
 * Get dimensions for video files
 */
const getVideoDimensions = (file: File): Promise<{width: number; height: number}> => {
	return new Promise((resolve, reject) => {
		const url = URL.createObjectURL(file);
		const video = document.createElement('video');

		video.addEventListener('loadedmetadata', () => {
			const {videoWidth: width, videoHeight: height} = video;
			URL.revokeObjectURL(url);
			resolve({width, height});
		});

		video.addEventListener('error', (e) => {
			URL.revokeObjectURL(url);
			reject(e);
		});

		video.src = url;
	});
};

/**
 * Create attachment objects from files
 */
const createAttachments = async (channelId: string, files: Array<File>): Promise<Array<UploadAttachment>> => {
	return Promise.all(
		files.map(async (file) => {
			let width = 0;
			let height = 0;

			try {
				if (isImage(file)) {
					({width, height} = await getImageDimensions(file));
				} else if (isVideo(file)) {
					({width, height} = await getVideoDimensions(file));
				}
			} catch (error) {
				logger.warn('Error getting file dimensions:', error);
			}

			// Create preview URL only for media files
			const previewURL = isMedia(file) ? URL.createObjectURL(file) : null;

			return {
				id: nextAttachmentId++,
				channelId,
				file,
				filename: file.name.replace(FILENAME_SPOILER_REGEX, ''),
				spoiler: file.name.startsWith('SPOILER_'),
				previewURL,
				status: 'pending',
				width,
				height,
			};
		}),
	);
};

/**
 * Upload a single attachment using the provided upload URL
 */
const uploadAttachmentContent = async (channelId: string, attachment: UploadAttachment): Promise<void> => {
	if (!attachment.uploadURL) {
		throw new Error('Missing upload URL for attachment');
	}

	Dispatcher.dispatch({
		type: 'UPLOAD_ATTACHMENT_START_UPLOAD',
		channelId,
		attachmentId: attachment.id,
	});

	try {
		// Simulate slow upload if developer option is enabled
		if (DeveloperOptionsStore.getSlowAttachmentUpload()) {
			await new Promise((resolve) => setTimeout(resolve, 5000));
		}

		await http.request('PUT', {
			url: attachment.uploadURL,
			body: await attachment.file.arrayBuffer(),
			headers: {
				'Content-Type': 'application/octet-stream',
				'Content-Length': attachment.file.size.toString(),
			},
			skipAuth: true, // Don't add auth token to pre-signed URL
			retries: 2, // Retry failed uploads
			timeout: 60000, // Longer timeout for large files
		});

		Dispatcher.dispatch({
			type: 'UPLOAD_ATTACHMENT_UPLOAD_COMPLETE',
			channelId,
			attachmentId: attachment.id,
			uploadURL: attachment.uploadURL,
			uploadFilename: attachment.uploadFilename!,
		});
	} catch (error) {
		logger.error('Upload failed:', error);

		ModalActionCreators.push(() => <AttachmentUploadFailedModal />);

		Dispatcher.dispatch({
			type: 'UPLOAD_ATTACHMENT_UPLOAD_FAILED',
			channelId,
			attachmentId: attachment.id,
		});

		throw error;
	}
};

/**
 * Request upload URLs for attachments
 */
const requestUploadUrls = async (
	channelId: string,
	attachments: Array<UploadAttachment>,
): Promise<Array<UploadAttachment>> => {
	try {
		// Simulate failure if developer option is enabled
		if (DeveloperOptionsStore.getForceFailUploads()) {
			throw new Error('Forced upload failure');
		}

		const response = await http.post<{
			attachments: Array<{id: number; upload_url: string; upload_filename: string}>;
		}>({
			url: Endpoints.CHANNEL_ATTACHMENTS(channelId),
			body: {
				files: attachments.map((attachment) => ({
					id: attachment.id,
					filename: attachment.file.name,
					file_size: attachment.file.size,
				})),
			},
			retries: 3, // Retry on server errors
		});

		const {attachments: uploadUrls} = response.data;

		// Associate upload URLs with attachments
		return attachments.map((attachment) => {
			const uploadData = uploadUrls.find((u) => u.id === attachment.id);

			if (!uploadData) {
				logger.warn(`No upload URL received for attachment ${attachment.id}`);
				return attachment;
			}

			return {
				...attachment,
				uploadURL: uploadData.upload_url,
				uploadFilename: uploadData.upload_filename,
			};
		});
	} catch (error) {
		logger.error('Failed to request upload URLs:', error);
		throw error;
	}
};

/**
 * Upload files to a channel
 */
export const upload = async ({channelId, files}: {channelId: string; files: Array<File>}): Promise<void> => {
	if (!files.length) {
		logger.warn('No files to upload');
		return;
	}

	// Create attachment objects
	const attachments = await createAttachments(channelId, files);

	// Dispatch creation to store
	Dispatcher.dispatch({
		type: 'UPLOAD_ATTACHMENT_CREATE',
		channelId,
		attachments,
	});

	try {
		// Request upload URLs from server
		const updatedAttachments = await requestUploadUrls(channelId, attachments);

		// Update attachments with upload URLs
		for (const attachment of updatedAttachments) {
			Dispatcher.dispatch({
				type: 'UPLOAD_ATTACHMENT_UPDATE',
				channelId,
				attachmentId: attachment.id,
				patch: attachment,
			});
		}

		// Start uploads in parallel
		await Promise.allSettled(
			updatedAttachments.map((attachment) => {
				// Only upload attachments that have a URL
				if (attachment.uploadURL) {
					return uploadAttachmentContent(channelId, attachment);
				}

				// Mark as failed if no upload URL
				Dispatcher.dispatch({
					type: 'UPLOAD_ATTACHMENT_UPLOAD_FAILED',
					channelId,
					attachmentId: attachment.id,
				});

				return Promise.resolve();
			}),
		);
	} catch (error) {
		logger.error('Upload process failed:', error);

		// Show error modal
		ModalActionCreators.push(() => <AttachmentUploadFailedModal />);

		// Mark all attachments as failed
		for (const attachment of attachments) {
			Dispatcher.dispatch({
				type: 'UPLOAD_ATTACHMENT_UPLOAD_FAILED',
				channelId,
				attachmentId: attachment.id,
			});
		}
	}
};

/**
 * Update attachment properties
 */
export const update = ({
	channelId,
	attachmentId,
	filename,
	spoiler,
}: {
	channelId: string;
	attachmentId: number;
	filename?: string;
	spoiler?: boolean;
}): void => {
	Dispatcher.dispatch({
		type: 'UPLOAD_ATTACHMENT_UPDATE',
		channelId,
		attachmentId,
		patch: {filename, spoiler},
	});
};

/**
 * Remove an attachment
 */
export const remove = ({channelId, attachmentId}: {channelId: string; attachmentId: number}): void => {
	// Clean up any object URLs to prevent memory leaks
	const attachment = UploadAttachmentStore.getUploadAttachments(channelId).find((a) => a.id === attachmentId);
	if (attachment?.previewURL) {
		URL.revokeObjectURL(attachment.previewURL);
	}

	Dispatcher.dispatch({
		type: 'UPLOAD_ATTACHMENT_DELETE',
		channelId,
		attachmentId,
	});
};

/**
 * Replace all attachments for a channel
 */
export const replace = ({channelId, attachments}: {channelId: string; attachments: Array<UploadAttachment>}): void => {
	// Clean up old preview URLs
	const oldAttachments = UploadAttachmentStore.getUploadAttachments(channelId);
	for (const attachment of oldAttachments) {
		if (attachment.previewURL) {
			URL.revokeObjectURL(attachment.previewURL);
		}
	}

	Dispatcher.dispatch({
		type: 'UPLOAD_ATTACHMENT_REPLACE',
		channelId,
		attachments,
	});
};

/**
 * Retry a failed attachment upload
 */
export const retryUpload = async ({
	channelId,
	attachmentId,
}: {
	channelId: string;
	attachmentId: number;
}): Promise<void> => {
	const attachment = UploadAttachmentStore.getUploadAttachments(channelId).find((a) => a.id === attachmentId);

	if (!attachment) {
		logger.error(`Attachment ${attachmentId} not found`);
		return;
	}

	// Update status to pending
	Dispatcher.dispatch({
		type: 'UPLOAD_ATTACHMENT_UPDATE',
		channelId,
		attachmentId,
		patch: {status: 'pending'},
	});

	try {
		// If we don't have an upload URL, we need to request one again
		if (!attachment.uploadURL) {
			const updatedAttachments = await requestUploadUrls(channelId, [attachment]);

			if (updatedAttachments[0].uploadURL) {
				Dispatcher.dispatch({
					type: 'UPLOAD_ATTACHMENT_UPDATE',
					channelId,
					attachmentId,
					patch: {
						uploadURL: updatedAttachments[0].uploadURL,
						uploadFilename: updatedAttachments[0].uploadFilename,
					},
				});

				await uploadAttachmentContent(channelId, updatedAttachments[0]);
			} else {
				throw new Error('Failed to get upload URL');
			}
		} else {
			await uploadAttachmentContent(channelId, attachment);
		}
	} catch (error) {
		logger.error(`Retry failed for attachment ${attachmentId}:`, error);
	}
};

/**
 * Clean up resources when leaving a channel
 */
export const cleanupChannel = (channelId: string): void => {
	// Revoke any object URLs to prevent memory leaks
	const attachments = UploadAttachmentStore.getUploadAttachments(channelId);

	for (const attachment of attachments) {
		if (attachment.previewURL) {
			URL.revokeObjectURL(attachment.previewURL);
		}
	}

	// Reset attachment counter if we're cleaning up all channels
	if (!channelId) {
		nextAttachmentId = 1;
	}
};
