import React from 'react';
import {useForm} from 'react-hook-form';
import {Link} from 'react-router-dom';
import type {RegisterData} from '~/actions/AuthenticationActionCreators';
import * as AuthenticationActionCreators from '~/actions/AuthenticationActionCreators';
import {Card, CardWrapper} from '~/components/form/Card';
import {Form} from '~/components/form/Form';
import {Checkbox, Input} from '~/components/form/Input';
import {Button} from '~/components/uikit/Button/Button';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import * as FormUtils from '~/utils/FormUtils';
import * as RouterUtils from '~/utils/RouterUtils';

const VALIDATION = {
	USERNAME: {
		MIN: 2,
		MAX: 32,
		PATTERN: /^[a-z0-9_.]+$/,
		CONSECUTIVE_PERIODS: /\.{2,}/,
	},
	NICKNAME: {
		MIN: 1,
		MAX: 32,
	},
	PASSWORD: {
		MIN: 8,
		MAX: 256,
	},
	EMAIL: {
		MAX: 254,
	},
} as const;

const validateUsername = (value: string) => {
	if (!value) return 'Username is required';
	if (value.length < VALIDATION.USERNAME.MIN) return `Username must be at least ${VALIDATION.USERNAME.MIN} characters`;
	if (value.length > VALIDATION.USERNAME.MAX) return `Username must be at most ${VALIDATION.USERNAME.MAX} characters`;
	if (!VALIDATION.USERNAME.PATTERN.test(value)) {
		return 'Username can only contain lowercase letters (a-z), numbers (0-9), periods (.), and underscores (_)';
	}
	if (VALIDATION.USERNAME.CONSECUTIVE_PERIODS.test(value)) {
		return 'Username cannot contain consecutive periods (..)';
	}
	if (value.toLowerCase().includes('flux')) {
		return 'Username cannot contain "flux"';
	}
	return true;
};

export const RegisterPage = () => {
	const [hasAgreedToTerms, setHasAgreedToTerms] = React.useState(false);

	const form = useForm<RegisterData>({
		mode: 'onBlur',
		defaultValues: {
			email: '',
			username: '',
			nickname: '',
			password: '',
			beta_code: '',
		},
		resolver: async (values) => {
			try {
				if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
					return {
						values: {},
						errors: {
							email: {
								type: 'validation',
								message: 'Please enter a valid email address',
							},
						},
					};
				}

				const usernameValidation = validateUsername(values.username);
				if (usernameValidation !== true) {
					return {
						values: {},
						errors: {
							username: {
								type: 'validation',
								message: usernameValidation,
							},
						},
					};
				}

				if (
					values.nickname &&
					(values.nickname.length < VALIDATION.NICKNAME.MIN || values.nickname.length > VALIDATION.NICKNAME.MAX)
				) {
					return {
						values: {},
						errors: {
							nickname: {
								type: 'validation',
								message: `Display name must be between ${VALIDATION.NICKNAME.MIN} and ${VALIDATION.NICKNAME.MAX} characters`,
							},
						},
					};
				}

				if (
					!values.password ||
					values.password.length < VALIDATION.PASSWORD.MIN ||
					values.password.length > VALIDATION.PASSWORD.MAX
				) {
					return {
						values: {},
						errors: {
							password: {
								type: 'validation',
								message: `Password must be between ${VALIDATION.PASSWORD.MIN} and ${VALIDATION.PASSWORD.MAX} characters`,
							},
						},
					};
				}

				return {
					values,
					errors: {},
				};
			} catch (error) {
				console.error('An error occurred during validation', error);
				return {
					values: {},
					errors: {
						root: {
							type: 'validation',
							message: 'An error occurred during validation',
						},
					},
				};
			}
		},
	});

	const onSubmit = async (data: RegisterData) => {
		try {
			const response = await AuthenticationActionCreators.register(data);
			Dispatcher.dispatch({type: 'SESSION_START', token: response.token});
			RouterUtils.replaceWith('/');
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'email');
		}
	};

	const handleUsernameChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			// Only allow lowercase characters in the UI input
			const normalizedValue = value.toLowerCase().replace(/[^a-z0-9_.]/g, '');
			form.setValue('username', normalizedValue);
		},
		[form],
	);

	return (
		<CardWrapper>
			<Card title={i18n.Messages.REGISTER_TITLE} description={i18n.Messages.REGISTER_DESCRIPTION}>
				<Form className="flex w-full flex-col gap-4" form={form} onSubmit={onSubmit}>
					<div className="flex flex-col">
						<Input
							{...form.register('email')}
							autoComplete="email"
							autoFocus={true}
							error={form.formState.errors.email?.message}
							label={i18n.Messages.EMAIL}
							maxLength={VALIDATION.EMAIL.MAX}
							minLength={1}
							placeholder={i18n.Messages.EMAIL_PLACEHOLDER}
							required={true}
							type="email"
						/>
					</div>

					<Input
						{...form.register('nickname')}
						footer={<p className="text-sm text-text-primary-muted">{i18n.Messages.DISPLAY_NAME_HELPER_TEXT}</p>}
						label={i18n.Messages.DISPLAY_NAME}
						maxLength={VALIDATION.NICKNAME.MAX}
						minLength={VALIDATION.NICKNAME.MIN}
						placeholder={form.watch('username') || i18n.Messages.DISPLAY_NAME_PLACEHOLDER}
						type="text"
					/>

					<div className="flex flex-col">
						<Input
							{...form.register('username')}
							autoComplete="username"
							error={form.formState.errors.username?.message}
							label={i18n.Messages.USERNAME}
							footer={
								<p className="text-sm text-text-primary-muted">
									Use only lowercase letters (a-z), numbers (0-9), periods (.), and underscores (_). No consecutive
									periods allowed.
								</p>
							}
							maxLength={VALIDATION.USERNAME.MAX}
							minLength={VALIDATION.USERNAME.MIN}
							name="username"
							placeholder="cooluser.name"
							required={true}
							type="text"
							onChange={handleUsernameChange}
						/>
					</div>

					<Input
						{...form.register('password')}
						autoComplete="new-password"
						error={form.formState.errors.password?.message}
						label={i18n.Messages.PASSWORD}
						maxLength={VALIDATION.PASSWORD.MAX}
						minLength={VALIDATION.PASSWORD.MIN}
						placeholder={'•'.repeat(32)}
						required={true}
						type="password"
					/>

					{import.meta.env.MODE !== 'development' && (
						<Input
							{...form.register('beta_code')}
							error={form.formState.errors.beta_code?.message}
							label={i18n.Messages.BETA_CODE}
							maxLength={256}
							minLength={1}
							placeholder={i18n.Messages.BETA_CODE_PLACEHOLDER}
							required={false}
							type="text"
						/>
					)}

					<Checkbox
						checkboxLabel={
							<>
								{i18n.Messages.LEGAL_AGREEMENT_TEXT}{' '}
								<Link className="text-text-link hover:underline" to=".">
									{i18n.Messages.LEGAL_AGREEMENT_LINK}
								</Link>
							</>
						}
						label={i18n.Messages.LEGAL_AGREEMENT}
						name="legalAgreement"
						onChange={(event) => setHasAgreedToTerms(event.target.checked)}
					/>

					<div className="flex flex-col gap-2.5">
						<Button type="submit" disabled={!hasAgreedToTerms} submitting={form.formState.isSubmitting}>
							{i18n.Messages.ACTIVATE_TIME_CIRCUITS}
						</Button>

						<div className="text-sm text-text-tertiary">
							{i18n.Messages.ALREADY_HAVE_AN_ACCOUNT}{' '}
							<Link className="text-text-link hover:underline" to="/login">
								{i18n.Messages.LOGIN}
							</Link>
						</div>
					</div>
				</Form>
			</Card>
		</CardWrapper>
	);
};
