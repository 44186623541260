import {DndContext, type DragEndEvent, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {restrictToHorizontalAxis} from '@dnd-kit/modifiers';
import {SortableContext, arrayMove, horizontalListSortingStrategy, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {ArrowsClockwise, Eye, EyeSlash, File, type Icon, Pencil, Trash} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import * as AttachmentActionCreators from '~/actions/AttachmentActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import styles from '~/components/channel/ChannelAttachmentArea.module.css';
import {AttachmentEditModal} from '~/components/modals/AttachmentEditModal';
import * as Modal from '~/components/modals/Modal';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {i18n} from '~/i18n';
import {ComponentDispatch} from '~/lib/ComponentDispatch';
import UploadAttachmentStore, {type UploadAttachment} from '~/stores/UploadAttachmentStore';
import * as DimensionUtils from '~/utils/DimensionUtils';

const useVideoThumbnail = (videoFile: File) => {
	const [thumbnail, setThumbnail] = React.useState<string | null>(null);

	React.useEffect(() => {
		const video = document.createElement('video');
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');
		if (!ctx) {
			return;
		}

		video.src = URL.createObjectURL(videoFile);

		video.addEventListener('loadeddata', () => {
			video.currentTime = 0;
		});

		video.addEventListener('seeked', () => {
			canvas.width = video.videoWidth;
			canvas.height = video.videoHeight;
			ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
			const thumbnailUrl = canvas.toDataURL('image/png');
			setThumbnail(thumbnailUrl);
			URL.revokeObjectURL(video.src);
		});
	}, [videoFile]);

	return thumbnail;
};

const VideoPreviewModal = ({src}: {src: string}) => (
	<Modal.Root label="Video" className="flex items-center justify-center rounded-none bg-transparent">
		<div className="relative">
			{/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
			<video controls={true} src={src} className="max-h-full max-w-full rounded-md" />
		</div>
	</Modal.Root>
);

const SortableAttachmentItem = ({
	attachment,
	channelId,
	isSortingList = false,
}: {
	attachment: UploadAttachment;
	channelId: string;
	isSortingList?: boolean;
}) => {
	const [spoilerHidden, setSpoilerHidden] = React.useState(true);
	const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: attachment.id});

	const handleClick = () => {
		if (attachment.spoiler && spoilerHidden) {
			setSpoilerHidden(false);
			return;
		}

		if (attachment.file.type.startsWith('image/')) {
			ModalActionCreators.push(() => (
				<ImagePreviewModal
					src={attachment.previewURL!}
					naturalWidth={attachment.width}
					naturalHeight={attachment.height}
				/>
			));
		} else if (attachment.file.type.startsWith('video/')) {
			ModalActionCreators.push(() => <VideoPreviewModal src={URL.createObjectURL(attachment.file)} />);
		}
	};

	const handleRetry = () => {
		AttachmentActionCreators.retryUpload({channelId, attachmentId: attachment.id});
	};

	const containerStyle = {
		width: '200px',
		height: '200px',
		position: 'relative' as const,
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0.5 : 1,
		cursor: isDragging ? 'grabbing' : 'default',
	};

	return (
		<li {...attributes} {...listeners} ref={setNodeRef} style={containerStyle} className={styles.upload} tabIndex={-1}>
			<div className={styles.uploadContainer}>
				{attachment.previewURL ? (
					<div className={styles.mediaContainer}>
						<div
							className={styles.clickableMedia}
							role="button"
							tabIndex={0}
							onClick={handleClick}
							onKeyDown={(event) => event.key === 'Enter' && handleClick()}
						>
							<div
								className={clsx(
									styles.spoilerContainer,
									attachment.spoiler && spoilerHidden && styles.hidden,
									attachment.spoiler && spoilerHidden && styles.hiddenSpoiler,
								)}
								aria-expanded={!spoilerHidden}
								aria-label={i18n.Messages.SPOILER}
								role="presentation"
								tabIndex={-1}
							>
								{attachment.spoiler && spoilerHidden && (
									<div className={clsx(styles.spoilerWarning, styles.obscureWarning)}>{i18n.Messages.SPOILER}</div>
								)}

								<div className={styles.spoilerInnerContainer} aria-hidden={spoilerHidden}>
									<div className={styles.spoilerWrapper}>
										{attachment.file.type.startsWith('image/') ? (
											<img
												src={attachment.previewURL}
												className={clsx(styles.media, attachment.spoiler && spoilerHidden && styles.spoiler)}
												aria-hidden={true}
												alt={attachment.filename}
											/>
										) : attachment.file.type.startsWith('video/') ? (
											<VideoThumbnail file={attachment.file} spoiler={attachment.spoiler && spoilerHidden} />
										) : null}
										<div className={styles.tags}>
											{attachment.spoiler && !spoilerHidden && (
												<span className={styles.altTag}>{i18n.Messages.SPOILER}</span>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className={styles.icon}>
						<File className={styles.iconImage} aria-label={attachment.filename} />
					</div>
				)}

				<div className={styles.filenameContainer}>
					<div className={styles.filename}>{attachment.filename}</div>
				</div>

				{!isSortingList && (
					<div className={styles.actionBarContainer}>
						{attachment.status === 'failed' ? (
							<div className={styles.actionBar}>
								<AttachmentActionBarButton icon={ArrowsClockwise} label={i18n.Messages.RETRY} onClick={handleRetry} />
								<AttachmentActionBarButton
									icon={Trash}
									label={i18n.Messages.REMOVE_ATTACHMENT}
									danger={true}
									onClick={() => AttachmentActionCreators.remove({channelId, attachmentId: attachment.id})}
								/>
							</div>
						) : (
							<AttachmentActionBar channelId={channelId} attachment={attachment} />
						)}
					</div>
				)}

				{attachment.status === 'uploading' && (
					<div className={styles.loadingOverlay}>
						<div className={styles.spinner} />
					</div>
				)}
			</div>
		</li>
	);
};

export const ChannelAttachmentArea = ({channelId}: {channelId: string}) => {
	const attachments = UploadAttachmentStore.useUploadAttachments(channelId);
	const prevAttachments = React.useRef(attachments);
	const [isDragging, setIsDragging] = React.useState(false);
	const sensors = useSensors(useSensor(PointerSensor, {activationConstraint: {distance: 8}}));

	const handleDragEnd = (event: DragEndEvent) => {
		const {active, over} = event;
		if (over && active.id !== over.id) {
			const oldIndex = attachments.findIndex((attachment) => attachment.id === active.id);
			const newIndex = attachments.findIndex((attachment) => attachment.id === over.id);
			const newArray = arrayMove(attachments.slice(), oldIndex, newIndex);
			AttachmentActionCreators.replace({channelId, attachments: newArray});
		}
		setIsDragging(false);
	};

	React.useEffect(() => {
		if (prevAttachments.current.length !== attachments.length) {
			ComponentDispatch.dispatch('SCROLL_TO_BOTTOM');
			prevAttachments.current = attachments;
		}
	}, [attachments]);

	if (attachments.length === 0) {
		return null;
	}

	return (
		<>
			<DndContext
				sensors={sensors}
				modifiers={[restrictToHorizontalAxis]}
				onDragEnd={handleDragEnd}
				onDragStart={() => setIsDragging(true)}
			>
				<SortableContext
					items={attachments.map((attachment) => attachment.id)}
					strategy={horizontalListSortingStrategy}
				>
					<ul className={styles.channelAttachmentArea}>
						{attachments.map((attachment) => (
							<SortableAttachmentItem
								key={attachment.id}
								attachment={attachment}
								channelId={channelId}
								isSortingList={isDragging}
							/>
						))}
					</ul>
				</SortableContext>
			</DndContext>
			<div className="h-px bg-background-modifier-hover" />
		</>
	);
};

const VideoThumbnail = ({file, spoiler}: {file: File; spoiler: boolean}) => {
	const thumbnailUrl = useVideoThumbnail(file);
	return (
		<img
			src={thumbnailUrl || ''}
			alt={file.name}
			className={clsx('h-full w-full object-cover', spoiler && styles.spoiler)}
		/>
	);
};

const AttachmentActionBarButton = ({
	label,
	icon: Icon,
	onClick,
	danger = false,
}: {
	label: string;
	icon: Icon;
	onClick: (event: React.MouseEvent | React.KeyboardEvent) => void;
	danger?: boolean;
}) => {
	const handleClick = (event: React.MouseEvent | React.KeyboardEvent) => {
		event.preventDefault();
		event.stopPropagation();
		onClick(event);
	};

	return (
		<Tooltip text={label}>
			<div
				aria-label={label}
				onClick={handleClick}
				onKeyDown={(event) => event.key === 'Enter' && handleClick(event)}
				role="button"
				tabIndex={0}
				className={clsx(styles.button, danger && styles.danger)}
			>
				<Icon className={styles.actionBarIcon} />
			</div>
		</Tooltip>
	);
};

const AttachmentActionBar = ({channelId, attachment}: {channelId: string; attachment: UploadAttachment}) => (
	<div className={styles.actionBarContainer}>
		<div aria-label={i18n.Messages.ATTACHMENT_ACTIONS} className={styles.actionBar}>
			<AttachmentActionBarButton
				icon={attachment.spoiler ? EyeSlash : Eye}
				label={attachment.spoiler ? i18n.Messages.UNSPOILER_ATTACHMENT : i18n.Messages.SPOILER_ATTACHMENT}
				onClick={() =>
					AttachmentActionCreators.update({channelId, attachmentId: attachment.id, spoiler: !attachment.spoiler})
				}
			/>
			<AttachmentActionBarButton
				icon={Pencil}
				label={i18n.Messages.EDIT_ATTACHMENT}
				onClick={() =>
					ModalActionCreators.push(() => <AttachmentEditModal channelId={channelId} attachment={attachment} />)
				}
			/>
			<AttachmentActionBarButton
				icon={Trash}
				label={i18n.Messages.REMOVE_ATTACHMENT}
				danger={true}
				onClick={() => AttachmentActionCreators.remove({channelId, attachmentId: attachment.id})}
			/>
		</div>
	</div>
);

const mediaCalculator = DimensionUtils.createCalculator({
	maxWidth: 712,
	maxHeight: 712,
	responsive: true,
});

const ImagePreviewModal = ({
	src,
	naturalWidth,
	naturalHeight,
}: {
	src: string;
	naturalWidth: number;
	naturalHeight: number;
}) => {
	const {dimensions} = mediaCalculator.calculate(
		{
			width: naturalWidth,
			height: naturalHeight,
		},
		{forceScale: true},
	);
	const newWidth = dimensions.width;
	const newHeight = dimensions.height;
	return (
		<Modal.Root label="Image" className="flex items-center justify-center rounded-none bg-transparent">
			<div className="relative">
				<div className="relative block select-text overflow-hidden" style={{width: newWidth, height: newHeight}}>
					<div className="h-full w-full" style={{aspectRatio: `${newWidth / newHeight} / 1`}}>
						<img alt="" src={src} style={{width: newWidth, height: newHeight}} />
					</div>
				</div>
			</div>
		</Modal.Root>
	);
};
