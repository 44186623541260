import {NodeType, type ParserResult, TimestampStyle} from '../types';

/**
 * Parses a Discord timestamp notation <t:timestamp:style>
 *
 * @param text - The text to parse
 * @returns Parser result or null if no timestamp found
 */
export function parseTimestamp(text: string): ParserResult | null {
	// Timestamps start with "<t:" and end with ">"
	if (!text.startsWith('<t:')) return null;

	const end = text.indexOf('>');
	if (end === -1) return null;

	const inner = text.slice(3, end);

	// Check if there are more than 2 parts (timestamp:style:extra)
	// If so, this is not a valid timestamp
	const allParts = inner.split(':');
	if (allParts.length > 2) return null;

	const [timestampPart, stylePart] = allParts;

	// Strict validation - timestamp must contain only digits
	if (!/^\d+$/.test(timestampPart)) return null;

	// Convert to number after strict validation
	const timestamp = Number(timestampPart);

	// Validate timestamp - must be positive
	if (timestamp === 0) return null;

	let style: TimestampStyle;
	if (stylePart !== undefined) {
		// Handle empty style part (e.g., <t:1618953630:>)
		if (stylePart === '') return null;

		// Get style from the first character
		const styleChar = stylePart[0];
		const parsedStyle = getTimestampStyle(styleChar);
		if (!parsedStyle) return null;

		style = parsedStyle;
	} else {
		// Default style if none provided
		style = TimestampStyle.ShortDateTime;
	}

	return {
		node: {
			type: NodeType.Timestamp,
			timestamp,
			style,
		},
		advance: end + 1,
	};
}

/**
 * Maps a style character to its corresponding TimestampStyle
 *
 * @param char - The style character (t, T, d, D, f, F, R)
 * @returns The corresponding TimestampStyle or null if invalid
 */
export function getTimestampStyle(char: string): TimestampStyle | null {
	switch (char) {
		case 't':
			return TimestampStyle.ShortTime;
		case 'T':
			return TimestampStyle.LongTime;
		case 'd':
			return TimestampStyle.ShortDate;
		case 'D':
			return TimestampStyle.LongDate;
		case 'f':
			return TimestampStyle.ShortDateTime;
		case 'F':
			return TimestampStyle.LongDateTime;
		case 'R':
			return TimestampStyle.RelativeTime;
		default:
			return null;
	}
}
