import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import {type Profile, ProfileRecord} from '~/records/ProfileRecord';
import UserProfileStore from '~/stores/UserProfileStore';

// Create a logger for user profile operations
const logger = new Logger('UserProfiles');

/**
 * Fetch a user's profile, optionally in the context of a guild
 */
export const fetch = async (userId: string, guildId?: string): Promise<ProfileRecord> => {
	try {
		// Check cache first
		const existingProfile = UserProfileStore.getProfile(userId, guildId);
		if (existingProfile) {
			logger.debug(`Using cached profile for user ${userId}${guildId ? ` in guild ${guildId}` : ''}`);
			return existingProfile;
		}

		logger.debug(`Fetching profile for user ${userId}${guildId ? ` in guild ${guildId}` : ''}`);
		const {data: profile} = await http.get<Profile>({
			url: Endpoints.USER_PROFILE(userId),
			query: guildId ? {guild_id: guildId} : undefined,
		});

		// Create a profile record and update the store
		const profileRecord = new ProfileRecord(profile, guildId);
		Dispatcher.dispatch({
			type: 'USER_PROFILE_CREATE',
			profile: profileRecord,
		});

		logger.debug(`Fetched and stored profile for user ${userId}${guildId ? ` in guild ${guildId}` : ''}`);
		return profileRecord;
	} catch (error) {
		logger.error(`Failed to fetch profile for user ${userId}${guildId ? ` in guild ${guildId}` : ''}:`, error);
		throw error;
	}
};
