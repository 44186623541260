import _ from 'lodash';
import type React from 'react';
import {UserSettingsModal} from '~/components/modals/UserSettingsModal';
import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';
import ModalStore from '~/stores/ModalStore';

// Create a logger for modal operations
const logger = new Logger('Modal');

export type ModalRender = React.ComponentType<any>;

/**
 * Pushes a new modal onto the stack
 */
export const push = (modal: ModalRender, customId?: string, props?: Record<string, any>): void => {
	// Special case for user settings modal to prevent duplicates
	if (modal === UserSettingsModal && ModalStore.isModalOpen(UserSettingsModal)) {
		logger.debug('Skipping duplicate UserSettingsModal');
		return;
	}

	const key = customId ?? _.uniqueId('modal');
	logger.debug(`Pushing modal: ${key}, component: ${modal.displayName || modal.name || 'unknown'}`);
	Dispatcher.dispatch({type: 'MODAL_PUSH', modal, props, key});
};

/**
 * Updates an existing modal's props
 */
export const update = (key: string, props: Record<string, any>, partial = true): void => {
	logger.debug(`Updating modal: ${key}, partial: ${partial}`);
	Dispatcher.dispatch({type: 'MODAL_UPDATE', key, props, partial});
};

/**
 * Pops the most recent modal from the stack
 */
export const pop = (): void => {
	logger.debug('Popping most recent modal');
	Dispatcher.dispatch({type: 'MODAL_POP'});
};

/**
 * Pops a specific modal by key
 */
export const popWithKey = (key: string): void => {
	logger.debug(`Popping modal with key: ${key}`);
	Dispatcher.dispatch({type: 'MODAL_POP', key});
};
