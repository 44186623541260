import emojiRegex from 'emoji-regex';
import UnicodeEmojis from '~/lib/UnicodeEmojis';
import * as EmojiUtils from '~/utils/EmojiUtils';
import {EmojiKind, NodeType, PLAINTEXT_SURROGATES, type ParserResult} from '../types';

/**
 * Main entry point for parsing emojis
 * Tries to parse standard emojis, shortcodes, and custom emojis
 *
 * @param text - The text to parse
 * @returns Parser result or null if no emoji found
 */
export function parseEmoji(text: string): ParserResult | null {
	// Check for standard Unicode emoji
	const regex = emojiRegex();
	const match = regex.exec(text);

	if (match && match.index === 0) {
		const candidate = match[0];

		// Some emoji-like characters should be treated as regular text
		if (PLAINTEXT_SURROGATES.has(candidate)) {
			return null;
		}
	}

	// Try to parse as standard emoji
	const standardEmoji = parseStandardEmoji(text, 0);
	if (standardEmoji) return standardEmoji;

	// Try to parse as emoji shortcode
	if (text.startsWith(':')) {
		return parseEmojiShortcode(text);
	}

	// Try to parse as custom emoji
	if (text.startsWith('<')) {
		return parseCustomEmoji(text);
	}

	return null;
}

/**
 * Parses a standard Unicode emoji
 *
 * @param text - The text to parse
 * @param start - The position to start parsing from
 * @returns Parser result or null if no standard emoji found
 */
export function parseStandardEmoji(text: string, start: number): ParserResult | null {
	const regex = emojiRegex();
	const match = regex.exec(text.slice(start));

	if (match && match.index === 0) {
		const candidate = match[0];

		// Convert emoji to codepoints and get its name
		const codepoints = EmojiUtils.convertToCodePoints(candidate);
		const name = UnicodeEmojis.getSurrogateName(candidate, false);

		return {
			node: {
				type: NodeType.Emoji,
				kind: {
					kind: EmojiKind.Standard,
					raw: candidate,
					codepoints,
					name,
				},
			},
			advance: [...candidate].length,
		};
	}

	return null;
}

/**
 * Parses an emoji shortcode like :smile: or :thumbsup::skin-tone-3:
 *
 * @param text - The text to parse
 * @returns Parser result or null if no valid shortcode found
 */
export function parseEmojiShortcode(text: string): ParserResult | null {
	if (!text.startsWith(':')) return null;

	const endPos = text.indexOf(':', 1);
	if (endPos === -1) return null;

	// Extract emoji name and possible skin tone
	const {baseName, skinTone} = extractEmojiName(text.slice(1, endPos));
	if (!isValidEmojiName(baseName)) return null;

	// Try to find the emoji by name
	const emoji = UnicodeEmojis.findEmojiByName(baseName);
	if (!emoji) return null;

	// Some emoji-like characters should be treated as regular text
	if (PLAINTEXT_SURROGATES.has(emoji)) {
		return {
			node: {type: NodeType.Text, content: emoji},
			advance: endPos + 1,
		};
	}

	// Apply skin tone if specified
	const finalEmoji = skinTone !== undefined ? UnicodeEmojis.findEmojiWithSkinTone(baseName, skinTone) : emoji;

	if (!finalEmoji) return null;

	const codepoints = EmojiUtils.convertToCodePoints(finalEmoji);
	return {
		node: {
			type: NodeType.Emoji,
			kind: {
				kind: EmojiKind.Standard,
				raw: finalEmoji,
				codepoints,
				name: baseName,
			},
		},
		advance: endPos + 1,
	};
}

/**
 * Extracts the base emoji name and optional skin tone from a shortcode
 *
 * @param fullName - The full emoji name from the shortcode
 * @returns Object with base name and optional skin tone
 */
export function extractEmojiName(fullName: string): {baseName: string; skinTone?: number} {
	const skinToneMatch = fullName.match(/::skin-tone-(\d)/);
	if (!skinToneMatch) return {baseName: fullName};

	const tone = Number.parseInt(skinToneMatch[1], 10);
	if (tone >= 1 && tone <= 5) {
		return {
			baseName: fullName.slice(0, skinToneMatch.index!),
			skinTone: tone,
		};
	}

	return {baseName: fullName};
}

/**
 * Validates that an emoji name follows the correct format
 *
 * @param name - The emoji name to validate
 * @returns Whether the name is valid
 */
export function isValidEmojiName(name: string): boolean {
	return Boolean(name && /^[a-zA-Z0-9_-]+$/.test(name));
}

/**
 * Parses a custom Discord emoji <:name:id> or <a:name:id> for animated
 *
 * @param text - The text to parse
 * @returns Parser result or null if not a valid custom emoji
 */
export function parseCustomEmoji(text: string): ParserResult | null {
	const emojiInfo = extractCustomEmojiInfo(text);
	if (!emojiInfo) return null;

	const {name, id, animated, advance} = emojiInfo;

	return {
		node: {
			type: NodeType.Emoji,
			kind: {
				kind: EmojiKind.Custom,
				name,
				id,
				animated,
			},
		},
		advance,
	};
}

/**
 * Extracts information from a custom emoji format
 *
 * @param text - The text to parse
 * @returns Custom emoji info or null if not a valid format
 */
export function extractCustomEmojiInfo(
	text: string,
): {name: string; id: string; animated: boolean; advance: number} | null {
	let animated = false;
	let startIndex = 0;

	// Check if this is an animated or static custom emoji
	if (text.startsWith('<a:')) {
		animated = true;
		startIndex = 3;
	} else if (text.startsWith('<:')) {
		startIndex = 2;
	} else {
		return null;
	}

	const end = text.indexOf('>');
	if (end === -1) return null;

	// Extract name and ID from the inner content
	const inner = text.slice(startIndex, end);
	const parts = inner.split(':');
	if (parts.length !== 2) return null;

	const [name, id] = parts;

	// Validate the name and ID
	if (!name || !id || !/^\d+$/.test(id)) return null;

	return {name, id, animated, advance: end + 1};
}
