import {ExponentialBackoff} from '~/lib/ExponentialBackoff';
import AuthenticationStore from '~/stores/AuthenticationStore';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface HttpRequestConfig {
	url: string;
	query?: Record<string, any> | URLSearchParams;
	body?: unknown;
	headers?: Record<string, string>;
	retries?: number;
	timeout?: number;
	signal?: AbortSignal;
	skipAuth?: boolean;
	skipParsing?: boolean;
}

export interface HttpResponse<T = unknown> {
	ok: boolean;
	status: number;
	statusText: string;
	headers: Record<string, string>;
	data: T;
	raw: Response;
}

export class HttpError extends Error {
	status?: number;
	response?: Response;
	data?: unknown;
	config: HttpRequestConfig;

	constructor(message: string, config: HttpRequestConfig, status?: number, response?: Response, data?: unknown) {
		super(message);
		this.name = 'HttpError';
		this.config = config;
		this.status = status;
		this.response = response;
		this.data = data;
	}

	static fromResponse(response: Response, data: unknown, config: HttpRequestConfig): HttpError {
		return new HttpError(
			`Request failed with status ${response.status}: ${config.url}`,
			config,
			response.status,
			response,
			data,
		);
	}

	static fromError(error: Error, config: HttpRequestConfig): HttpError {
		const message =
			error instanceof DOMException && error.name === 'AbortError'
				? `Request timeout exceeded (${config.timeout}ms): ${config.url}`
				: `Network error: ${error.message}`;

		return new HttpError(message, config);
	}
}

type RequestInterceptor = (config: HttpRequestConfig) => Promise<HttpRequestConfig> | HttpRequestConfig;
type ResponseInterceptor = (response: HttpResponse) => Promise<HttpResponse> | HttpResponse;
type ErrorInterceptor = (error: HttpError) => Promise<HttpResponse | HttpError> | HttpResponse | HttpError;

export class HttpClient {
	private baseUrl: string;
	private apiVersion: string;
	private defaultTimeout = 30000;
	private defaultRetries = 0;

	private requestInterceptors: Array<RequestInterceptor> = [];
	private responseInterceptors: Array<ResponseInterceptor> = [];
	private errorInterceptors: Array<ErrorInterceptor> = [];

	constructor() {
		this.baseUrl = import.meta.env.PUBLIC_API_ENDPOINT || '';
		this.apiVersion = import.meta.env.PUBLIC_API_VERSION || '1';
	}

	setDefaults({timeout, retries}: {timeout?: number; retries?: number} = {}): void {
		if (timeout !== undefined) this.defaultTimeout = timeout;
		if (retries !== undefined) this.defaultRetries = retries;
	}

	addInterceptors({
		request,
		response,
		error,
	}: {
		request?: RequestInterceptor;
		response?: ResponseInterceptor;
		error?: ErrorInterceptor;
	} = {}): () => void {
		const cleanups: Array<() => void> = [];

		if (request) {
			this.requestInterceptors.push(request);
			cleanups.push(() => {
				const index = this.requestInterceptors.indexOf(request);
				if (index !== -1) this.requestInterceptors.splice(index, 1);
			});
		}

		if (response) {
			this.responseInterceptors.push(response);
			cleanups.push(() => {
				const index = this.responseInterceptors.indexOf(response);
				if (index !== -1) this.responseInterceptors.splice(index, 1);
			});
		}

		if (error) {
			this.errorInterceptors.push(error);
			cleanups.push(() => {
				const index = this.errorInterceptors.indexOf(error);
				if (index !== -1) this.errorInterceptors.splice(index, 1);
			});
		}

		return () => cleanups.forEach((cleanup) => cleanup());
	}

	private getFullUrl(url: string, query?: Record<string, any> | URLSearchParams): string {
		// Handle absolute URLs
		if (!url.startsWith('/') || url.startsWith('//')) {
			return url;
		}

		// Build API URL with version
		const apiUrl = `${this.baseUrl}/v${this.apiVersion}${url}`;

		// Add query parameters if provided
		if (!query) return apiUrl;

		const searchParams =
			query instanceof URLSearchParams
				? query
				: new URLSearchParams(
						Object.entries(query)
							.filter(([_, v]) => v != null)
							.map(([k, v]) => [k, typeof v === 'object' ? JSON.stringify(v) : String(v)]),
					);

		const queryString = searchParams.toString();
		return queryString ? `${apiUrl}?${queryString}` : apiUrl;
	}

	private async executeRequest<T = unknown>(
		method: HttpMethod,
		config: HttpRequestConfig,
		retryCount = 0,
		backoff?: ExponentialBackoff,
	): Promise<HttpResponse<T>> {
		// Apply default values but maintain the HttpRequestConfig type
		const finalConfig: HttpRequestConfig = {
			...config,
			timeout: config.timeout !== undefined ? config.timeout : this.defaultTimeout,
			retries: config.retries !== undefined ? config.retries : this.defaultRetries,
		};

		try {
			// Apply request interceptors
			let transformedConfig: HttpRequestConfig = {...finalConfig};
			for (const interceptor of this.requestInterceptors) {
				transformedConfig = await interceptor(transformedConfig);
			}

			// Setup timeout if needed
			let timeoutId: number | undefined;
			let abortController: AbortController | undefined;

			if (!transformedConfig.signal && transformedConfig.timeout) {
				abortController = new AbortController();
				transformedConfig.signal = abortController.signal;

				if (transformedConfig.timeout > 0) {
					timeoutId = window.setTimeout(() => {
						abortController?.abort();
					}, transformedConfig.timeout);
				}
			}

			try {
				// Prepare headers
				const headers: Record<string, string> = {...(transformedConfig.headers || {})};

				// Add auth token for API requests
				if (!transformedConfig.skipAuth && transformedConfig.url.startsWith('/')) {
					const token = AuthenticationStore.getToken();
					if (token) {
						headers.Authorization = token;
					}
				}

				// Set content type for requests with body
				if (transformedConfig.body && !headers['Content-Type']) {
					headers['Content-Type'] = 'application/json';
				}

				// Execute the fetch request
				const response = await fetch(this.getFullUrl(transformedConfig.url, transformedConfig.query), {
					method,
					headers,
					body: transformedConfig.body
						? typeof transformedConfig.body === 'string'
							? transformedConfig.body
							: transformedConfig.body instanceof ArrayBuffer || transformedConfig.body instanceof Blob
								? transformedConfig.body
								: JSON.stringify(transformedConfig.body)
						: undefined,
					signal: transformedConfig.signal,
				});

				// Clear timeout if we set one
				if (timeoutId !== undefined) {
					clearTimeout(timeoutId);
				}

				// Parse the response data
				let data: T;
				if (transformedConfig.skipParsing || response.status === 204) {
					data = null as unknown as T;
				} else {
					const contentType = response.headers.get('content-type') || '';
					data = contentType.includes('application/json')
						? await response.json()
						: ((await response.text()) as unknown as T);
				}

				// Create response object
				const result: HttpResponse<T> = {
					ok: response.ok,
					status: response.status,
					statusText: response.statusText,
					headers: Object.fromEntries(response.headers.entries()),
					data,
					raw: response,
				};

				// Handle successful response
				if (response.ok) {
					// Apply response interceptors
					let transformedResponse: HttpResponse = result;
					for (const interceptor of this.responseInterceptors) {
						transformedResponse = await interceptor(transformedResponse);
					}
					return transformedResponse as HttpResponse<T>;
				}

				// Handle error response - check if we should retry
				const shouldRetry = response.status === 429 || response.status >= 500;
				const retriesConfig = transformedConfig.retries !== undefined ? transformedConfig.retries : 0;

				if (shouldRetry && retriesConfig > 0 && retryCount < retriesConfig) {
					// Initialize backoff if not already set
					const retryBackoff =
						backoff ||
						new ExponentialBackoff({
							minDelay: 1000,
							maxDelay: 30000,
							jitter: true,
						});

					// Wait for the backoff delay
					await new Promise((resolve) => setTimeout(resolve, retryBackoff.next()));

					// Try again
					return this.executeRequest<T>(method, transformedConfig, retryCount + 1, retryBackoff);
				}

				// Create error object
				const error = HttpError.fromResponse(response, data, transformedConfig);

				// Apply error interceptors
				let transformedError: HttpError | HttpResponse = error;
				for (const interceptor of this.errorInterceptors) {
					transformedError = await interceptor(transformedError instanceof HttpError ? transformedError : error);

					// If an interceptor returns a successful response, return it
					if ('data' in transformedError && 'ok' in transformedError && transformedError.ok === true) {
						return transformedError as HttpResponse<T>;
					}
				}

				// If we reach here, throw the error
				throw transformedError instanceof HttpError ? transformedError : error;
			} catch (error) {
				// Clear timeout if we set one
				if (timeoutId !== undefined) {
					clearTimeout(timeoutId);
				}

				// Handle fetch errors
				const httpError =
					error instanceof HttpError
						? error
						: error instanceof Error
							? HttpError.fromError(error, transformedConfig)
							: new HttpError(String(error), transformedConfig);

				// Check if we should retry network errors
				const isNetworkError = !(httpError.status && httpError.status >= 400);
				const retriesConfig = transformedConfig.retries !== undefined ? transformedConfig.retries : 0;

				if (isNetworkError && retriesConfig > 0 && retryCount < retriesConfig) {
					// Initialize backoff if not already set
					const retryBackoff =
						backoff ||
						new ExponentialBackoff({
							minDelay: 1000,
							maxDelay: 30000,
							jitter: true,
						});

					// Wait for the backoff delay
					await new Promise((resolve) => setTimeout(resolve, retryBackoff.next()));

					// Try again
					return this.executeRequest<T>(method, transformedConfig, retryCount + 1, retryBackoff);
				}

				// Apply error interceptors
				let transformedError: HttpError | HttpResponse = httpError;
				for (const interceptor of this.errorInterceptors) {
					transformedError = await interceptor(transformedError instanceof HttpError ? transformedError : httpError);

					// If an interceptor returns a successful response, return it
					if ('data' in transformedError && 'ok' in transformedError && transformedError.ok === true) {
						return transformedError as HttpResponse<T>;
					}
				}

				// If we reach here, throw the error
				throw transformedError instanceof HttpError ? transformedError : httpError;
			}
		} catch (error) {
			// Convert any error to HttpError
			const httpError =
				error instanceof HttpError
					? error
					: error instanceof Error
						? new HttpError(error.message, finalConfig)
						: new HttpError(String(error), finalConfig);

			throw httpError;
		}
	}

	async request<T = any>(method: HttpMethod, urlOrConfig: string | HttpRequestConfig): Promise<HttpResponse<T>> {
		const config: HttpRequestConfig = typeof urlOrConfig === 'string' ? {url: urlOrConfig} : urlOrConfig;
		return this.executeRequest<T>(method, config);
	}

	// Convenience methods
	async get<T = any>(urlOrConfig: string | HttpRequestConfig): Promise<HttpResponse<T>> {
		return this.request<T>('GET', urlOrConfig);
	}

	async post<T = any>(urlOrConfig: string | HttpRequestConfig, data?: unknown): Promise<HttpResponse<T>> {
		const config =
			typeof urlOrConfig === 'string'
				? {url: urlOrConfig, body: data}
				: {...urlOrConfig, body: data ?? urlOrConfig.body};
		return this.request<T>('POST', config);
	}

	async put<T = any>(urlOrConfig: string | HttpRequestConfig, data?: unknown): Promise<HttpResponse<T>> {
		const config =
			typeof urlOrConfig === 'string'
				? {url: urlOrConfig, body: data}
				: {...urlOrConfig, body: data ?? urlOrConfig.body};
		return this.request<T>('PUT', config);
	}

	async patch<T = any>(urlOrConfig: string | HttpRequestConfig, data?: unknown): Promise<HttpResponse<T>> {
		const config =
			typeof urlOrConfig === 'string'
				? {url: urlOrConfig, body: data}
				: {...urlOrConfig, body: data ?? urlOrConfig.body};
		return this.request<T>('PATCH', config);
	}

	async delete<T = any>(urlOrConfig: string | HttpRequestConfig): Promise<HttpResponse<T>> {
		return this.request<T>('DELETE', urlOrConfig);
	}
}

// Create and export a default client instance
const http = new HttpClient();
export default http;
