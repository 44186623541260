import type React from 'react';
import markupStyles from '~/styles/Markup.module.css';
import {type Node, NodeType, ParserFlags} from '../parser';
import {MarkdownContext, type MarkdownParseOptions, type MarkdownRenderOptions} from '../types';
import {shouldRenderJumboEmojis} from '../utils/jumbo-detector';

import {
	AlertRenderer,
	BlockquoteRenderer,
	HeadingRenderer,
	ListRenderer,
	SequenceRenderer,
	SubtextRenderer,
	TableRenderer,
} from './common/block-elements';
import {CodeBlockRenderer, InlineCodeRenderer} from './common/code-elements';
import {
	EmphasisRenderer,
	SpoilerRenderer,
	StrikethroughRenderer,
	StrongRenderer,
	TripleAsteriskRenderer,
	TripleUnderscoreRenderer,
	UnderlineRenderer,
} from './common/formatting-elements';
// Import all renderers
import {EmojiRenderer} from './emoji-renderer';
import {LinkRenderer} from './list-renderer';
import {MentionRenderer} from './mention-renderer';
import {TextRenderer} from './text-renderer';
import {TimestampRenderer} from './timestamp-renderer';

/**
 * Default parser flags configuration - allows all markdown features
 */
export const DEFAULT_PARSER_FLAGS =
	ParserFlags.ALLOW_SPOILERS |
	ParserFlags.ALLOW_HEADINGS |
	ParserFlags.ALLOW_LISTS |
	ParserFlags.ALLOW_CODE_BLOCKS |
	ParserFlags.ALLOW_MASKED_LINKS |
	ParserFlags.ALLOW_COMMAND_MENTIONS |
	ParserFlags.ALLOW_GUILD_NAVIGATIONS |
	ParserFlags.ALLOW_USER_MENTIONS |
	ParserFlags.ALLOW_ROLE_MENTIONS |
	ParserFlags.ALLOW_CHANNEL_MENTIONS |
	ParserFlags.ALLOW_EVERYONE_MENTIONS |
	ParserFlags.ALLOW_BLOCKQUOTES |
	ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES |
	ParserFlags.ALLOW_SUBTEXT |
	ParserFlags.ALLOW_TABLES |
	ParserFlags.ALLOW_ALERTS |
	ParserFlags.ALLOW_AUTOLINKS;

/**
 * Parser flags for inline content - disables block elements
 */
export const INLINE_PARSER_FLAGS =
	DEFAULT_PARSER_FLAGS &
	~(
		ParserFlags.ALLOW_BLOCKQUOTES |
		ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES |
		ParserFlags.ALLOW_SUBTEXT |
		ParserFlags.ALLOW_TABLES |
		ParserFlags.ALLOW_ALERTS
	);

/**
 * Parser flags for user biography content - disables certain elements
 */
export const BIO_PARSER_FLAGS =
	DEFAULT_PARSER_FLAGS &
	~(
		ParserFlags.ALLOW_HEADINGS |
		ParserFlags.ALLOW_CODE_BLOCKS |
		ParserFlags.ALLOW_ROLE_MENTIONS |
		ParserFlags.ALLOW_EVERYONE_MENTIONS |
		ParserFlags.ALLOW_SUBTEXT |
		ParserFlags.ALLOW_TABLES |
		ParserFlags.ALLOW_ALERTS
	);

/**
 * Parser flags for embed descriptions - disables certain elements
 */
export const EMBED_PARSER_FLAGS =
	DEFAULT_PARSER_FLAGS &
	~(ParserFlags.ALLOW_HEADINGS | ParserFlags.ALLOW_TABLES | ParserFlags.ALLOW_ALERTS | ParserFlags.ALLOW_AUTOLINKS);

/**
 * Gets the appropriate parser flags for the specified context
 */
export function getParserFlagsForContext(context: MarkdownContext): number {
	switch (context) {
		case MarkdownContext.INLINE:
			return INLINE_PARSER_FLAGS;
		case MarkdownContext.BIO:
			return BIO_PARSER_FLAGS;
		case MarkdownContext.EMBED:
			return EMBED_PARSER_FLAGS;
		default:
			return DEFAULT_PARSER_FLAGS;
	}
}

/**
 * Registry of all node renderers
 */
const renderers: Record<NodeType, any> = {
	[NodeType.Sequence]: SequenceRenderer,
	[NodeType.Text]: TextRenderer,
	[NodeType.Strong]: StrongRenderer,
	[NodeType.Emphasis]: EmphasisRenderer,
	[NodeType.Underline]: UnderlineRenderer,
	[NodeType.Strikethrough]: StrikethroughRenderer,
	[NodeType.Spoiler]: SpoilerRenderer,
	[NodeType.Timestamp]: TimestampRenderer,
	[NodeType.Blockquote]: BlockquoteRenderer,
	[NodeType.CodeBlock]: CodeBlockRenderer,
	[NodeType.InlineCode]: InlineCodeRenderer,
	[NodeType.Link]: LinkRenderer,
	[NodeType.Mention]: MentionRenderer,
	[NodeType.Emoji]: EmojiRenderer,
	[NodeType.List]: ListRenderer,
	[NodeType.Heading]: HeadingRenderer,
	[NodeType.Subtext]: SubtextRenderer,
	[NodeType.TripleAsterisk]: TripleAsteriskRenderer,
	[NodeType.TripleUnderscore]: TripleUnderscoreRenderer,
	[NodeType.Table]: TableRenderer,
	[NodeType.TableRow]: () => null, // Handled by TableRenderer
	[NodeType.TableCell]: () => null, // Handled by TableRenderer
	[NodeType.Alert]: AlertRenderer,
};

/**
 * Renders a single node
 */
function renderNode(node: Node, key: string, options: MarkdownRenderOptions): React.ReactNode {
	const renderer = renderers[node.type];
	if (!renderer) {
		console.warn(`No renderer found for node type: ${node.type}`);
		return null;
	}

	// Create a function that can be passed to renderers to handle their children
	const renderChildrenFn = (children: Array<Node>) =>
		children.map((child, i) => renderNode(child, `${key}-${i}`, options));

	return renderer({
		node,
		key,
		renderChildren: renderChildrenFn,
		options,
	});
}

/**
 * Renders an array of nodes
 */
export function render(nodes: Array<Node>, options: MarkdownParseOptions): React.ReactNode {
	// Determine if emojis should be jumbo size
	// (only in message context and if content is eligible)
	const shouldJumboEmojis = options.context === MarkdownContext.MESSAGE && shouldRenderJumboEmojis(nodes);

	// Create extended options with jumbo emoji flag
	const renderOptions: MarkdownRenderOptions = {
		...options,
		shouldJumboEmojis,
	};

	// Render each node in the array
	return nodes.map((node, i) => renderNode(node, `${options.context}-${i}`, renderOptions));
}

/**
 * Wraps rendered content based on context
 */
export function wrapRenderedContent(content: React.ReactNode, context: MarkdownContext): React.ReactNode {
	if (context === MarkdownContext.INLINE) {
		return <div className={markupStyles.inlineFormat}>{content}</div>;
	}

	return content;
}
