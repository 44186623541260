import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import AuthenticationStore from '~/stores/AuthenticationStore';
import MessageStore from '~/stores/MessageStore';

type MessageReplyState = Readonly<{
	messageId: string;
	mentioning: boolean;
}>;

type State = Readonly<{
	replyingMessageIds: Readonly<Record<string, MessageReplyState>>;
	highlightMessageId: string | null;
}>;

const initialState: State = {
	replyingMessageIds: {},
	highlightMessageId: null,
};

class MessageReplyStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'MESSAGE_REPLY_START':
				return this.handleMessageReplyStart(action);
			case 'MESSAGE_REPLY_MENTIONING':
				return this.handleMessageReplyMentioning(action);
			case 'MESSAGE_REPLY_STOP':
				return this.handleMessageReplyStop(action);
			case 'MESSAGE_HIGHLIGHT':
				return this.handleMessageHighlight(action);
			case 'MESSAGE_HIGHLIGHT_CLEAR':
				return this.handleMessageHighlightClear();
			default:
				return false;
		}
	}

	useIsReplying(channelId: string, messageId: string): boolean {
		const {replyingMessageIds} = this.useStore();
		return replyingMessageIds[channelId]?.messageId === messageId;
	}

	useReplyingMessage(channelId: string): MessageReplyState | null {
		const {replyingMessageIds} = this.useStore();
		return replyingMessageIds[channelId] ?? null;
	}

	useIsHighlight(messageId: string): boolean {
		const {highlightMessageId} = this.useStore();
		return highlightMessageId === messageId;
	}

	private handleMessageReplyStart({
		channelId,
		messageId,
		mentioning: initialMentioning,
	}: {
		channelId: string;
		messageId: string;
		mentioning: boolean;
	}): boolean {
		const message = MessageStore.getMessage(channelId, messageId);
		if (!message) {
			return false;
		}

		const mentioning =
			message.author.id === AuthenticationStore.getId() || message.webhookId ? false : initialMentioning;

		this.setState((prevState) => ({
			...prevState,
			replyingMessageIds: {
				...prevState.replyingMessageIds,
				[channelId]: {messageId, mentioning},
			},
		}));

		return true;
	}

	private handleMessageReplyMentioning({channelId, mentioning}: {channelId: string; mentioning: boolean}): boolean {
		this.setState((prevState) => {
			const currentReply = prevState.replyingMessageIds[channelId];
			if (!currentReply) {
				return prevState;
			}

			return {
				...prevState,
				replyingMessageIds: {
					...prevState.replyingMessageIds,
					[channelId]: {
						...currentReply,
						mentioning,
					},
				},
			};
		});

		return true;
	}

	private handleMessageReplyStop({channelId}: {channelId: string}): boolean {
		this.setState((prevState) => {
			const {[channelId]: _, ...remainingReplies} = prevState.replyingMessageIds;

			return {
				...prevState,
				replyingMessageIds: remainingReplies,
			};
		});

		return true;
	}

	private handleMessageHighlight({messageId}: {messageId: string}): boolean {
		this.setState((prevState) => ({
			...prevState,
			highlightMessageId: messageId,
		}));

		return true;
	}

	private handleMessageHighlightClear(): boolean {
		this.setState((prevState) => ({
			...prevState,
			highlightMessageId: null,
		}));

		return true;
	}
}

export default new MessageReplyStore();
