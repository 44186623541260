const createRegex = (patterns: Array<string>) => new RegExp(patterns.join('|'), 'gu');

const CHARACTER_MAPPINGS = [
	{
		character: 'h',
		matcher: createRegex([
			'H',
			'һ',
			'հ',
			'Ꮒ',
			'ℎ',
			'\uD835\uDC21',
			'\uD835\uDC89',
			'\uD835\uDCBD',
			'\uD835\uDCF1',
			'\uD835\uDD25',
			'\uD835\uDD59',
			'\uD835\uDD8D',
			'\uD835\uDCC1',
			'\uD835\uDDF5',
			'\uD835\uDE29',
			'\uD835\uDE5D',
			'\uD835\uDE91',
			'ｈ',
		]),
	},
	{
		character: 't',
		matcher: createRegex([
			'T',
			'\uD835\uDC2D',
			'\uD835\uDC61',
			'\uD835\uDC95',
			'\uD835\uDCC9',
			'\uD835\uDCFD',
			'\uD835\uDD31',
			'\uD835\uDD65',
			'\uD835\uDD99',
			'\uD835\uDDCD',
			'\uD835\uDE01',
			'\uD835\uDE35',
			'\uD835\uDE69',
			'\uD835\uDE9D',
		]),
	},
	{
		character: 'p',
		matcher: createRegex([
			'P',
			'ρ',
			'ϱ',
			'р',
			'⍴',
			'ⲣ',
			'\uD835\uDC29',
			'\uD835\uDC5D',
			'\uD835\uDC91',
			'\uD835\uDCC5',
			'\uD835\uDCF9',
			'\uD835\uDD2D',
			'\uD835\uDD61',
			'\uD835\uDD95',
			'\uD835\uDDC9',
			'\uD835\uDDFD',
			'\uD835\uDE31',
			'\uD835\uDE65',
			'\uD835\uDE99',
			'\uD835\uDED2',
			'\uD835\uDEE0',
			'\uD835\uDF0C',
			'\uD835\uDF1A',
			'\uD835\uDF46',
			'\uD835\uDF54',
			'\uD835\uDF80',
			'\uD835\uDF8E',
			'\uD835\uDFBA',
			'\uD835\uDFC8',
			'ｐ',
			'ҏ',
		]),
	},
	{
		character: 's',
		matcher: createRegex([
			'S',
			'ƽ',
			'ѕ',
			'ꜱ',
			'ꮪ',
			'\uD801\uDC48',
			'\uD806\uDCC1',
			'\uD835\uDC2C',
			'\uD835\uDC60',
			'\uD835\uDC94',
			'\uD835\uDCC8',
			'\uD835\uDCFC',
			'\uD835\uDD30',
			'\uD835\uDD64',
			'\uD835\uDD98',
			'\uD835\uDDCC',
			'\uD835\uDE00',
			'\uD835\uDE34',
			'\uD835\uDE68',
			'\uD835\uDE9C',
			'ｓ',
		]),
	},
	{
		character: ':',
		matcher: createRegex([
			'ː',
			'˸',
			'։',
			'׃',
			'܃',
			'܄',
			'ः',
			'ઃ',
			'᛬',
			'᠃',
			'᠉',
			'⁚',
			'∶',
			'ꓽ',
			'꞉',
			'︰',
			'：',
			';',
			';',
		]),
	},
	{
		character: '/',
		matcher: createRegex(['᜵', '⁁', '⁄', '∕', '╱', '⟋', '⧸', 'Ⳇ', '⼃', '〳', 'ノ', '㇓', '丿', '\uD834\uDE3A']),
	},
];

const replaceSpecialCharacters = (input: string) => {
	let result = input;
	for (const mapping of CHARACTER_MAPPINGS) {
		result = result.replace(mapping.matcher, mapping.character);
	}
	return result;
};

const SPECIAL_CHARACTERS = [
	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0,
	0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
	1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
	1, 1, 1, 0, 0, 0, 1, 0,
];

const encodeComponent = (component: string) => {
	let decoded: string;
	try {
		decoded = decodeURIComponent(component);
	} catch {
		return component;
	}

	let encoded = '';
	let original = component;
	let decodedRemaining = decoded;

	while (original.length > 0) {
		const originalCodePoint = original.codePointAt(0)!;
		const decodedCodePoint = decodedRemaining.codePointAt(0)!;

		if (originalCodePoint === decodedCodePoint) {
			encoded += String.fromCodePoint(originalCodePoint);
			original = original.substring(String.fromCodePoint(originalCodePoint).length);
			decodedRemaining = decodedRemaining.substring(String.fromCodePoint(decodedCodePoint).length);
			continue;
		}

		const decodedChar = String.fromCodePoint(decodedCodePoint);
		const encodedChar = encodeURIComponent(decodedChar);
		const encodeLength = encodedChar === decodedChar ? 3 : encodedChar.length;

		const codePoint = decodedCodePoint;
		if (
			codePoint >= 0 && codePoint < 128
				? SPECIAL_CHARACTERS[codePoint] !== 1
				: [
						8206,
						8207,
						8234,
						8235,
						8236,
						8237,
						8238,
						1564,
						8294,
						8295,
						8296,
						8297,
						128271,
						128272,
						128274,
						128275,
						133,
						160,
						5760,
						8192,
						8193,
						8194,
						8195,
						8196,
						8197,
						8198,
						8199,
						8200,
						8201,
						8202,
						8232,
						8233,
						8239,
						8287,
						12288,
						10240,
						173,
						847,
						...Array.from({length: 6}, (_, i) => 1536 + i),
						1757,
						1807,
						2274,
						4447,
						4448,
						6068,
						6069,
						6155,
						6156,
						6157,
						6158,
						8203,
						8204,
						8205,
						8288,
						8289,
						8290,
						8291,
						8292,
						8293,
						...Array.from({length: 6}, (_, i) => 8298 + i),
						12644,
						...Array.from({length: 9}, (_, i) => 65520 + i),
						...Array.from({length: 16}, (_, i) => 65024 + i),
						65279,
						65440,
						65529,
						65530,
						65531,
						69821,
						69837,
						...Array.from({length: 9}, (_, i) => 78896 + i),
						...Array.from({length: 4}, (_, i) => 113824 + i),
						...Array.from({length: 8}, (_, i) => 119155 + i),
						...Array.from({length: 4096}, (_, i) => 917504 + i),
					].includes(codePoint)
		) {
			encoded += encodedChar;
		} else {
			encoded += decodedChar;
		}

		original = original.substring(encodeLength);
		decodedRemaining = decodedRemaining.substring(decodedChar.length);
	}

	return encoded;
};

export const serializeURL = (url: string | URL): string => {
	if (typeof url === 'string') {
		if (!URL.canParse(url)) {
			return url;
		}
		url = new URL(url);
	}

	let serialized = '';

	if (url.origin === 'null' && url.pathname.startsWith('//')) {
		return url.protocol;
	}

	let userInfo = '';
	if (url.username) {
		userInfo += url.username;
	}
	if (url.password) {
		userInfo += `:${url.password}`;
	}
	if (userInfo) {
		userInfo += '@';
	}

	serialized += `${url.protocol}//${userInfo}${url.host}`;
	serialized += encodeComponent(url.pathname);
	serialized += encodeComponent(url.search);
	serialized += encodeComponent(url.hash);

	return serialized;
};

const WHITESPACE_CHARACTERS_REGEX = /\p{Cf}|\p{Zl}|\p{Zp}|[^\P{Cc}\n]|[^\P{Zs} ]|͏|឴|឵|ᅠ|ㅤ|ﾠ/gu;

export const sanitizeWhitespace = (text: string) => text.replace(WHITESPACE_CHARACTERS_REGEX, '');

export const sanitizeFully = (input: string) => replaceSpecialCharacters(input);

export const getInitialsFromName = (name: string) =>
	name
		.split('')
		.filter((_char, index) => index === 0 || name[index - 1] === ' ')
		.map((char) => char.toUpperCase())
		.join('');
