import punycode from 'punycode/';

/**
 * Utility functions for URL validation and processing
 */

/**
 * Validates a URL string
 *
 * @param urlStr - URL string to validate
 * @returns Whether the URL is valid
 */
export function isValidUrl(urlStr: string): boolean {
	try {
		const url = new URL(urlStr);
		return url.protocol === 'http:' || url.protocol === 'https:' || url.protocol === 'mailto:';
	} catch {
		return false;
	}
}

/**
 * Validates an email address
 * Uses a simplified regex that doesn't allow quoted parts to prevent spoofing
 *
 * @param email - Email address to validate
 * @returns Whether the email is valid
 */
export function isValidEmail(email: string): boolean {
	// For our implementation, using a simplified regex
	// that allows alphanumeric characters, dots, underscores, hyphens, and plus signs in the local part
	// but doesn't allow quotes as that could be used for spoofing
	const emailRegex =
		/^[a-zA-Z0-9._%+\-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegex.test(email);
}

/**
 * Converts a URL to ASCII representation of its domain
 * (Handles internationalized domain names)
 *
 * @param target - URL to convert
 * @returns URL with ASCII domain
 */
export function convertToAsciiUrl(target: string): string {
	try {
		const url = new URL(target);
		if (url.hostname) {
			// Convert internationalized domain name to punycode ASCII
			const asciiHost = punycode.toASCII(url.hostname);
			url.hostname = asciiHost;
			return url.toString();
		}
		return target;
	} catch {
		return target;
	}
}
