import {
	Bell,
	FolderPlus,
	Gear,
	type Icon,
	ListChecks,
	PlusCircle,
	Shield,
	SignOut,
	UserPlus,
} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import {Permissions} from '~/Constants';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as PopoutActionCreators from '~/actions/PopoutActionCreators';
import * as ReadStateActionCreators from '~/actions/ReadStateActionCreators';
import {ChannelCreateModal} from '~/components/modals/ChannelCreateModal';
import {GuildSettingsModal} from '~/components/modals/GuildSettingsModal';
import {InviteModal} from '~/components/modals/InviteModal';
import {i18n} from '~/i18n';
import type {GuildRecord} from '~/records/GuildRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import SelectedChannelStore from '~/stores/SelectedChannelStore';
import * as PermissionUtils from '~/utils/PermissionUtils';

const GuildHeaderPopoutItem = (props: {title: string; icon: Icon; onClick?: () => void; danger?: boolean}) => {
	const handleSelect = React.useCallback(() => {
		PopoutActionCreators.close();
		props.onClick?.();
	}, [props]);

	return (
		<div
			className={clsx(
				'flex cursor-pointer justify-between gap-2 rounded-md p-2 leading-4 hover:bg-brand-primary hover:text-brand-primary-fill',
				props.danger && 'text-status-danger hover:bg-status-danger',
			)}
			onClick={handleSelect}
			onKeyDown={(event) => event.key === 'Enter' && handleSelect()}
			role="button"
			tabIndex={0}
		>
			<span>{props.title}</span>
			<props.icon className="h-4 w-4 flex-shrink-0" />
		</div>
	);
};

export const GuildHeaderPopout = ({guild}: {guild: GuildRecord}) => {
	const canManageGuild = PermissionUtils.can(Permissions.MANAGE_GUILD, {guildId: guild.id});
	const canManageChannels = PermissionUtils.can(Permissions.MANAGE_CHANNELS, {guildId: guild.id});
	const canCreateInvite = PermissionUtils.can(Permissions.CREATE_INVITE, {guildId: guild.id});

	return (
		<div className="flex w-[230px] flex-col gap-0.5 rounded-md border border-background-header-secondary bg-background-primary px-[6px] py-[8px]">
			{canCreateInvite && (
				<GuildHeaderPopoutItem
					icon={UserPlus}
					title={i18n.Messages.INVITE_MEMBERS}
					onClick={() => {
						ModalActionCreators.push(() => (
							<InviteModal channelId={SelectedChannelStore.getSelectedChannel(guild.id)} />
						));
					}}
				/>
			)}
			{canManageGuild && (
				<GuildHeaderPopoutItem
					icon={Gear}
					title={i18n.Messages.GUILD_SETTINGS}
					onClick={() => ModalActionCreators.push(() => <GuildSettingsModal guildId={guild.id} />)}
				/>
			)}
			{canManageChannels && (
				<GuildHeaderPopoutItem
					icon={PlusCircle}
					title={i18n.Messages.CREATE_CHANNEL}
					onClick={() => ModalActionCreators.push(() => <ChannelCreateModal guildId={guild.id} />)}
				/>
			)}
			{canManageChannels && <GuildHeaderPopoutItem icon={FolderPlus} title={i18n.Messages.CREATE_CATEGORY} />}
			<GuildHeaderPopoutItem icon={Bell} title={i18n.Messages.USER_SETTINGS_NOTIFICATION} />
			<GuildHeaderPopoutItem icon={Shield} title={i18n.Messages.USER_SETTINGS_PRIVACY} />
			<GuildHeaderPopoutItem
				icon={ListChecks}
				onClick={() => ReadStateActionCreators.ackGuild(guild.id)}
				title={i18n.Messages.MARK_AS_READ}
			/>
			{!guild.isOwner(AuthenticationStore.getId()) && (
				<GuildHeaderPopoutItem
					danger={true}
					icon={SignOut}
					onClick={() => GuildActionCreators.leave(guild.id)}
					title={i18n.Messages.LEAVE_GUILD}
				/>
			)}
		</div>
	);
};
