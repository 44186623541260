import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {Message} from '~/records/MessageRecord';

// Create a logger for mentions operations
const logger = new Logger('Mentions');

/**
 * Fetch recent mentions for the current user
 */
export const fetch = async (): Promise<Array<Message>> => {
	// Dispatch pending action to update UI
	Dispatcher.dispatch({type: 'RECENT_MENTIONS_FETCH_PENDING'});

	try {
		logger.debug('Fetching recent mentions');
		const {data} = await http.get<Array<Message>>({url: Endpoints.USER_MENTIONS});

		// Dispatch success with the fetched mentions
		Dispatcher.dispatch({
			type: 'RECENT_MENTIONS_FETCH_SUCCESS',
			messages: data,
		});

		logger.debug(`Successfully fetched ${data.length} recent mentions`);
		return data;
	} catch (error) {
		// Dispatch error action to update UI
		Dispatcher.dispatch({type: 'RECENT_MENTIONS_FETCH_ERROR', error});

		logger.error('Failed to fetch recent mentions:', error);
		throw error;
	}
};

/**
 * Remove a message from recent mentions
 */
export const remove = async (messageId: string): Promise<void> => {
	try {
		// Optimistically update UI
		Dispatcher.dispatch({type: 'RECENT_MENTION_DELETE', messageId});

		logger.debug(`Removing message ${messageId} from recent mentions`);
		await http.delete({url: Endpoints.USER_MENTION(messageId)});

		logger.debug(`Successfully removed message ${messageId} from recent mentions`);
	} catch (error) {
		// We could potentially add logic here to revert the UI update if needed

		logger.error(`Failed to remove message ${messageId} from recent mentions:`, error);
		throw error;
	}
};
