import {Endpoints} from '~/Endpoints';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {Message} from '~/records/MessageRecord';

// Create a logger for saved messages operations
const logger = new Logger('SavedMessages');

/**
 * Fetch all saved messages for the current user
 */
export const fetch = async (): Promise<Array<Message>> => {
	// Dispatch pending action to update UI
	Dispatcher.dispatch({type: 'SAVED_MESSAGES_FETCH_PENDING'});

	try {
		logger.debug('Fetching saved messages');
		const {data} = await http.get<Array<Message>>({url: Endpoints.USER_SAVED_MESSAGES});

		// Dispatch success with the fetched messages
		Dispatcher.dispatch({
			type: 'SAVED_MESSAGES_FETCH_SUCCESS',
			messages: data,
		});

		logger.debug(`Successfully fetched ${data.length} saved messages`);
		return data;
	} catch (error) {
		// Dispatch error action to update UI
		Dispatcher.dispatch({type: 'SAVED_MESSAGES_FETCH_ERROR', error});

		logger.error('Failed to fetch saved messages:', error);
		throw error;
	}
};

/**
 * Save a message for later
 */
export const create = async (channelId: string, messageId: string): Promise<void> => {
	try {
		logger.debug(`Saving message ${messageId} from channel ${channelId}`);
		await http.post({
			url: Endpoints.USER_SAVED_MESSAGES,
			body: {channel_id: channelId, message_id: messageId},
		});

		// Show success toast to the user
		ToastActionCreators.createToast({
			type: 'success',
			children: i18n.Messages.SAVED_FOR_LATER,
		});

		logger.debug(`Successfully saved message ${messageId}`);
	} catch (error) {
		logger.error(`Failed to save message ${messageId}:`, error);

		// We might want to show an error toast here
		throw error;
	}
};

/**
 * Remove a message from saved messages
 */
export const remove = async (messageId: string): Promise<void> => {
	try {
		// Optimistically update UI
		Dispatcher.dispatch({type: 'SAVED_MESSAGE_DELETE', messageId});

		logger.debug(`Removing message ${messageId} from saved messages`);
		await http.delete({url: Endpoints.USER_SAVED_MESSAGE(messageId)});

		// Show success toast to the user
		ToastActionCreators.createToast({
			type: 'success',
			children: i18n.Messages.REMOVED_FROM_BOOKMARKS,
		});

		logger.debug(`Successfully removed message ${messageId} from saved messages`);
	} catch (error) {
		logger.error(`Failed to remove message ${messageId} from saved messages:`, error);

		// We might want to show an error toast and revert UI changes here
		throw error;
	}
};
