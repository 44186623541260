import type React from 'react';
import type {Node} from './parser';

/**
 * Defines the context in which markdown content is being rendered
 */
export enum MarkdownContext {
	/** Inline format, typically used for replies or compact views */
	INLINE = 'inline',

	/** User biography format */
	BIO = 'bio',

	/** Standard message format */
	MESSAGE = 'message',

	/** Unfurled embed descriptions */
	EMBED = 'embed',

	/** Any other context */
	OTHER = 'other',
}

/**
 * Options for markdown parsing and rendering
 */
export interface MarkdownParseOptions {
	/** The context in which the markdown is being rendered */
	context: MarkdownContext;

	/** Whether to disable animated emoji in the output */
	disableAnimatedEmoji?: boolean;

	/** ID of the channel where this content appears */
	channelId?: string;

	/** ID of the message containing this content */
	messageId?: string;

	/** ID of the guild where this content appears */
	guildId?: string;
}

/**
 * Extended options used during the rendering phase
 */
export interface MarkdownRenderOptions extends MarkdownParseOptions {
	/** Whether emojis should be rendered in jumbo size */
	shouldJumboEmojis: boolean;
}

/**
 * Props passed to all renderers
 */
export interface RendererProps<T extends Node = Node> {
	/** The node to render */
	node: T;

	/** Unique key for React rendering */
	key: string;

	/** Function to render child nodes */
	renderChildren: (nodes: Array<Node>) => React.ReactNode;

	/** Rendering options and context */
	options: MarkdownRenderOptions;
}
