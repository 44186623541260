import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import {type BetaCode, BetaCodeRecord} from '~/records/BetaCodeRecord';

// Create a simple logger
const logger = new Logger('BetaCodes');

/**
 * Fetch all beta codes for the current user
 */
export const fetch = async () => {
	Dispatcher.dispatch({type: 'BETA_CODES_FETCH_PENDING'});

	try {
		const {data} = await http.get<Array<BetaCode>>({
			url: Endpoints.USER_BETA_CODES,
			retries: 1, // Simple retry for server errors
		});

		Dispatcher.dispatch({
			type: 'BETA_CODES_FETCH_SUCCESS',
			betaCodes: data,
		});

		return data;
	} catch (error) {
		logger.error('Failed to fetch beta codes:', error);

		Dispatcher.dispatch({
			type: 'BETA_CODES_FETCH_ERROR',
			error,
		});

		throw error;
	}
};

/**
 * Create a new beta code
 */
export const create = async () => {
	Dispatcher.dispatch({type: 'BETA_CODE_CREATE_PENDING'});

	try {
		const {data} = await http.post<BetaCode>({
			url: Endpoints.USER_BETA_CODES,
		});

		const betaCode = new BetaCodeRecord(data);

		Dispatcher.dispatch({
			type: 'BETA_CODE_CREATE_SUCCESS',
			betaCode,
		});

		return betaCode;
	} catch (error) {
		logger.error('Failed to create beta code:', error);

		Dispatcher.dispatch({
			type: 'BETA_CODE_CREATE_ERROR',
			error,
		});

		throw error;
	}
};

/**
 * Delete a beta code
 */
export const remove = async (code: string) => {
	if (!code) {
		throw new Error('No beta code provided');
	}

	Dispatcher.dispatch({
		type: 'BETA_CODE_DELETE_PENDING',
		code,
	});

	try {
		await http.delete({
			url: Endpoints.USER_BETA_CODE(code),
		});

		Dispatcher.dispatch({
			type: 'BETA_CODE_DELETE_SUCCESS',
			code,
		});
	} catch (error) {
		logger.error(`Failed to delete beta code ${code}:`, error);

		Dispatcher.dispatch({
			type: 'BETA_CODE_DELETE_ERROR',
			code,
			error,
		});

		throw error;
	}
};
