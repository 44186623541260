import AppStorage from '~/lib/AppStorage';

/**
 * Log levels in order of increasing severity
 */
export enum LogLevel {
	Trace = 0,
	Debug = 1,
	Info = 2,
	Warn = 3,
	Error = 4,
	Fatal = 5,
	Silent = 6,
}

// Default styles for log levels
const DEFAULT_STYLES = {
	Trace: {color: '#6c757d', fontWeight: 'normal'},
	Debug: {color: '#17a2b8', fontWeight: 'normal'},
	Info: {color: '#28a745', fontWeight: 'normal'},
	Warn: {color: '#ffc107', fontWeight: 'normal'},
	Error: {color: '#dc3545', fontWeight: 'normal'},
	Fatal: {color: '#dc3545', fontWeight: 'bold'},
};

/**
 * Simple logger class
 */
export class Logger {
	private name: string;

	/**
	 * Create a new logger
	 */
	constructor(name = 'default') {
		this.name = name;
	}

	/**
	 * Determine the current log level based on environment and developer options
	 */
	private getCurrentLogLevel(): LogLevel {
		return AppStorage.getItem('debugLoggingEnabled') === 'true' || import.meta.env.DEV ? LogLevel.Debug : LogLevel.Info;
	}

	/**
	 * Create a child logger
	 */
	child(suffix: string): Logger {
		return new Logger(`${this.name}:${suffix}`);
	}

	/**
	 * Log methods for each level
	 */
	trace(...args: Array<unknown>): void {
		this.log(LogLevel.Trace, ...args);
	}
	debug(...args: Array<unknown>): void {
		this.log(LogLevel.Debug, ...args);
	}
	info(...args: Array<unknown>): void {
		this.log(LogLevel.Info, ...args);
	}
	warn(...args: Array<unknown>): void {
		this.log(LogLevel.Warn, ...args);
	}
	error(...args: Array<unknown>): void {
		this.log(LogLevel.Error, ...args);
	}
	fatal(...args: Array<unknown>): void {
		this.log(LogLevel.Fatal, ...args);
	}

	/**
	 * Main log method
	 */
	private log(level: LogLevel, ...args: Array<unknown>): void {
		const minLevel = this.getCurrentLogLevel();
		if (level < minLevel) return;

		const levelName = LogLevel[level];
		const timestamp = new Date().toLocaleTimeString();
		const prefix = `[${timestamp}] [${this.name}] [${levelName}]`;

		const style = DEFAULT_STYLES[levelName as keyof typeof DEFAULT_STYLES];
		const consoleMethod = this.getConsoleMethod(level);

		if (style) {
			console[consoleMethod](
				`%c${prefix}`,
				`color:${style.color};font-weight:${style.fontWeight || 'normal'}`,
				...args,
			);
		} else {
			console[consoleMethod](prefix, ...args);
		}
	}

	/**
	 * Get the appropriate console method for the log level
	 */
	private getConsoleMethod(level: LogLevel): 'log' | 'debug' | 'info' | 'warn' | 'error' {
		switch (level) {
			case LogLevel.Trace:
			case LogLevel.Debug:
				return 'debug';
			case LogLevel.Info:
				return 'info';
			case LogLevel.Warn:
				return 'warn';
			case LogLevel.Error:
			case LogLevel.Fatal:
				return 'error';
			default:
				return 'log';
		}
	}
}

// Default logger instance
export const logger = new Logger('app');

// Helper to get a named logger
export function getLogger(name: string): Logger {
	return new Logger(name);
}
