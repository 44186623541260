import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {i18n} from '~/i18n';
import {Logger} from '~/lib/Logger';

// Create a logger for clipboard operations
const logger = new Logger('Clipboard');

/**
 * Copies text to the clipboard and shows a toast notification
 */
export const copy = async (text: string): Promise<void> => {
	try {
		logger.debug('Copying text to clipboard');
		await navigator.clipboard.writeText(text);

		logger.debug('Text successfully copied to clipboard');
		ToastActionCreators.createToast({
			type: 'success',
			children: i18n.Messages.COPIED_TO_CLIPBOARD,
		});
	} catch (error) {
		logger.error('Failed to copy text to clipboard:', error);
		ToastActionCreators.createToast({
			type: 'error',
			children: i18n.Messages.FAILED_TO_COPY_TO_CLIPBOARD,
		});
	}
};
