import {GuildNavKind, MentionKind, type MentionNode, NodeType, ParserFlags, type ParserResult} from '../types';

/**
 * Parses a mention in angle brackets
 * This handles user, channel, role, command, and guild navigation mentions
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Parser result or null if no mention found
 */
export function parseMention(text: string, parserFlags: number): ParserResult | null {
	if (!text.startsWith('<')) return null;

	const end = text.indexOf('>');
	if (end === -1) return null;

	const inner = text.slice(1, end);
	let mentionNode: MentionNode | null = null;

	if (inner.startsWith('@')) {
		mentionNode = parseUserOrRoleMention(inner, parserFlags);
	} else if (inner.startsWith('#')) {
		mentionNode = parseChannelMention(inner, parserFlags);
	} else if (inner.startsWith('/')) {
		mentionNode = parseCommandMention(inner, parserFlags);
	} else if (inner.startsWith('id:')) {
		mentionNode = parseGuildNavigation(inner, parserFlags);
	}

	return mentionNode ? {node: mentionNode, advance: end + 1} : null;
}

/**
 * Parses a simple mention (@everyone, @here) that doesn't use angle brackets
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Parser result or null if no simple mention found
 */
export function parseSimpleMention(text: string, parserFlags: number): ParserResult | null {
	if (!(parserFlags & ParserFlags.ALLOW_EVERYONE_MENTIONS)) return null;

	if (text.startsWith('@everyone')) {
		return {
			node: {type: NodeType.Mention, kind: {kind: MentionKind.Everyone}},
			advance: '@everyone'.length,
		};
	}

	if (text.startsWith('@here')) {
		return {
			node: {type: NodeType.Mention, kind: {kind: MentionKind.Here}},
			advance: '@here'.length,
		};
	}

	return null;
}

/**
 * Parses a user or role mention
 * User mentions: <@123456789>, <@!123456789>
 * Role mentions: <@&123456789>
 *
 * @param inner - The content inside the angle brackets
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Mention node or null if not a valid user/role mention
 */
export function parseUserOrRoleMention(inner: string, parserFlags: number): MentionNode | null {
	if (inner.startsWith('@&')) {
		// Role mention
		const roleId = inner.slice(2);
		if (roleId && /^\d+$/.test(roleId) && parserFlags & ParserFlags.ALLOW_ROLE_MENTIONS) {
			return {
				type: NodeType.Mention,
				kind: {kind: MentionKind.Role, id: roleId},
			};
		}
	} else {
		// User mention - can be <@123456> or <@!123456> (the ! was used for nicknames)
		const userId = inner.startsWith('@!') ? inner.slice(2) : inner.slice(1);
		if (userId && /^\d+$/.test(userId) && parserFlags & ParserFlags.ALLOW_USER_MENTIONS) {
			return {
				type: NodeType.Mention,
				kind: {kind: MentionKind.User, id: userId},
			};
		}
	}

	return null;
}

/**
 * Parses a channel mention <#123456789>
 *
 * @param inner - The content inside the angle brackets
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Mention node or null if not a valid channel mention
 */
export function parseChannelMention(inner: string, parserFlags: number): MentionNode | null {
	const channelId = inner.slice(1);
	if (channelId && /^\d+$/.test(channelId) && parserFlags & ParserFlags.ALLOW_CHANNEL_MENTIONS) {
		return {
			type: NodeType.Mention,
			kind: {kind: MentionKind.Channel, id: channelId},
		};
	}

	return null;
}

/**
 * Parses a command mention </command:123456789>
 * Command mentions can have subcommands and subcommand groups
 *
 * @param inner - The content inside the angle brackets
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Mention node or null if not a valid command mention
 */
export function parseCommandMention(inner: string, parserFlags: number): MentionNode | null {
	if (!(parserFlags & ParserFlags.ALLOW_COMMAND_MENTIONS)) return null;

	// Command mentions are formatted as: /command:id or /command subcommand:id or /command subcommandgroup subcommand:id
	const parts = inner.split(':');
	if (parts.length !== 2) return null;

	const [commandPart, idPart] = parts;
	if (!idPart || !/^\d+$/.test(idPart)) return null;

	// Split the command part to get the command name and possible subcommands
	const segments = commandPart.slice(1).trim().split(' ');
	if (segments.length === 0) return null;

	return {
		type: NodeType.Mention,
		kind: {
			kind: MentionKind.Command,
			name: segments[0],
			// If we have 3 segments, the middle one is a subcommand group
			subcommandGroup: segments.length === 3 ? segments[1] : undefined,
			// If we have 2 or more segments, the last one is a subcommand
			subcommand: segments.length >= 2 ? segments[segments.length - 1] : undefined,
			id: idPart,
		},
	};
}

/**
 * Parses a guild navigation mention <id:type> or <id:type:id>
 * These are used for specific Discord UI navigations
 *
 * @param inner - The content inside the angle brackets
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Mention node or null if not a valid guild navigation mention
 */
export function parseGuildNavigation(inner: string, parserFlags: number): MentionNode | null {
	if (!(parserFlags & ParserFlags.ALLOW_GUILD_NAVIGATIONS)) return null;

	const parts = inner.split(':');
	if (parts.length < 2 || parts.length > 3) return null;

	const [idLabel, navType, navId] = parts;
	if (idLabel !== 'id') return null;

	const navigationType = getNavigationType(navType);
	if (!navigationType) return null;

	// Special case for linked roles
	if (navigationType === GuildNavKind.LinkedRoles) {
		return createLinkedRolesNavigation(parts.length === 3 ? navId : undefined);
	}

	// Basic navigation types require exactly 2 parts
	if (parts.length !== 2) return null;
	return createBasicNavigation(navigationType);
}

/**
 * Gets the navigation type from a string
 *
 * @param navTypeLower - The navigation type string
 * @returns The corresponding GuildNavKind or null if invalid
 */
export function getNavigationType(navTypeLower: string): GuildNavKind | null {
	switch (navTypeLower) {
		case 'customize':
			return GuildNavKind.Customize;
		case 'browse':
			return GuildNavKind.Browse;
		case 'guide':
			return GuildNavKind.Guide;
		case 'linked-roles':
			return GuildNavKind.LinkedRoles;
		default:
			return null;
	}
}

/**
 * Creates a LinkedRoles navigation mention
 *
 * @param id - Optional ID for the linked role
 * @returns Mention node for LinkedRoles navigation
 */
export function createLinkedRolesNavigation(id?: string): MentionNode {
	return {
		type: NodeType.Mention,
		kind: {
			kind: MentionKind.GuildNavigation,
			navigationType: GuildNavKind.LinkedRoles,
			id,
		},
	};
}

/**
 * Creates a basic navigation mention
 *
 * @param navigationType - The type of navigation
 * @returns Mention node for basic navigation
 */
export function createBasicNavigation(navigationType: GuildNavKind): MentionNode {
	return {
		type: NodeType.Mention,
		kind: {
			kind: MentionKind.GuildNavigation,
			navigationType,
		},
	};
}
