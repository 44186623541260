import ChannelStore from '~/stores/ChannelStore';
import EmojiStore from '~/stores/EmojiStore';
import * as AvatarUtils from '~/utils/AvatarUtils';
import * as EmojiUtils from '~/utils/EmojiUtils';
import {EmojiKind, type EmojiNode} from '../parser';

/**
 * Interface for emoji rendering metadata
 */
export interface EmojiRenderData {
	/** URL to the emoji image */
	url: string;

	/** Tooltip/alt text for the emoji */
	name: string;

	/** Whether the emoji is animated */
	isAnimated: boolean;

	/** The emoji ID */
	id?: string;
}

/**
 * Gets emoji rendering data for both standard and custom emojis
 */
export function getEmojiRenderData(
	emojiNode: EmojiNode,
	guildId?: string,
	disableAnimatedEmoji = false,
): EmojiRenderData {
	const {kind} = emojiNode;
	const emojiName = `:${kind.name}:`;

	// Handle standard Unicode emojis
	if (kind.kind === EmojiKind.Standard) {
		return {
			url: EmojiUtils.getTwemojiURL(kind.codepoints),
			name: emojiName,
			isAnimated: false,
		};
	}

	// Handle custom Discord emojis
	const {id, animated} = kind;
	const shouldAnimate = !disableAnimatedEmoji && animated;

	// Get emoji context from the guild if available
	const channel = guildId ? ChannelStore.getChannel(guildId) : undefined;
	const disambiguatedEmoji = EmojiStore.getDisambiguatedEmojiContext(channel?.guildId).getById(id);
	const finalEmojiName = `:${disambiguatedEmoji?.name || kind.name}:`;

	return {
		url: AvatarUtils.getEmojiURL({id, animated: shouldAnimate}),
		name: finalEmojiName,
		isAnimated: shouldAnimate,
		id,
	};
}
