import {EMOJI_NAME_RE} from '~/lib/UnicodeEmojis';
import {type Node, NodeType, type TextNode} from '../parser';

/**
 * Determines whether emojis within a set of nodes should be rendered as jumbo (large) emojis.
 *
 * Conditions for jumbo emojis:
 * 1. Contains between 1 and 6 emojis
 * 2. Only contains emojis and whitespace (no other content)
 */
export function shouldRenderJumboEmojis(nodes: Array<Node>): boolean {
	// Count emoji nodes and text nodes that represent only emoji or whitespace
	const emojiCount = nodes.filter((node) => {
		return (
			node.type === NodeType.Emoji || (node.type === NodeType.Text && EMOJI_NAME_RE.test((node as TextNode).content))
		);
	}).length;

	// Check if we should render as jumbo emojis:
	// - Must have at least one emoji
	// - Must not exceed maximum jumbo emoji count (6)
	// - Must only contain emoji nodes and whitespace text nodes
	return (
		emojiCount > 0 &&
		emojiCount <= 6 &&
		nodes.every((node) => {
			return (
				node.type === NodeType.Emoji ||
				(node.type === NodeType.Text &&
					((node as TextNode).content.trim() === '' || EMOJI_NAME_RE.test((node as TextNode).content)))
			);
		})
	);
}
