import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import * as UserActionCreators from '~/actions/UserActionCreators';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import * as Modal from '~/components/modals/Modal';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';
import type {HttpResponse} from '~/lib/HttpClient';
import UserStore from '~/stores/UserStore';
import * as FormUtils from '~/utils/FormUtils';

type FormInputs = {
	username: string;
	password: string;
};

export const UsernameChangeModal = () => {
	const user = UserStore.getCurrentUser()!;
	const [isSubmitting, setIsSubmitting] = useState(false);

	const form = useForm<FormInputs>({
		defaultValues: {
			username: user.username,
			password: '',
		},
	});

	const handleSubmit = async (data: FormInputs) => {
		try {
			setIsSubmitting(true);
			await UserActionCreators.update({
				username: data.username,
				password: data.password,
			});
			ModalActionCreators.pop();
			ToastActionCreators.createToast({type: 'success', children: i18n.Messages.USERNAME_CHANGED});
		} catch (error) {
			FormUtils.handleError(form, error as HttpResponse, 'username');
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Modal.Root label={i18n.Messages.CHANGE_USERNAME_MODAL_TITLE} size="small">
			<Form form={form} onSubmit={handleSubmit}>
				<div className="flex h-full w-full flex-col bg-background-secondary">
					<div className="flex items-center justify-center border-background-header-secondary border-b px-4 py-3">
						<h2 className="font-semibold text-lg text-text-primary">{i18n.Messages.CHANGE_USERNAME_MODAL_TITLE}</h2>
					</div>

					<div className="flex-1 overflow-auto">
						<div className="space-y-6 p-6">
							<div>
								<p className="mb-4 text-sm text-text-primary-muted">
									Use only lowercase letters (a-z), numbers (0-9), periods (.), and underscores (_). No consecutive
									periods allowed.
								</p>
								<Input
									{...form.register('username')}
									autoComplete="username"
									autoFocus
									error={form.formState.errors.username?.message}
									label={i18n.Messages.USERNAME}
									name="username"
									placeholder="cooluser123"
									type="text"
								/>
							</div>

							<Input
								{...form.register('password')}
								autoComplete="current-password"
								error={form.formState.errors.password?.message}
								label={i18n.Messages.PASSWORD}
								placeholder="••••••••••••••••••••••••••••••"
								type="password"
							/>
						</div>
					</div>

					<div className="flex justify-end gap-3 border-background-header-secondary border-t p-4">
						<Button onClick={ModalActionCreators.pop} variant="ghost" aria-label="Cancel">
							{i18n.Messages.CANCEL}
						</Button>

						<Button
							type="submit"
							submitting={isSubmitting}
							variant="brand"
							disabled={!form.watch('username') || !form.watch('password')}
							aria-label="Change Username"
						>
							{i18n.Messages.SAVE_CHANGES}
						</Button>
					</div>
				</div>
			</Form>
		</Modal.Root>
	);
};
