import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {Guild} from '~/records/GuildRecord';
import type {GuildRole} from '~/records/GuildRoleRecord';
import ChannelStore from '~/stores/ChannelStore';

// Create a logger for guild operations
const logger = new Logger('Guilds');

/**
 * Create a new guild
 */
export const create = async (params: Pick<Guild, 'name'> & {icon?: string | null}): Promise<Guild> => {
	try {
		const {data} = await http.post<Guild>({
			url: Endpoints.GUILDS,
			body: params,
		});

		logger.debug(`Created new guild: ${params.name}`);
		return data;
	} catch (error) {
		logger.error('Failed to create guild:', error);
		throw error;
	}
};

/**
 * Update an existing guild's properties
 */
export const update = async (
	guildId: string,
	params: Pick<Guild, 'name' | 'description'> & {icon?: string | null},
): Promise<Guild> => {
	try {
		const {data} = await http.patch<Guild>({
			url: Endpoints.GUILD(guildId),
			body: params,
		});

		logger.debug(`Updated guild ${guildId}`);
		return data;
	} catch (error) {
		logger.error(`Failed to update guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Update the order of channels in a guild
 */
export const sortChannels = async (guildId: string, positions: Array<string>): Promise<void> => {
	// Create a backup of current positions to restore in case of failure
	const channelPositionsCopy = ChannelStore.getGuildChannels(guildId)
		.slice()
		.sort((a, b) => a.position - b.position)
		.map((channel) => channel.id);

	// Optimistically update the UI
	Dispatcher.dispatch({type: 'CHANNEL_POSITIONS_UPDATE', guildId, positions});

	try {
		await http.patch({
			url: Endpoints.GUILD_CHANNELS(guildId),
			body: positions,
		});

		logger.debug(`Updated channel positions for guild ${guildId}`);
	} catch (error) {
		// Restore the original positions if the update fails
		Dispatcher.dispatch({type: 'CHANNEL_POSITIONS_UPDATE', guildId, positions: channelPositionsCopy});

		logger.error(`Failed to update channel positions for guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Update the vanity URL code for a guild
 */
export const updateVanityURL = async (guildId: string, code: string | null): Promise<string> => {
	try {
		const {data} = await http.patch<{code: string}>({
			url: Endpoints.GUILD_VANITY_URL(guildId),
			body: {code},
		});

		logger.debug(`Updated vanity URL for guild ${guildId} to ${code || 'none'}`);
		return data.code;
	} catch (error) {
		logger.error(`Failed to update vanity URL for guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Create a new role in a guild
 */
export const createRole = async (guildId: string, name: string): Promise<void> => {
	try {
		await http.post({
			url: Endpoints.GUILD_ROLES(guildId),
			body: {name},
		});

		logger.debug(`Created role "${name}" in guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to create role in guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Update an existing role in a guild
 */
export const updateRole = async (guildId: string, roleId: string, patch: Partial<GuildRole>): Promise<void> => {
	try {
		await http.patch({
			url: Endpoints.GUILD_ROLE(guildId, roleId),
			body: patch,
		});

		logger.debug(`Updated role ${roleId} in guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to update role ${roleId} in guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Delete a role from a guild
 */
export const deleteRole = async (guildId: string, roleId: string): Promise<void> => {
	try {
		await http.delete({
			url: Endpoints.GUILD_ROLE(guildId, roleId),
		});

		logger.debug(`Deleted role ${roleId} from guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to delete role ${roleId} from guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Update the order of roles in a guild
 */
export const sortRoles = async (guildId: string, positions: Array<string>): Promise<void> => {
	try {
		await http.patch({
			url: Endpoints.GUILD_ROLES(guildId),
			body: positions,
		});

		logger.debug(`Updated role positions for guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to update role positions for guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Delete a guild entirely
 */
export const remove = async (guildId: string, password: string): Promise<void> => {
	try {
		await http.post({
			url: Endpoints.GUILD_DELETE(guildId),
			body: {password},
		});

		logger.debug(`Deleted guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to delete guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Leave a guild (as a member)
 */
export const leave = async (guildId: string): Promise<void> => {
	try {
		await http.delete({
			url: Endpoints.USER_GUILDS(guildId),
		});

		logger.debug(`Left guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to leave guild ${guildId}:`, error);
		throw error;
	}
};
