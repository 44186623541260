import {Endpoints} from '~/Endpoints';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {GuildMember} from '~/records/GuildMemberRecord';

// Create a logger for guild member operations
const logger = new Logger('GuildMembers');

/**
 * Update a guild member's properties
 */
export const update = async (guildId: string, userId: string, params: Partial<GuildMember>): Promise<void> => {
	try {
		await http.patch({
			url: Endpoints.GUILD_MEMBER(guildId, userId),
			body: params,
		});

		logger.debug(`Updated member ${userId} in guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to update member ${userId} in guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Add a role to a guild member
 */
export const addRole = async (guildId: string, userId: string, roleId: string): Promise<void> => {
	try {
		await http.put({
			url: Endpoints.GUILD_MEMBER_ROLE(guildId, userId, roleId),
		});

		logger.debug(`Added role ${roleId} to member ${userId} in guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to add role ${roleId} to member ${userId} in guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Remove a role from a guild member
 */
export const removeRole = async (guildId: string, userId: string, roleId: string): Promise<void> => {
	try {
		await http.delete({
			url: Endpoints.GUILD_MEMBER_ROLE(guildId, userId, roleId),
		});

		logger.debug(`Removed role ${roleId} from member ${userId} in guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to remove role ${roleId} from member ${userId} in guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Update the current user's guild member properties
 */
export const updateMe = async (guildId: string, params: Partial<GuildMember>): Promise<void> => {
	try {
		await http.patch({
			url: Endpoints.GUILD_MEMBER(guildId),
			body: params,
		});

		logger.debug(`Updated current user's member settings in guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to update current user's member settings in guild ${guildId}:`, error);
		throw error;
	}
};
