import {Clipboard} from '@phosphor-icons/react';
import clsx from 'clsx';
import highlight from 'highlight.js';
import katex from 'katex';
import type React from 'react';
import * as TextCopyActionCreators from '~/actions/TextCopyActionCreators';
import markupStyles from '~/styles/Markup.module.css';
import type {CodeBlockNode, InlineCodeNode} from '../../parser';
import type {RendererProps} from '../../types';

/**
 * Renders a code block node
 */
export function CodeBlockRenderer({node, key}: RendererProps<CodeBlockNode>): React.ReactElement {
	const {content, language} = node;

	// Create copy button component for code blocks
	const copyButton = (
		<div className={markupStyles.codeActions}>
			<button
				type="button"
				className={markupStyles.copyButton}
				onClick={() => TextCopyActionCreators.copy(content)}
				aria-label="Copy code"
			>
				<Clipboard className="h-4 w-4" />
			</button>
		</div>
	);

	// Handle LaTeX rendering
	if (language?.toLowerCase() === 'latex') {
		try {
			const html = katex.renderToString(content, {
				displayMode: true,
				throwOnError: false,
				errorColor: 'var(--text-danger)',
				trust: false,
				strict: false,
				output: 'html',
			});

			return (
				<div key={key} className={markupStyles.latexCodeBlock}>
					<div className={markupStyles.codeContainer}>
						{copyButton}
						<div
							className={markupStyles.latexContent}
							// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
							dangerouslySetInnerHTML={{__html: html}}
						/>
					</div>
				</div>
			);
		} catch (error) {
			console.error('KaTeX rendering error:', error);
			// Fall through to regular code block rendering on error
		}
	}

	// Handle regular code blocks with syntax highlighting
	let codeContent: React.ReactElement;

	if (language && highlight.getLanguage(language)) {
		const highlighted = highlight.highlight(content, {
			language: language,
			ignoreIllegals: true,
		});

		codeContent = (
			// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
			<code className={clsx(markupStyles.hljs, language)} dangerouslySetInnerHTML={{__html: highlighted.value}} />
		);
	} else {
		codeContent = <code className={markupStyles.hljs}>{content}</code>;
	}

	return (
		<pre key={key}>
			<div className={markupStyles.codeContainer}>
				{copyButton}
				{codeContent}
			</div>
		</pre>
	);
}

/**
 * Renders an inline code node
 */
export function InlineCodeRenderer({node, key}: RendererProps<InlineCodeNode>): React.ReactElement {
	// Normalize whitespace in inline code
	const normalizedContent = node.content.replace(/\s+/g, ' ');

	return (
		<code key={key} className={markupStyles.inline}>
			{normalizedContent}
		</code>
	);
}
