import {MessageStates} from '~/Constants';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import type {Channel} from '~/records/ChannelRecord';
import {type Message, type MessagePartial, MessageRecord} from '~/records/MessageRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ChannelStore from '~/stores/ChannelStore';
import type {ReactionEmoji} from '~/utils/ReactionUtils';

type MessageMap = Record<string, ReadonlyArray<MessageRecord>>;

type State = Readonly<{
	messages: MessageMap;
}>;

const initialState: State = {
	messages: {},
};

class MessageStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen();
			case 'MESSAGE_CREATE':
				return this.handleMessageCreate(action);
			case 'MESSAGE_UPDATE':
				return this.handleMessageUpdate(action);
			case 'MESSAGE_REACTION_ADD':
				return this.handleMessageReactionAdd(action);
			case 'MESSAGE_REACTION_REMOVE':
				return this.handleMessageReactionRemove(action);
			case 'MESSAGE_REACTION_REMOVE_ALL':
				return this.handleMessageReactionRemoveAll(action);
			case 'MESSAGE_REACTION_REMOVE_EMOJI':
				return this.handleMessageReactionRemoveEmoji(action);
			case 'MESSAGE_DELETE':
				return this.handleMessageDelete(action);
			case 'MESSAGE_DELETE_BULK':
				return this.handleMessageDeleteBulk(action);
			case 'CHANNEL_DELETE':
				return this.handleChannelDelete(action);
			case 'GUILD_DELETE':
				return this.handleGuildDelete(action);
			case 'MESSAGE_SEND_ERROR':
				return this.handleMessageSendError(action);
			case 'MESSAGES_FETCH_SUCCESS':
				return this.handleMessagesFetchSuccess(action);
			default:
				return false;
		}
	}

	getMessage(channelId: string, messageId: string): MessageRecord | null {
		return this.state.messages[channelId]?.find((message) => message.id === messageId) ?? null;
	}

	getLastEditableMessage(channelId: string): MessageRecord | null {
		const currentUserId = AuthenticationStore.getId();
		return (
			this.state.messages[channelId]
				?.slice()
				.reverse()
				.find(
					(message) =>
						message.isUserMessage() && message.author.id === currentUserId && message.state === MessageStates.SENT,
				) ?? null
		);
	}

	getCountBelowMessage(channelId: string, messageId: string, mentionsOnly = false): number {
		const messages = this.state.messages[channelId];
		if (!messages) return 0;

		const messageIndex = messages.findIndex((message) => message.id === messageId);
		if (messageIndex === -1) return 0;

		if (mentionsOnly) {
			return messages.slice(messageIndex + 1).filter((message) => message.isMentioned()).length;
		}

		return messages.length - 1 - messageIndex;
	}

	useMessages(channelId: string): ReadonlyArray<MessageRecord> {
		const {messages} = this.useStore();
		return messages[channelId] ?? [];
	}

	useMessage(channelId: string, messageId: string): MessageRecord | null {
		return this.useMessages(channelId).find((message) => message.id === messageId) ?? null;
	}

	private handleConnectionOpen(): boolean {
		this.setState(initialState);
		return true;
	}

	private handleMessageCreate({message, optimistic}: {message: Message; optimistic?: boolean}): boolean {
		this.setState((prevState) => {
			const channelMessages = [...(prevState.messages[message.channel_id] ?? [])];
			const existingMessageIndex = channelMessages.findIndex((m) => m.id === message.id);

			if (existingMessageIndex !== -1) {
				return prevState;
			}

			const currentUserId = AuthenticationStore.getId();
			if (!optimistic && message.nonce != null && message.author.id === currentUserId) {
				const existingNonceIndex = channelMessages.findIndex((m) => m.nonce === message.nonce);

				if (existingNonceIndex !== -1) {
					return {
						messages: {
							...prevState.messages,
							[message.channel_id]: Object.freeze([
								...channelMessages.slice(0, existingNonceIndex),
								new MessageRecord(message),
								...channelMessages.slice(existingNonceIndex + 1),
							]),
						},
					};
				}
			}

			return {
				messages: {
					...prevState.messages,
					[message.channel_id]: Object.freeze([...channelMessages, new MessageRecord(message)]),
				},
			};
		});
		return true;
	}

	private handleMessageUpdate({message}: {message: Message | MessagePartial}): boolean {
		return this.updateMessageAtIndex(message.channel_id, message.id, (existingMessage) =>
			existingMessage.withUpdates(message),
		);
	}

	private updateMessageAtIndex(
		channelId: string,
		messageId: string,
		updateFn: (message: MessageRecord) => MessageRecord,
	): boolean {
		this.setState((prevState) => {
			const channelMessages = prevState.messages[channelId];
			if (!channelMessages) return prevState;

			const messageIndex = channelMessages.findIndex((m) => m.id === messageId);
			if (messageIndex === -1) return prevState;

			return {
				messages: {
					...prevState.messages,
					[channelId]: Object.freeze([
						...channelMessages.slice(0, messageIndex),
						updateFn(channelMessages[messageIndex]),
						...channelMessages.slice(messageIndex + 1),
					]),
				},
			};
		});
		return true;
	}

	private handleMessageReactionAdd({
		channelId,
		messageId,
		userId,
		emoji,
	}: {
		channelId: string;
		messageId: string;
		userId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageAtIndex(channelId, messageId, (message) =>
			message.withReaction(emoji, true, userId === AuthenticationStore.getId()),
		);
	}

	private handleMessageReactionRemove({
		channelId,
		messageId,
		userId,
		emoji,
	}: {
		channelId: string;
		messageId: string;
		userId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageAtIndex(channelId, messageId, (message) =>
			message.withReaction(emoji, false, userId === AuthenticationStore.getId()),
		);
	}

	private handleMessageReactionRemoveAll({channelId, messageId}: {channelId: string; messageId: string}): boolean {
		return this.updateMessageAtIndex(channelId, messageId, (message) => message.withUpdates({reactions: []}));
	}

	private handleMessageReactionRemoveEmoji({
		channelId,
		messageId,
		emoji,
	}: {
		channelId: string;
		messageId: string;
		emoji: ReactionEmoji;
	}): boolean {
		return this.updateMessageAtIndex(channelId, messageId, (message) => message.withoutReactionEmoji(emoji));
	}

	private handleMessageDelete({channelId, messageId}: {channelId: string; messageId: string}): boolean {
		this.setState((prevState) => ({
			messages: {
				...prevState.messages,
				[channelId]: (prevState.messages[channelId] ?? []).filter((message) => message.id !== messageId),
			},
		}));
		return true;
	}

	private handleMessageDeleteBulk({
		channelId,
		messageIds,
	}: {
		channelId: string;
		messageIds: ReadonlyArray<string>;
	}): boolean {
		const messageIdSet = new Set(messageIds);
		this.setState((prevState) => ({
			messages: {
				...prevState.messages,
				[channelId]: (prevState.messages[channelId] ?? []).filter((message) => !messageIdSet.has(message.id)),
			},
		}));
		return true;
	}

	private handleChannelDelete({channel}: {channel: Channel}): boolean {
		this.setState((prevState) => {
			const {[channel.id]: deletedMessages, ...remainingMessages} = prevState.messages;
			return {messages: remainingMessages};
		});
		return true;
	}

	private handleGuildDelete({guildId}: {guildId: string}): boolean {
		const guildChannelIds = new Set(ChannelStore.getGuildChannels(guildId).map((channel) => channel.id));

		this.setState((prevState) => ({
			messages: Object.fromEntries(
				Object.entries(prevState.messages).filter(([channelId]) => !guildChannelIds.has(channelId)),
			),
		}));
		return true;
	}

	private handleMessageSendError({channelId, nonce}: {channelId: string; nonce: string}): boolean {
		this.setState((prevState) => {
			const channelMessages = prevState.messages[channelId];
			if (!channelMessages) return prevState;

			const messageIndex = channelMessages.findIndex((m) => m.nonce === nonce);
			if (messageIndex === -1) return prevState;

			return {
				messages: {
					...prevState.messages,
					[channelId]: Object.freeze([
						...channelMessages.slice(0, messageIndex),
						channelMessages[messageIndex].withUpdates({state: MessageStates.FAILED}),
						...channelMessages.slice(messageIndex + 1),
					]),
				},
			};
		});

		return true;
	}

	private handleMessagesFetchSuccess({
		channelId,
		messages,
	}: {
		channelId: string;
		messages: ReadonlyArray<Message>;
	}): boolean {
		this.setState((prevState) => {
			const existingMessages = prevState.messages[channelId] ?? [];
			const existingMessageIds = new Set(existingMessages.map((m) => m.id));

			const newMessages = messages
				.filter((message) => !existingMessageIds.has(message.id))
				.map((message) => new MessageRecord(message));

			const updatedMessages = [...existingMessages, ...newMessages].sort((a, b) => a.createdAt - b.createdAt);

			return {
				messages: {
					...prevState.messages,
					[channelId]: Object.freeze(updatedMessages),
				},
			};
		});
		return true;
	}
}

export default new MessageStore();
