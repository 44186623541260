import type {FieldValues, Path, UseFormReturn} from 'react-hook-form';
import {type APIErrorCode, APIErrorCodes} from '~/Constants';
import type {HttpError, HttpResponse} from '~/lib/HttpClient';

interface ValidationError {
	path: string;
	message: string;
}

interface APIErrorResponse {
	code: APIErrorCode;
	message: string;
	errors?: Array<ValidationError>;
}

/**
 * Handle API errors and set appropriate form errors
 *
 * @param form React Hook Form instance
 * @param error HTTP response error
 * @param defaultPath Default form field to set error on if specific field isn't available
 */
export const handleError = <T extends FieldValues>(
	form: UseFormReturn<T>,
	error: HttpResponse<unknown> | HttpError,
	defaultPath: Path<T>,
) => {
	// Handle ResponseError type which has data
	if ('data' in error && error.data) {
		const errorData = error.data as APIErrorResponse;

		// Handle validation errors with specific field paths
		if (errorData.code === APIErrorCodes.INPUT_VALIDATION_ERROR && errorData.errors?.length) {
			for (const validationError of errorData.errors) {
				const path = validationError.path as Path<T>;
				const message = validationError.message;
				form.setError(path, {type: 'server', message});
			}
		}
		// Handle general error message
		else if (errorData.message) {
			form.setError(defaultPath, {type: 'server', message: errorData.message});
		}
		return;
	}

	// Fallback for other error types or if data property doesn't exist
	form.setError(defaultPath, {
		type: 'server',
		message: 'An unexpected error occurred. Please try again.',
	});
};
