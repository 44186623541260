import {DateTime} from 'luxon';
import type React from 'react';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import markupStyles from '~/styles/Markup.module.css';
import type {TimestampNode} from '../parser';
import type {RendererProps} from '../types';
import {formatTimestamp} from '../utils/date-formatter';

/**
 * Renders a timestamp node
 */
export function TimestampRenderer({node, key}: RendererProps<TimestampNode>): React.ReactElement {
	const {timestamp, style} = node;

	// Convert to milliseconds and create DateTime object
	const totalMillis = timestamp * 1000;
	const date = DateTime.fromMillis(totalMillis);

	// Format timestamp based on style
	const displayTime = formatTimestamp(timestamp, style);

	// Full date-time format for tooltip
	const tooltipFormat = "cccc, LLLL d, yyyy 'at' h:mm:ss a ZZZZ";
	const fullDateTime = date.toFormat(tooltipFormat);

	return (
		<Tooltip key={key} text={fullDateTime} position="top" delay={200} maxWidth="xl">
			<time className={markupStyles.timestamp} dateTime={date.toISO() ?? ''}>
				{displayTime}
			</time>
		</Tooltip>
	);
}
