import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';

// Create a logger for window events
const logger = new Logger('Window');

/**
 * Sets the application focus state
 */
export const focus = (focused: boolean): void => {
	logger.debug(`Window focus changed: ${focused}`);
	Dispatcher.dispatch({type: 'WINDOW_FOCUS', focused});
};

/**
 * Notifies stores that the window has been resized
 */
export const resized = (): void => {
	logger.debug('Window resized');
	Dispatcher.dispatch({type: 'WINDOW_RESIZED'});
};
