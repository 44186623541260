import {Endpoints} from '~/Endpoints';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';

// Create a logger for Tenor operations
const logger = new Logger('Tenor');

// Default locale for all Tenor requests
const LOCALE = 'en_US';

/**
 * Represents a GIF from Tenor
 */
export type TenorGif = {
	id: string;
	title: string;
	url: string;
	src: string;
	proxy_src: string;
	width: number;
	height: number;
};

/**
 * Represents a Tenor category
 */
type TenorCategory = {
	name: string;
	src: string;
	proxy_src: string;
};

/**
 * Represents the featured content from Tenor
 */
export type TenorFeatured = {
	categories: Array<TenorCategory>;
	gifs: Array<TenorGif>;
};

// Cache for featured content to reduce API calls
let tenorFeaturedCache: TenorFeatured | null = null;

/**
 * Search for GIFs on Tenor
 */
export const search = async (q: string): Promise<Array<TenorGif>> => {
	try {
		logger.debug(`Searching for GIFs with query: "${q}"`);
		const {data} = await http.get<Array<TenorGif>>({
			url: Endpoints.TENOR_SEARCH,
			query: {q, locale: LOCALE},
		});

		logger.debug(`Found ${data.length} GIFs for query "${q}"`);
		return data;
	} catch (error) {
		logger.error(`Failed to search for GIFs with query "${q}":`, error);
		throw error;
	}
};

/**
 * Get featured content from Tenor (categories and GIFs)
 * Uses a cache to reduce API calls
 */
export const getFeatured = async (): Promise<TenorFeatured> => {
	// Return cached data if available
	if (tenorFeaturedCache) {
		logger.debug('Returning cached featured Tenor content');
		return tenorFeaturedCache;
	}

	try {
		logger.debug('Fetching featured Tenor content');
		const {data} = await http.get<TenorFeatured>({
			url: Endpoints.TENOR_FEATURED,
			query: {locale: LOCALE},
		});

		// Cache the response for future calls
		tenorFeaturedCache = data;

		logger.debug(`Fetched featured Tenor content: ${data.categories.length} categories and ${data.gifs.length} GIFs`);
		return data;
	} catch (error) {
		logger.error('Failed to fetch featured Tenor content:', error);
		throw error;
	}
};

/**
 * Get trending GIFs from Tenor
 */
export const getTrending = async (): Promise<Array<TenorGif>> => {
	try {
		logger.debug('Fetching trending Tenor GIFs');
		const {data} = await http.get<Array<TenorGif>>({
			url: Endpoints.TENOR_TRENDING_GIFS,
			query: {locale: LOCALE},
		});

		logger.debug(`Fetched ${data.length} trending Tenor GIFs`);
		return data;
	} catch (error) {
		logger.error('Failed to fetch trending Tenor GIFs:', error);
		throw error;
	}
};

/**
 * Register a GIF share with Tenor for analytics
 */
export const registerShare = async (id: string, q: string): Promise<void> => {
	try {
		logger.debug(`Registering GIF share: id=${id}, query="${q}"`);
		await http.post({
			url: Endpoints.TENOR_REGISTER_SHARE,
			body: {id, q, locale: LOCALE},
		});

		logger.debug(`Successfully registered GIF share for id=${id}`);
	} catch (error) {
		logger.error(`Failed to register GIF share for id=${id}:`, error);
		// We might want to just log but not throw here since this is analytics
		// and shouldn't block the user experience
	}
};

/**
 * Get search suggestions from Tenor
 */
export const suggest = async (q: string): Promise<Array<string>> => {
	try {
		logger.debug(`Getting Tenor search suggestions for: "${q}"`);
		const {data} = await http.get<Array<string>>({
			url: Endpoints.TENOR_SUGGEST,
			query: {q, locale: LOCALE},
		});

		logger.debug(`Received ${data.length} suggestions for query "${q}"`);
		return data;
	} catch (error) {
		logger.error(`Failed to get suggestions for query "${q}":`, error);
		throw error;
	}
};
