import React from 'react';
import {useForm} from 'react-hook-form';
import {Link, useHistory} from 'react-router-dom';
import * as AuthenticationActionCreators from '~/actions/AuthenticationActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Card, CardWrapper} from '~/components/form/Card';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import {Button} from '~/components/uikit/Button/Button';
import {i18n} from '~/i18n';

type ForgotPasswordForm = {
	email: string;
};

export const ForgotPasswordPage = () => {
	const [emailSent, setEmailSent] = React.useState(false);
	const history = useHistory();
	const form = useForm<ForgotPasswordForm>();

	const onSubmit = async (data: ForgotPasswordForm) => {
		try {
			await AuthenticationActionCreators.forgotPassword(data.email);
			setEmailSent(true);
		} catch (error) {
			console.error(error);
			ToastActionCreators.createToast({
				children: i18n.Messages.TOAST_FORGOT_PASSWORD_ERROR,
				type: 'error',
			});
		}
	};

	if (emailSent) {
		return (
			<CardWrapper>
				<Card
					title={i18n.Messages.FORGOT_PASSWORD_EMAIL_SENT_TITLE}
					description={i18n.Messages.FORGOT_PASSWORD_EMAIL_SENT_DESCRIPTION}
				>
					<Button onClick={() => history.push('/login')}>{i18n.Messages.RETURN_TO_LOGIN}</Button>
				</Card>
			</CardWrapper>
		);
	}

	return (
		<CardWrapper>
			<Card title={i18n.Messages.FORGOT_PASSWORD_TITLE} description={i18n.Messages.FORGOT_PASSWORD_DESCRIPTION}>
				<Form className="flex w-full flex-col gap-4" form={form} onSubmit={onSubmit}>
					<Input
						{...form.register('email')}
						autoComplete="email"
						autoFocus={true}
						error={form.formState.errors.email?.message}
						label={i18n.Messages.EMAIL}
						placeholder={i18n.Messages.EMAIL_PLACEHOLDER}
						required={true}
						type="email"
					/>

					<div className="flex flex-col gap-2.5">
						<Button type="submit" submitting={form.formState.isSubmitting}>
							{i18n.Messages.SEND_RESET_EMAIL}
						</Button>
						<div className="text-sm text-text-tertiary">
							<Link className="text-text-link hover:underline" to="/login">
								{i18n.Messages.RETURN_TO_LOGIN}
							</Link>
						</div>
					</div>
				</Form>
			</Card>
		</CardWrapper>
	);
};
