import {CircleNotch} from '@phosphor-icons/react';
import React from 'react';
import {useHistory} from 'react-router-dom';
import * as AuthenticationActionCreators from '~/actions/AuthenticationActionCreators';
import {Card, CardWrapper} from '~/components/form/Card';
import {Button} from '~/components/uikit/Button/Button';

export const VerifyEmailPage = () => {
	const [isVerifying, setIsVerifying] = React.useState(false);
	const [isVerified, setIsVerified] = React.useState(false);
	const [error, setError] = React.useState(false);
	const history = useHistory();

	React.useEffect(() => {
		const verifyEmail = async () => {
			// Extract token from URL hash
			const hash = window.location.hash;
			if (!hash || !hash.startsWith('#token=')) {
				setError(true);
				return;
			}

			const token = hash.substring(7);
			setIsVerifying(true);

			try {
				const result = await AuthenticationActionCreators.verifyEmail(token);
				if (result.success) {
					setIsVerified(true);
				} else {
					setError(true);
				}
			} catch (error) {
				console.error(error);
				setError(true);
			} finally {
				setIsVerifying(false);
			}
		};

		verifyEmail();
	}, []);

	if (isVerifying) {
		return (
			<CardWrapper>
				<Card title="Verifying Email" description="Please wait while we verify your email...">
					<div className="flex justify-center">
						<CircleNotch className="h-8 w-8 animate-spin text-brand-primary" weight="bold" />
					</div>
				</Card>
			</CardWrapper>
		);
	}

	if (isVerified) {
		return (
			<CardWrapper>
				<Card title="Email Verified!" description="Your email has been successfully verified.">
					<Button onClick={() => history.push('/')}>Return to Fluxer</Button>
				</Card>
			</CardWrapper>
		);
	}

	if (error) {
		return (
			<CardWrapper>
				<Card title="Verification Failed" description="The verification link is invalid or has expired.">
					<Button onClick={() => history.push('/login')}>Return to Login</Button>
				</Card>
			</CardWrapper>
		);
	}

	return null;
};
