import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';

// Create a logger for update checks
const logger = new Logger('UpdateChecker');

/**
 * Structure of the version.json response
 */
interface VersionInfo {
	build_sha: string;
}

/**
 * Check if a new version of the application is available
 */
export const checkForUpdates = async (): Promise<void> => {
	try {
		const currentVersion = import.meta.env.PUBLIC_BUILD_SHA;

		if (!currentVersion) {
			logger.warn('Missing current version information');
			return;
		}

		// Cache-busting parameter using 5-minute time blocks
		const cacheKey = Math.floor(Date.now() / 1000 / 60 / 5);

		const response = await http.get<VersionInfo>({
			url: `${location.protocol}//${location.host}/version.json`,
			query: {_: cacheKey.toString()},
			// Add retry mechanism
			retries: 1,
			// Skip auth token since this is a public endpoint
			skipAuth: true,
		});

		logger.info(`Version check: current=${currentVersion}, remote=${response.data.build_sha}`);

		if (response.data.build_sha !== currentVersion) {
			// Dispatch the exact same event that was in the original code
			Dispatcher.dispatch({type: 'UPDATE_READY'});
		}
	} catch (error) {
		// Log errors but don't disrupt application flow
		logger.error('Failed to check for updates:', error);
	}
};
