import {MAX_INLINE_DEPTH} from '../types/constants';

/**
 * FormattingContext keeps track of the state of formatting while parsing.
 * It helps maintain a stack of active formatting markers to properly handle
 * nested formatting and prevent conflicting formats.
 */
export class FormattingContext {
	/**
	 * Stack of active formatting delimiters and their types (single/double)
	 * Format: [delimiter, isDouble]
	 */
	private readonly formattingStack: Array<[string, boolean]> = [];

	/**
	 * Stores the current text content being processed, useful for context-aware decisions
	 */
	private currentTextContent = '';

	/**
	 * Tracks the current nesting depth of formatting markers
	 */
	private currentDepth = 0;

	/**
	 * Determines if a new formatting delimiter can be entered
	 *
	 * @param delimiter - The formatting delimiter character (e.g., '*', '_')
	 * @param isDouble - Whether this is a double delimiter (e.g., '**', '__')
	 * @returns Whether the formatting can be entered
	 */
	canEnterFormatting(delimiter: string, isDouble: boolean): boolean {
		// Special handling for underscores to avoid conflicts with word_with_underscores
		if (delimiter === '_' && isDouble) {
			// Count underscores without spreading string
			const underscoreCount = (this.currentTextContent.match(/_/g) || []).length;
			if (underscoreCount > 0) return false;
		}

		// Don't allow entering the same formatting type that's already active
		if (this.isFormattingActive(delimiter, isDouble)) return false;

		// Enforce maximum nesting depth
		return this.currentDepth < MAX_INLINE_DEPTH;
	}

	/**
	 * Checks if a specific formatting type is already active
	 *
	 * @param delimiter - The formatting delimiter character
	 * @param isDouble - Whether this is a double delimiter
	 * @returns Whether this formatting type is active
	 */
	isFormattingActive(delimiter: string, isDouble: boolean): boolean {
		// Check if this exact formatting type is already in the stack
		return this.formattingStack.some(([d, double]) => d === delimiter && double === isDouble);
	}

	/**
	 * Adds a formatting marker to the stack
	 *
	 * @param delimiter - The formatting delimiter character
	 * @param isDouble - Whether this is a double delimiter
	 */
	pushFormatting(delimiter: string, isDouble: boolean): void {
		this.formattingStack.push([delimiter, isDouble]);
		this.currentDepth++;
	}

	/**
	 * Sets the current text content for context-aware decisions
	 *
	 * @param text - The current text being processed
	 */
	setCurrentText(text: string): void {
		this.currentTextContent = text;
	}
}
