import {
	type AlertNode,
	type BlockquoteNode,
	type FormattingNode,
	type HeadingNode,
	type LinkNode,
	type ListNode,
	type Node,
	NodeType,
	type SequenceNode,
	type SubtextNode,
	type TableCellNode,
	type TableNode,
	type TableRowNode,
	type TextNode,
} from '../types';

/**
 * Utility functions for working with the AST
 */

/**
 * Flattens and optimizes an AST by merging text nodes and handling special cases
 *
 * @param nodes - Array of nodes to flatten
 */
export function flattenAST(nodes: Array<Node>): void {
	// First, flatten each individual node
	for (const node of nodes) {
		flattenNode(node);
	}

	// Then flatten the array of nodes
	flattenChildren(nodes);
}

/**
 * Flattens a single node by processing its children recursively
 *
 * @param node - Node to flatten
 */
export function flattenNode(node: Node): void {
	switch (node.type) {
		// Formatting nodes with children
		case NodeType.Strong:
		case NodeType.Emphasis:
		case NodeType.Underline:
		case NodeType.Strikethrough:
		case NodeType.Spoiler:
		case NodeType.Sequence:
		case NodeType.Heading:
		case NodeType.Subtext: {
			const childNode = node as FormattingNode | HeadingNode | SubtextNode;
			for (const child of childNode.children) {
				flattenNode(child);
			}
			// Inside these nodes, we're definitely not in a blockquote
			flattenChildren(childNode.children, false);
			break;
		}

		// Blockquote nodes need special handling
		case NodeType.Blockquote: {
			const blockquoteNode = node as BlockquoteNode;
			for (const child of blockquoteNode.children) {
				flattenNode(child);
			}
			// Always merge text nodes inside blockquotes
			flattenChildren(blockquoteNode.children, true);
			break;
		}

		// List nodes have items with children
		case NodeType.List: {
			const listNode = node as ListNode;
			for (const item of listNode.items) {
				for (const child of item.children) {
					flattenNode(child);
				}
				flattenChildren(item.children, false);
			}
			break;
		}

		// Link nodes may have a text node or sequence
		case NodeType.Link: {
			const linkNode = node as LinkNode;
			if (linkNode.text) {
				flattenNode(linkNode.text);
				if (linkNode.text.type === NodeType.Sequence) {
					const sequenceNode = linkNode.text as SequenceNode;
					for (const child of sequenceNode.children) {
						flattenNode(child);
					}
					flattenChildren(sequenceNode.children, false);
				}
			}
			break;
		}

		// Table nodes have header and rows
		case NodeType.Table: {
			const tableNode = node as TableNode;
			flattenTableRow(tableNode.header);
			for (const row of tableNode.rows) {
				flattenTableRow(row);
			}
			break;
		}

		// Table row nodes have cells
		case NodeType.TableRow: {
			flattenTableRow(node as TableRowNode);
			break;
		}

		// Table cell nodes have children
		case NodeType.TableCell: {
			const cellNode = node as TableCellNode;
			for (const child of cellNode.children) {
				flattenNode(child);
			}
			flattenChildren(cellNode.children, false);
			break;
		}

		// Alert nodes have children
		case NodeType.Alert: {
			const alertNode = node as AlertNode;
			for (const child of alertNode.children) {
				flattenNode(child);
			}
			flattenChildren(alertNode.children, false);
			break;
		}
	}
}

/**
 * Helper function to flatten table rows
 *
 * @param row - Table row to flatten
 */
export function flattenTableRow(row: TableRowNode): void {
	for (const cell of row.cells) {
		for (const child of cell.children) {
			flattenNode(child);
		}
		flattenChildren(cell.children, false);
	}
}

/**
 * Flattens an array of nodes, merging adjacent text nodes
 *
 * @param nodes - Array of nodes to flatten
 * @param insideBlockquote - Whether these nodes are inside a blockquote
 */
export function flattenChildren(nodes: Array<Node>, insideBlockquote = false): void {
	flattenFormattingNodes(nodes);
	combineAdjacentTextNodes(nodes, insideBlockquote);
	removeEmptyTextNodesBetweenAlerts(nodes);
}

/**
 * Flattens formatting nodes with the same type as their children
 *
 * @param nodes - Array of nodes to process
 */
export function flattenFormattingNodes(nodes: Array<Node>): void {
	let i = 0;
	while (i < nodes.length) {
		const node = nodes[i];
		if (isFormattingNode(node)) {
			const formattingNode = node as FormattingNode;
			flattenSameType(formattingNode.children, node.type);
		}
		i++;
	}
}

/**
 * Checks if a node is a formatting node
 *
 * @param node - Node to check
 * @returns Whether the node is a formatting node
 */
export function isFormattingNode(node: Node): boolean {
	return (
		node.type === NodeType.Strong ||
		node.type === NodeType.Emphasis ||
		node.type === NodeType.Underline ||
		node.type === NodeType.Strikethrough ||
		node.type === NodeType.Spoiler ||
		node.type === NodeType.Sequence
	);
}

/**
 * Flattens nodes of the same type by pulling up their children
 *
 * @param children - Array of nodes to process
 * @param nodeType - Type of node to flatten
 */
export function flattenSameType(children: Array<Node>, nodeType: NodeType): void {
	let i = 0;
	while (i < children.length) {
		const child = children[i];
		if (child.type === nodeType) {
			const innerNodes = 'children' in child ? (child as FormattingNode).children : [];
			children.splice(i, 1, ...innerNodes);
		} else {
			i++;
		}
	}
}

/**
 * Combines adjacent text nodes into a single text node
 *
 * @param nodes - Array of nodes to process
 * @param insideBlockquote - Whether these nodes are inside a blockquote
 */
export function combineAdjacentTextNodes(nodes: Array<Node>, insideBlockquote = false): void {
	if (nodes.length <= 1) return;

	// Special handling for blockquotes - we need to consolidate all text nodes
	if (insideBlockquote) {
		const result: Array<Node> = [];
		let currentText = '';
		let nonTextNodeSeen = false;

		for (let i = 0; i < nodes.length; i++) {
			const node = nodes[i];

			if (node.type === NodeType.Text) {
				// If we've seen a non-text node and now have text again, start fresh
				if (nonTextNodeSeen) {
					if (currentText) {
						result.push({type: NodeType.Text, content: currentText});
						currentText = '';
					}
					nonTextNodeSeen = false;
				}
				currentText += (node as TextNode).content;
			} else {
				if (currentText) {
					result.push({type: NodeType.Text, content: currentText});
					currentText = '';
				}
				result.push(node);
				nonTextNodeSeen = true;
			}
		}

		if (currentText) {
			result.push({type: NodeType.Text, content: currentText});
		}

		nodes.length = 0;
		nodes.push(...result);
		return;
	}

	// For non-blockquote content, we need more nuanced handling
	const result: Array<Node> = [];
	let currentTextNode: TextNode | null = null;

	for (let i = 0; i < nodes.length; i++) {
		const node = nodes[i];

		if (node.type === NodeType.Text) {
			const textNode = node as TextNode;
			const content = textNode.content;

			const isMalformedHeading = content.trim().startsWith('#');
			const isMalformedSubtext = content.trim().startsWith('-#');

			// Special content that should be kept as separate nodes
			if (isMalformedHeading || isMalformedSubtext) {
				if (currentTextNode) {
					result.push(currentTextNode);
					currentTextNode = null;
				}
				result.push({...textNode});
			}
			// Regular text nodes
			else if (currentTextNode) {
				// If we're looking at a newline node, decide whether to merge or keep separate
				if (content.trim() === '' && content.includes('\n')) {
					// Only keep separate if it's a significant newline (more than one)
					if (content.includes('\n\n')) {
						result.push(currentTextNode);
						result.push({...textNode});
						currentTextNode = null;
					} else {
						currentTextNode.content += content;
					}
				} else {
					currentTextNode.content += content;
				}
			} else {
				currentTextNode = {...textNode};
			}
		} else {
			if (currentTextNode) {
				result.push(currentTextNode);
				currentTextNode = null;
			}
			result.push(node);
		}
	}

	if (currentTextNode) {
		result.push(currentTextNode);
	}

	nodes.length = 0;
	nodes.push(...result);
}

/**
 * Removes empty text nodes between alert nodes
 *
 * @param nodes - Array of nodes to process
 */
export function removeEmptyTextNodesBetweenAlerts(nodes: Array<Node>): void {
	// Filter nodes in place more efficiently than using splice in a loop
	const result: Array<Node> = [];

	for (let i = 0; i < nodes.length; i++) {
		const currentNode = nodes[i];

		// Skip empty text nodes between alerts
		if (
			i > 0 &&
			i < nodes.length - 1 &&
			currentNode.type === NodeType.Text &&
			(currentNode as TextNode).content.trim() === '' &&
			nodes[i - 1].type === NodeType.Alert &&
			nodes[i + 1].type === NodeType.Alert
		) {
			continue;
		}

		result.push(currentNode);
	}

	// Replace the original array contents
	nodes.length = 0;
	nodes.push(...result);
}

/**
 * Merges adjacent text nodes
 *
 * @param nodes - Array of nodes to process
 * @returns Array with merged text nodes
 */
export function mergeTextNodes(nodes: Array<Node>): Array<Node> {
	const mergedNodes: Array<Node> = [];
	let currentText = '';

	for (const node of nodes) {
		if (node.type === NodeType.Text) {
			currentText += (node as TextNode).content;
		} else {
			if (currentText) {
				mergedNodes.push({type: NodeType.Text, content: currentText});
				currentText = '';
			}
			mergedNodes.push(node);
		}
	}

	if (currentText) {
		mergedNodes.push({type: NodeType.Text, content: currentText});
	}

	return mergedNodes;
}

/**
 * Adds a text node to an array of nodes if the text is not empty
 *
 * @param nodes - Array of nodes to add to
 * @param text - Text content to add
 */
export function addTextNode(nodes: Array<Node>, text: string): void {
	if (text) {
		nodes.push({type: NodeType.Text, content: text});
	}
}
