import type React from 'react';
import type {TextNode} from '../parser';
import {MarkdownContext, type RendererProps} from '../types';

/**
 * Renders a text node
 */
export function TextRenderer({node, key, options}: RendererProps<TextNode>): React.ReactElement {
	// In inline context, replace newlines with spaces
	const content = options.context === MarkdownContext.INLINE ? node.content.replace(/\n/g, ' ') : node.content;

	return <span key={key}>{content}</span>;
}
