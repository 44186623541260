import type {Action} from '~/flux/ActionTypes';
import {PersistedStore} from '~/flux/PersistedStore';

export type AccessibilitySettings = Readonly<{
	saturationFactor: number;
	alwaysUnderlineLinks: boolean;
	enableTextSelection: boolean;
}>;

const initialState: AccessibilitySettings = Object.freeze({
	saturationFactor: 1,
	alwaysUnderlineLinks: false,
	enableTextSelection: false,
});

class AccessibilityStore extends PersistedStore<AccessibilitySettings> {
	constructor() {
		super(initialState, 'AccessibilityStore', 1);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'ACCESSIBILITY_SETTINGS_UPDATE':
				return this.handleAccessibilitySettingsUpdate(action);
			default:
				return false;
		}
	}

	getSaturationFactor(): number {
		return this.state.saturationFactor;
	}

	getAlwaysUnderlineLinks(): boolean {
		return this.state.alwaysUnderlineLinks;
	}

	getEnableTextSelection(): boolean {
		return this.state.enableTextSelection;
	}

	private handleAccessibilitySettingsUpdate({data}: {data: Readonly<Partial<AccessibilitySettings>>}): boolean {
		this.setState((prevState) => ({
			...prevState,
			...this.validateSettings(data),
		}));

		return true;
	}

	private validateSettings(data: Readonly<Partial<AccessibilitySettings>>): Partial<AccessibilitySettings> {
		return {
			saturationFactor: Math.max(0, Math.min(1, data.saturationFactor ?? this.state.saturationFactor)),
			alwaysUnderlineLinks: data.alwaysUnderlineLinks ?? this.state.alwaysUnderlineLinks,
			enableTextSelection: data.enableTextSelection ?? this.state.enableTextSelection,
		};
	}
}

export default new AccessibilityStore();
