import {Endpoints} from '~/Endpoints';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {EmojiUploadFailedModal} from '~/components/modals/EmojiUploadFailedModal';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import {type UserPartial, UserRecord} from '~/records/UserRecord';

// Create a logger for emoji operations
const logger = new Logger('Emojis');

// Valid file extensions for emoji uploads
const VALID_EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif'];

/**
 * Checks if the file has a valid extension for emoji upload
 */
const isValidFileExtension = (file: File): boolean => {
	const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';
	return VALID_EXTENSIONS.includes(fileExtension);
};

/**
 * Converts a File object to base64 string
 */
const readFileAsBase64 = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64str = reader.result?.toString().split(',')[1] ?? '';
			resolve(base64str);
		};
		reader.onerror = () => reject(new Error('File reading failed'));
		reader.readAsDataURL(file);
	});
};

/**
 * Upload an emoji to a guild
 */
export const upload = async (guildId: string, file: File): Promise<void> => {
	try {
		if (!isValidFileExtension(file)) {
			logger.error(`Invalid file extension for emoji upload: ${file.name}`);
			ModalActionCreators.push(() => <EmojiUploadFailedModal />);
			return;
		}

		const base64str = await readFileAsBase64(file);
		let fileName =
			file.name
				.split('.')
				.shift()
				?.replace(/[^a-zA-Z0-9_]/g, '') ?? '';
		fileName = fileName.padEnd(2, '_').slice(0, 32);

		await http.post({
			url: Endpoints.GUILD_EMOJIS(guildId),
			body: {name: fileName, image: base64str},
		});

		logger.debug(`Uploaded emoji ${fileName} to guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to upload emoji to guild ${guildId}:`, error);
		ModalActionCreators.push(() => <EmojiUploadFailedModal />);
		throw error;
	}
};

/**
 * Update an emoji in a guild
 */
export const update = async (guildId: string, emojiId: string, data: {name: string}): Promise<void> => {
	try {
		await http.patch({
			url: Endpoints.GUILD_EMOJI(guildId, emojiId),
			body: data,
		});

		logger.debug(`Updated emoji ${emojiId} in guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to update emoji ${emojiId} in guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Remove an emoji from a guild
 */
export const remove = async (guildId: string, emojiId: string): Promise<void> => {
	try {
		await http.delete({
			url: Endpoints.GUILD_EMOJI(guildId, emojiId),
		});

		logger.debug(`Removed emoji ${emojiId} from guild ${guildId}`);
	} catch (error) {
		logger.error(`Failed to remove emoji ${emojiId} from guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Get the user who created an emoji
 */
export const getUser = async (guildId: string, emojiId: string): Promise<UserRecord> => {
	try {
		const {data} = await http.get<UserPartial>({
			url: Endpoints.GUILD_EMOJI_USER(guildId, emojiId),
		});

		logger.debug(`Retrieved user info for emoji ${emojiId} in guild ${guildId}`);
		return new UserRecord(data);
	} catch (error) {
		logger.error(`Failed to get user for emoji ${emojiId} in guild ${guildId}:`, error);
		throw error;
	}
};
