import type {TooltipItemProps} from '~/components/uikit/Tooltip';
import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';

// Create a logger for tooltip operations
const logger = new Logger('Tooltip');

/**
 * Shows a tooltip with the given ID and properties
 */
export const show = (id: string, tooltip: TooltipItemProps): void => {
	logger.debug(`Showing tooltip: ${id}`);
	Dispatcher.dispatch({type: 'TOOLTIP_SHOW', id, tooltip});
};

/**
 * Hides the tooltip with the given ID
 */
export const hide = (id: string): void => {
	logger.debug(`Hiding tooltip: ${id}`);
	Dispatcher.dispatch({type: 'TOOLTIP_HIDE', id});
};
