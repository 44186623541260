import invariant from 'tiny-invariant';
import {Endpoints} from '~/Endpoints';
import * as AttachmentActionCreators from '~/actions/AttachmentActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {MessageDeleteFailedModal} from '~/components/alerts/MessageDeleteFailedModal';
import {MessageDeleteTooQuickModal} from '~/components/alerts/MessageDeleteTooQuickModal';
import {MessageEditFailedModal} from '~/components/alerts/MessageEditFailedModal';
import {MessageEditTooQuickModal} from '~/components/alerts/MessageEditTooQuickModal';
import {MessageSendFailedModal} from '~/components/alerts/MessageSendFailedModal';
import {MessageSendTooQuickModal} from '~/components/alerts/MessageSendTooQuickModal';
import Dispatcher from '~/flux/Dispatcher';
import http, {type HttpError} from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {Message} from '~/records/MessageRecord';
import DeveloperOptionsStore from '~/stores/DeveloperOptionsStore';
import type {UploadAttachment} from '~/stores/UploadAttachmentStore';

// Create a logger for message operations
const logger = new Logger('MessageActionCreators');

// Cache for pending delete promises to avoid duplicate requests
const pendingDeletePromises = new Map<string, Promise<void>>();

type AllowedMentions = {
	replied_user: boolean;
};

type MessageReference = {
	message_id: string;
};

const DEFAULT_ALLOWED_MENTIONS: AllowedMentions = {
	replied_user: true,
};

const DEFAULT_FLAGS = 0;

type SendMessageParams = {
	content: string;
	nonce: string;
	uploadAttachments?: ReadonlyArray<UploadAttachment>;
	allowedMentions?: AllowedMentions;
	messageReference?: MessageReference;
	flags?: number;
};

type MessageQueueItem = {
	channelId: string;
	params: SendMessageParams;
	resolve: (value: Message | PromiseLike<Message>) => void;
	reject: (reason?: any) => void;
};

// Queue for managing message sending to prevent rate limiting
const messageQueue: Array<MessageQueueItem> = [];
let inFlightMessages = 0;

const MAX_INFLIGHT_MESSAGES = 3;

/**
 * Process the message queue, respecting the maximum number of in-flight messages
 */
const processQueue = async (): Promise<void> => {
	if (inFlightMessages >= MAX_INFLIGHT_MESSAGES) {
		logger.warn(`Too many messages in flight (${inFlightMessages}), showing rate limit modal`);
		ModalActionCreators.push(MessageSendTooQuickModal);
		return;
	}

	if (messageQueue.length === 0) {
		return;
	}

	const {channelId, params, resolve, reject} = messageQueue.shift()!;
	inFlightMessages++;

	logger.debug(`Processing queued message for channel ${channelId}, queue length: ${messageQueue.length}`);

	try {
		const message = await sendMessage(channelId, params);
		resolve(message);
	} catch (error) {
		reject(error);
	} finally {
		inFlightMessages--;
		processQueue();
	}
};

/**
 * Send a message to a channel (internal implementation)
 */
const sendMessage = async (channelId: string, params: SendMessageParams): Promise<Message> => {
	if (DeveloperOptionsStore.getSlowMessageSend()) {
		logger.debug('Slow message send enabled, delaying by 3 seconds');
		await new Promise((resolve) => setTimeout(resolve, 3000));
	}

	// Clear attachments from store as we're about to send them
	AttachmentActionCreators.replace({channelId, attachments: []});

	try {
		const requestBody: Record<string, any> = {
			content: params.content,
			nonce: params.nonce,
		};

		if (params.uploadAttachments?.length) {
			requestBody.attachments = params.uploadAttachments.map((attachment) => {
				invariant(attachment.uploadFilename, 'Attachment uploadFilename is required');
				return {
					id: attachment.id,
					description: attachment.description,
					filename: attachment.filename,
					title: attachment.filename,
					upload_filename: attachment.uploadFilename,
				};
			});
		}

		if (params.allowedMentions && JSON.stringify(params.allowedMentions) !== JSON.stringify(DEFAULT_ALLOWED_MENTIONS)) {
			requestBody.allowed_mentions = params.allowedMentions;
		}

		if (params.messageReference) {
			requestBody.message_reference = params.messageReference;
		}

		if (params.flags && params.flags !== DEFAULT_FLAGS) {
			requestBody.flags = params.flags;
		}

		logger.debug(`Sending message to channel ${channelId}`);
		const {data} = await http.post<Message>({
			url: Endpoints.CHANNEL_MESSAGES(channelId),
			body: requestBody,
		});

		logger.debug(`Successfully sent message to channel ${channelId}`);
		return data;
	} catch (error) {
		logger.error(`Failed to send message to channel ${channelId}:`, error);

		// Dispatch error event
		Dispatcher.dispatch({
			type: 'MESSAGE_SEND_ERROR',
			channelId,
			nonce: params.nonce,
		});

		// Restore attachments if there were any
		if (params.uploadAttachments?.length) {
			AttachmentActionCreators.replace({channelId, attachments: Array.from(params.uploadAttachments)});
		}

		// Show appropriate error modal
		if ((error as HttpError)?.status === 429) {
			ModalActionCreators.push(MessageSendTooQuickModal);
		} else {
			ModalActionCreators.push(MessageSendFailedModal);
		}

		throw error;
	}
};

/**
 * Send a message to a channel (queued implementation)
 */
export const send = async (channelId: string, params: SendMessageParams): Promise<Message> =>
	new Promise((resolve, reject) => {
		logger.debug(`Queueing message for channel ${channelId}`);
		messageQueue.push({channelId, params, resolve, reject});
		processQueue();
	});

/**
 * Edit an existing message
 */
export const edit = async (channelId: string, messageId: string, params: Partial<Message>): Promise<Message> => {
	try {
		logger.debug(`Editing message ${messageId} in channel ${channelId}`);
		const {data} = await http.patch<Message>({
			url: Endpoints.CHANNEL_MESSAGE(channelId, messageId),
			body: params,
		});

		logger.debug(`Successfully edited message ${messageId} in channel ${channelId}`);
		return data;
	} catch (error) {
		logger.error(`Failed to edit message ${messageId} in channel ${channelId}:`, error);

		// Show appropriate error modal
		if ((error as HttpError)?.status === 429) {
			ModalActionCreators.push(MessageEditTooQuickModal);
		} else {
			ModalActionCreators.push(MessageEditFailedModal);
		}

		throw error;
	}
};

/**
 * Delete a message
 */
export const remove = async (channelId: string, messageId: string): Promise<void> => {
	// Return existing promise if already deleting
	const pendingPromise = pendingDeletePromises.get(messageId);
	if (pendingPromise) {
		logger.debug(`Using in-flight delete request for message ${messageId}`);
		return pendingPromise;
	}

	// Create a new delete promise
	const deletePromise = (async () => {
		try {
			logger.debug(`Deleting message ${messageId} in channel ${channelId}`);
			await http.delete({
				url: Endpoints.CHANNEL_MESSAGE(channelId, messageId),
			});

			logger.debug(`Successfully deleted message ${messageId} in channel ${channelId}`);
		} catch (error) {
			logger.error(`Failed to delete message ${messageId} in channel ${channelId}:`, error);

			// Show appropriate error modal based on status code
			const status = (error as HttpError)?.status;
			if (status === 429) {
				ModalActionCreators.push(MessageDeleteTooQuickModal);
			} else if (status === 404) {
				// Message already deleted, ignore
				logger.debug(`Message ${messageId} was already deleted (404 response)`);
			} else {
				ModalActionCreators.push(MessageDeleteFailedModal);
			}

			throw error;
		} finally {
			// Clean up the pending promise cache
			pendingDeletePromises.delete(messageId);
		}
	})();

	// Store the promise in our cache
	pendingDeletePromises.set(messageId, deletePromise);
	return deletePromise;
};

/**
 * Fetch messages for a channel
 */
export const fetch = async (
	channelId: string,
	params: {limit?: number; around?: string; before?: string; after?: string},
): Promise<Array<Message>> => {
	// Apply developer slowdown if enabled
	if (DeveloperOptionsStore.getSlowMessageLoad()) {
		logger.debug('Slow message load enabled, delaying by 3 seconds');
		await new Promise((resolve) => setTimeout(resolve, 3000));
	}

	// Dispatch fetch start action
	Dispatcher.dispatch({
		type: 'MESSAGES_FETCH_START',
		channelId,
		params,
	});

	try {
		// Fetch messages
		const timeStart = Date.now();
		logger.debug(`Fetching messages for channel ${channelId}`);

		const {data: messages} = await http.get<Array<Message>>({
			url: Endpoints.CHANNEL_MESSAGES(channelId),
			query: params,
		});

		// Determine pagination state
		const isBefore = params.before != null;
		const isAfter = params.after != null;
		const isReplacement = params.before == null && params.after == null;
		const hasMoreBefore = params.around != null || (messages.length === params.limit && (isBefore || isReplacement));
		const hasMoreAfter = params.around != null || (isAfter && messages.length === params.limit);
		const hasMore = hasMoreBefore || hasMoreAfter;

		logger.info(`Fetched ${messages.length} messages for channel ${channelId}, took ${Date.now() - timeStart}ms`);

		// Dispatch success action with messages
		Dispatcher.dispatch({
			type: 'MESSAGES_FETCH_SUCCESS',
			channelId,
			messages,
			hasMore,
		});

		return messages;
	} catch (error) {
		logger.error(`Failed to fetch messages for channel ${channelId}:`, error);

		// Dispatch error action
		Dispatcher.dispatch({
			type: 'MESSAGES_FETCH_ERROR',
			channelId,
			error,
		});

		throw error;
	}
};
