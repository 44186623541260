import clsx from 'clsx';
import type React from 'react';
import {useParams} from 'react-router-dom';
import {Avatar} from '~/components/uikit/Avatar';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {GuildMemberRecord} from '~/records/GuildMemberRecord';
import type {GuildRoleRecord} from '~/records/GuildRoleRecord';
import type {Emoji} from '~/stores/EmojiStore';
import EmojiStore from '~/stores/EmojiStore';
import GuildStore from '~/stores/GuildStore';
import PresenceStore from '~/stores/PresenceStore';
import * as ChannelUtils from '~/utils/ChannelUtils';
import * as ColorUtils from '~/utils/ColorUtils';
import * as NicknameUtils from '~/utils/NicknameUtils';

export type AutocompleteType = 'mention' | 'channel' | 'emoji' | 'command';
export type MentionKind = 'member' | 'role' | '@everyone' | '@here';

type Command = {
	name: string;
	content: string;
};

export const COMMANDS: Array<Command> = [
	{
		name: '/shrug',
		content: '¯\\_(ツ)_/¯',
	},
	{
		name: '/tableflip',
		content: '(╯°□°)╯︵ ┻━┻',
	},
	{
		name: '/unflip',
		content: '┬─┬ ノ( ゜-゜ノ)',
	},
];

export type AutocompleteOption =
	| {
			type: 'mention';
			kind: 'member';
			member: GuildMemberRecord;
	  }
	| {
			type: 'mention';
			kind: 'role';
			role: GuildRoleRecord;
	  }
	| {
			type: 'mention';
			kind: '@everyone' | '@here';
	  }
	| {
			type: 'channel';
			channel: ChannelRecord;
	  }
	| {
			type: 'emoji';
			emoji: Emoji;
	  }
	| {
			type: 'command';
			command: Command;
	  };

export const isMentionMember = (
	option: AutocompleteOption,
): option is {type: 'mention'; kind: 'member'; member: GuildMemberRecord} =>
	option.type === 'mention' && option.kind === 'member';

export const isMentionRole = (
	option: AutocompleteOption,
): option is {type: 'mention'; kind: 'role'; role: GuildRoleRecord} =>
	option.type === 'mention' && option.kind === 'role';

export const isSpecialMention = (
	option: AutocompleteOption,
): option is {
	type: 'mention';
	kind: '@everyone' | '@here';
} => option.type === 'mention' && (option.kind === '@everyone' || option.kind === '@here');

export const isChannel = (option: AutocompleteOption): option is {type: 'channel'; channel: ChannelRecord} =>
	option.type === 'channel';

export const isEmoji = (option: AutocompleteOption): option is {type: 'emoji'; emoji: Emoji} => option.type === 'emoji';

export const isCommand = (option: AutocompleteOption): option is {type: 'command'; command: Command} =>
	option.type === 'command';

export const Autocomplete = ({
	type,
	onSelect,
	selectedIndex,
	options,
	setSelectedIndex,
}: {
	type: AutocompleteType;
	onSelect: (value: string) => void;
	selectedIndex: number;
	options: Array<AutocompleteOption>;
	setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => (
	<div className="absolute right-0 bottom-[calc(100%+8px)] left-0 z-elevated-1 mx-4 overflow-hidden truncate rounded-md bg-background-primary">
		<ScrollArea className="flex max-h-[490px] flex-col gap-1 py-2">
			{type === 'mention' ? (
				<AutocompleteMention
					onSelect={onSelect}
					selectedIndex={selectedIndex}
					options={options}
					setSelectedIndex={setSelectedIndex}
				/>
			) : type === 'channel' ? (
				<AutocompleteChannel
					onSelect={onSelect}
					selectedIndex={selectedIndex}
					options={options}
					setSelectedIndex={setSelectedIndex}
				/>
			) : type === 'command' ? (
				<AutocompleteCommand
					onSelect={onSelect}
					selectedIndex={selectedIndex}
					options={options}
					setSelectedIndex={setSelectedIndex}
				/>
			) : (
				<AutocompleteEmoji
					onSelect={onSelect}
					selectedIndex={selectedIndex}
					options={options}
					setSelectedIndex={setSelectedIndex}
				/>
			)}
		</ScrollArea>
	</div>
);

const AutocompleteItem = ({
	icon,
	name,
	description,
	isSelected,
	onSelect,
	onHover,
}: {
	icon?: React.ReactNode;
	name: React.ReactNode;
	description?: string;
	isSelected: boolean;
	onSelect: () => void;
	onHover: () => void;
}) => (
	<div
		className="px-1.5 font-medium text-[14px] leading-[16px]"
		role="button"
		tabIndex={0}
		onClick={onSelect}
		onPointerEnter={onHover}
		onKeyDown={(event) => event.key === 'Enter' && onSelect()}
	>
		<div
			className={clsx(
				'cursor-pointer rounded-md p-2',
				isSelected ? 'bg-background-modifier-hover' : 'hover:bg-background-modifier-hover',
			)}
		>
			<div className="flex min-h-[16px] items-center text-text-primary">
				{icon && <div className="mr-2 flex-none">{icon}</div>}

				<div className="min-w-[10ch] flex-shrink flex-grow-0 overflow-hidden">
					<div className="max-w-full truncate font-normal text-base text-text-primary leading-[1.25]">{name}</div>
				</div>

				{description && (
					<div className="ml-4 min-w-[10ch] flex-shrink-0 flex-grow basis-[10ch] truncate text-right font-normal text-text-primary-muted text-xs leading-[1.33]">
						<span>{description}</span>
					</div>
				)}
			</div>
		</div>
	</div>
);

const AutocompleteMention = ({
	onSelect,
	selectedIndex,
	options,
	setSelectedIndex,
}: {
	onSelect: (value: string) => void;
	selectedIndex: number;
	options: Array<AutocompleteOption>;
	setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const {guildId} = useParams<{guildId: string}>();
	const guild = GuildStore.useGuild(guildId)!;
	const members = options.filter(isMentionMember);
	const roles = options.filter(isMentionRole);
	const specialMentions = options.filter(isSpecialMention);

	return (
		<>
			{members.length > 0 && (
				<>
					{members.map((option, index) => (
						<AutocompleteItem
							key={option.member.user.id}
							icon={
								<Avatar user={option.member.user} size={24} status={PresenceStore.getStatus(option.member.user.id)} />
							}
							name={NicknameUtils.getNickname(option.member.user, guild.id)}
							description={option.member.user.handle}
							isSelected={index === selectedIndex}
							onSelect={() => onSelect(`<@${option.member.user.id}>`)}
							onHover={() => setSelectedIndex(index)}
						/>
					))}
					<div className="my-1 h-[1px] bg-background-modifier-hover" aria-hidden={true} />
				</>
			)}
			{specialMentions.length > 0 &&
				specialMentions.map((option, index) => {
					const currentIndex = members.length + index;
					return (
						<AutocompleteItem
							key={option.kind}
							name={option.kind}
							description={
								option.kind === '@everyone'
									? i18n.Messages.MENTION_DESCRIPTION_EVERYONE
									: i18n.Messages.MENTION_DESCRIPTION_HERE
							}
							isSelected={currentIndex === selectedIndex}
							onSelect={() => onSelect(option.kind)}
							onHover={() => setSelectedIndex(currentIndex)}
						/>
					);
				})}
			{roles.length > 0 && (
				<>
					<div className="my-1 h-[1px] bg-background-modifier-hover" aria-hidden={true} />
					{roles.map((option, index) => {
						const currentIndex = members.length + specialMentions.length + index;
						return (
							<AutocompleteItem
								key={option.role.id}
								name={
									<span style={{color: option.role.color ? ColorUtils.int2rgb(option.role.color) : undefined}}>
										@{option.role.name}
									</span>
								}
								description={i18n.Messages.MENTION_DESCRIPTION_ROLE}
								isSelected={currentIndex === selectedIndex}
								onSelect={() => onSelect(`<@&${option.role.id}>`)}
								onHover={() => setSelectedIndex(currentIndex)}
							/>
						);
					})}
				</>
			)}
		</>
	);
};

const AutocompleteChannel = ({
	onSelect,
	selectedIndex,
	options,
	setSelectedIndex,
}: {
	onSelect: (value: string) => void;
	selectedIndex: number;
	options: Array<AutocompleteOption>;
	setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const channels = options.filter(isChannel);
	return channels.map((option, index) => (
		<AutocompleteItem
			key={option.channel.id}
			icon={ChannelUtils.getIcon(option.channel.type, {className: 'h-4 w-4'})}
			name={option.channel.name}
			isSelected={index === selectedIndex}
			onSelect={() => onSelect(`<#${option.channel.id}>`)}
			onHover={() => {
				Dispatcher.dispatch({type: 'HIGHLIGHT_CHANNEL', channelId: option.channel.id});
				setSelectedIndex(index);
			}}
		/>
	));
};

const AutocompleteCommand = ({
	onSelect,
	selectedIndex,
	options,
	setSelectedIndex,
}: {
	onSelect: (value: string) => void;
	selectedIndex: number;
	options: Array<AutocompleteOption>;
	setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const commands = options.filter(isCommand);
	return commands.map((option, index) => (
		<AutocompleteItem
			key={option.command.name}
			name={option.command.name}
			description={i18n.format(i18n.Messages.APPENDS_TO_YOUR_MESSAGE, {text: option.command.content})}
			isSelected={index === selectedIndex}
			onSelect={() => onSelect(option.command.content)}
			onHover={() => setSelectedIndex(index)}
		/>
	));
};

const AutocompleteEmoji = ({
	onSelect,
	selectedIndex,
	options,
	setSelectedIndex,
}: {
	onSelect: (value: string) => void;
	selectedIndex: number;
	options: Array<AutocompleteOption>;
	setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const emojis = options.filter(isEmoji);
	return emojis.map((option, index) => (
		<AutocompleteItem
			key={option.emoji.name}
			name={`:${option.emoji.name}:`}
			description={option.emoji.guildId ? GuildStore.getGuild(option.emoji.guildId)?.name : i18n.Messages.DEFAULT_EMOJI}
			icon={<img draggable={false} className="h-6 w-6" src={option.emoji.url ?? ''} alt={option.emoji.name} />}
			isSelected={index === selectedIndex}
			onSelect={() => onSelect(EmojiStore.getEmojiMarkdown(option.emoji))}
			onHover={() => setSelectedIndex(index)}
		/>
	));
};
