import {Endpoints} from '~/Endpoints';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {Channel} from '~/records/ChannelRecord';

// Create a logger for channel operations
const logger = new Logger('Channels');

/**
 * Create a new channel in a guild
 */
export const create = async (guildId: string, params: Pick<Channel, 'name' | 'url' | 'type'>) => {
	try {
		const {data} = await http.post<Channel>({
			url: Endpoints.GUILD_CHANNELS(guildId),
			body: params,
		});

		return data;
	} catch (error) {
		logger.error('Failed to create channel:', error);
		throw error;
	}
};

/**
 * Update an existing channel
 */
export const update = async (channelId: string, params: Partial<Pick<Channel, 'name' | 'topic' | 'url'>>) => {
	try {
		const {data} = await http.patch<Channel>({
			url: Endpoints.CHANNEL(channelId),
			body: params,
		});

		return data;
	} catch (error) {
		logger.error(`Failed to update channel ${channelId}:`, error);
		throw error;
	}
};

/**
 * Delete a channel
 */
export const remove = async (channelId: string) => {
	try {
		await http.delete({
			url: Endpoints.CHANNEL(channelId),
		});
	} catch (error) {
		logger.error(`Failed to delete channel ${channelId}:`, error);
		throw error;
	}
};
