import {type Icon, NetworkSlash, SmileySad} from '@phosphor-icons/react';
import React from 'react';
import {useParams} from 'react-router-dom';
import * as ReadStateActionCreators from '~/actions/ReadStateActionCreators';
import {GuildNavbar} from '~/components/layout/GuildNavbar';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import GuildAvailabilityStore from '~/stores/GuildAvailabilityStore';
import GuildStore from '~/stores/GuildStore';
import MobileLayoutStore from '~/stores/MobileLayoutStore';

const GuildUnavailable = ({icon: Icon, title, description}: {icon: Icon; title: string; description: string}) => (
	<div className="flex h-full min-h-0 w-full min-w-0 flex-col items-center justify-center gap-4 px-8">
		<div className="flex flex-col items-center gap-1 text-center">
			<Icon className="h-16 w-16 text-text-tertiary" />
			<h1 className="font-medium text-2xl text-text-primary">{title}</h1>
			<p className="text-text-tertiary">{description}</p>
		</div>
	</div>
);

export const GuildLayout = ({children}: {children: React.ReactNode}) => {
	const {guildId} = useParams<{guildId: string}>();
	const mobileLayout = MobileLayoutStore.useStore();
	const guild = GuildStore.useGuild(guildId);
	const unavailableGuilds = GuildAvailabilityStore.useUnavailableGuilds();

	React.useEffect(() => {
		if (!guild) {
			return;
		}
		window.addEventListener('keydown', (event) => {
			const shouldMarkCurrentGuildAsRead = event.key === 'Escape' && event.shiftKey;
			const shouldMarkAllGuildsAsRead = event.key === 'F9' && event.shiftKey && (event.ctrlKey || event.metaKey);
			if (shouldMarkCurrentGuildAsRead) {
				event.preventDefault();
				ReadStateActionCreators.ackGuild(guild.id);
			} else if (shouldMarkAllGuildsAsRead) {
				event.preventDefault();
				const guilds = GuildStore.getGuilds();
				for (const guild of guilds) {
					ReadStateActionCreators.ackGuild(guild.id);
				}
			}
		});
	}, [guild]);

	React.useEffect(() => {
		if (!guildId) {
			Dispatcher.dispatch({type: 'GUILD_DESELECT'});
			return;
		}
		Dispatcher.dispatch({type: 'GUILD_SELECT', guildId});
		return () => {
			Dispatcher.dispatch({type: 'GUILD_DESELECT'});
		};
	}, [guildId]);

	if (!guild) {
		if (guildId && unavailableGuilds.has(guildId)) {
			return (
				<GuildUnavailable
					icon={NetworkSlash}
					title={i18n.Messages.GUILD_OUTAGE_TITLE}
					description={i18n.Messages.GUILD_OUTAGE_DESCRIPTION}
				/>
			);
		}

		return (
			<GuildUnavailable
				icon={SmileySad}
				title={i18n.Messages.GUILD_NOT_FOUND_TITLE}
				description={i18n.Messages.GUILD_NOT_FOUND_DESCRIPTION}
			/>
		);
	}

	return (
		<div className="grid h-full min-h-0 w-full min-w-0 bg-background-secondary md:grid-cols-[15rem,1fr]">
			{(!mobileLayout.enabled || mobileLayout.navExpanded) && <GuildNavbar guild={guild} />}
			{!(mobileLayout.enabled && mobileLayout.navExpanded) && (
				<div className="grid h-full min-h-0 w-full min-w-0 bg-background-tertiary">
					<div className="h-full min-h-0 w-full min-w-0 bg-background-chat-primary">{children}</div>
				</div>
			)}
		</div>
	);
};
