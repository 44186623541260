import React from 'react';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import styles from '~/components/modals/ConfirmModal.module.css';
import * as Modal from '~/components/modals/Modal';
import {SafeMarkdown} from '~/lib/markdown';
import {MarkdownContext} from '~/lib/markdown/types';
import ChannelStore from '~/stores/ChannelStore';

export const ChannelTopicModal = ({channelId}: {channelId: string}) => {
	const channel = ChannelStore.useChannel(channelId);

	React.useEffect(() => {
		if (!channel?.topic) {
			ModalActionCreators.pop();
		}
	}, [channel]);

	if (!channel?.topic) {
		return null;
	}

	return (
		<Modal.Root label={`#${channel.name}`} size="small">
			<Modal.Header title={`#${channel.name}`} />
			<Modal.Content className={styles.content}>
				<SafeMarkdown
					content={channel.topic}
					options={{
						context: MarkdownContext.OTHER,
						channelId,
					}}
				/>
			</Modal.Content>
		</Modal.Root>
	);
};
