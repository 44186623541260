import {Bicycle, BowlFood, Flag, GameController, Heart, Leaf, Magnet, Smiley} from '@phosphor-icons/react';
import emojiData from '~/data/emojis.json';
import {i18n} from '~/i18n';
import EmojiStore from '~/stores/EmojiStore';
import * as EmojiUtils from '~/utils/EmojiUtils';

type EmojiCategory = 'people' | 'nature' | 'food' | 'activity' | 'travel' | 'objects' | 'symbols' | 'flags';

type EmojiData = {
	names: Array<string>;
	surrogates: string;
	skins?: Array<EmojiSkin>;
};

type EmojiSkin = {
	names: Array<string>;
	surrogates: string;
};

type EmojiInfo = {
	url: string;
	name: string;
	surrogatePair: string;
};

const CATEGORIES: Array<EmojiCategory> = [
	'people',
	'nature',
	'food',
	'activity',
	'travel',
	'objects',
	'symbols',
	'flags',
];

const CATEGORY_ICONS = {
	people: Smiley,
	nature: Leaf,
	food: BowlFood,
	activity: GameController,
	travel: Bicycle,
	objects: Magnet,
	symbols: Heart,
	flags: Flag,
} as const;

export const EMOJI_NAME_RE = /^:([^\s:]+?(?:::skin-tone-\d)?):/;
export const EMOJI_NAME_AND_SKIN_TONE_RE = /:([\w+-]+)(?:::skin-tone-\d)?:/g;

export class UnicodeEmoji {
	private readonly _data: {
		uniqueName: string;
		names: Array<string>;
		allNamesString: string;
		skins: Array<EmojiSkin>;
		surrogates: string;
		index: number;
		hasSkins: boolean;
		defaultURL: string;
		skinsByName: Map<string, EmojiInfo>;
		urlForSkinTone: Map<string, string>;
	};

	constructor(
		data: EmojiData,
		index: number,
		private readonly surrogateToName: Map<string, string>,
	) {
		const skinsByName = new Map<string, EmojiInfo>();
		const urlForSkinTone = new Map<string, string>();
		const skins = data.skins ?? [];
		const hasSkins = skins.length > 0;

		if (hasSkins) {
			skins.forEach((skin, i) => {
				const url = EmojiUtils.getEmojiURL(skin.surrogates);
				urlForSkinTone.set(skin.surrogates, url);
				const nameWithSkin = `${data.names[0]}::skin-tone-${i + 1}`;
				skinsByName.set(nameWithSkin, {
					name: nameWithSkin,
					surrogatePair: skin.surrogates,
					url,
				});
				surrogateToName.set(skin.surrogates, nameWithSkin);
			});
		}

		this._data = {
			uniqueName: data.names[0],
			names: [...data.names],
			allNamesString: data.names.length > 1 ? `:${data.names.join(': :')}:` : `:${data.names[0]}:`,
			skins: [...skins],
			surrogates: data.surrogates,
			index,
			hasSkins,
			defaultURL: EmojiUtils.getEmojiURL(data.surrogates),
			skinsByName,
			urlForSkinTone,
		};
	}

	get uniqueName(): string {
		return this._data.uniqueName;
	}

	get names(): Array<string> {
		return this._data.names;
	}

	get allNamesString(): string {
		return this._data.allNamesString;
	}

	get hasSkins(): boolean {
		return this._data.hasSkins;
	}

	get index(): number {
		return this._data.index;
	}

	get defaultURL(): string {
		return this._data.defaultURL;
	}

	get url(): string {
		const skinTone = EmojiStore.getSkinTone();
		if (!this.hasSkins || !skinTone) return this.defaultURL;
		return this._data.urlForSkinTone.get(this.surrogatePair) ?? this.defaultURL;
	}

	get name(): string {
		const skinTone = EmojiStore.getSkinTone();
		if (!this.hasSkins || !skinTone) return this.uniqueName;
		const skinName = this.surrogateToName.get(`skin-tone-${skinTone}`);
		return skinName ? `${this.uniqueName}::${skinName}` : this.uniqueName;
	}

	get surrogatePair(): string {
		const skinInfo = this._data.skinsByName.get(this.name);
		return skinInfo?.surrogatePair ?? this._data.surrogates;
	}

	get baseSurrogate(): string {
		return this._data.surrogates;
	}

	get skinsByNameMap(): Map<string, EmojiInfo> {
		return this._data.skinsByName;
	}

	forEachSkin(callback: (skin: Readonly<EmojiInfo>) => void): void {
		this._data.skinsByName.forEach(callback);
	}
}

export class UnicodeEmojis {
	private emojis: Array<UnicodeEmoji> = [];
	private emojisByCategory = new Map<EmojiCategory, Array<UnicodeEmoji>>();
	private surrogateToName = new Map<string, string>();

	constructor() {
		this.initialize();
	}

	private initialize(): void {
		let index = 0;

		CATEGORIES.forEach((category) => {
			const categoryEmojis: Array<UnicodeEmoji> = [];
			const categoryData = (emojiData as Record<EmojiCategory, Array<EmojiData>>)[category];

			categoryData.forEach((data) => {
				const emoji = new UnicodeEmoji(data, index++, this.surrogateToName);
				this.emojis.push(emoji);
				categoryEmojis.push(emoji);

				this.surrogateToName.set(data.surrogates, data.names[0]);
				data.skins?.forEach((skin, i) => {
					this.surrogateToName.set(skin.surrogates, `${data.names[0]}::skin-tone-${i + 1}`);
				});
			});

			this.emojisByCategory.set(category, categoryEmojis);
		});
	}

	findEmojiByName(name: string): string | null {
		const emoji = this.emojis.find((e) => e.names.includes(name));
		return emoji?.surrogatePair ?? null;
	}

	findEmojiWithSkinTone(name: string, skinTone: number): string | null {
		if (skinTone < 1 || skinTone > 5) return null;
		const emoji = this.emojis.find((e) => e.names.includes(name));
		if (!emoji?.hasSkins) return null;

		const skinName = `${emoji.uniqueName}::skin-tone-${skinTone}`;
		return emoji.skinsByNameMap.get(skinName)?.surrogatePair ?? null;
	}

	getSurrogateName(surrogate: string, includeColons = true): string {
		const name = this.surrogateToName.get(surrogate) ?? '';
		return includeColons ? `:${name}:` : name;
	}

	getCategoryIcon(category: EmojiCategory) {
		return CATEGORY_ICONS[category] ?? CATEGORY_ICONS.people;
	}

	getCategoryLabel(category: EmojiCategory): string {
		const labels = {
			people: i18n.Messages.EMOJI_CATEGORY_PEOPLE,
			nature: i18n.Messages.EMOJI_CATEGORY_NATURE,
			food: i18n.Messages.EMOJI_CATEGORY_FOOD,
			activity: i18n.Messages.EMOJI_CATEGORY_ACTIVITY,
			travel: i18n.Messages.EMOJI_CATEGORY_TRAVEL,
			objects: i18n.Messages.EMOJI_CATEGORY_OBJECTS,
			symbols: i18n.Messages.EMOJI_CATEGORY_SYMBOLS,
			flags: i18n.Messages.EMOJI_CATEGORY_FLAGS,
		};
		return labels[category] ?? '';
	}

	getCategories(): Array<EmojiCategory> {
		return CATEGORIES;
	}

	getCategoryForEmoji(emoji: UnicodeEmoji): EmojiCategory | null {
		for (const [category, emojis] of this.emojisByCategory.entries()) {
			if (emojis.includes(emoji)) return category;
		}
		return null;
	}

	forEachEmoji(callback: (emoji: UnicodeEmoji) => void): void {
		this.emojis.forEach(callback);
	}
}

export default new UnicodeEmojis();
