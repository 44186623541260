import type {Popout, PopoutKey} from '~/components/uikit/Popout';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';

type State = {
	readonly popouts: Readonly<Record<PopoutKey, Popout>>;
};

class PopoutStore extends Store<State> {
	constructor() {
		super({popouts: {}});
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'POPOUT_OPEN': {
				const {popout} = action;
				this.setState((prevState) => {
					if (!popout.dependsOn) {
						return {
							popouts: {[popout.key]: popout},
						};
					}
					const parentChain = this.getParentPopoutChain(popout.dependsOn, prevState.popouts);
					return {
						popouts: {
							...parentChain,
							[popout.key]: popout,
						},
					};
				});
				popout.onOpen?.();
				return true;
			}
			case 'POPOUT_CLOSE': {
				const {key} = action;
				if (key == null) {
					this.setState({popouts: {}});
					return true;
				}
				this.setState((prevState) => {
					const closingPopout = prevState.popouts[key];
					const newPopouts = this.createNewRef(prevState.popouts);

					// Get the parent chain before removing the popout
					const parentChain = closingPopout?.dependsOn
						? this.getParentPopoutChain(closingPopout.dependsOn, prevState.popouts)
						: {};

					// Remove the popout and its dependents
					this.removePopoutAndDependents(key, newPopouts);

					// Restore the parent chain
					Object.assign(newPopouts, parentChain);

					closingPopout?.onClose?.();
					return {popouts: newPopouts};
				});
				return true;
			}
			case 'POPOUT_CLOSE_ALL':
				this.setState({popouts: {}});
				return true;
			case 'POPOUT_REPOSITION': {
				const {key} = action;
				this.setState((prevState) => {
					const existingPopout = prevState.popouts[key];
					if (!existingPopout) return prevState;
					return {
						popouts: {
							...prevState.popouts,
							[key]: {
								...existingPopout,
								shouldReposition: true,
							},
						},
					};
				});
				return true;
			}
			default:
				return false;
		}
	}

	isOpen(key: PopoutKey): boolean {
		return key in this.state.popouts;
	}

	getPopouts(): ReadonlyArray<Popout> {
		return Object.values(this.state.popouts);
	}

	private getParentPopoutChain(
		dependsOnKey: PopoutKey,
		allPopouts: Record<PopoutKey, Popout>,
	): Record<PopoutKey, Popout> {
		const result: Record<PopoutKey, Popout> = {};
		let currentKey = dependsOnKey;
		while (currentKey != null) {
			const popout = allPopouts[currentKey];
			if (!popout) break;
			result[currentKey] = popout;
			currentKey = popout.dependsOn as PopoutKey;
		}
		return result;
	}

	private removePopoutAndDependents(key: PopoutKey, popouts: Record<PopoutKey, Popout>): void {
		const dependentKeys = Object.entries(popouts)
			.filter(([_, popout]) => popout.dependsOn === key)
			.map(([k]) => k);

		dependentKeys.forEach((depKey) => {
			this.removePopoutAndDependents(depKey, popouts);
		});
		delete popouts[key];
	}
}

export default new PopoutStore();
