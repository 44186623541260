import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {AuthSession} from '~/records/AuthSessionRecord';
import AuthSessionStore from '~/stores/AuthSessionStore';

// Create a dedicated logger for auth sessions operations
const logger = new Logger('AuthSessionsService');

/**
 * Fetch all active authentication sessions for the current user
 */
export const fetch = async (): Promise<void> => {
	logger.debug('Fetching authentication sessions');
	Dispatcher.dispatch({type: 'AUTH_SESSIONS_FETCH_PENDING'});

	try {
		const response = await http.get<Array<AuthSession>>({
			url: Endpoints.AUTH_SESSIONS,
			retries: 2, // Retry on network or server errors
		});

		logger.info(`Fetched ${response.data.length} authentication sessions`);

		Dispatcher.dispatch({
			type: 'AUTH_SESSIONS_FETCH_SUCCESS',
			authSessions: response.data,
		});
	} catch (error) {
		logger.error('Failed to fetch authentication sessions:', error);

		Dispatcher.dispatch({
			type: 'AUTH_SESSIONS_FETCH_ERROR',
		});

		throw error;
	}
};

/**
 * Log out specific sessions identified by their hashes
 */
export const logout = async (sessionIdHashes: Array<string>, password: string): Promise<void> => {
	if (!sessionIdHashes.length) {
		logger.warn('Attempted to logout with empty session list');
		return;
	}

	logger.debug(`Logging out ${sessionIdHashes.length} sessions`);
	Dispatcher.dispatch({
		type: 'AUTH_SESSIONS_LOGOUT_PENDING',
		sessionIdHashes,
	});

	try {
		await http.post({
			url: Endpoints.AUTH_SESSIONS_LOGOUT,
			body: {
				session_id_hashes: sessionIdHashes,
				password,
			},
			// Use longer timeout since this is a sensitive operation
			timeout: 10000,
			// Don't retry automatically for security operations
			retries: 0,
		});

		logger.info(`Successfully logged out ${sessionIdHashes.length} sessions`);

		Dispatcher.dispatch({
			type: 'AUTH_SESSIONS_LOGOUT_SUCCESS',
			sessionIdHashes,
		});
	} catch (error) {
		logger.error('Failed to log out sessions:', error);

		Dispatcher.dispatch({
			type: 'AUTH_SESSIONS_LOGOUT_ERROR',
			sessionIdHashes,
		});

		throw error;
	}
};

/**
 * Convenience method to log out a single session
 */
export const logoutSession = async (sessionIdHash: string, password: string): Promise<void> => {
	return logout([sessionIdHash], password);
};

/**
 * Log out all sessions except the current one
 */
export const logoutAllOtherSessions = async (password: string): Promise<void> => {
	const currentSessionId = AuthSessionStore.getAuthSessionIdHash();

	if (!currentSessionId) {
		logger.warn('Cannot determine current session ID');
		throw new Error('Current session information unavailable');
	}

	// Get all sessions that are not the current one
	const sessionsToLogout = AuthSessionStore.getAuthSessions()
		.filter((session) => session.id !== currentSessionId)
		.map((session) => session.id);

	if (sessionsToLogout.length === 0) {
		logger.info('No other sessions to log out');
		return;
	}

	await logout(sessionsToLogout, password);
};
