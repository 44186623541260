import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import ChannelStore from '~/stores/ChannelStore';
import GuildStore from '~/stores/GuildStore';
import ReadStateStore from '~/stores/ReadStateStore';
import * as ReadStateUtils from '~/utils/ReadStateUtils';

// Create a logger for read state operations
const logger = new Logger('ReadStates');

/**
 * Interface for bulk read state acknowledgment
 */
type AckBulkReadState = {
	channel_id: string;
	message_id: string;
};

/**
 * Interface for message acknowledgment parameters
 */
type AckMessageParams = {
	channelId: string;
	messageId: string;
	mentionCount?: number;
	manual?: boolean;
};

// Map to track pending read state commits with timeouts
const pendingCommits: Map<string, NodeJS.Timeout> = new Map();

/**
 * Schedule a commit for a read state with debouncing
 */
const scheduleCommit = (channelId: string, messageId: string, mentionCount: number, manual?: boolean) => {
	// Clear existing timeout if present (debounce)
	if (pendingCommits.has(channelId)) {
		clearTimeout(pendingCommits.get(channelId)!);
		logger.debug(`Cleared pending read state commit for channel ${channelId}`);
	}

	// Set new timeout for commit
	pendingCommits.set(
		channelId,
		setTimeout(async () => {
			pendingCommits.delete(channelId);

			// Verify the read state is still valid before committing
			const readState = ReadStateStore.getReadState(channelId);
			if (!readState || readState.message_id !== messageId) {
				logger.debug(`Read state for channel ${channelId} changed, skipping commit`);
				return;
			}

			try {
				logger.debug(`Committing read state for channel ${channelId} at message ${messageId}`);
				await http.post({
					url: Endpoints.CHANNEL_MESSAGE_ACK(channelId, readState.message_id),
					body: {manual, mention_count: mentionCount},
				});

				logger.debug(`Successfully committed read state for channel ${channelId}`);
			} catch (error) {
				logger.error(`Failed to commit read state for channel ${channelId}:`, error);
				// No need to rethrow as this is a background operation
			}
		}, 3000),
	);

	logger.debug(`Scheduled read state commit for channel ${channelId} in 3 seconds`);
};

/**
 * Acknowledge a read state for a message
 */
export const ack = async ({channelId, messageId, mentionCount = 0, manual}: AckMessageParams): Promise<void> => {
	// Optimistically update the UI
	Dispatcher.dispatch({
		type: 'MESSAGE_ACK',
		channelId,
		messageId,
		mentionCount,
		manual,
		optimistic: true,
	});

	logger.debug(`Optimistically acknowledged message ${messageId} in channel ${channelId}`);

	// Check if we already have a non-optimistic read state with this message
	const readState = ReadStateStore.getReadState(channelId);
	if (readState && !readState.optimistic && readState.message_id === messageId) {
		logger.debug(`Already have non-optimistic read state for ${channelId}, skipping commit`);
		return;
	}

	// Schedule the commit to persist the read state
	scheduleCommit(channelId, messageId, mentionCount, manual);
};

/**
 * Acknowledge multiple read states at once
 */
export const ackBulk = async (readStates: Array<AckBulkReadState>): Promise<void> => {
	try {
		// Optimistically update UI for all read states
		for (const readState of readStates) {
			Dispatcher.dispatch({
				type: 'MESSAGE_ACK',
				channelId: readState.channel_id,
				messageId: readState.message_id,
				mentionCount: 0,
				optimistic: true,
			});
		}

		logger.debug(`Optimistically acknowledged ${readStates.length} read states`);

		// Persist the bulk acknowledgment
		await http.post({
			url: Endpoints.READ_STATES_ACK_BULK,
			body: {read_states: readStates},
		});

		logger.debug(`Successfully committed ${readStates.length} bulk read states`);
	} catch (error) {
		logger.error('Failed to acknowledge bulk read states:', error);
		throw error;
	}
};

/**
 * Acknowledge all unread messages in a guild
 */
export const ackGuild = async (guildId: string): Promise<void> => {
	try {
		const guild = GuildStore.getGuild(guildId);
		if (!guild) {
			logger.warn(`Could not find guild ${guildId} for acknowledgment`);
			return;
		}

		// Collect all unread channels
		const readStates: Array<AckBulkReadState> = [];
		for (const channel of ChannelStore.getGuildChannels(guildId)) {
			const readState = ReadStateStore.getReadState(channel.id);
			const hasUnreadMessages = ReadStateUtils.hasUnreadMessages(channel, readState);

			if (!hasUnreadMessages) {
				continue;
			}

			if (!channel.lastMessageId) {
				logger.debug(`Channel ${channel.id} has no last message ID, skipping`);
				continue;
			}

			readStates.push({
				channel_id: channel.id,
				message_id: channel.lastMessageId as string,
			});
		}

		if (readStates.length > 0) {
			logger.debug(`Acknowledging ${readStates.length} channels in guild ${guildId}`);
			await ackBulk(readStates);
		} else {
			logger.debug(`No unread channels in guild ${guildId}`);
		}
	} catch (error) {
		logger.error(`Failed to acknowledge all messages in guild ${guildId}:`, error);
		throw error;
	}
};

/**
 * Delete a read state for a channel
 */
export const deleteReadState = async (channelId: string): Promise<void> => {
	try {
		logger.debug(`Deleting read state for channel ${channelId}`);
		await http.delete({url: Endpoints.CHANNEL_MESSAGES_ACK(channelId)});
		logger.debug(`Successfully deleted read state for channel ${channelId}`);
	} catch (error) {
		logger.error(`Failed to delete read state for channel ${channelId}:`, error);
		throw error;
	}
};
