import {DateTime} from 'luxon';
import {i18n} from '~/i18n';
import {TimestampStyle} from '../parser';

/**
 * Formats a time according to the specified style
 */
export function formatTime(date: DateTime, longFormat: boolean): string {
	return longFormat
		? date.millisecond
			? date.toFormat('h:mm:ss.SSS a')
			: date.toFormat('h:mm:ss a')
		: date.toLocaleString(DateTime.TIME_SIMPLE);
}

/**
 * Formats a date according to the specified style
 */
export function formatDate(date: DateTime, longFormat: boolean): string {
	return longFormat ? date.toLocaleString(DateTime.DATE_FULL) : date.toLocaleString(DateTime.DATE_SHORT);
}

/**
 * Checks if the given date is today
 */
export function isToday(date: DateTime): boolean {
	const today = DateTime.now();
	return date.hasSame(today, 'day');
}

/**
 * Checks if the given date is yesterday
 */
export function isYesterday(date: DateTime): boolean {
	const yesterday = DateTime.now().minus({days: 1});
	return date.hasSame(yesterday, 'day');
}

/**
 * Formats a timestamp as a relative time string (e.g., "2 hours ago")
 */
export function formatRelativeTime(timestamp: number): string {
	const date = DateTime.fromSeconds(timestamp);
	const now = DateTime.now();

	if (isToday(date)) {
		return i18n.format(i18n.Messages.TIME_TODAY, {
			time: formatTime(date, false),
		});
	}

	if (isYesterday(date)) {
		return i18n.format(i18n.Messages.TIME_YESTERDAY, {
			time: formatTime(date, false),
		});
	}

	const diff = date.diff(now).shiftTo('years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds');
	const {years, months, weeks, days, hours, minutes, seconds} = diff.toObject();

	if (years && Math.abs(years) > 0) {
		const key = date > now ? i18n.Messages.TIME_RELATIVE_YEARS_FROM_NOW : i18n.Messages.TIME_RELATIVE_YEARS_AGO;
		return i18n.format(key, {time: Math.abs(Math.round(years))});
	}

	if (months && Math.abs(months) > 0) {
		const key = date > now ? i18n.Messages.TIME_RELATIVE_MONTHS_FROM_NOW : i18n.Messages.TIME_RELATIVE_MONTHS_AGO;
		return i18n.format(key, {time: Math.abs(Math.round(months))});
	}

	if (weeks && Math.abs(weeks) > 0) {
		const key = date > now ? i18n.Messages.TIME_RELATIVE_WEEKS_FROM_NOW : i18n.Messages.TIME_RELATIVE_WEEKS_AGO;
		return i18n.format(key, {time: Math.abs(Math.round(weeks))});
	}

	if (days && Math.abs(days) > 0) {
		const key = date > now ? i18n.Messages.TIME_RELATIVE_DAYS_FROM_NOW : i18n.Messages.TIME_RELATIVE_DAYS_AGO;
		return i18n.format(key, {time: Math.abs(Math.round(days))});
	}

	if (hours && Math.abs(hours) > 0) {
		const key = date > now ? i18n.Messages.TIME_RELATIVE_HOURS_FROM_NOW : i18n.Messages.TIME_RELATIVE_HOURS_AGO;
		return i18n.format(key, {time: Math.abs(Math.round(hours))});
	}

	if (minutes && Math.abs(minutes) > 0) {
		const key = date > now ? i18n.Messages.TIME_RELATIVE_MINUTES_FROM_NOW : i18n.Messages.TIME_RELATIVE_MINUTES_AGO;
		return i18n.format(key, {time: Math.abs(Math.round(minutes))});
	}

	const key = date > now ? i18n.Messages.TIME_RELATIVE_SECONDS_FROM_NOW : i18n.Messages.TIME_RELATIVE_SECONDS_AGO;
	return i18n.format(key, {time: Math.abs(Math.round(seconds ?? 1))});
}

/**
 * Formats a timestamp according to the specified style
 */
export function formatTimestamp(timestamp: number, style: TimestampStyle): string {
	const date = DateTime.fromMillis(timestamp * 1000);

	switch (style) {
		case TimestampStyle.ShortTime:
			return formatTime(date, false);

		case TimestampStyle.LongTime:
			return formatTime(date, true);

		case TimestampStyle.ShortDate:
			return formatDate(date, false);

		case TimestampStyle.LongDate:
			return formatDate(date, true);

		case TimestampStyle.ShortDateTime:
			return date.toLocaleString(DateTime.DATETIME_SHORT);

		case TimestampStyle.LongDateTime:
			return date.toFormat("cccc, LLLL d, yyyy 'at' h:mm:ss a");

		case TimestampStyle.RelativeTime:
			return formatRelativeTime(timestamp);

		default:
			return date.toLocaleString(DateTime.DATETIME_SHORT);
	}
}
