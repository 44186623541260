import 'highlight.js/styles/github-dark.css';
import 'katex/dist/katex.min.css';

import '~/i18n';
import '~/tailwind.css';

import {IconContext} from '@phosphor-icons/react';
import {FocusGuard, LOCK_STACK} from 'focus-layers';
import React from 'react';
import type {ReactNode} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import * as WindowActionCreators from '~/actions/WindowActionCreators';
import {ChannelIndexPage} from '~/components/channel/ChannelIndexPage';
import {ChannelLayout} from '~/components/channel/ChannelLayout';
import {FluxerIcon} from '~/components/icons/FluxerIcon';
import {AppLayout} from '~/components/layout/AppLayout';
import {AuthLayout} from '~/components/layout/AuthLayout';
import {GuildLayout} from '~/components/layout/GuildLayout';
import {GuildsLayout} from '~/components/layout/GuildsLayout';
import {Modals} from '~/components/modals/Modals';
import {ForgotPasswordPage} from '~/components/pages/ForgotPasswordPage';
import {LoginPage} from '~/components/pages/LoginPage';
import {RegisterPage} from '~/components/pages/RegisterPage';
import {ResetPasswordPage} from '~/components/pages/ResetPasswordPage';
import {VerifyEmailPage} from '~/components/pages/VerifyEmailPage';
import {Button} from '~/components/uikit/Button/Button';
import {Popouts} from '~/components/uikit/Popout/Popouts';
import {SVGMasks} from '~/components/uikit/SVGMasks';
import {Toasts} from '~/components/uikit/Toast/Toasts';
import {Tooltips} from '~/components/uikit/Tooltip/Tooltips';
import {i18n} from '~/i18n';
import AppStorage from '~/lib/AppStorage';
import AccessibilityStore from '~/stores/AccessibilityStore';
import UserSettingsStore from '~/stores/UserSettingsStore';
import * as ContextMenuUtils from '~/utils/ContextMenuUtils';
import * as RouterUtils from '~/utils/RouterUtils';

const ErrorFallback = () => {
	return (
		<div className="flex h-screen flex-col items-center justify-center gap-3 px-4 py-8 text-center">
			<FluxerIcon className="h-24 w-24" />
			<div className="my-2 flex flex-col items-center gap-2 text-center">
				<h1 className="font-bold text-3xl">{i18n.Messages.ERROR_BOUNDARY_TITLE}</h1>
				<p className="text-text-primary-muted">{i18n.Messages.ERROR_BOUNDARY_DESCRIPTION}</p>
			</div>
			<div className="flex flex-col gap-4 md:flex-row">
				<Button onClick={() => location.reload()}>{i18n.Messages.ERROR_BOUNDARY_RELOAD}</Button>
				<Button
					onClick={() => {
						AppStorage.clear();
						location.reload();
					}}
					variant="danger"
				>
					{i18n.Messages.ERROR_BOUNDARY_RESET_APP}
				</Button>
			</div>
		</div>
	);
};

const NotFound = () => {
	return (
		<div className="flex h-screen flex-col items-center justify-center gap-3 px-4 py-8 text-center">
			<FluxerIcon className="h-24 w-24" />
			<div className="my-2 flex flex-col items-center gap-2 text-center">
				<h1 className="font-bold text-3xl">{i18n.Messages.NOT_FOUND_TITLE}</h1>
				<p className="text-text-primary-muted">{i18n.Messages.NOT_FOUND_DESCRIPTION}</p>
			</div>
			<div className="flex flex-col gap-4 md:flex-row">
				<Button
					onClick={() => {
						window.location.href = '/';
					}}
				>
					{i18n.Messages.NOT_FOUND_BUTTON}
				</Button>
			</div>
		</div>
	);
};

type AppWrapperProps = {
	children: ReactNode;
};

const AppWrapper = ({children}: AppWrapperProps) => {
	const userSettings = UserSettingsStore.useStore();
	const {saturationFactor, alwaysUnderlineLinks, enableTextSelection} = AccessibilityStore.useStore();
	const [focusLockActive, setFocusLockActive] = React.useState(false);

	React.useEffect(() => {
		if (import.meta.env.PUBLIC_BUILD_SHA && import.meta.env.PUBLIC_BUILD_TIMESTAMP) {
			console.info(`[BUILD INFO] ${import.meta.env.PUBLIC_BUILD_SHA} - ${import.meta.env.PUBLIC_BUILD_TIMESTAMP}`);
		}

		const preventScroll = (event: Event) => event.preventDefault();
		const handleBlur = () => WindowActionCreators.focus(false);
		const handleFocus = () => WindowActionCreators.focus(true);
		const handleResize = () => WindowActionCreators.resized();
		const handleContextMenu = ContextMenuUtils.contextMenuCallbackWeb;

		document.addEventListener('scroll', preventScroll);
		window.addEventListener('blur', handleBlur);
		window.addEventListener('focus', handleFocus);
		window.addEventListener('mousedown', handleFocus);
		window.addEventListener('resize', handleResize);
		window.addEventListener('contextmenu', handleContextMenu, false);

		return () => {
			document.removeEventListener('scroll', preventScroll);
			window.removeEventListener('blur', handleBlur);
			window.removeEventListener('focus', handleFocus);
			window.removeEventListener('mousedown', handleFocus);
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('contextmenu', handleContextMenu, false);
		};
	}, []);

	React.useEffect(() => {
		return LOCK_STACK.subscribe(setFocusLockActive);
	}, []);

	React.useEffect(() => {
		const htmlNode = document.documentElement;
		htmlNode.classList.add(`theme-${userSettings.theme}`);
		htmlNode.style.setProperty('--saturation-factor', saturationFactor.toString());
		htmlNode.style.setProperty('--user-select', enableTextSelection ? 'auto' : 'none');

		if (alwaysUnderlineLinks) {
			htmlNode.style.setProperty('--link-decoration', 'underline');
		} else {
			htmlNode.style.removeProperty('--link-decoration');
		}

		return () => {
			htmlNode.classList.remove(`theme-${userSettings.theme}`);
			htmlNode.style.removeProperty('--saturation-factor');
			htmlNode.style.removeProperty('--link-decoration');
			htmlNode.style.removeProperty('--user-select');
		};
	}, [userSettings.theme, saturationFactor, alwaysUnderlineLinks, enableTextSelection]);

	React.useEffect(() => {
		let styleElement: HTMLStyleElement | null = null;
		if (userSettings.custom_css) {
			styleElement = document.createElement('style');
			styleElement.textContent = userSettings.custom_css;
			document.head.appendChild(styleElement);
		}
		return () => {
			if (styleElement) {
				document.head.removeChild(styleElement);
			}
		};
	}, [userSettings.custom_css]);

	return (
		<IconContext.Provider value={{color: 'currentColor', weight: 'fill'}}>
			<SVGMasks />
			<FocusGuard />
			<div aria-hidden={focusLockActive}>{children}</div>
			<Modals />
			<Popouts />
			<Toasts />
			<Tooltips />
			<FocusGuard />
		</IconContext.Provider>
	);
};

export const App = () => {
	return (
		<Router history={RouterUtils.getHistory()}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<AppWrapper>
					<Switch>
						<Route exact path="/" render={() => <Redirect to="/channels" />} />

						<Route path={['/login', '/register', '/forgot', '/reset', '/verify']}>
							<AuthLayout>
								<Switch>
									<Route path="/login" component={LoginPage} />
									<Route path="/register" component={RegisterPage} />
									<Route path="/forgot" component={ForgotPasswordPage} />
									<Route path="/reset" component={ResetPasswordPage} />
									<Route path="/verify" component={VerifyEmailPage} />
								</Switch>
							</AuthLayout>
						</Route>

						<Route
							path="/channels/:guildId?/:channelId?"
							render={({match}: any) => {
								const {guildId, channelId} = match.params;
								return (
									<AppLayout>
										<GuildsLayout>
											{guildId && (
												<GuildLayout>
													{channelId && (
														<ChannelLayout>
															<ChannelIndexPage />
														</ChannelLayout>
													)}
												</GuildLayout>
											)}
										</GuildsLayout>
									</AppLayout>
								);
							}}
						/>

						<Route component={NotFound} />
					</Switch>
				</AppWrapper>
			</ErrorBoundary>
		</Router>
	);
};
