import React from 'react';
import {useForm} from 'react-hook-form';
import {Link, Redirect, useHistory} from 'react-router-dom';
import * as AuthenticationActionCreators from '~/actions/AuthenticationActionCreators';
import * as ToastActionCreators from '~/actions/ToastActionCreators';
import {Card, CardWrapper} from '~/components/form/Card';
import {Form} from '~/components/form/Form';
import {Input} from '~/components/form/Input';
import {Button} from '~/components/uikit/Button/Button';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import AuthenticationStore from '~/stores/AuthenticationStore';

type ResetPasswordForm = {
	password: string;
	confirmPassword: string;
};

export const ResetPasswordPage = () => {
	const [token, setToken] = React.useState<string | null>(null);
	const history = useHistory();
	const form = useForm<ResetPasswordForm>();
	const {token: authToken} = AuthenticationStore.useStore();

	React.useEffect(() => {
		// Extract token from URL hash
		const hash = window.location.hash;
		if (hash?.startsWith('#token=')) {
			setToken(hash.substring(7));
		}
	}, []);

	const onSubmit = async (data: ResetPasswordForm) => {
		if (!token) {
			ToastActionCreators.createToast({
				children: i18n.Messages.RESET_PASSWORD_INVALID_TOKEN_TITLE,
				type: 'error',
			});
			return;
		}

		if (data.password !== data.confirmPassword) {
			form.setError('confirmPassword', {
				message: 'Passwords do not match',
			});
			return;
		}

		try {
			const result = await AuthenticationActionCreators.resetPassword(token, data.password);
			Dispatcher.dispatch({type: 'SESSION_START', token: result.token});
			history.push('/');
		} catch (error) {
			console.error(error);
			ToastActionCreators.createToast({
				children: i18n.Messages.RESET_PASSWORD_ERROR,
				type: 'error',
			});
		}
	};

	if (authToken) {
		return <Redirect to="/" />;
	}

	if (!token) {
		return (
			<CardWrapper>
				<Card
					title={i18n.Messages.RESET_PASSWORD_INVALID_TOKEN_TITLE}
					description={i18n.Messages.RESET_PASSWORD_INVALID_TOKEN_DESCRIPTION}
				>
					<Button onClick={() => history.push('/forgot')}>{i18n.Messages.TRY_AGAIN}</Button>
				</Card>
			</CardWrapper>
		);
	}

	return (
		<CardWrapper>
			<Card title={i18n.Messages.RESET_PASSWORD_TITLE} description={i18n.Messages.RESET_PASSWORD_DESCRIPTION}>
				<Form className="flex w-full flex-col gap-4" form={form} onSubmit={onSubmit}>
					<Input
						{...form.register('password')}
						autoComplete="new-password"
						autoFocus={true}
						error={form.formState.errors.password?.message}
						label={i18n.Messages.NEW_PASSWORD}
						type="password"
						placeholder="•••••••••••••"
						required={true}
					/>

					<Input
						{...form.register('confirmPassword')}
						autoComplete="new-password"
						error={form.formState.errors.confirmPassword?.message}
						label={i18n.Messages.CONFIRM_PASSWORD}
						type="password"
						placeholder="•••••••••••••"
						required={true}
					/>

					<div className="flex flex-col gap-2.5">
						<Button type="submit" submitting={form.formState.isSubmitting}>
							{i18n.Messages.RESET_PASSWORD}
						</Button>
						<div className="text-sm text-text-tertiary">
							<Link className="text-text-link hover:underline" to="/login">
								{i18n.Messages.RETURN_TO_LOGIN}
							</Link>
						</div>
					</div>
				</Form>
			</Card>
		</CardWrapper>
	);
};
