import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';

// Create a logger for typing operations
const logger = new Logger('Typing');

/**
 * Send a typing indicator to a channel
 */
export const sendTyping = async (channelId: string): Promise<void> => {
	try {
		logger.debug(`Sending typing indicator to channel ${channelId}`);
		await http.post({url: Endpoints.CHANNEL_TYPING(channelId)});

		logger.debug(`Successfully sent typing indicator to channel ${channelId}`);
	} catch (error) {
		logger.error(`Failed to send typing indicator to channel ${channelId}:`, error);
		// Not rethrowing error here as typing failures are not critical to user experience
		// and should be silently handled to avoid disrupting the typing flow
	}
};

/**
 * Stop typing indicator for a user in a channel
 */
export const stopTyping = (channelId: string, userId: string): void => {
	try {
		logger.debug(`Stopping typing indicator for user ${userId} in channel ${channelId}`);
		Dispatcher.dispatch({type: 'TYPING_STOP', channelId, userId});

		logger.debug(`Successfully stopped typing indicator for user ${userId} in channel ${channelId}`);
	} catch (error) {
		logger.error(`Failed to stop typing indicator for user ${userId} in channel ${channelId}:`, error);
		// We don't rethrow here as this is a UI-only operation that should not disrupt the user flow
	}
};
