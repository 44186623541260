import type {DragEndEvent} from '@dnd-kit/core';
import {DndContext, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {SortableContext, arrayMove, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {CaretDown, Gear, type Icon, SealCheck, UserPlus} from '@phosphor-icons/react';
import {clsx} from 'clsx';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {ChannelTypes, Permissions, StatusTypeToLabel} from '~/Constants';
import * as GuildActionCreators from '~/actions/GuildActionCreators';
import * as MessageActionCreators from '~/actions/MessageActionCreators';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {TypingUsers, getTypingText, usePresentableTypingUsers} from '~/components/channel/TypingUsers';
import styles from '~/components/layout/GuildNavbar.module.css';
import {ChannelSettingsModal} from '~/components/modals/ChannelSettingsModal';
import {InviteModal} from '~/components/modals/InviteModal';
import {UserSettingsModal} from '~/components/modals/UserSettingsModal';
import {GuildHeaderPopout} from '~/components/popouts/GuildHeaderPopout';
import {UserAreaPopout} from '~/components/popouts/UserAreaPopout';
import {Avatar} from '~/components/uikit/Avatar';
import {MentionBadge} from '~/components/uikit/MentionBadge';
import {Popout} from '~/components/uikit/Popout/Popout';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import Dispatcher from '~/flux/Dispatcher';
import {useHover} from '~/hooks/useHover';
import {useMergeRefs} from '~/hooks/useMergeRefs';
import {i18n} from '~/i18n';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {GuildRecord} from '~/records/GuildRecord';
import type {UserRecord} from '~/records/UserRecord';
import AutocompleteStore from '~/stores/AutocompleteStore';
import ChannelStore from '~/stores/ChannelStore';
import MobileLayoutStore from '~/stores/MobileLayoutStore';
import PresenceStore from '~/stores/PresenceStore';
import ReadStateStore from '~/stores/ReadStateStore';
import UserStore from '~/stores/UserStore';
import * as ChannelUtils from '~/utils/ChannelUtils';
import * as NicknameUtils from '~/utils/NicknameUtils';
import * as PermissionUtils from '~/utils/PermissionUtils';
import * as RouterUtils from '~/utils/RouterUtils';

const ChannelItemIcon = ({
	icon: Icon,
	label,
	isSelected = false,
	onClick,
	className,
}: {
	icon: Icon;
	label: string;
	isSelected?: boolean;
	onClick?: () => void;
	className?: string;
}) => {
	const handleSelect = (event: React.KeyboardEvent | React.MouseEvent) => {
		event.stopPropagation();
		onClick?.();
	};
	return (
		<Tooltip text={label}>
			<div
				className={clsx(
					'flex h-4 w-4 cursor-pointer items-center justify-center rounded-full transition-colors duration-200',
					isSelected ? 'text-text-primary' : 'text-text-primary-muted hover:text-text-primary',
					className,
				)}
				aria-label={label}
				onClick={handleSelect}
				onKeyDown={(event) => event.key === 'Enter' && handleSelect(event)}
				role="button"
				tabIndex={0}
			>
				<Icon className="h-4 w-4" />
			</div>
		</Tooltip>
	);
};

const HOVER_DELAY_MS = 250;
const MESSAGE_PRELOAD_LIMIT = 50;

const ChannelItem = ({
	guild,
	channel,
	isSortingList = false,
}: {
	guild: GuildRecord;
	channel: ChannelRecord;
	isSortingList?: boolean;
}) => {
	const [hoverRef, isHovering] = useHover();
	const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id: channel.id});
	const location = useLocation();
	const channelPath = `/channels/${guild.id}/${channel.id}`;
	const hasUnreadMessages = ReadStateStore.useChannelUnreadMessages(channel.id);
	const isSelected = location.pathname.startsWith(channelPath);
	const mentionCount = ReadStateStore.useChannelMentionCount(channel.id);
	const isHighlight = mentionCount > 0 || hasUnreadMessages;
	const isAutocompleteHighlight = AutocompleteStore.useHighlightChannelId() === channel.id;
	const typingUsers = usePresentableTypingUsers(channel);
	const hoverTimeoutRef = React.useRef<number | null>(null);
	const preloadingRef = React.useRef(false);

	const canManageChannels = PermissionUtils.can(Permissions.MANAGE_CHANNELS, {
		guildId: guild.id,
		channelId: channel.id,
	});
	const canCreateInvite = PermissionUtils.can(Permissions.CREATE_INVITE, {
		guildId: guild.id,
		channelId: channel.id,
	});

	const preloadChannelData = React.useCallback(async () => {
		if (preloadingRef.current) {
			return;
		}
		preloadingRef.current = true;

		try {
			if (channel.type === ChannelTypes.GUILD_LINK) {
				return;
			}
			await MessageActionCreators.fetch(channel.id, {limit: MESSAGE_PRELOAD_LIMIT});
		} catch {}
	}, [channel]);

	const handleMouseEnter = React.useCallback(() => {
		if (hoverTimeoutRef.current) {
			window.clearTimeout(hoverTimeoutRef.current);
		}

		hoverTimeoutRef.current = window.setTimeout(() => {
			preloadChannelData();
		}, HOVER_DELAY_MS);
	}, [preloadChannelData]);

	const handleMouseLeave = React.useCallback(() => {
		if (hoverTimeoutRef.current) {
			window.clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
		}
	}, []);

	React.useEffect(() => {
		return () => {
			if (hoverTimeoutRef.current) {
				window.clearTimeout(hoverTimeoutRef.current);
			}
		};
	}, []);

	const handleSelect = React.useCallback(() => {
		if (channel.type === ChannelTypes.GUILD_LINK) {
			channel.url && window.open(channel.url, '_blank');
			return;
		}

		RouterUtils.transitionTo(channelPath);
		Dispatcher.dispatch({type: 'CHANNEL_SELECT', guildId: guild.id, channelId: channel.id});
		Dispatcher.dispatch({type: 'MOBILE_LAYOUT_STATE_UPDATE', navExpanded: false, chatExpanded: true});
	}, [channel, guild, channelPath]);

	return (
		<div className="relative w-full">
			{!isSelected && hasUnreadMessages && (
				<div className="-left-1 -translate-y-1/2 absolute top-1/2 h-2 w-2 rounded-r-full bg-text-primary" />
			)}

			<div
				{...listeners}
				{...attributes}
				aria-label={channel.name}
				className={clsx(
					'relative mx-2 flex w-full-0 cursor-pointer items-center gap-1.5 overflow-hidden truncate rounded-md px-[8px] py-[6px]',
					isAutocompleteHighlight && 'shadow-[0_0_0_2px_var(--brand-primary)]',
					{
						'text-text-primary': isHighlight,
						'text-text-primary-muted': !(isHighlight || isSelected),
						'bg-background-modifier-selected text-text-primary': isSelected,
						'hover:bg-background-modifier-hover hover:text-text-chat': !isSelected,
					},
				)}
				onClick={handleSelect}
				onKeyDown={(event) => event.key === 'Enter' && handleSelect()}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				ref={useMergeRefs([setNodeRef, hoverRef])}
				style={{
					transform: CSS.Transform.toString(transform),
					transition,
					opacity: isDragging ? 0.5 : 1,
					cursor: isDragging ? 'grabbing' : undefined,
				}}
				role="button"
				tabIndex={0}
			>
				<Tooltip text={ChannelUtils.getName(channel.type)}>
					<div>{ChannelUtils.getIcon(channel.type, {className: 'h-[20px] w-[20px] text-text-primary-muted'})}</div>
				</Tooltip>

				<span className="flex-auto truncate font-medium text-[16px] leading-[20px]">{channel.name}</span>

				{!isSortingList && (
					<div className="ml-auto flex items-center justify-center gap-1">
						{!isSelected && typingUsers.length > 0 && (
							<Tooltip
								text={() => (
									<span className="max-w-lg whitespace-break-spaces break-words">
										{getTypingText(typingUsers, channel)}
									</span>
								)}
							>
								<div>
									<TypingUsers channel={channel} withText={false} />
								</div>
							</Tooltip>
						)}

						{!isSelected && <MentionBadge mentionCount={mentionCount} />}

						{canCreateInvite && (isSelected || isHovering) && (
							<ChannelItemIcon
								icon={UserPlus}
								label={i18n.Messages.INVITE_MEMBERS}
								onClick={() => ModalActionCreators.push(() => <InviteModal channelId={channel.id} />)}
							/>
						)}

						{canManageChannels && (isSelected || isHovering) && (
							<ChannelItemIcon
								icon={Gear}
								label={i18n.Messages.CHANNEL_SETTINGS}
								onClick={() => ModalActionCreators.push(() => <ChannelSettingsModal channelId={channel.id} />)}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const sortChannels = (channels: ReadonlyArray<ChannelRecord>) => [...channels].sort((a, b) => a.position - b.position);

const ChannelList = ({guild}: {guild: GuildRecord}) => {
	const [isDragging, setIsDragging] = React.useState(false);
	const channels = ChannelStore.useGuildChannels(guild.id);
	const mobileLayout = MobileLayoutStore.useStore();
	const sensors = useSensors(useSensor(PointerSensor, {activationConstraint: {distance: 8}}));
	const sortedChannels = React.useMemo(() => sortChannels(channels), [channels]);
	const canManageChannels = PermissionUtils.can(Permissions.MANAGE_CHANNELS, {guildId: guild.id});

	const handleDragEnd = React.useCallback(
		(event: DragEndEvent) => {
			const {active, over} = event;
			if (over && active.id !== over.id) {
				const oldIndex = sortedChannels.findIndex((channel) => channel.id === active.id);
				const newIndex = sortedChannels.findIndex((channel) => channel.id === over.id);
				const newArray = arrayMove(channels.slice(), oldIndex, newIndex);
				GuildActionCreators.sortChannels(
					guild.id,
					newArray.map((channel) => channel.id),
				);
			}
			setIsDragging(false);
		},
		[channels, sortedChannels, guild.id],
	);

	const renderChannel = React.useCallback(
		(channel: ChannelRecord) => (
			<ChannelItem channel={channel} key={channel.id} guild={guild} isSortingList={isDragging} />
		),
		[guild, isDragging],
	);

	return (
		<ScrollArea className="bg-background-tertiary">
			<div className="h-2.5" />
			<div className="flex flex-col gap-1">
				<div className="flex flex-col gap-1 md:max-w-[15rem]">
					<DndContext
						modifiers={[restrictToVerticalAxis]}
						onDragEnd={handleDragEnd}
						onDragStart={() => setIsDragging(true)}
						sensors={sensors}
					>
						<SortableContext
							disabled={!canManageChannels || channels.length === 1 || mobileLayout.enabled}
							items={sortedChannels.map((channel) => channel.id)}
							strategy={verticalListSortingStrategy}
						>
							{sortedChannels.map((channel) => renderChannel(channel))}
						</SortableContext>
					</DndContext>
				</div>
			</div>
			<div className="h-2.5" />
		</ScrollArea>
	);
};

const GuildHeader = ({guild}: {guild: GuildRecord}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	return (
		<Popout
			uniqueId="guild-header"
			render={() => <GuildHeaderPopout guild={guild} />}
			position="bottom"
			onOpen={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
		>
			<div className="flex cursor-pointer items-center justify-center gap-1 border-theme-border border-b-theme-border-width bg-background-header-primary px-4 transition-colors duration-300 ease-in-out hover:bg-background-header-primary-hover aria-expanded:bg-background-header-primary-hover md:w-60">
				<div className="flex items-center gap-1 truncate">
					{guild.features.has('VERIFIED') && (
						<Tooltip text={i18n.Messages.GUILD_VERIFIED_TOOLTIP} position="bottom">
							<SealCheck className="h-4 w-4 flex-shrink-0 text-text-primary" />
						</Tooltip>
					)}
					<span className="truncate font-semibold text-text-primary">{guild.name}</span>
				</div>
				<CaretDown
					weight="bold"
					className="ml-auto h-4 w-4 flex-shrink-0 text-text-primary"
					style={{
						transform: `rotate3d(0, 0, -1, ${isOpen ? 180 : 0}deg)`,
						transition: 'transform .2s ease',
					}}
				/>
			</div>
		</Popout>
	);
};

const UserArea = ({user}: {user: UserRecord}) => {
	const [hoverRef, isHovering] = useHover();
	const [isOpen, setIsOpen] = React.useState(false);
	const forceHover = isHovering || isOpen;
	const status = PresenceStore.useUserStatus(user.id);

	return (
		<div
			ref={hoverRef}
			className="relative flex items-center justify-between gap-2 border-theme-border border-t-theme-border-width bg-background-secondary-alt px-2 font-medium text-[14px] text-text-primary-muted"
		>
			<Popout
				uniqueId="user-area"
				render={() => <UserAreaPopout />}
				position="top"
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
			>
				<div
					className={clsx(
						'-ml-[2px] relative flex cursor-pointer items-center rounded-md pl-[2px] transition-colors duration-200 ease-in-out hover:bg-background-modifier-hover',
						isOpen && 'bg-background-modifier-hover',
						'min-w-0 flex-1',
					)}
				>
					<Avatar user={user} size={32} status={status} />
					<div className="ml-2 min-w-0 flex-1 select-text py-1">
						<div className="truncate font-semibold text-sm text-text-primary leading-[18px]">
							{NicknameUtils.getNickname(user)}
						</div>
						<div className="truncate text-[12px] text-text-primary-muted leading-[13px]">
							<div className={clsx(styles.hoverRoll, forceHover && styles.forceHover)}>
								<div className={styles.hovered}>{user.handle}</div>
								<div className={styles.default}>{StatusTypeToLabel[status as keyof typeof StatusTypeToLabel]}</div>
							</div>
						</div>
					</div>
				</div>
			</Popout>

			<div className="flex flex-initial items-stretch">
				<Tooltip text={i18n.Messages.USER_SETTINGS}>
					<button
						type="button"
						aria-label={i18n.Messages.USER_SETTINGS}
						className="relative flex h-8 w-8 cursor-pointer items-center justify-center rounded-md text-text-primary-muted leading-[0px] transition-colors duration-100 ease-in-out hover:bg-background-modifier-hover hover:text-text-primary"
						onClick={() => ModalActionCreators.push(UserSettingsModal)}
					>
						<Gear className="h-5 w-5" />
					</button>
				</Tooltip>
			</div>
		</div>
	);
};

export const GuildNavbar = ({guild}: {guild: GuildRecord}) => {
	const user = UserStore.useCurrentUser();
	if (!user) {
		return null;
	}
	return (
		<div className="grid h-full min-h-0 w-full min-w-0 select-none grid-rows-[3.5rem,1fr] overflow-hidden border-theme-border border-x-theme-border-width bg-background-secondary md:grid-rows-[3.5rem,1fr,3.5rem]">
			<GuildHeader guild={guild} />
			<ChannelList guild={guild} />
			<UserArea user={user} />
		</div>
	);
};
