import {FormattingContext} from '../parser/formatting-context';
import {type Node, NodeType, ParserFlags, type ParserResult} from '../types';
import {MAX_LINE_LENGTH} from '../types/constants';
import * as ASTUtils from '../utils/ast-utils';
import * as StringUtils from '../utils/string-utils';
import * as EmojiParsers from './emoji-parsers';
import * as LinkParsers from './link-parsers';
import * as MentionParsers from './mention-parsers';
import * as TimestampParsers from './timestamp-parsers';

/**
 * Main entry point for parsing inline content
 * Parses text into an array of inline nodes
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Array of parsed nodes
 */
export function parseInline(text: string, parserFlags: number): Array<Node> {
	const context = new FormattingContext();
	const nodes = parseInlineWithContext(text, context, parserFlags);
	ASTUtils.flattenAST(nodes);
	return nodes;
}

/**
 * Parses inline content with a given formatting context
 * The context tracks the state of active formatting markers
 *
 * @param text - The text to parse
 * @param context - The formatting context to use
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Array of parsed nodes
 */
export function parseInlineWithContext(text: string, context: FormattingContext, parserFlags: number): Array<Node> {
	if (!text) {
		return [];
	}

	const nodes: Array<Node> = [];
	let accumulatedText = '';
	let position = 0;
	const characters = [...text];

	while (position < characters.length) {
		// Handle escaped characters
		if (characters[position] === '\\' && position + 1 < characters.length) {
			const nextChar = characters[position + 1];
			if (StringUtils.isEscapableCharacter(nextChar)) {
				accumulatedText += nextChar;
				position += 2;
				continue;
			}
		}

		// Get remaining text for specific parsers
		const remainingText = characters.slice(position).join('');

		// Check if we're in a quoted angle bracket context
		let insideQuotedAngleBracket = false;
		if (accumulatedText.endsWith('<"') || accumulatedText.endsWith("<'")) {
			insideQuotedAngleBracket = true;
		}

		// Skip URL extraction if inside quoted angle brackets
		if (!insideQuotedAngleBracket && StringUtils.startsWithUrl(remainingText)) {
			const urlResult = LinkParsers.extractUrlSegment(remainingText, parserFlags);
			if (urlResult) {
				ASTUtils.addTextNode(nodes, accumulatedText);
				accumulatedText = '';
				nodes.push(urlResult.node);
				position += urlResult.advance;
				continue;
			}
		}

		// Handle underscore that's part of a word, not formatting
		if (StringUtils.isWordUnderscore(characters, position)) {
			accumulatedText += '_';
			position += 1;
			continue;
		}

		// Try to parse emoji
		const emojiResult = EmojiParsers.parseEmoji(remainingText);
		if (emojiResult) {
			ASTUtils.addTextNode(nodes, accumulatedText);
			accumulatedText = '';
			nodes.push(emojiResult.node);
			position += emojiResult.advance;
			continue;
		}

		// Try to parse special sequence (links, formatting, mentions, etc.)
		context.setCurrentText(accumulatedText);
		const specialResult = parseSpecialSequence(remainingText, context, parserFlags);
		if (specialResult) {
			ASTUtils.addTextNode(nodes, accumulatedText);
			accumulatedText = '';
			nodes.push(specialResult.node);
			position += specialResult.advance;
			continue;
		}

		// Add current character to accumulated text
		accumulatedText += characters[position];
		position += 1;

		// Prevent excessive text length
		if (accumulatedText.length > MAX_LINE_LENGTH) {
			ASTUtils.addTextNode(nodes, accumulatedText);
			accumulatedText = '';
			break;
		}
	}

	// Add any remaining text
	ASTUtils.addTextNode(nodes, accumulatedText);

	// Merge adjacent text nodes for efficiency
	return ASTUtils.mergeTextNodes(nodes);
}

/**
 * Tries to parse a special sequence like formatting, links, mentions, etc.
 *
 * @param text - The text to parse
 * @param context - The formatting context
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Parser result or null if no special sequence found
 */
export function parseSpecialSequence(
	text: string,
	context: FormattingContext,
	parserFlags: number,
): ParserResult | null {
	return (
		LinkParsers.parseAutolink(text, parserFlags) ||
		LinkParsers.parseEmailLink(text, parserFlags) ||
		parseFormatting(text, context, parserFlags) ||
		MentionParsers.parseMention(text, parserFlags) ||
		MentionParsers.parseSimpleMention(text, parserFlags) ||
		TimestampParsers.parseTimestamp(text) ||
		(parserFlags & ParserFlags.ALLOW_MASKED_LINKS
			? LinkParsers.parseLink(text, parserFlags, (t) => parseInline(t, parserFlags))
			: null)
	);
}

/**
 * Parses text formatting markers like bold, italic, etc.
 *
 * @param text - The text to parse
 * @param context - The formatting context
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Parser result or null if no formatting found
 */
export function parseFormatting(text: string, context: FormattingContext, parserFlags: number): ParserResult | null {
	const markerInfo = getFormattingMarkerInfo(text);
	if (!markerInfo) return null;

	const {marker, nodeType, markerLength} = markerInfo;

	// For spoilers, check if they're allowed
	if (nodeType === NodeType.Spoiler && !(parserFlags & ParserFlags.ALLOW_SPOILERS)) {
		return null;
	}

	// Check if we can enter this formatting in the current context
	if (!context.canEnterFormatting(marker[0], marker.length > 1)) return null;

	const endResult = findFormattingEnd(text, marker, markerLength, nodeType);
	if (!endResult) return null;

	const {endPosition, innerContent} = endResult;
	const isBlock = context.isFormattingActive(marker[0], marker.length > 1);

	// Create the formatting node
	const formattingNode = createFormattingNode(
		nodeType,
		innerContent,
		marker,
		isBlock,
		(text: string, ctx: FormattingContext) => parseInlineWithContext(text, ctx, parserFlags),
	);

	return {node: formattingNode, advance: endPosition + markerLength};
}

/**
 * Gets information about a formatting marker at the start of text
 *
 * @param text - The text to analyze
 * @returns Information about the marker or null if no marker found
 */
export function getFormattingMarkerInfo(
	text: string,
): {marker: string; nodeType: NodeType; markerLength: number} | null {
	const chars = [...text];

	// Check for triple markers (***text*** or ___text___)
	if (chars.length >= 3) {
		if (chars[0] === '*' && chars[1] === '*' && chars[2] === '*') {
			return {marker: '***', nodeType: NodeType.TripleAsterisk, markerLength: 3};
		}
		if (chars[0] === '_' && chars[1] === '_' && chars[2] === '_') {
			return {marker: '___', nodeType: NodeType.TripleUnderscore, markerLength: 3};
		}
	}

	// Check for double markers (**text**, __text__, ~~text~~, ||text||)
	if (chars.length >= 2) {
		if (chars[0] === '|' && chars[1] === '|') {
			return {marker: '||', nodeType: NodeType.Spoiler, markerLength: 2};
		}
		if (chars[0] === '~' && chars[1] === '~') {
			return {marker: '~~', nodeType: NodeType.Strikethrough, markerLength: 2};
		}
		if (chars[0] === '*' && chars[1] === '*') {
			return {marker: '**', nodeType: NodeType.Strong, markerLength: 2};
		}
		if (chars[0] === '_' && chars[1] === '_') {
			return {marker: '__', nodeType: NodeType.Underline, markerLength: 2};
		}
	}

	// Check for single markers (`code`, *text*, _text_)
	if (chars.length >= 1) {
		if (chars[0] === '`') {
			return {marker: '`', nodeType: NodeType.InlineCode, markerLength: 1};
		}
		if (chars[0] === '*') {
			return {marker: '*', nodeType: NodeType.Emphasis, markerLength: 1};
		}
		if (chars[0] === '_') {
			return {marker: '_', nodeType: NodeType.Emphasis, markerLength: 1};
		}
	}

	return null;
}

/**
 * Finds the end of a formatting section
 *
 * @param text - The text to search
 * @param marker - The formatting marker to find
 * @param markerLength - The length of the marker
 * @param nodeType - The type of formatting node
 * @returns The end position and inner content, or null if no end found
 */
export function findFormattingEnd(
	text: string,
	marker: string,
	markerLength: number,
	nodeType: NodeType,
): {endPosition: number; innerContent: string} | null {
	const chars = [...text];
	let position = markerLength;
	let nestedLevel = 0;
	let endPosition: number | null = null;

	// Inline code has simpler rules - just find the next backtick
	if (nodeType === NodeType.InlineCode) {
		while (position < chars.length) {
			if (chars[position] === '`') {
				endPosition = position;
				break;
			}
			position++;
			if (position > MAX_LINE_LENGTH) break;
		}
	} else {
		// For other formatting, handle nesting and escaping
		while (position < chars.length) {
			// Check for escaped characters first
			if (chars[position] === '\\' && position + 1 < chars.length) {
				position += 2;
				continue;
			}

			// Check if we've found our closing marker
			if (StringUtils.matchMarker(chars, position, marker)) {
				if (nestedLevel === 0) {
					endPosition = position;
					break;
				}
				nestedLevel--;
			} else if (
				position + marker.length <= chars.length &&
				marker.length > 1 &&
				chars[position] === marker[0] &&
				chars[position + 1] === marker[0]
			) {
				// Handle potential nested markers of the same type
				nestedLevel++;
			}

			position++;
			if (position > MAX_LINE_LENGTH) break;
		}
	}

	if (endPosition === null) return null;

	const innerChars = chars.slice(markerLength, endPosition);
	return {endPosition, innerContent: innerChars.join('')};
}

/**
 * Creates a formatting node with the given parameters
 *
 * @param nodeType - The type of formatting node
 * @param innerContent - The content inside the formatting markers
 * @param marker - The formatting marker
 * @param isBlock - Whether this is a block-level element
 * @param parseInlineWithContext - Function to parse the inner content
 * @returns The created formatting node
 */
export function createFormattingNode(
	nodeType: NodeType,
	innerContent: string,
	marker: string,
	isBlock,
	parseInlineWithContext: (text: string, context: FormattingContext) => Array<Node>,
): Node {
	// Create a new context for nested formatting
	const newContext = new FormattingContext();
	newContext.pushFormatting(marker[0], marker.length > 1);

	switch (nodeType) {
		case NodeType.InlineCode:
			return {type: NodeType.InlineCode, content: innerContent};

		case NodeType.TripleAsterisk:
		case NodeType.TripleUnderscore: {
			// Triple markers create nested strong + emphasis
			const emphasisContext = new FormattingContext();
			emphasisContext.pushFormatting('*', true);
			const innerNodes = parseInlineWithContext(innerContent, emphasisContext);
			return {
				type: NodeType.Emphasis,
				children: [{type: NodeType.Strong, children: innerNodes}],
			};
		}

		case NodeType.Strong:
			return {
				type: NodeType.Strong,
				children: parseInlineWithContext(innerContent, newContext),
			};

		case NodeType.Emphasis:
			return {
				type: NodeType.Emphasis,
				children: parseInlineWithContext(innerContent, newContext),
			};

		case NodeType.Underline:
			return {
				type: NodeType.Underline,
				children: parseInlineWithContext(innerContent, newContext),
			};

		case NodeType.Strikethrough:
			return {
				type: NodeType.Strikethrough,
				children: parseInlineWithContext(innerContent, newContext),
			};

		case NodeType.Spoiler:
			return {
				type: NodeType.Spoiler,
				children: parseInlineWithContext(innerContent, newContext),
				isBlock,
			};

		case NodeType.Sequence:
			return {
				type: NodeType.Sequence,
				children: parseInlineWithContext(innerContent, newContext),
			};

		default:
			// This should never happen if we've defined all formatting node types
			throw new Error(`Unexpected node type: ${nodeType}`);
	}
}
