import {Crown} from '@phosphor-icons/react';
import {clsx} from 'clsx';
import {OFFLINE_STATUS_TYPES, UserTypes} from '~/Constants';
import {PreloadableUserPopout} from '~/components/channel/PreloadableUserPopout';
import {UserTag} from '~/components/channel/UserTag';
import {Avatar} from '~/components/uikit/Avatar';
import {ScrollArea} from '~/components/uikit/ScrollArea';
import {Tooltip} from '~/components/uikit/Tooltip/Tooltip';
import {i18n} from '~/i18n';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {GuildMemberRecord} from '~/records/GuildMemberRecord';
import type {GuildRecord} from '~/records/GuildRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ChannelMemberStore, {type MemberGroup} from '~/stores/GuildMemberListStore';
import PresenceStore from '~/stores/PresenceStore';
import TypingStore from '~/stores/TypingStore';
import * as NicknameUtils from '~/utils/NicknameUtils';

const ChannelMember = ({
	guild,
	channelId,
	member,
}: {
	guild: GuildRecord;
	channelId: string;
	member: GuildMemberRecord;
}) => {
	const isTyping = TypingStore.useIsTyping(channelId, member.user.id);
	const status = PresenceStore.useUserStatus(member.user.id);

	return (
		<PreloadableUserPopout user={member.user} isWebhook={false} guildId={guild.id} position="left-start">
			<div
				className={clsx(
					'relative my-px ml-2 cursor-pointer rounded-md text-text-chat',
					!member.isCurrentUser() && OFFLINE_STATUS_TYPES.has(status as any) && 'opacity-30',
					'hover:bg-background-modifier-hover hover:text-text-primary hover:opacity-100 aria-expanded:bg-background-modifier-selected aria-expanded:text-text-primary aria-expanded:opacity-100',
				)}
			>
				<div className="grid h-[42px] min-w-0 grid-cols-[1fr,auto] items-center gap-1 px-2">
					<span className="flex items-center gap-2.5 overflow-hidden font-medium">
						<div className="flex h-8 w-8 flex-none items-center justify-center">
							<Avatar
								user={member.user}
								status={status}
								size={32}
								isTyping={isTyping}
								showOffline={member.user.id === AuthenticationStore.getId() || isTyping}
							/>
						</div>
						<div className="flex min-w-0 flex-grow items-center truncate">
							<span className="truncate" style={{color: member.getHighestRoleColor()}}>
								{NicknameUtils.getNickname(member.user, guild.id)}
							</span>
							{guild.isOwner(member.user.id) && (
								<div className="mt-[0.2em] ml-[4px]">
									<Tooltip text={i18n.Messages.GUILD_OWNER}>
										<Crown className="h-[14px] w-[14px] text-[hsl(39,57%,64%)]" />
									</Tooltip>
								</div>
							)}
							{member.user.type === UserTypes.AUTOMATED && <UserTag className="-mt-[.3em]" />}
						</div>
					</span>
				</div>
			</div>
		</PreloadableUserPopout>
	);
};

const MemberListGroup = ({guild, group, channelId}: {guild: GuildRecord; group: MemberGroup; channelId: string}) => (
	<div className="flex flex-col">
		<div className="px-4 font-medium text-sm text-text-primary-muted">
			{group.displayName} — {group.count}
		</div>
		<div className="flex flex-col">
			{group.members.map((member) => (
				<ChannelMember guild={guild} channelId={channelId} key={member.user.id} member={member} />
			))}
		</div>
		<div className="h-2.5" />
	</div>
);

export const ChannelMembers = ({guild, channel}: {guild: GuildRecord; channel: ChannelRecord}) => {
	const memberList = ChannelMemberStore.useMemberList(guild.id, channel.id);
	return (
		<div className="h-full min-h-0 w-[100vw] min-w-0 overflow-hidden border-theme-border border-x-theme-border-width bg-background-tertiary md:w-[15rem]">
			<ScrollArea className="flex flex-col gap-4 bg-background-tertiary pr-2">
				<div className="h-2.5" />
				{memberList.groups.map((group) => (
					<MemberListGroup guild={guild} channelId={channel.id} group={group} key={group.id} />
				))}
				<div className="h-2.5" />
			</ScrollArea>
		</div>
	);
};
