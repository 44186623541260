import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {Message} from '~/records/MessageRecord';

// Create a logger for pins operations
const logger = new Logger('Pins');

/**
 * Fetch pinned messages for a channel
 */
export const fetch = async (channelId: string) => {
	Dispatcher.dispatch({type: 'CHANNEL_PINS_FETCH_PENDING', channelId});

	try {
		const {data} = await http.get<Array<{message: Message}>>({
			url: Endpoints.CHANNEL_PINS(channelId),
		});

		const messages = data.map(({message}) => message);

		Dispatcher.dispatch({
			type: 'CHANNEL_PINS_FETCH_SUCCESS',
			channelId,
			messages,
		});

		return messages;
	} catch (error) {
		logger.error(`Failed to fetch pins for channel ${channelId}:`, error);

		Dispatcher.dispatch({
			type: 'CHANNEL_PINS_FETCH_ERROR',
			channelId,
			error,
		});

		throw error;
	}
};

/**
 * Pin a message in a channel
 */
export const pin = async (channelId: string, messageId: string): Promise<void> => {
	try {
		await http.put({
			url: Endpoints.CHANNEL_PIN(channelId, messageId),
		});

		logger.debug(`Pinned message ${messageId} in channel ${channelId}`);
	} catch (error) {
		logger.error(`Failed to pin message ${messageId} in channel ${channelId}:`, error);
		throw error;
	}
};

/**
 * Unpin a message from a channel
 */
export const unpin = async (channelId: string, messageId: string): Promise<void> => {
	try {
		await http.delete({
			url: Endpoints.CHANNEL_PIN(channelId, messageId),
		});

		logger.debug(`Unpinned message ${messageId} from channel ${channelId}`);
	} catch (error) {
		logger.error(`Failed to unpin message ${messageId} from channel ${channelId}:`, error);
		throw error;
	}
};
