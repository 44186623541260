/**
 * Options for configuring exponential backoff behavior
 */
export interface ExponentialBackoffOptions {
	/** Minimum delay in milliseconds */
	minDelay: number;

	/** Maximum delay in milliseconds */
	maxDelay: number;

	/** Maximum number of retry attempts (default: Infinity) */
	maxNumOfAttempts?: number;

	/** Multiplicative factor for backoff growth (default: 2) */
	factor?: number;

	/** Whether to add randomized jitter to prevent thundering herd (default: true) */
	jitter?: boolean;

	/** Maximum jitter percentage as a decimal (default: 0.25 = 25%) */
	jitterFactor?: number;
}

/**
 * Implements an exponential backoff strategy with configurable parameters
 * for handling retry logic in networking scenarios.
 *
 * Features:
 * - Exponential growth with configurable factor
 * - Min/max delay bounds
 * - Optional jitter to prevent thundering herd
 * - Attempt counting and limiting
 */
export class ExponentialBackoff {
	private attempts = 0;
	private readonly factor: number;
	private readonly maxAttempts: number;
	private readonly useJitter: boolean;
	private readonly jitterFactor: number;

	/**
	 * Creates a new exponential backoff instance
	 *
	 * @param options Configuration options for the backoff strategy
	 * @throws Error if invalid options are provided
	 */
	constructor(private readonly options: ExponentialBackoffOptions) {
		// Validate options
		if (options.minDelay <= 0) {
			throw new Error('minDelay must be greater than 0');
		}

		if (options.maxDelay < options.minDelay) {
			throw new Error('maxDelay must be greater than or equal to minDelay');
		}

		// Set defaults and store options
		this.factor = options.factor ?? 2;
		this.maxAttempts = options.maxNumOfAttempts ?? Number.POSITIVE_INFINITY;
		this.useJitter = options.jitter ?? true;
		this.jitterFactor = options.jitterFactor ?? 0.25; // 25% jitter by default

		// Additional validation
		if (this.factor <= 1) {
			throw new Error('factor must be greater than 1');
		}

		if (this.maxAttempts <= 0) {
			throw new Error('maxNumOfAttempts must be greater than 0');
		}

		if (this.jitterFactor < 0 || this.jitterFactor > 1) {
			throw new Error('jitterFactor must be between 0 and 1');
		}
	}

	/**
	 * Calculate the next backoff delay in milliseconds
	 *
	 * @returns The calculated delay with optional jitter applied
	 */
	next(): number {
		// Increment attempt counter
		this.attempts++;

		// Calculate base delay with exponential growth, bounded by min/max
		const baseDelay = Math.min(this.options.minDelay * this.factor ** (this.attempts - 1), this.options.maxDelay);

		// Apply jitter if enabled
		if (this.useJitter) {
			// Calculate maximum jitter amount (both positive and negative)
			const maxJitter = baseDelay * this.jitterFactor;

			// Apply random jitter between -maxJitter and +maxJitter
			const jitter = (Math.random() * 2 - 1) * maxJitter;

			// Ensure final delay stays within bounds
			return Math.max(this.options.minDelay, Math.min(baseDelay + jitter, this.options.maxDelay));
		}

		return baseDelay;
	}

	/**
	 * Get the current attempt count
	 */
	getCurrentAttempts(): number {
		return this.attempts;
	}

	/**
	 * Get the maximum allowed attempts
	 */
	getMaxAttempts(): number {
		return this.maxAttempts;
	}

	/**
	 * Check if maximum retry attempts have been reached
	 */
	isExhausted(): boolean {
		return this.attempts >= this.maxAttempts;
	}

	/**
	 * Get the minimum possible delay
	 */
	getMinDelay(): number {
		return this.options.minDelay;
	}

	/**
	 * Get the maximum possible delay
	 */
	getMaxDelay(): number {
		return this.options.maxDelay;
	}

	/**
	 * Reset the attempt counter to start fresh
	 */
	reset(): void {
		this.attempts = 0;
	}

	/**
	 * Creates a simple backoff that doubles delay each attempt
	 *
	 * @param minDelay Starting delay in milliseconds
	 * @param maxDelay Maximum delay in milliseconds
	 * @param maxAttempts Maximum number of attempts
	 * @returns A configured ExponentialBackoff instance
	 */
	static create(minDelay: number, maxDelay: number, maxAttempts?: number): ExponentialBackoff {
		return new ExponentialBackoff({
			minDelay,
			maxDelay,
			maxNumOfAttempts: maxAttempts,
		});
	}
}
