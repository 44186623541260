/**
 * Application Constants and Types
 *
 * This module defines all the constants, enums and types used throughout the application.
 * Constants are organized into logical sections based on their domain.
 */

import {i18n} from '~/i18n';

// -------------------------------------------------------------------------
// System Constants
// -------------------------------------------------------------------------

/** Epoch timestamp (January 1, 2015) used for ID generation */
export const FLUXER_EPOCH = 1420070400000;

/** Special ID representing the current user */
export const ME = '@me';

/** ID used for system-generated messages */
export const SYSTEM_USER_ID = 0n;

// -------------------------------------------------------------------------
// Limits and Specifications
// -------------------------------------------------------------------------

/**
 * Application numeric limits
 */
export const Limits = {
	/** Maximum attachments allowed per message */
	ATTACHMENTS_PER_MESSAGE: 10,

	/** Maximum pins allowed per channel */
	CHANNEL_PINS: 100,

	/** Maximum embeds allowed per message */
	EMBEDS_PER_MESSAGE: 10,

	/** Maximum favorite GIFs a user can save */
	FAVORITE_GIFS: 1000,

	/** Maximum reactions allowed per message */
	REACTIONS_PER_MESSAGE: 30,

	/** Maximum channels allowed per guild */
	GUILD_CHANNELS: 500,

	/** Maximum animated emojis allowed per guild */
	GUILD_EMOJIS_ANIMATED: 250,

	/** Maximum static emojis allowed per guild */
	GUILD_EMOJIS_STATIC: 250,

	/** Maximum invites allowed per guild */
	GUILD_INVITES: 1000,

	/** Maximum roles allowed per guild */
	GUILD_ROLES: 250,

	/** Maximum guilds a user can be in */
	GUILDS: 200,

	/** Maximum users per message reaction */
	USERS_PER_MESSAGE_REACTION: 5000,

	/** Maximum webhooks allowed per channel */
	WEBHOOKS_PER_CHANNEL: 15,

	/** Maximum webhooks allowed per guild */
	WEBHOOKS_PER_GUILD: 1000,
} as const;

// -------------------------------------------------------------------------
// Media Settings
// -------------------------------------------------------------------------

/**
 * Media size limits (in bytes)
 */
export const MediaSizes = {
	/** Maximum avatar file size (10 MB) */
	AVATAR_MAX: 10 * 1024 * 1024,

	/** Maximum emoji file size after processing (384 KB) */
	EMOJI_MAX: 384 * 1024,

	/** Maximum unprocessed emoji file size (1 MB) */
	EMOJI_MAX_UNPROCESSED: 1024 * 1024,

	/** Maximum attachment file size (1 GB) */
	ATTACHMENT_MAX: 1024 * 1024 * 1024,
} as const;

/**
 * Media format specifications
 */
export const MediaFormats = {
	/** Allowed avatar file extensions */
	AVATAR_EXTENSIONS: new Set(['jpeg', 'png', 'webp', 'gif']),

	/** Allowed emoji file extensions */
	EMOJI_EXTENSIONS: new Set(['jpeg', 'png', 'gif']),

	/** Emoji skin tone modifiers */
	SKIN_TONE_SURROGATES: ['🏻', '🏼', '🏽', '🏾', '🏿'],
} as const;

/**
 * Regular expressions for parsing mentions
 */
export const MentionRegex = {
	/** Matches user mentions: <@!123456789012345678> or <@123456789012345678> */
	USER: /<@!?(?<userId>\d+)>/g,

	/** Matches channel mentions: <#123456789012345678> */
	CHANNEL: /<#(?<channelId>\d+)>/g,

	/** Matches role mentions: <@&123456789012345678> */
	ROLE: /<@&(?<roleId>\d+)>/g,
} as const;

// -------------------------------------------------------------------------
// Role and Color Constants
// -------------------------------------------------------------------------

/** Default role color (light gray) */
export const DEFAULT_ROLE_COLOR = 0x99aab5;

/**
 * Standard role colors for default role selector
 * These are Discord's default role colors
 */
export const ROLE_COLORS = [
	0x1abc9c, 0x2ecc71, 0x3498db, 0x9b59b6, 0xe91e63, 0xf1c40f, 0xe67e22, 0xe74c3c, 0x95a5a6, 0x607d8b, 0x11806a,
	0x1f8b4c, 0x206694, 0x71368a, 0xad1457, 0xc27c0e, 0xa84300, 0x992d22, 0x979c9f, 0x546e7a,
] as const;

/**
 * Role-related flags
 */
export enum GuildRoleFlags {
	NONE = 0,
	/** Whether the role is shown separately in the sidebar */
	HOISTED = 1 << 0,
	/** Whether the role can be mentioned by anyone */
	MENTIONABLE = 1 << 1,
}

// -------------------------------------------------------------------------
// User-related Types and Constants
// -------------------------------------------------------------------------

/**
 * User types
 */
export enum UserType {
	/** Regular user account */
	DEFAULT = 0,
	/** Bot or automated account */
	AUTOMATED = 1,
	/** System-level account */
	SYSTEM = 2,
}

/**
 * User flags for special properties and badges
 */
export enum UserFlags {
	NONE = 0,
	/** User is a staff member */
	STAFF = 1 << 0,
}

/**
 * Status types for user presence
 */
export enum StatusType {
	/** User is active */
	ONLINE = 'online',
	/** User has enabled Do Not Disturb mode */
	DND = 'dnd',
	/** User is inactive/away */
	IDLE = 'idle',
	/** User appears offline but can see messages */
	INVISIBLE = 'invisible',
	/** User is actually offline */
	OFFLINE = 'offline',
}

/** Status types that should be treated as "offline" */
export const OFFLINE_STATUS_TYPES = new Set([StatusType.OFFLINE, StatusType.INVISIBLE]);

/** Map of status types to display labels */
export const StatusTypeToLabel: Record<StatusType, string> = {
	[StatusType.ONLINE]: i18n.Messages.ONLINE,
	[StatusType.DND]: i18n.Messages.DND,
	[StatusType.IDLE]: i18n.Messages.IDLE,
	[StatusType.INVISIBLE]: i18n.Messages.INVISIBLE,
	[StatusType.OFFLINE]: i18n.Messages.OFFLINE,
};

/** Map of status types to descriptions */
export const StatusTypeToDescription: Record<StatusType, string> = {
	[StatusType.ONLINE]: i18n.Messages.ONLINE_DESCRIPTION,
	[StatusType.DND]: i18n.Messages.DND_DESCRIPTION,
	[StatusType.IDLE]: i18n.Messages.IDLE_DESCRIPTION,
	[StatusType.INVISIBLE]: i18n.Messages.INVISIBLE_DESCRIPTION,
	[StatusType.OFFLINE]: i18n.Messages.OFFLINE_DESCRIPTION,
};

/**
 * Types for recent mentions
 */
export enum RecentMentionType {
	/** Mention targets a specific user */
	USER = 0,
	/** Mention targets everyone in a channel */
	EVERYONE = 1,
	/** Mention targets a role */
	ROLE = 2,
}

/**
 * Friend request source flags
 */
export enum FriendSourceFlags {
	NONE = 0,
	/** Anyone can add the user */
	EVERYONE = 1 << 0,
	/** Only friends of friends can add the user */
	MUTUAL_FRIENDS = 1 << 1,
	/** Only users from shared servers can add the user */
	MUTUAL_GUILDS = 1 << 2,
}

// -------------------------------------------------------------------------
// Theme-related Types
// -------------------------------------------------------------------------

/**
 * UI theme types
 */
export enum ThemeType {
	/** Dark theme (default) */
	DARK = 'dark',
	/** Light theme */
	LIGHT = 'light',
	/** Solarized theme (high contrast) */
	SOLARIZED = 'solarized',
	/** Midnight theme (darker variant) */
	MIDNIGHT = 'midnight',
	/** Twilight theme (purple accents) */
	TWILIGHT = 'twilight',
	/** Sparkle theme (bright and colorful) */
	SPARKLE = 'sparkle',
}

// -------------------------------------------------------------------------
// Message-related Types and Constants
// -------------------------------------------------------------------------

/**
 * Message types
 */
export enum MessageType {
	/** Standard user message */
	DEFAULT = 0,
	/** System message about pinned content */
	CHANNEL_PINNED_MESSAGE = 1,
	/** System message about a user joining */
	USER_JOIN = 2,
	/** Message that is a reply to another message */
	REPLY = 3,
}

/**
 * Message flags
 */
export enum MessageFlags {
	NONE = 0,
	/** Message mentions @everyone or @here */
	MENTION_EVERYONE = 1 << 0,
	/** Message is pinned in its channel */
	PINNED = 1 << 1,
	/** Message has embeds that are not shown */
	SUPPRESS_EMBEDS = 1 << 2,
	/** Message does not trigger notifications */
	SUPPRESS_NOTIFICATIONS = 1 << 3,
}

/**
 * Message attachment flags
 */
export enum MessageAttachmentFlags {
	NONE = 0,
	/** Attachment is marked as a spoiler */
	SPOILER = 1 << 0,
	/** Attachment is animated (like a GIF) */
	ANIMATED = 1 << 1,
}

/**
 * Message embed types
 */
export enum MessageEmbedType {
	/** Rich embed with multiple components */
	RICH = 'rich',
	/** Article/blog post embed */
	ARTICLE = 'article',
	/** Simple link embed */
	LINK = 'link',
	/** Image embed */
	IMAGE = 'image',
	/** Video embed */
	VIDEO = 'video',
	/** Audio embed */
	AUDIO = 'audio',
	/** Animated GIF embed */
	GIFV = 'gifv',
}

/**
 * Message embed media flags
 */
export enum MessageEmbedMediaFlags {
	NONE = 0,
	/** Embedded media is animated */
	ANIMATED = 1 << 0,
}

/**
 * Message delivery states
 */
export enum MessageState {
	/** Message successfully sent to server */
	SENT = 'SENT',
	/** Message is currently being sent */
	SENDING = 'SENDING',
	/** Message failed to send */
	FAILED = 'FAILED',
}

// -------------------------------------------------------------------------
// Channel-related Types and Constants
// -------------------------------------------------------------------------

/**
 * Channel types
 */
export enum ChannelType {
	/** Standard text channel in a guild */
	GUILD_TEXT = 0,
	/** Document/note channel in a guild */
	GUILD_DOCUMENT = 1,
	/** Link collection channel in a guild */
	GUILD_LINK = 2,
}

/** Channel types that belong to guilds */
export const GUILD_CHANNEL_TYPES = new Set([
	ChannelType.GUILD_TEXT,
	ChannelType.GUILD_DOCUMENT,
	ChannelType.GUILD_LINK,
]);

/** Channel types that can contain text messages */
export const GUILD_TEXT_CHANNEL_TYPES = new Set([ChannelType.GUILD_TEXT, ChannelType.GUILD_DOCUMENT]);

/**
 * Channel flags
 */
export enum ChannelFlags {
	NONE = 0,
	/** Channel is muted for the current user */
	MUTED = 1 << 0,
}

/**
 * Channel permission overwrite types
 */
export enum ChannelOverwriteType {
	/** Overwrite applies to a role */
	ROLE = 0,
	/** Overwrite applies to a specific user */
	USER = 1,
}

// -------------------------------------------------------------------------
// Guild and Invite Types
// -------------------------------------------------------------------------

/**
 * Invite types
 */
export enum InviteType {
	/** Standard guild invite */
	GUILD = 0,
}

// -------------------------------------------------------------------------
// Permission-related Constants
// -------------------------------------------------------------------------

/**
 * Permission flags for roles and users
 * Using BigInt for 64-bit permission support
 */
export const Permissions = {
	NONE: 0n,
	/** Allows creating invites */
	CREATE_INVITE: 1n << 0n,
	/** Allows kicking members */
	KICK_MEMBERS: 1n << 1n,
	/** Allows banning members */
	BAN_MEMBERS: 1n << 2n,
	/** Grants all permissions and bypasses channel permission overwrites */
	ADMINISTRATOR: 1n << 3n,
	/** Allows management and editing of channels */
	MANAGE_CHANNELS: 1n << 4n,
	/** Allows management and editing of the guild */
	MANAGE_GUILD: 1n << 5n,
	/** Allows adding reactions to messages */
	ADD_REACTIONS: 1n << 6n,
	/** Allows viewing audit log */
	VIEW_AUDIT_LOG: 1n << 7n,
	/** Allows using priority speaker in voice channels */
	PRIORITY_SPEAKER: 1n << 8n,
	/** Allows streaming in voice channels */
	STREAM: 1n << 9n,
	/** Allows viewing channels */
	VIEW_CHANNEL: 1n << 10n,
	/** Allows sending messages in channels */
	SEND_MESSAGES: 1n << 11n,
	/** Allows managing messages of others */
	MANAGE_MESSAGES: 1n << 12n,
	/** Allows embedding links in messages */
	EMBED_LINKS: 1n << 13n,
	/** Allows uploading files */
	ATTACH_FILES: 1n << 14n,
	/** Allows reading message history */
	READ_MESSAGE_HISTORY: 1n << 15n,
	/** Allows using @everyone and @here */
	MENTION_EVERYONE: 1n << 16n,
	/** Allows using emojis from other servers */
	USE_EXTERNAL_EMOJIS: 1n << 17n,
	/** Allows connecting to voice channels */
	CONNECT: 1n << 18n,
	/** Allows speaking in voice channels */
	SPEAK: 1n << 19n,
	/** Allows muting members in voice channels */
	MUTE_MEMBERS: 1n << 20n,
	/** Allows deafening members in voice channels */
	DEAFEN_MEMBERS: 1n << 21n,
	/** Allows moving members between voice channels */
	MOVE_MEMBERS: 1n << 22n,
	/** Allows using voice activity detection */
	USE_VAD: 1n << 23n,
	/** Allows changing own nickname */
	CHANGE_NICKNAME: 1n << 24n,
	/** Allows managing nicknames of others */
	MANAGE_NICKNAMES: 1n << 25n,
	/** Allows managing roles */
	MANAGE_ROLES: 1n << 26n,
	/** Allows managing webhooks */
	MANAGE_WEBHOOKS: 1n << 27n,
	/** Allows managing emojis and stickers */
	MANAGE_GUILD_EXPRESSIONS: 1n << 28n,
	/** Allows using application commands */
	USE_APPLICATION_COMMANDS: 1n << 29n,
	/** Allows requesting to speak in stage channels */
	REQUEST_TO_SPEAK: 1n << 30n,
	/** Allows managing events */
	MANAGE_EVENTS: 1n << 31n,
	/** Allows managing threads */
	MANAGE_THREADS: 1n << 32n,
	/** Allows creating public threads */
	CREATE_PUBLIC_THREADS: 1n << 33n,
	/** Allows creating private threads */
	CREATE_PRIVATE_THREADS: 1n << 34n,
	/** Allows using stickers from other servers */
	USE_EXTERNAL_STICKERS: 1n << 35n,
	/** Allows sending messages in threads */
	SEND_MESSAGES_IN_THREADS: 1n << 36n,
	/** Allows timeouts and other moderation actions */
	MODERATE_MEMBERS: 1n << 37n,
	/** Allows using soundboard in voice channels */
	USE_SOUNDBOARD: 1n << 38n,
	/** Allows creating emojis, stickers, etc. */
	CREATE_GUILD_EXPRESSIONS: 1n << 39n,
	/** Allows creating scheduled events */
	CREATE_EVENTS: 1n << 40n,
	/** Allows using sounds from other servers */
	USE_EXTERNAL_SOUNDS: 1n << 41n,
	/** Allows sending voice messages */
	SEND_VOICE_MESSAGES: 1n << 42n,
	/** Allows creating polls */
	SEND_POLLS: 1n << 43n,
};

/** Combined bitmask of all permissions */
export const ALL_PERMISSIONS = Object.values(Permissions).reduce((acc, p) => acc | p, 0n);

/** Default permissions granted to normal users */
export const DEFAULT_PERMISSIONS =
	Permissions.CREATE_INVITE |
	Permissions.ADD_REACTIONS |
	Permissions.STREAM |
	Permissions.VIEW_CHANNEL |
	Permissions.SEND_MESSAGES |
	Permissions.EMBED_LINKS |
	Permissions.ATTACH_FILES |
	Permissions.READ_MESSAGE_HISTORY |
	Permissions.USE_EXTERNAL_EMOJIS |
	Permissions.CONNECT |
	Permissions.SPEAK |
	Permissions.USE_VAD |
	Permissions.CHANGE_NICKNAME |
	Permissions.USE_APPLICATION_COMMANDS |
	Permissions.REQUEST_TO_SPEAK |
	Permissions.CREATE_PUBLIC_THREADS |
	Permissions.CREATE_PRIVATE_THREADS |
	Permissions.USE_EXTERNAL_STICKERS |
	Permissions.SEND_MESSAGES_IN_THREADS |
	Permissions.USE_SOUNDBOARD |
	Permissions.USE_EXTERNAL_SOUNDS |
	Permissions.SEND_VOICE_MESSAGES |
	Permissions.SEND_POLLS;

// -------------------------------------------------------------------------
// Gateway and API Communication
// -------------------------------------------------------------------------

/**
 * Gateway operation codes
 */
export enum GatewayOpcode {
	/** Server dispatches an event */
	DISPATCH = 0,
	/** Server requests initialization */
	HELLO = 1,
	/** Client sends authentication */
	IDENTIFY = 2,
	/** Client requests to resume a session */
	RESUME = 3,
	/** Client sends a heartbeat */
	HEARTBEAT = 4,
	/** Server acknowledges a heartbeat */
	HEARTBEAT_ACK = 5,
	/** Client updates its presence */
	PRESENCE_UPDATE = 6,
	/** Server reports session is invalid */
	INVALID_SESSION = 7,
	/** Server requests reconnection */
	RECONNECT = 8,
}

/**
 * Gateway close event codes
 */
export enum GatewayCloseCode {
	/** Unknown error occurred */
	UNKNOWN_ERROR = 4000,
	/** Client sent an invalid opcode */
	UNKNOWN_OPCODE = 4001,
	/** Invalid payload format */
	DECODE_ERROR = 4002,
	/** Client sent payload before authenticating */
	NOT_AUTHENTICATED = 4003,
	/** Authentication failed */
	AUTHENTICATION_FAILED = 4004,
	/** Client already authenticated */
	ALREADY_AUTHENTICATED = 4005,
	/** Invalid sequence number in resume */
	INVALID_SEQ = 4006,
	/** Too many requests (rate limited) */
	RATE_LIMITED = 4007,
	/** Client did not send heartbeat in time */
	HEARTBEAT_TIMEOUT = 4008,
	/** Client using invalid API version */
	INVALID_API_VERSION = 4009,
}

/**
 * API error codes
 */
export enum APIErrorCode {
	/** General error */
	GENERAL_ERROR = 0,

	// Resource Errors (1000-1999)
	/** Requested channel does not exist */
	UNKNOWN_CHANNEL = 1000,
	/** Requested invite does not exist */
	UNKNOWN_INVITE = 1001,
	/** Requested message does not exist */
	UNKNOWN_MESSAGE = 1002,
	/** Requested guild does not exist */
	UNKNOWN_GUILD = 1003,
	/** Requested emoji does not exist */
	UNKNOWN_GUILD_EMOJI = 1004,
	/** Requested guild member does not exist */
	UNKNOWN_GUILD_MEMBER = 1005,
	/** Requested role does not exist */
	UNKNOWN_GUILD_ROLE = 1006,
	/** Requested sticker does not exist */
	UNKNOWN_GUILD_STICKER = 1007,
	/** Requested user does not exist */
	UNKNOWN_USER = 1008,
	/** Requested user note does not exist */
	UNKNOWN_USER_NOTE = 1009,
	/** Requested webhook does not exist */
	UNKNOWN_WEBHOOK = 1010,
	/** Requested beta code does not exist */
	UNKNOWN_BETA_CODE = 1011,

	// Permission Errors (2000-2999)
	/** Missing access to resource */
	MISSING_ACCESS = 2000,
	/** Missing specific permission required */
	MISSING_PERMISSIONS = 2001,
	/** User is banned from the guild */
	BANNED_FROM_GUILD = 2002,

	// Limit Errors (3000-3999)
	/** Channel has reached max pins */
	MAX_CHANNEL_PINS = 3000,
	/** User has reached max favorite GIFs */
	MAX_FAVORITE_GIFS = 3001,
	/** Message has reached max reactions */
	MAX_REACTIONS_PER_MESSAGE = 3002,
	/** Guild has reached max channels */
	MAX_GUILD_CHANNELS = 3003,
	/** Guild has reached max animated emojis */
	MAX_GUILD_EMOJIS_ANIMATED = 3004,
	/** Guild has reached max static emojis */
	MAX_GUILD_EMOJIS_STATIC = 3005,
	/** Guild has reached max invites */
	MAX_GUILD_INVITES = 3006,
	/** Guild has reached max members */
	MAX_GUILD_MEMBERS = 3007,
	/** Guild has reached max roles */
	MAX_GUILD_ROLES = 3008,
	/** Guild has reached max stickers */
	MAX_GUILD_STICKERS = 3009,
	/** User has reached max guilds */
	MAX_USER_GUILDS = 3010,
	/** Message reaction has reached max users */
	MAX_USERS_PER_MESSAGE_REACTION = 3011,
	/** Channel has reached max webhooks */
	MAX_WEBHOOKS_PER_CHANNEL = 3012,
	/** Guild has reached max webhooks */
	MAX_WEBHOOKS_PER_GUILD = 3013,

	// Authentication and Validation Errors (4000-4999)
	/** Missing authentication or invalid token */
	UNAUTHORIZED = 4000,
	/** Input validation failed */
	INPUT_VALIDATION_ERROR = 4001,
	/** Cannot send empty message */
	CANNOT_SEND_EMPTY_MESSAGE = 4002,
	/** Target channel does not support messages */
	CANNOT_SEND_MESSAGE_TO_NON_TEXT_CHANNEL = 4003,
	/** Cannot edit message owned by another user */
	CANNOT_EDIT_OTHER_USER_MESSAGE = 4004,
	/** Cannot edit system message */
	CANNOT_EDIT_SYSTEM_MESSAGE = 4005,
	/** MFA not enabled */
	MFA_NOT_ENABLED = 4006,
	/** MFA not disabled */
	MFA_NOT_DISABLED = 4007,
}

// Export backwards compatibility aliases to avoid breaking changes
export const APIErrorCodes = APIErrorCode;
export const ChannelOverwriteTypes = ChannelOverwriteType;
export const ChannelTypes = ChannelType;
export const GatewayCloseCodes = GatewayCloseCode;
export const GatewayOpCodes = GatewayOpcode;
export const InviteTypes = InviteType;
export const MessageEmbedTypes = MessageEmbedType;
export const MessageStates = MessageState;
export const MessageTypes = MessageType;
export const RecentMentionTypes = RecentMentionType;
export const StatusTypes = StatusType;
export const ThemeTypes = ThemeType;
export const UserTypes = UserType;

// Export individual limit constants for backward compatibility
export const MAX_ATTACHMENTS_PER_MESSAGE = Limits.ATTACHMENTS_PER_MESSAGE;
export const MAX_CHANNEL_PINS = Limits.CHANNEL_PINS;
export const MAX_EMBEDS_PER_MESSAGE = Limits.EMBEDS_PER_MESSAGE;
export const MAX_FAVORITE_GIFS = Limits.FAVORITE_GIFS;
export const MAX_REACTIONS_PER_MESSAGE = Limits.REACTIONS_PER_MESSAGE;
export const MAX_GUILD_CHANNELS = Limits.GUILD_CHANNELS;
export const MAX_GUILD_EMOJIS_ANIMATED = Limits.GUILD_EMOJIS_ANIMATED;
export const MAX_GUILD_EMOJIS_STATIC = Limits.GUILD_EMOJIS_STATIC;
export const MAX_GUILD_INVITES = Limits.GUILD_INVITES;
export const MAX_GUILD_ROLES = Limits.GUILD_ROLES;
export const MAX_GUILDS = Limits.GUILDS;
export const MAX_USERS_PER_MESSAGE_REACTION = Limits.USERS_PER_MESSAGE_REACTION;
export const MAX_WEBHOOKS_PER_CHANNEL = Limits.WEBHOOKS_PER_CHANNEL;
export const MAX_WEBHOOKS_PER_GUILD = Limits.WEBHOOKS_PER_GUILD;

// Export individual media size constants for backward compatibility
export const AVATAR_MAX_SIZE = MediaSizes.AVATAR_MAX;
export const EMOJI_MAX_SIZE = MediaSizes.EMOJI_MAX;
export const EMOJI_MAX_UNPROCESSED_SIZE = MediaSizes.EMOJI_MAX_UNPROCESSED;
export const ATTACHMENT_MAX_SIZE = MediaSizes.ATTACHMENT_MAX;

// Export individual media format constants for backward compatibility
export const AVATAR_EXTENSIONS = MediaFormats.AVATAR_EXTENSIONS;
export const EMOJI_EXTENSIONS = MediaFormats.EMOJI_EXTENSIONS;
export const SKIN_TONE_SURROGATES = MediaFormats.SKIN_TONE_SURROGATES;

// Export individual regex constants for backward compatibility
export const USER_MENTION_REGEX = MentionRegex.USER;
export const CHANNEL_MENTION_REGEX = MentionRegex.CHANNEL;
export const ROLE_MENTION_REGEX = MentionRegex.ROLE;
