import {Endpoints} from '~/Endpoints';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {BackupCode} from '~/records/UserRecord';

// Create a logger for MFA operations
const logger = new Logger('MFA');

/**
 * Enable TOTP-based multi-factor authentication for the current user
 * @param secret The TOTP secret
 * @param code The current TOTP code for verification
 * @returns Array of backup codes for the user
 */
export const enableMfaTotp = async (secret: string, code: string): Promise<Array<BackupCode>> => {
	try {
		logger.debug('Enabling TOTP-based MFA');
		const {data} = await http.post<{backup_codes: Array<BackupCode>}>({
			url: Endpoints.USER_MFA_TOTP_ENABLE,
			body: {secret, code},
		});

		logger.debug('Successfully enabled TOTP-based MFA');
		return data.backup_codes;
	} catch (error) {
		logger.error('Failed to enable TOTP-based MFA:', error);
		throw error;
	}
};

/**
 * Disable TOTP-based multi-factor authentication for the current user
 * @param code The current TOTP code for verification
 * @param password The user's password for verification
 */
export const disableMfaTotp = async (code: string, password: string): Promise<void> => {
	try {
		logger.debug('Disabling TOTP-based MFA');
		await http.post({
			url: Endpoints.USER_MFA_TOTP_DISABLE,
			body: {code, password},
		});

		logger.debug('Successfully disabled TOTP-based MFA');
	} catch (error) {
		logger.error('Failed to disable TOTP-based MFA:', error);
		throw error;
	}
};

/**
 * Get or regenerate MFA backup codes
 * @param password The user's password for verification
 * @param regenerate Whether to generate new backup codes
 * @returns Array of backup codes for the user
 */
export const getBackupCodes = async (password: string, regenerate = false): Promise<Array<BackupCode>> => {
	try {
		logger.debug(`${regenerate ? 'Regenerating' : 'Fetching'} MFA backup codes`);
		const {data} = await http.post<{backup_codes: Array<BackupCode>}>({
			url: Endpoints.USER_MFA_BACKUP_CODES,
			body: {password, regenerate},
		});

		logger.debug(`Successfully ${regenerate ? 'regenerated' : 'fetched'} MFA backup codes`);
		return data.backup_codes;
	} catch (error) {
		logger.error(`Failed to ${regenerate ? 'regenerate' : 'fetch'} MFA backup codes:`, error);
		throw error;
	}
};
