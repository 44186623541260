import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {UserSettingsModal} from '~/components/modals/UserSettingsModal';
import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';
import type {UserSettingsTabType} from '~/stores/UserSettingsModalStore';

// Create a logger for user settings operations
const logger = new Logger('UserSettings');

/**
 * Opens the user settings modal with a specific tab selected
 */
export const openUserSettingsTab = (tab: UserSettingsTabType): void => {
	logger.debug(`Opening user settings modal with tab: ${tab}`);
	selectTab(tab);
	ModalActionCreators.push(UserSettingsModal);
};

/**
 * Selects a specific tab in the user settings modal
 */
export const selectTab = (tab: UserSettingsTabType): void => {
	logger.debug(`Selected user settings tab: ${tab}`);
	Dispatcher.dispatch({type: 'USER_SETTINGS_MODAL_TAB_SELECT', tab});
};
