import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';

// Create a dedicated logger for authentication operations
const logger = new Logger('AuthService');

/**
 * Registration request data
 */
export interface RegisterData {
	email: string;
	username: string;
	nickname: string;
	password: string;
	beta_code: string;
}

/**
 * Login response types
 */
interface StandardLoginResponse {
	mfa: false;
	totp: false;
	token: string;
}

interface MfaLoginResponse {
	mfa: true;
	totp: true;
	ticket: string;
}

type LoginResponse = StandardLoginResponse | MfaLoginResponse;

/**
 * Additional response types
 */
interface TokenResponse {
	token: string;
}

interface SuccessResponse {
	success: boolean;
}

/**
 * Login with email and password
 */
export const login = async (email: string, password: string): Promise<LoginResponse> => {
	try {
		const response = await http.post<LoginResponse>({
			url: Endpoints.AUTH_LOGIN,
			body: {email, password},
		});

		logger.debug('Login successful', {mfa: response.data.mfa});
		return response.data;
	} catch (error) {
		logger.error('Login failed', error);
		throw error;
	}
};

/**
 * Complete MFA login with Time-based One-Time Password (TOTP)
 */
export const loginMfaTotp = async (code: string, ticket: string): Promise<TokenResponse> => {
	try {
		const response = await http.post<TokenResponse>({
			url: Endpoints.AUTH_LOGIN_MFA_TOTP,
			body: {code, ticket},
		});

		logger.debug('MFA authentication successful');
		return response.data;
	} catch (error) {
		logger.error('MFA authentication failed', error);
		throw error;
	}
};

/**
 * Register a new account
 */
export const register = async (data: RegisterData): Promise<TokenResponse> => {
	try {
		const response = await http.post<TokenResponse>({
			url: Endpoints.AUTH_REGISTER,
			body: data,
		});

		logger.info('Registration successful');
		return response.data;
	} catch (error) {
		logger.error('Registration failed', error);
		throw error;
	}
};

/**
 * Request password reset email
 * Note: Always returns success for security reasons
 */
export const forgotPassword = async (email: string): Promise<SuccessResponse> => {
	try {
		const response = await http.post<SuccessResponse>({
			url: Endpoints.AUTH_FORGOT_PASSWORD,
			body: {email},
		});

		logger.debug('Password reset email sent');
		return response.data;
	} catch (error) {
		// Always return success for security reasons, even on failure
		logger.warn('Password reset request failed, but returning success to user', error);
		return {success: true};
	}
};

/**
 * Reset password using reset token
 */
export const resetPassword = async (token: string, password: string): Promise<TokenResponse> => {
	try {
		const response = await http.post<TokenResponse>({
			url: Endpoints.AUTH_RESET_PASSWORD,
			body: {token, password},
		});

		logger.info('Password reset successful');
		return response.data;
	} catch (error) {
		logger.error('Password reset failed', error);
		throw error;
	}
};

/**
 * Verify email address using verification token
 */
export const verifyEmail = async (token: string): Promise<SuccessResponse> => {
	try {
		const response = await http.post<SuccessResponse>({
			url: Endpoints.AUTH_VERIFY_EMAIL,
			body: {token},
		});

		logger.info('Email verification successful');
		return response.data;
	} catch (error) {
		logger.error('Email verification failed', error);
		throw error;
	}
};

/**
 * Response for resending verification email
 */
interface ResendVerificationResponse {
	success: boolean;
	rate_limited: boolean;
	retry_after: number;
}

/**
 * Resend verification email for the current user
 */
export const resendVerificationEmail = async (): Promise<ResendVerificationResponse> => {
	try {
		const response = await http.post<ResendVerificationResponse>({
			url: Endpoints.AUTH_RESEND_VERIFICATION,
		});

		logger.info('Verification email resent', {
			rateLimited: response.data.rate_limited,
		});
		return response.data;
	} catch (error) {
		logger.error('Failed to resend verification email', error);
		throw error;
	}
};

/**
 * Handle the client-side logout process
 */
const doLogout = (): void => {
	logger.info('Executing client-side logout');
	Dispatcher.dispatch({type: 'MODAL_POP_ALL'});
	Dispatcher.dispatch({type: 'LOGOUT'});
};

/**
 * Logout current session
 * Will proceed with client-side logout even if the server request fails
 */
export const logout = async (): Promise<void> => {
	try {
		await http.post({
			url: Endpoints.AUTH_LOGOUT,
			timeout: 5000, // Allow for a shorter timeout
			retries: 0, // Don't retry - just log out if it fails
		});

		logger.info('Logout successful');
		doLogout();
	} catch (error) {
		// For any error, still perform client-side logout
		logger.warn('Logout request failed, proceeding with client logout', error);
		doLogout();
	}
};
