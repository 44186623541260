import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {UserPrivate} from '~/records/UserRecord';

// Create a logger for user operations
const logger = new Logger('User');

/**
 * Update the current user's profile information
 *
 * @param user The user data to update, may include avatar, password changes, etc.
 * @returns The updated user object, potentially with a new token
 */
export const update = async (
	user: Partial<UserPrivate> & {
		avatar?: string | null;
		new_password?: string;
		password?: string;
	},
): Promise<UserPrivate & {token?: string}> => {
	try {
		logger.debug('Updating current user profile');

		const {data} = await http.patch<UserPrivate & {token?: string}>({
			url: Endpoints.USER_ME,
			body: user,
		});

		// Clear cached user profiles to ensure fresh data is fetched
		Dispatcher.dispatch({type: 'USER_PROFILES_CLEAR'});

		logger.debug('Successfully updated user profile');

		// Log specific updates (without sensitive information)
		const updatedFields = Object.keys(user).filter((key) => !['password', 'new_password'].includes(key));

		if (updatedFields.length > 0) {
			logger.debug(`Updated fields: ${updatedFields.join(', ')}`);
		}

		// Note if token was refreshed
		if (data.token) {
			logger.debug('Authentication token was refreshed');
		}

		return data;
	} catch (error) {
		logger.error('Failed to update user profile:', error);
		throw error;
	}
};
