import * as ReadStateActionCreators from '~/actions/ReadStateActionCreators';
import type {Action} from '~/flux/ActionTypes';
import {Store} from '~/flux/Store';
import {Logger} from '~/lib/Logger';
import type {Channel} from '~/records/ChannelRecord';
import {type Message, messageMentionsCurrentUser} from '~/records/MessageRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ChannelStore from '~/stores/ChannelStore';
import GuildAvailabilityStore from '~/stores/GuildAvailabilityStore';
import * as ReadStateUtils from '~/utils/ReadStateUtils';
import * as SnowflakeUtils from '~/utils/SnowflakeUtils';

const logger = new Logger('ReadStateStore');
const ONE_HOUR_IN_MS = 60 * 60 * 1000;

export type ReadState = Readonly<{
	channel_id: string;
	message_id: string;
	mention_count: number;
	last_pin_at?: number | null;
	manual?: boolean;
	optimistic?: boolean;
}>;

type ReadStates = Readonly<Record<string, ReadState>>;

type State = Readonly<{
	readonly readStates: ReadStates;
}>;

const initialState: State = {
	readStates: {},
};

class ReadStateStore extends Store<State> {
	constructor() {
		super(initialState);
	}

	handleAction(action: Action): boolean {
		switch (action.type) {
			case 'CONNECTION_OPEN':
				return this.handleConnectionOpen(action);
			case 'MESSAGE_ACK':
				return this.handleMessageAck(action);
			case 'MESSAGE_CREATE':
				return this.handleMessageCreate(action);
			case 'CHANNEL_PINS_ACK':
				return this.handleChannelPinsAck(action);
			case 'CHANNEL_DELETE':
				return this.handleChannelDelete(action);
			default:
				return false;
		}
	}

	private createReadState(channelId: string): ReadState {
		return Object.freeze({
			channel_id: channelId,
			message_id: '',
			mention_count: 0,
		});
	}

	private updateReadStates(updater: (currentStates: ReadStates) => ReadStates): void {
		this.setState((prevState) => ({
			...prevState,
			readStates: updater(prevState.readStates),
		}));
	}

	private handleConnectionOpen({readStates}: {readStates: ReadonlyArray<ReadState>}): boolean {
		const newReadStates = Object.freeze(
			readStates.reduce<Record<string, ReadState>>(
				(acc, readState) => ({
					// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
					...acc,
					[readState.channel_id]: Object.freeze(readState),
				}),
				{},
			),
		);

		this.setState({
			readStates: newReadStates,
		});

		setTimeout(() => this.cleanupReadStates(), 0);
		return true;
	}

	private handleMessageAck({
		channelId,
		messageId,
		mentionCount,
		manual,
		optimistic,
	}: {
		channelId: string;
		messageId: string;
		mentionCount: number;
		manual?: boolean;
		optimistic?: boolean;
	}): boolean {
		this.updateReadStates((currentStates) => ({
			...currentStates,
			[channelId]: Object.freeze({
				...(currentStates[channelId] ?? this.createReadState(channelId)),
				message_id: messageId,
				mention_count: mentionCount,
				manual,
				optimistic,
			}),
		}));
		return true;
	}

	private handleMessageCreate({message}: {message: Message}): boolean {
		const currentUserId = AuthenticationStore.getId();
		const channelId = message.channel_id;

		if (message.nonce != null && message.author.id === currentUserId) {
			this.updateReadStates((currentStates) => ({
				...currentStates,
				[channelId]: Object.freeze({
					...(currentStates[channelId] ?? this.createReadState(channelId)),
					message_id: message.id,
					mention_count: 0,
					manual: false,
				}),
			}));
			return true;
		}

		if (!messageMentionsCurrentUser(message)) {
			return false;
		}

		this.updateReadStates((currentStates) => {
			const existingReadState = currentStates[channelId];
			if (!existingReadState) {
				return currentStates;
			}

			return {
				...currentStates,
				[channelId]: Object.freeze({
					...existingReadState,
					mention_count: existingReadState.mention_count + 1,
				}),
			};
		});

		return true;
	}

	private handleChannelPinsAck({channelId, lastPinAt}: {channelId: string; lastPinAt: number}): boolean {
		this.updateReadStates((currentStates) => {
			const existingReadState = currentStates[channelId];
			if (!existingReadState) {
				return currentStates;
			}

			return {
				...currentStates,
				[channelId]: Object.freeze({
					...existingReadState,
					last_pin_at: lastPinAt,
				}),
			};
		});

		return true;
	}

	private handleChannelDelete({channel}: {channel: Channel}): boolean {
		this.updateReadStates((currentStates) => {
			const {[channel.id]: _, ...remainingStates} = currentStates;
			return remainingStates;
		});
		return true;
	}

	private isReadStateOld(readState: ReadState): boolean {
		return SnowflakeUtils.extractTimestamp(readState.message_id) < Date.now() - ONE_HOUR_IN_MS;
	}

	private cleanupReadStates(): void {
		if (GuildAvailabilityStore.totalUnavailableGuilds > 0) {
			return;
		}

		this.updateReadStates((currentStates) =>
			Object.freeze(
				Object.entries(currentStates).reduce<Record<string, ReadState>>((acc, [channelId, readState]) => {
					const channel = ChannelStore.getChannel(channelId);
					const shouldRemove = !channel && this.isReadStateOld(readState);

					if (shouldRemove) {
						logger.info('Deleting old read state', {channelId});
						ReadStateActionCreators.deleteReadState(channelId);
						return acc;
					}

					return {
						// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
						...acc,
						[channelId]: readState,
					};
				}, {}),
			),
		);
	}

	getReadState(channelId: string): ReadState | null {
		return this.state.readStates[channelId] ?? null;
	}

	useReadState(channelId: string): ReadState | null {
		const {readStates} = this.useStore();
		return readStates[channelId] ?? null;
	}

	useChannelMentionCount(channelId: string): number {
		return this.useReadState(channelId)?.mention_count ?? 0;
	}

	useChannelUnreadMessages(channelId: string): boolean {
		const readState = this.useReadState(channelId);
		const channel = ChannelStore.getChannel(channelId);
		return channel ? ReadStateUtils.hasUnreadMessages(channel, readState) : false;
	}

	useGuildReadStates(guildId: string): ReadonlyArray<ReadState> {
		const {readStates} = this.useStore();
		return Object.freeze(
			ChannelStore.getGuildChannels(guildId)
				.map((channel) => readStates[channel.id])
				.filter((readState): readState is ReadState => readState !== undefined),
		);
	}

	useGuildUnreadMessages(guildId: string): boolean {
		return this.useGuildReadStates(guildId).some((readState) => {
			const channel = ChannelStore.getChannel(readState.channel_id);
			return channel ? ReadStateUtils.hasUnreadMessages(channel, readState) : false;
		});
	}

	useGuildMentionCount(guildId: string): number {
		return this.useGuildReadStates(guildId).reduce((sum, readState) => sum + readState.mention_count, 0);
	}
}

export default new ReadStateStore();
