import EventEmitter from 'eventemitter3';
import type {GatewayOpcode, StatusType} from '~/Constants';
import {GatewayCloseCodes, GatewayOpCodes} from '~/Constants';
import {ExponentialBackoff} from '~/lib/ExponentialBackoff';
import {Logger} from '~/lib/Logger';

// Timeout constants (in milliseconds)
export enum GatewayTimeouts {
	HeartbeatAck = 15000, // How long to wait for heartbeat ack before reconnecting
	ResumeWindow = 180000, // How long a session can be resumed after disconnection (3 minutes)
	MinReconnect = 1000, // Minimum delay between reconnection attempts
	MaxReconnect = 30000, // Maximum delay between reconnection attempts
	Hello = 20000, // How long to wait for HELLO op before timing out
}

// Simplified connection states
export enum GatewayState {
	Disconnected = 'DISCONNECTED', // Not connected to gateway
	Connecting = 'CONNECTING', // Attempting to establish connection
	Connected = 'CONNECTED', // Connection established and authenticated
	Reconnecting = 'RECONNECTING', // Temporarily disconnected, attempting to reconnect
}

// Gateway payload structure
export type GatewayPayload = {
	op: GatewayOpcode;
	d?: unknown;
	s?: number;
	t?: string;
};

// Client identification properties
export type GatewaySocketProperties = {
	os: string;
	browser: string;
	device: string;
	locale: string;
	user_agent: string;
	browser_version: string;
	os_version: string;
	build_timestamp: string;
};

// Connection options
export type GatewaySocketOptions = {
	token: string;
	apiVersion: number;
	properties: GatewaySocketProperties;
};

// Events emitted by the gateway socket
export type GatewaySocketEvents = {
	connecting: () => void;
	connected: () => void;
	ready: (data: unknown) => void;
	resumed: () => void;
	disconnect: (event: {code: number; reason: string; wasClean: boolean}) => void;
	error: (error: Error | Event | CloseEvent) => void;
	message: (payload: GatewayPayload) => void;
	dispatch: (type: string, data: unknown) => void;
	stateChange: (newState: GatewayState, oldState: GatewayState) => void;
	heartbeat: (sequence: number) => void;
	heartbeatAck: () => void;
	networkStatusChange: (online: boolean) => void;
};

/**
 * WebSocket client for Discord Gateway API
 *
 * Handles connection, reconnection, heartbeats, and session management
 * in a single unified class.
 */
export class GatewaySocket extends EventEmitter<GatewaySocketEvents> {
	private readonly logger: Logger;
	private readonly backoff: ExponentialBackoff;

	// WebSocket connection
	private websocket: WebSocket | null = null;
	private state = GatewayState.Disconnected;

	// Session state
	private sessionId: string | null = null;
	private sequence = 0;
	private lastReconnectTime = 0;

	// Heartbeat state
	private heartbeatInterval: number | null = null;
	private heartbeatTimer: number | null = null;
	private ackTimer: number | null = null;
	private waitingForAck = false;
	private lastHeartbeatAckTime: number | null = null;
	private lastHeartbeatTime: number | null = null;

	// Connection state
	private helloTimeout: number | null = null;
	private reconnectTimeout: number | null = null;
	private intentionalDisconnect = false;
	private immediateReconnect = false;

	constructor(
		private readonly url: string,
		private readonly options: GatewaySocketOptions,
	) {
		super();
		this.logger = new Logger('Gateway');

		// Set up backoff for reconnection attempts
		this.backoff = new ExponentialBackoff({
			minDelay: GatewayTimeouts.MinReconnect,
			maxDelay: GatewayTimeouts.MaxReconnect,
			maxNumOfAttempts: 10,
		});
	}

	/**
	 * Start connecting to the gateway
	 */
	connect(): void {
		// Don't connect if already connecting or connected
		if (this.state === GatewayState.Connecting || this.state === GatewayState.Connected) {
			this.logger.debug('Already connecting or connected');
			return;
		}

		this.intentionalDisconnect = false;
		this.setConnectionState(GatewayState.Connecting);
		this.establishConnection();
	}

	/**
	 * Create a new WebSocket connection to the gateway
	 */
	private establishConnection(): void {
		this.cleanupConnection();

		const gatewayUrl = this.buildGatewayUrl();
		this.logger.debug(`Connecting to ${gatewayUrl}`);

		try {
			this.websocket = new WebSocket(gatewayUrl);

			// Set up event handlers
			this.websocket.addEventListener('open', this.handleOpen);
			this.websocket.addEventListener('message', this.handleMessage);
			this.websocket.addEventListener('close', this.handleClose);
			this.websocket.addEventListener('error', this.handleError);

			this.startHelloTimeout();
			this.emit('connecting');
		} catch (error) {
			this.logger.error('Failed to create WebSocket:', error);
			this.handleConnectionFailure();
		}
	}

	/**
	 * Remove event listeners and close the WebSocket connection
	 */
	private cleanupConnection(): void {
		if (this.websocket) {
			try {
				// Remove event listeners
				this.websocket.removeEventListener('open', this.handleOpen);
				this.websocket.removeEventListener('message', this.handleMessage);
				this.websocket.removeEventListener('close', this.handleClose);
				this.websocket.removeEventListener('error', this.handleError);

				// Close the connection if still open
				if (this.websocket.readyState === WebSocket.OPEN) {
					this.websocket.close(1000, 'Cleaning up connection');
				}
			} catch (error) {
				this.logger.error('Error cleaning up connection:', error);
			}

			this.websocket = null;
		}
	}

	/**
	 * Handle WebSocket connection establishment
	 */
	private handleOpen = (): void => {
		this.logger.info('WebSocket connection established');
		this.emit('connected');
	};

	/**
	 * Handle received WebSocket messages
	 */
	private handleMessage = (event: MessageEvent): void => {
		try {
			const payload = JSON.parse(event.data) as GatewayPayload;
			this.logger.debug('Received message:', payload);

			// Update sequence number for dispatch messages
			if (
				this.state === GatewayState.Connected &&
				payload.op === GatewayOpCodes.DISPATCH &&
				payload.s !== undefined &&
				payload.s > this.sequence
			) {
				this.sequence = payload.s;
			}

			this.processGatewayPayload(payload);
			this.emit('message', payload);
		} catch (error) {
			this.logger.error('Failed to parse message:', error);
			this.disconnect(GatewayCloseCodes.DECODE_ERROR, 'Message decode error');
		}
	};

	/**
	 * Handle WebSocket closure
	 */
	private handleClose = (event: CloseEvent): void => {
		this.logger.warn(`WebSocket closed: [${event.code}] ${event.reason}`);
		this.clearHelloTimeout();
		this.stopHeartbeat();

		this.emit('disconnect', {
			code: event.code,
			reason: event.reason,
			wasClean: event.wasClean,
		});

		// Handle closure based on close code
		if (event.code === GatewayCloseCodes.AUTHENTICATION_FAILED) {
			// Don't reconnect on authentication failure
			this.logger.error('Authentication failed');
			this.setConnectionState(GatewayState.Disconnected);
		} else if (!this.intentionalDisconnect) {
			// Try to reconnect on unexpected closure
			this.handleConnectionFailure();
		} else {
			// Intentional disconnect
			this.setConnectionState(GatewayState.Disconnected);
		}
	};

	/**
	 * Handle WebSocket errors
	 */
	private handleError = (event: Event): void => {
		this.logger.error('WebSocket error:', event);
		this.emit('error', event);

		// Handle reconnection on error
		if (this.state !== GatewayState.Reconnecting) {
			this.handleConnectionFailure();
		}
	};

	/**
	 * Process received gateway payload
	 */
	private processGatewayPayload(payload: GatewayPayload): void {
		switch (payload.op) {
			case GatewayOpCodes.DISPATCH:
				this.handleDispatch(payload);
				break;

			case GatewayOpCodes.HEARTBEAT:
				this.logger.debug('Server requested heartbeat');
				this.sendHeartbeat();
				break;

			case GatewayOpCodes.HEARTBEAT_ACK:
				this.handleHeartbeatAck();
				break;

			case GatewayOpCodes.HELLO: {
				this.clearHelloTimeout();
				const helloData = payload.d as {heartbeat_interval: number};
				this.startHeartbeat(helloData.heartbeat_interval);

				// Authenticate based on whether we can resume
				if (this.canResume()) {
					this.resume();
				} else {
					this.identify();
				}
				break;
			}

			case GatewayOpCodes.INVALID_SESSION: {
				const resumable = payload.d as boolean;
				this.logger.info(`Session invalidated, resumable: ${resumable}`);

				if (resumable) {
					// Wait briefly before resuming (per Discord recommendations)
					setTimeout(() => this.resume(), 2500 + Math.random() * 1000);
				} else {
					// Session fully invalid, need to start over
					this.resetSession();
					// Wait briefly before re-identifying (per Discord recommendations)
					setTimeout(() => this.identify(), 2500 + Math.random() * 1000);
				}
				break;
			}

			case GatewayOpCodes.RECONNECT:
				this.logger.info('Server requested reconnect');
				this.immediateReconnect = true;
				this.disconnect(4000, 'Server requested reconnect', true);
				break;
		}
	}

	/**
	 * Handle dispatch events
	 */
	private handleDispatch(payload: GatewayPayload): void {
		if (!payload.t) return;

		switch (payload.t) {
			case 'READY': {
				// Store session ID and update state
				const readyData = payload.d as {session_id: string};
				this.sessionId = readyData.session_id;
				this.resetBackoff();
				this.setConnectionState(GatewayState.Connected);
				this.logger.info(`New session established: ${this.sessionId}`);
				this.emit('ready', payload.d);
				break;
			}

			case 'RESUMED':
				this.setConnectionState(GatewayState.Connected);
				this.resetBackoff();
				this.logger.info('Session resumed successfully');
				this.emit('resumed');
				break;
		}

		// Emit dispatch event
		this.emit('dispatch', payload.t, payload.d);
	}

	/**
	 * Send identify payload to authenticate
	 */
	private identify(): void {
		this.logger.info('Identifying with gateway');
		this.send({
			op: GatewayOpCodes.IDENTIFY,
			d: {
				token: this.options.token,
				properties: this.options.properties,
			},
		});
	}

	/**
	 * Send resume payload to reuse existing session
	 */
	private resume(): void {
		if (!this.sessionId) {
			this.logger.warn('Attempted to resume without session ID');
			this.identify();
			return;
		}

		this.logger.info(`Resuming session ${this.sessionId}`);
		this.send({
			op: GatewayOpCodes.RESUME,
			d: {
				token: this.options.token,
				session_id: this.sessionId,
				seq: this.sequence,
			},
		});
	}

	/**
	 * Start sending heartbeats at the specified interval
	 */
	private startHeartbeat(interval: number): void {
		this.stopHeartbeat();
		this.heartbeatInterval = interval;

		// Add jitter to first heartbeat to prevent thundering herd
		const jitter = Math.random() * interval;
		this.scheduleHeartbeat(jitter);
		this.logger.debug(`Heartbeat started with interval ${interval}ms`);
	}

	/**
	 * Schedule the next heartbeat
	 */
	private scheduleHeartbeat(delay: number): void {
		if (!this.heartbeatInterval) return;

		this.heartbeatTimer = window.setTimeout(() => {
			this.sendHeartbeat();
			if (this.heartbeatInterval) {
				this.scheduleHeartbeat(this.heartbeatInterval);
			}
		}, delay);
	}

	/**
	 * Send a heartbeat to the gateway
	 */
	private sendHeartbeat(): void {
		// If we're still waiting for an ack, the connection might be unstable
		if (this.waitingForAck) {
			this.logger.warn('No heartbeat acknowledgment received, triggering reconnect');
			this.handleHeartbeatTimeout();
			return;
		}

		if (this.send({op: GatewayOpCodes.HEARTBEAT, d: this.sequence})) {
			this.waitingForAck = true;
			this.lastHeartbeatTime = Date.now();
			this.emit('heartbeat', this.sequence);
			this.startAckTimeout();
			this.logger.debug(`Sent heartbeat with sequence ${this.sequence}`);
		} else {
			this.logger.error('Failed to send heartbeat');
			this.handleHeartbeatTimeout();
		}
	}

	/**
	 * Start timeout for heartbeat acknowledgment
	 */
	private startAckTimeout(): void {
		this.ackTimer = window.setTimeout(() => {
			if (this.waitingForAck) {
				this.logger.warn('Heartbeat acknowledgment timeout exceeded');
				this.handleHeartbeatTimeout();
			}
		}, GatewayTimeouts.HeartbeatAck);
	}

	/**
	 * Handle heartbeat acknowledgment from server
	 */
	private handleHeartbeatAck(): void {
		this.waitingForAck = false;
		this.lastHeartbeatAckTime = Date.now();

		if (this.ackTimer !== null) {
			clearTimeout(this.ackTimer);
			this.ackTimer = null;
		}

		this.logger.debug('Received heartbeat acknowledgment');
		this.emit('heartbeatAck');
	}

	/**
	 * Handle heartbeat timeout (no ACK received)
	 */
	private handleHeartbeatTimeout(): void {
		this.logger.warn('Heartbeat acknowledgment timeout');
		// Immediately reconnect on heartbeat timeout
		this.immediateReconnect = true;
		this.disconnect(4000, 'Heartbeat acknowledgment timeout', true);
	}

	/**
	 * Stop the heartbeat mechanism
	 */
	private stopHeartbeat(): void {
		if (this.heartbeatTimer !== null) {
			clearTimeout(this.heartbeatTimer);
			this.heartbeatTimer = null;
		}

		if (this.ackTimer !== null) {
			clearTimeout(this.ackTimer);
			this.ackTimer = null;
		}

		this.waitingForAck = false;
		this.heartbeatInterval = null;
		this.logger.debug('Heartbeat stopped');
	}

	/**
	 * Handle connection failures
	 */
	private handleConnectionFailure(): void {
		if (this.intentionalDisconnect) {
			this.setConnectionState(GatewayState.Disconnected);
			return;
		}

		this.setConnectionState(GatewayState.Reconnecting);
		this.scheduleReconnect();
	}

	/**
	 * Schedule a reconnection attempt
	 */
	private scheduleReconnect(): void {
		// Clear any existing reconnect timer
		if (this.reconnectTimeout !== null) {
			clearTimeout(this.reconnectTimeout);
		}

		// Reset backoff if max retries reached
		if (this.hasExceededMaxRetries()) {
			this.logger.warn('Maximum reconnection attempts reached, resetting backoff');
			this.resetBackoff();
		}

		// Calculate delay (immediate if requested)
		const delay = this.immediateReconnect ? 0 : this.getNextReconnectDelay();
		this.immediateReconnect = false;

		this.logger.info(`Scheduling reconnect in ${delay}ms`);

		this.reconnectTimeout = window.setTimeout(() => {
			this.reconnectTimeout = null;

			// Check if session is still resumable
			if (!this.canResume()) {
				this.logger.info('Session no longer resumable, resetting');
				this.resetSession();
			}

			// Attempt to connect
			this.connect();
		}, delay);
	}

	/**
	 * Check if maximum retry attempts have been reached
	 */
	private hasExceededMaxRetries(): boolean {
		return this.backoff.getCurrentAttempts() >= this.backoff.getMaxAttempts();
	}

	/**
	 * Calculate delay for next reconnection attempt
	 */
	private getNextReconnectDelay(): number {
		const now = Date.now();
		const timeSinceLastReconnect = now - this.lastReconnectTime;

		// Ensure minimum time between reconnection attempts
		if (timeSinceLastReconnect < GatewayTimeouts.MinReconnect) {
			return GatewayTimeouts.MinReconnect;
		}

		this.lastReconnectTime = now;
		return this.backoff.next();
	}

	/**
	 * Reset the retry backoff
	 */
	private resetBackoff(): void {
		this.backoff.reset();
	}

	/**
	 * Determine if current session can be resumed
	 */
	private canResume(): boolean {
		const now = Date.now();

		// No session ID means we can't resume
		if (!this.sessionId) {
			return false;
		}

		// Check if within resume window based on last heartbeat ack
		if (this.lastHeartbeatAckTime !== null) {
			return now - this.lastHeartbeatAckTime <= GatewayTimeouts.ResumeWindow;
		}

		// If no ack received yet, check based on last sent heartbeat
		if (this.lastHeartbeatTime !== null) {
			return now - this.lastHeartbeatTime <= GatewayTimeouts.ResumeWindow;
		}

		// We have a session ID but no heartbeat history yet
		return true;
	}

	/**
	 * Reset the session completely
	 */
	private resetSession(): void {
		const hadSession = Boolean(this.sessionId);
		this.sessionId = null;
		this.sequence = 0;
		this.resetBackoff();

		if (hadSession) {
			this.logger.info('Session reset');
		}
	}

	/**
	 * Start timeout for initial HELLO message
	 */
	private startHelloTimeout(): void {
		this.clearHelloTimeout();
		this.helloTimeout = window.setTimeout(() => {
			this.logger.warn('Hello timeout - no HELLO received');
			this.disconnect(4000, 'Hello timeout');
		}, GatewayTimeouts.Hello);
	}

	/**
	 * Clear the HELLO timeout
	 */
	private clearHelloTimeout(): void {
		if (this.helloTimeout !== null) {
			clearTimeout(this.helloTimeout);
			this.helloTimeout = null;
		}
	}

	/**
	 * Build gateway URL with parameters
	 */
	private buildGatewayUrl(): string {
		const gatewayUrl = new URL(this.url);
		gatewayUrl.searchParams.set('v', this.options.apiVersion.toString());
		gatewayUrl.searchParams.set('encoding', 'json');
		return gatewayUrl.toString();
	}

	/**
	 * Send a payload to the gateway
	 */
	private send(payload: GatewayPayload): boolean {
		if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
			this.logger.warn('Attempted to send message while disconnected');
			return false;
		}

		try {
			const data = JSON.stringify(payload);
			this.websocket.send(data);
			this.logger.debug('Sent:', payload);
			return true;
		} catch (error) {
			this.logger.error('Failed to send message:', error);
			return false;
		}
	}

	/**
	 * Update the connection state
	 */
	private setConnectionState(newState: GatewayState): void {
		if (this.state !== newState) {
			const oldState = this.state;
			this.state = newState;
			this.logger.info(`State changed: ${oldState} -> ${newState}`);
			this.emit('stateChange', newState, oldState);
		}
	}

	/**
	 * Disconnect from the gateway
	 */
	disconnect(code = 1000, reason = 'Client disconnecting', resumable = false): void {
		this.logger.info(`Disconnecting: [${code}] ${reason}, resumable: ${resumable}`);
		this.intentionalDisconnect = !resumable;

		// Clear timeouts
		this.clearHelloTimeout();
		if (this.reconnectTimeout !== null) {
			clearTimeout(this.reconnectTimeout);
			this.reconnectTimeout = null;
		}

		// Stop heartbeat if not resumable
		if (!resumable) {
			this.stopHeartbeat();
		}

		// Close WebSocket connection
		if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
			try {
				this.websocket.close(code, reason);
			} catch (error) {
				this.logger.error('Error closing WebSocket:', error);
			}
		}

		// If resumable, schedule reconnect, otherwise mark as disconnected
		if (resumable) {
			this.setConnectionState(GatewayState.Reconnecting);
			this.scheduleReconnect();
		} else {
			this.setConnectionState(GatewayState.Disconnected);
		}
	}

	simulateNetworkDisconnect(): void {
		if (this.isConnected()) {
			this.logger.info('Simulating network disconnection with automatic reconnection');

			// Use code 4000 which is a standard code for a non-error, temporary disconnect
			// This will explicitly set resumable to true to ensure we attempt reconnection
			this.disconnect(4000, 'Simulated network disconnection', true);
		} else {
			this.logger.warn('Cannot simulate disconnection: socket not connected');
		}
	}

	/**
	 * Reset the connection
	 */
	reset(reconnect = true): void {
		this.logger.info(`Resetting connection, reconnect: ${reconnect}`);

		// Clear all timeouts
		this.clearHelloTimeout();
		if (this.reconnectTimeout !== null) {
			clearTimeout(this.reconnectTimeout);
			this.reconnectTimeout = null;
		}

		// Stop heartbeat
		this.stopHeartbeat();

		// Reset session
		this.resetSession();

		// Close connection
		this.cleanupConnection();

		// Set state to disconnected
		this.setConnectionState(GatewayState.Disconnected);

		// Reconnect if requested
		if (reconnect) {
			this.immediateReconnect = true;
			this.connect();
		}
	}

	/**
	 * Handle network status changes (online/offline)
	 */
	handleNetworkStatusChange(online: boolean): void {
		this.logger.info(`Network status changed - Online: ${online}`);
		this.emit('networkStatusChange', online);

		if (online) {
			// When network comes back online, connect if not already connected
			if (this.state === GatewayState.Disconnected || this.state === GatewayState.Reconnecting) {
				this.immediateReconnect = true;
				this.connect();
			}
		} else if (this.state === GatewayState.Connected) {
			// When network goes offline, disconnect with resumable flag
			this.disconnect(1000, 'Network offline', true);
		}
	}

	/**
	 * Update the user's presence
	 */
	updatePresence(status: StatusType): void {
		if (this.state === GatewayState.Connected) {
			this.send({
				op: GatewayOpCodes.PRESENCE_UPDATE,
				d: {status},
			});
		}
	}

	/**
	 * Update the token used for authentication
	 */
	setToken(token: string): void {
		this.options.token = token;
	}

	/**
	 * Get the current connection state
	 */
	getState(): GatewayState {
		return this.state;
	}

	/**
	 * Get the current session ID
	 */
	getSessionId(): string | null {
		return this.sessionId;
	}

	/**
	 * Get the current sequence number
	 */
	getSequence(): number {
		return this.sequence;
	}

	/**
	 * Check if currently connected
	 */
	isConnected(): boolean {
		return this.state === GatewayState.Connected && this.websocket?.readyState === WebSocket.OPEN;
	}

	/**
	 * Check if currently connecting
	 */
	isConnecting(): boolean {
		return this.state === GatewayState.Connecting;
	}
}
