import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import AuthenticationStore from '~/stores/AuthenticationStore';
import GuildMemberStore from '~/stores/GuildMemberStore';
import InviteStore, {type Invite} from '~/stores/InviteStore';
import * as RouterUtils from '~/utils/RouterUtils';

// Create a logger for invite operations
const logger = new Logger('Invites');

// Cache for in-flight invite fetch promises to avoid duplicate requests
const invitePromises = new Map();

/**
 * Fetch an invite by code
 */
export const fetch = async (code: string): Promise<Invite> => {
	// Return existing promise if already fetching
	if (invitePromises.has(code)) {
		logger.debug(`Using in-flight request for invite code ${code}`);
		return invitePromises.get(code);
	}

	// Return cached invite if available
	const invite = InviteStore.getInvite(code);
	if (invite) {
		logger.debug(`Using cached invite for code ${code}`);
		return Promise.resolve(invite);
	}

	// Create a new fetch promise
	const invitePromise = (async () => {
		try {
			logger.debug(`Fetching invite with code ${code}`);
			const {data} = await http.get<Invite>({url: Endpoints.INVITE(code)});

			// Update the store with the fetched invite
			Dispatcher.dispatch({type: 'INVITE_CREATE', invite: data});
			logger.debug(`Successfully fetched invite for code ${code}`);

			return data;
		} catch (error) {
			logger.error(`Failed to fetch invite with code ${code}:`, error);
			throw error;
		}
	})();

	// Store the promise in our cache
	invitePromises.set(code, invitePromise);

	// Clean up the cache when the promise resolves or rejects
	invitePromise.finally(() => {
		invitePromises.delete(code);
	});

	return invitePromise;
};

/**
 * Accept an invite by code
 */
export const accept = async (code: string): Promise<Invite> => {
	try {
		logger.debug(`Accepting invite with code ${code}`);
		const {data} = await http.post<Invite>({url: Endpoints.INVITE(code)});

		// Update the store with the accepted invite
		Dispatcher.dispatch({type: 'INVITE_CREATE', invite: data});
		logger.debug(`Successfully accepted invite for code ${code}`);

		return data;
	} catch (error) {
		logger.error(`Failed to accept invite with code ${code}:`, error);
		throw error;
	}
};

/**
 * Accept an invite and transition to the corresponding channel
 */
export const acceptAndTransitionToChannel = async (code: string): Promise<void> => {
	try {
		logger.debug(`Fetching invite details before accepting: ${code}`);
		const invite = await fetch(code);

		// Check if the user is already a member of the guild
		const userId = AuthenticationStore.getId();
		if (GuildMemberStore.getMember(invite.guild.id, userId) != null) {
			logger.debug(`User already in guild ${invite.guild.id}, transitioning to channel ${invite.channel.id}`);
			RouterUtils.transitionTo(`/channels/${invite.guild.id}/${invite.channel.id}`);
			return;
		}

		// Accept the invite and then transition
		logger.debug(`User not in guild, accepting invite ${code}`);
		await accept(code);

		logger.debug(`Transitioning to channel ${invite.channel.id} in guild ${invite.guild.id}`);
		RouterUtils.transitionTo(`/channels/${invite.guild.id}/${invite.channel.id}`);
	} catch (error) {
		logger.error(`Failed to accept invite and transition for code ${code}:`, error);
		throw error;
	}
};

/**
 * Create a new invite for a channel
 */
export const create = async (channelId: string): Promise<Invite> => {
	try {
		logger.debug(`Creating invite for channel ${channelId}`);
		const {data} = await http.post<Invite>({
			url: Endpoints.CHANNEL_INVITES(channelId),
			body: {},
		});

		// Update the store with the new invite
		Dispatcher.dispatch({type: 'INVITE_CREATE', invite: data});
		logger.debug(`Successfully created invite for channel ${channelId}`);

		return data;
	} catch (error) {
		logger.error(`Failed to create invite for channel ${channelId}:`, error);
		throw error;
	}
};
