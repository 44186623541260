import type {Popout} from '~/components/uikit/Popout';
import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';

// Create a logger for popout operations
const logger = new Logger('Popout');

/**
 * Opens a popout window
 */
export const open = (popout: Popout): void => {
	logger.debug(`Opening popout: ${popout.key || 'unknown'}`);
	Dispatcher.dispatch({type: 'POPOUT_OPEN', popout});
};

/**
 * Closes a specific popout by key, or the most recent one if key is not provided
 */
export const close = (key?: string | number): void => {
	logger.debug(`Closing popout${key ? `: ${key}` : ''}`);
	Dispatcher.dispatch({type: 'POPOUT_CLOSE', key});
};

/**
 * Closes all open popouts
 */
export const closeAll = (): void => {
	logger.debug('Closing all popouts');
	Dispatcher.dispatch({type: 'POPOUT_CLOSE_ALL'});
};

/**
 * Repositions a popout by key
 */
export const reposition = (key: string | number): void => {
	logger.debug(`Repositioning popout: ${key}`);
	Dispatcher.dispatch({type: 'POPOUT_REPOSITION', key});
};
