import React from 'react';
import type {Action} from '~/flux/ActionTypes';
import Dispatcher from '~/flux/Dispatcher';

export type StateListener<T> = (state: T) => void;
export type StateUpdater<T> = (prevState: T) => T;

export abstract class Store<T> {
	protected state: T;
	private readonly initialState: T;

	private listeners: Set<StateListener<T>> = new Set();
	private readonly boundSubscribe: (listener: StateListener<T>) => () => void;
	private readonly boundGetState: () => T;

	private isUpdating = false;
	private hasQueuedUpdate = false;
	private pendingState: T | null = null;

	private readonly storeName: string;

	constructor(initialState: T, options: {name?: string} = {}) {
		this.state = initialState;
		this.initialState = initialState;

		this.boundSubscribe = this.subscribe.bind(this);
		this.boundGetState = this.getState.bind(this);

		this.storeName = options.name ?? this.constructor.name;

		Dispatcher.register(this.safeHandleAction.bind(this));
	}

	subscribe(listener: StateListener<T>): () => void {
		if (import.meta.env.MODE !== 'production') {
			if (this.listeners.has(listener)) {
				console.warn(`${this.storeName}: Duplicate subscription detected`);
			}
		}

		this.listeners.add(listener);

		return () => {
			this.listeners.delete(listener);
		};
	}

	addChangeListener(listener: StateListener<T>): void {
		this.subscribe(listener);
	}

	removeChangeListener(listener: StateListener<T>): void {
		this.listeners.delete(listener);
	}

	protected setState(newState: T | ((prevState: T) => T)): void {
		const nextState = typeof newState === 'function' ? (newState as (prevState: T) => T)(this.state) : newState;

		if (Object.is(nextState, this.state)) {
			return;
		}

		if (this.isUpdating) {
			this.pendingState = nextState;
			this.hasQueuedUpdate = true;
			return;
		}

		try {
			this.isUpdating = true;
			this.state = nextState;
			this.notifyListeners();
		} finally {
			this.isUpdating = false;

			if (this.hasQueuedUpdate && this.pendingState !== null) {
				const queuedState = this.pendingState;
				this.pendingState = null;
				this.hasQueuedUpdate = false;
				this.setState(queuedState);
			}
		}
	}

	protected createNewRef<S>(obj: S): S {
		if (Array.isArray(obj)) {
			return [...obj] as unknown as S;
		}
		if (obj instanceof Set) {
			return new Set(obj) as unknown as S;
		}
		if (obj instanceof Map) {
			return new Map(obj) as unknown as S;
		}
		if (obj && typeof obj === 'object') {
			return {...obj};
		}
		return obj;
	}

	private notifyListeners(): void {
		const currentState = this.state;
		const currentListeners = Array.from(this.listeners);

		queueMicrotask(() => {
			currentListeners.forEach((listener) => {
				try {
					if (this.listeners.has(listener)) {
						listener(currentState);
					}
				} catch (error) {
					console.error(`${this.storeName}: Error in listener:`, error);
				}
			});
		});
	}

	getState(): T {
		return this.state;
	}

	reset(): void {
		this.setState(this.initialState);
	}

	useStore(): T {
		return React.useSyncExternalStore(this.boundSubscribe, this.boundGetState);
	}

	private async safeHandleAction(action: Action): Promise<boolean> {
		try {
			const result = this.handleAction(action);

			if (result instanceof Promise) {
				const asyncResult = await result;
				return asyncResult ?? false;
			}

			return result ?? false;
		} catch (error) {
			console.error(`${this.storeName}: Error handling action:`, action, error);
			return false;
		}
	}

	// biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
	abstract handleAction(action: Action): boolean | void | Promise<boolean | void>;

	syncWith(stores: Array<Store<any>>, callback: () => any, timeout = 0): void {
		const debouncedCallback = debounce(timeout, () => {
			if (callback() !== false) {
				this.notifyListeners();
			}
		});

		stores.forEach((store) => {
			store.subscribe(debouncedCallback);
		});
	}
}

const debounce = (delay: number, callback: (...args: Array<any>) => void) => {
	let timerId: NodeJS.Timeout | null = null;

	return (...args: Array<any>) => {
		if (timerId) {
			clearTimeout(timerId);
		}
		timerId = setTimeout(() => callback(...args), delay);
	};
};
