import invariant from 'tiny-invariant';
import {ALL_PERMISSIONS, Permissions} from '~/Constants';
import type {ChannelRecord} from '~/records/ChannelRecord';
import type {GuildMemberRecord} from '~/records/GuildMemberRecord';
import type {GuildRecord} from '~/records/GuildRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ChannelStore from '~/stores/ChannelStore';
import GuildMemberStore from '~/stores/GuildMemberStore';
import GuildStore from '~/stores/GuildStore';

export const computeBasePermissions = ({guild, member}: {guild: GuildRecord; member: GuildMemberRecord}): bigint => {
	if (guild.isOwner(member.user.id)) {
		return ALL_PERMISSIONS;
	}

	const everyoneRole = guild.roles[guild.id];
	if (!everyoneRole) {
		throw new Error('No @everyone role found');
	}

	let permissions = everyoneRole.permissions;

	for (const roleId of member.roles) {
		const role = guild.roles[roleId];
		if (role) {
			permissions |= role.permissions;
		}
	}

	if (permissions & Permissions.ADMINISTRATOR) {
		return ALL_PERMISSIONS;
	}

	return permissions;
};

export const computeOverwrites = ({
	basePermissions,
	member,
	channel,
}: {
	basePermissions: bigint;
	member: GuildMemberRecord;
	channel: ChannelRecord;
}): bigint => {
	if (basePermissions & Permissions.ADMINISTRATOR) {
		return ALL_PERMISSIONS;
	}

	let permissions = basePermissions;

	const everyoneOverwrite = channel.overwrites[member.guildId];
	if (everyoneOverwrite) {
		permissions &= ~everyoneOverwrite.deny;
		permissions |= everyoneOverwrite.allow;
	}

	let allow = 0n;
	let deny = 0n;

	for (const roleId of member.roles) {
		const roleOverwrite = channel.overwrites[roleId];
		if (roleOverwrite) {
			allow |= roleOverwrite.allow;
			deny |= roleOverwrite.deny;
		}
	}

	permissions &= ~deny;
	permissions |= allow;

	const memberOverwrite = channel.overwrites[member.user.id];
	if (memberOverwrite) {
		permissions &= ~memberOverwrite.deny;
		permissions |= memberOverwrite.allow;
	}

	return permissions;
};

export const computePermissions = ({
	guild,
	member,
	channel,
}: {
	guild: GuildRecord;
	member: GuildMemberRecord;
	channel: ChannelRecord | null;
}): bigint => {
	const basePermissions = computeBasePermissions({guild, member});
	if (!channel) {
		return basePermissions;
	}
	return computeOverwrites({basePermissions, member, channel});
};

export const getMaxRolePosition = (member: GuildMemberRecord) => {
	const guild = GuildStore.getGuild(member.guildId);
	invariant(guild, `Guild ${member.guildId} not found`);
	const intersection = Object.values(guild.roles).filter((role) => member.roles.has(role.id));
	return Math.max(...intersection.map((role) => role.position), -1);
};

export const checkTargetMember = (origin: GuildMemberRecord, target: GuildMemberRecord) => {
	const guild = GuildStore.getGuild(origin.guildId);
	invariant(guild, `Guild ${origin.guildId} not found`);
	if (guild.isOwner(origin.user.id)) {
		return true;
	}
	if (guild.isOwner(target.user.id)) {
		return false;
	}
	const originMax = getMaxRolePosition(origin);
	const targetMax = getMaxRolePosition(target);
	return originMax > targetMax || originMax === targetMax;
};

export const checkTargetRole = (origin: GuildMemberRecord, targetRoleId: string) => {
	const guild = GuildStore.getGuild(origin.guildId);
	invariant(guild, `Guild ${origin.guildId} not found`);
	const targetRole = Object.values(guild.roles).find((role) => role.id === targetRoleId);
	if (!targetRole || targetRole.isEveryone) {
		return false;
	}
	if (guild.isOwner(origin.user.id)) {
		return true;
	}
	const originMax = getMaxRolePosition(origin);
	return originMax >= targetRole.position;
};

export const checkMoveRoleFromTo = (origin: GuildMemberRecord, from: number, to: number) => {
	const guild = GuildStore.getGuild(origin.guildId);
	invariant(guild, `Guild ${origin.guildId} not found`);
	if (guild.isOwner(origin.user.id)) {
		return true;
	}
	const fromRole = Object.values(guild.roles).find((role) => role.position === from);
	const toRole = Object.values(guild.roles).find((role) => role.position === to);
	if (!(fromRole && toRole)) {
		return false;
	}
	const originMax = getMaxRolePosition(origin);
	return originMax >= fromRole.position && originMax >= toRole.position;
};

export const getAssignableRoleIds = (origin: GuildMemberRecord) => {
	const guild = GuildStore.getGuild(origin.guildId);
	invariant(guild, `Guild ${origin.guildId} not found`);
	const originMax = getMaxRolePosition(origin);
	return new Set(
		Object.values(guild.roles)
			.filter((role) => role.position < originMax)
			.map((role) => role.id),
	);
};

export const can = (
	permission: bigint,
	{guildId, userId, channelId}: {guildId: string; userId?: string; channelId?: string | null},
): boolean => {
	const guild = GuildStore.getGuild(guildId);
	const member = GuildMemberStore.getMember(guildId, userId ?? AuthenticationStore.getId());
	const channel = channelId ? ChannelStore.getChannel(channelId)! : null;
	if (!(guild && member)) {
		return false;
	}
	const permissions = computePermissions({guild, member, channel});
	return (permissions & permission) === permission;
};
