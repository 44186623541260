import {type Node, NodeType, ParserFlags, type ParserResult} from '../types';
import {MAX_LINK_URL_LENGTH} from '../types/constants';
import * as StringUtils from '../utils/string-utils';
import * as URLUtils from '../utils/url-utils';

/**
 * Parses a Markdown-style link [text](url)
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param parseInline - Function to parse inline content
 * @returns Parser result or null if no link found
 */
export function parseLink(
	text: string,
	_parserFlags: number,
	parseInline: (text: string) => Array<Node>,
): ParserResult | null {
	if (!text.startsWith('[')) return null;

	const linkParts = extractLinkParts(text);
	if (!linkParts) {
		// Check for potential link spoofing patterns
		const spoofedLinkMatch = /^\[https?:\/\/[^\s\[\]]+\]\(https?:\/\/[^\s\[\]]+\)$/.test(text);
		const nestedLinkMatch = /\[[^\]]+\]\([^)]+\)/.test(text) && /\[[^\]]+\]\([^)]+\)/.test(text);

		if (spoofedLinkMatch || nestedLinkMatch) {
			return {
				node: {type: NodeType.Text, content: text},
				advance: text.length,
			};
		}

		return null;
	}

	try {
		if (URLUtils.isValidUrl(linkParts.url)) {
			const finalUrl = URLUtils.convertToAsciiUrl(linkParts.url);
			const inlineNodes = parseInline(linkParts.linkText);

			return {
				node: {
					type: NodeType.Link,
					text: inlineNodes.length === 1 ? inlineNodes[0] : {type: NodeType.Sequence, children: inlineNodes},
					url: finalUrl,
					escaped: linkParts.isEscaped,
				},
				advance: linkParts.advanceBy,
			};
		}
	} catch {
		return {
			node: {type: NodeType.Text, content: text.slice(0, linkParts.advanceBy)},
			advance: linkParts.advanceBy,
		};
	}

	return null;
}

/**
 * Extracts the components of a Markdown link
 *
 * @param text - The text to parse
 * @returns Link parts object or null if not a valid link
 */
export function extractLinkParts(
	text: string,
): {linkText: string; url: string; isEscaped: boolean; advanceBy: number} | null {
	const chars = [...text];
	const bracketResult = findClosingBracket(chars);
	const {bracketPosition, linkText} = bracketResult;

	if (bracketPosition === null || linkText === null) return null;

	if (bracketPosition + 1 >= chars.length || chars[bracketPosition + 1] !== '(') return null;

	const trimmedLinkText = linkText.trim();

	// Check for nested links
	const containsLinkSyntax = /\[[^\]]*\]\([^)]*\)/.test(trimmedLinkText);

	// Check for email spoofing
	const isEmailSpoofing = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedLinkText);

	// If it contains link syntax or is an email, reject it
	if (containsLinkSyntax || isEmailSpoofing) {
		return null;
	}

	const urlInfo = extractUrl(chars, bracketPosition + 2);
	if (!urlInfo) return null;

	// Don't parse links with quoted URLs
	if (urlInfo.url.includes('"') || urlInfo.url.includes("'")) {
		return null;
	}

	// Check if the link text looks like a URL or domain-like with path
	const isUrlOrDomainLike =
		/^(?:https?:\/\/)?(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(?:\/[^\s\[\]]*)?$/.test(
			trimmedLinkText,
		);

	if (isUrlOrDomainLike) {
		try {
			// Extract domains from both link text and URL
			const textDomain = extractDomainFromString(trimmedLinkText);
			const urlDomain = extractDomainFromString(urlInfo.url);

			// If domains don't match, reject the link
			if (textDomain && urlDomain && textDomain !== urlDomain) {
				return null;
			}
		} catch {
			// If domain extraction fails, be safe and reject the link
			return null;
		}
	}

	return {
		linkText,
		...urlInfo,
	};
}

/**
 * Finds the closing bracket in a Markdown link
 *
 * @param chars - The characters to search
 * @returns Position of the closing bracket and the text inside
 */
export function findClosingBracket(chars: Array<string>): {bracketPosition: number | null; linkText: string | null} {
	let position = 1;
	let nestedBrackets = 0;

	while (position < chars.length) {
		if (chars[position] === '[') {
			nestedBrackets++;
			position++;
		} else if (chars[position] === ']') {
			if (nestedBrackets > 0) {
				nestedBrackets--;
				position++;
			} else {
				return {
					bracketPosition: position,
					linkText: chars.slice(1, position).join(''),
				};
			}
		} else if (chars[position] === '\\' && position + 1 < chars.length) {
			position += 2;
		} else {
			position++;
		}

		if (position > MAX_LINK_URL_LENGTH) break;
	}

	return {bracketPosition: null, linkText: null};
}

/**
 * Extracts the URL portion of a Markdown link
 *
 * @param chars - The characters to search
 * @param startPos - The starting position (after the opening parenthesis)
 * @returns URL information or null if not a valid URL
 */
export function extractUrl(
	chars: Array<string>,
	startPos: number,
): {url: string; isEscaped: boolean; advanceBy: number} | null {
	return chars[startPos] === '<' ? extractEscapedUrl(chars, startPos + 1) : extractUnescapedUrl(chars, startPos);
}

/**
 * Extracts an escaped URL from angle brackets
 *
 * @param chars - The characters to search
 * @param urlStart - The starting position (after the opening angle bracket)
 * @returns URL information or null if not a valid URL
 */
export function extractEscapedUrl(
	chars: Array<string>,
	urlStart: number,
): {url: string; isEscaped: boolean; advanceBy: number} | null {
	let currentPos = urlStart;

	while (currentPos < chars.length) {
		if (chars[currentPos] === '>') {
			const url = chars.slice(urlStart, currentPos).join('');

			currentPos++;
			while (currentPos < chars.length && chars[currentPos] !== ')') {
				currentPos++;
			}

			return {
				url,
				isEscaped: true,
				advanceBy: currentPos + 1,
			};
		}
		currentPos++;
	}

	return null;
}

/**
 * Extracts an unescaped URL (not in angle brackets)
 *
 * @param chars - The characters to search
 * @param urlStart - The starting position
 * @returns URL information or null if not a valid URL
 */
export function extractUnescapedUrl(
	chars: Array<string>,
	urlStart: number,
): {url: string; isEscaped: boolean; advanceBy: number} | null {
	let currentPos = urlStart;
	let nestedParens = 0;

	while (currentPos < chars.length) {
		if (chars[currentPos] === '(') {
			nestedParens++;
			currentPos++;
		} else if (chars[currentPos] === ')') {
			if (nestedParens > 0) {
				nestedParens--;
				currentPos++;
			} else {
				const url = chars
					.slice(urlStart, currentPos)
					.join('')
					.replace(/[.,!?:;]+$/, '');

				return {
					url,
					isEscaped: false,
					advanceBy: currentPos + 1,
				};
			}
		} else {
			currentPos++;
		}
	}

	return null;
}

/**
 * Parses a URL segment in plain text
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Parser result or null if no URL found
 */
export function extractUrlSegment(text: string, parserFlags: number): ParserResult | null {
	// Check for ALLOW_AUTOLINKS flag
	if (!(parserFlags & ParserFlags.ALLOW_AUTOLINKS)) return null;

	// We only want to auto-detect http and https URLs, not mailto URLs
	const prefixLength = text.startsWith('https://') ? 8 : text.startsWith('http://') ? 7 : 0;
	if (prefixLength === 0) return null;

	let end = prefixLength;
	while (end < text.length && !StringUtils.isUrlTerminationChar(text[end])) {
		end++;
		if (end - prefixLength > MAX_LINK_URL_LENGTH) break;
	}

	const urlString = text.slice(0, end).replace(/[.,!?:;]+$/, '');

	// Check if this URL is surrounded by quotes by examining context
	const isInQuotes = (end < text.length && text[end] === '"') || text.substring(0, prefixLength).includes('"');

	if (URLUtils.isValidUrl(urlString)) {
		// Extra check to ensure we don't auto-link mailto URLs
		if (urlString.startsWith('mailto:')) return null;

		const finalUrl = URLUtils.convertToAsciiUrl(urlString);
		return {
			node: {type: NodeType.Link, text: undefined, url: finalUrl, escaped: isInQuotes},
			advance: urlString.length,
		};
	}

	return null;
}

/**
 * Parses an autolink in angle brackets <https://example.com>
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Parser result or null if no autolink found
 */
export function parseAutolink(text: string, parserFlags: number): ParserResult | null {
	// Check for ALLOW_AUTOLINKS flag
	if (!(parserFlags & ParserFlags.ALLOW_AUTOLINKS)) return null;

	if (!text.startsWith('<')) return null;

	// Don't parse auto-links with quotes after the opening bracket
	if (text.startsWith('<"') || text.startsWith("<'")) {
		return null;
	}

	if (!StringUtils.startsWithUrl(text.slice(1))) return null;

	const end = text.indexOf('>');
	if (end === -1) return null;

	const urlString = text.slice(1, end);
	if (urlString.length > MAX_LINK_URL_LENGTH) return null;

	if (URLUtils.isValidUrl(urlString)) {
		// Explicitly block mailto URLs in angle brackets - those should only be handled by parseEmailLink
		if (urlString.startsWith('mailto:')) return null;

		const finalUrl = URLUtils.convertToAsciiUrl(urlString);
		return {
			node: {type: NodeType.Link, text: undefined, url: finalUrl, escaped: true},
			advance: end + 1,
		};
	}

	return null;
}

/**
 * Parses an email link in angle brackets <user@example.com>
 *
 * @param text - The text to parse
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @returns Parser result or null if no email link found
 */
export function parseEmailLink(text: string, parserFlags: number): ParserResult | null {
	// Check for ALLOW_AUTOLINKS flag
	if (!(parserFlags & ParserFlags.ALLOW_AUTOLINKS)) return null;

	if (!text.startsWith('<')) return null;

	const end = text.indexOf('>');
	if (end === -1) return null;

	// Extract the content between the angle brackets
	const content = text.slice(1, end);

	// If it's a URL, it should be handled by parseAutolink not this method
	if (content.startsWith('http://') || content.startsWith('https://')) return null;

	// Check if the content is a valid email address
	if (URLUtils.isValidEmail(content)) {
		return {
			node: {
				type: NodeType.Link,
				text: {type: NodeType.Text, content: content},
				url: `mailto:${content}`,
				escaped: true,
			},
			advance: end + 1,
		};
	}

	return null;
}

/**
 * Extracts and normalizes domain from a string
 *
 * @param input - The input string that may contain a domain
 * @returns Normalized domain or empty string if extraction fails
 */
export function extractDomainFromString(input: string): string {
	try {
		// Ensure it has a protocol for URL parsing
		const urlString = input.startsWith('http') ? input : `http://${input}`;
		const url = new URL(urlString);
		let domain = url.hostname;

		// Remove www. prefix if present
		if (domain.startsWith('www.')) {
			domain = domain.substring(4);
		}

		return domain;
	} catch {
		// If URL parsing fails, return empty string
		return '';
	}
}
