import * as BlockParsers from '../parsers/block-parsers';
import * as InlineParsers from '../parsers/inline-parsers';
import * as ListParsers from '../parsers/list-parsers';
import {type Node, NodeType} from '../types';
import {MAX_AST_NODES, MAX_LINES, MAX_LINE_LENGTH} from '../types/constants';
import * as ASTUtils from '../utils/ast-utils';

/**
 * Main parser class that converts text input into an AST.
 * This parser handles both block-level and inline Markdown elements.
 */
export class Parser {
	/**
	 * The lines of text to parse
	 */
	private readonly lines: Array<string>;

	/**
	 * Current line being processed
	 */
	private currentLineIndex: number;

	/**
	 * Total number of lines in the input
	 */
	private readonly totalLineCount: number;

	/**
	 * Flags that control which Markdown features are enabled
	 */
	private readonly parserFlags: number;

	/**
	 * Counter for tracking the number of nodes created to enforce limits
	 */
	private nodeCount: number;

	/**
	 * Creates a new Parser instance
	 *
	 * @param input - The text to parse
	 * @param flags - Bit flags controlling which features are enabled
	 */
	constructor(input: string, flags: number) {
		// For empty input, avoid unnecessary work
		if (!input || input === '') {
			this.lines = [];
			this.currentLineIndex = 0;
			this.totalLineCount = 0;
			this.parserFlags = flags;
			this.nodeCount = 0;
			return;
		}

		// Efficiently split the input and limit the number of lines
		const lines = input.split('\n');
		if (lines.length > MAX_LINES) {
			lines.length = MAX_LINES; // Truncate without creating a new array
		}

		// Remove empty arrays more efficiently
		if (lines.length === 1 && lines[0] === '') {
			this.lines = [];
		} else {
			this.lines = lines;
		}

		this.currentLineIndex = 0;
		this.totalLineCount = this.lines.length;
		this.parserFlags = flags;
		this.nodeCount = 0;
	}

	/**
	 * Parses the input text into an AST
	 *
	 * @returns Array of AST nodes
	 */
	parse(): Array<Node> {
		const ast: Array<Node> = [];
		if (this.totalLineCount === 0) return ast;

		while (this.currentLineIndex < this.totalLineCount && this.nodeCount <= MAX_AST_NODES) {
			const line = this.lines[this.currentLineIndex];
			let lineLength = line.length;
			if (lineLength > MAX_LINE_LENGTH) {
				this.lines[this.currentLineIndex] = line.slice(0, MAX_LINE_LENGTH);
				lineLength = MAX_LINE_LENGTH;
			}

			const trimmedLine = line.trimStart();
			if (trimmedLine === '') {
				const blankLineCount = this.countBlankLines(this.currentLineIndex);
				if (ast.length > 0 && this.currentLineIndex + blankLineCount < this.totalLineCount) {
					const nextLine = this.lines[this.currentLineIndex + blankLineCount];
					const nextTrimmed = nextLine.trimStart();

					// Check if the next line is a heading to handle spacing correctly
					const isNextHeading = nextTrimmed
						? BlockParsers.parseHeading(nextTrimmed, (text) => InlineParsers.parseInline(text, this.parserFlags)) !==
							null
						: false;

					const isPreviousHeading = ast[ast.length - 1]?.type === NodeType.Heading;

					if (!isNextHeading && !isPreviousHeading) {
						// For each blank line, add a newline character
						// We want n-1 newlines for n blank lines (since one is implicit)
						const newlines = '\n'.repeat(blankLineCount);
						ast.push({type: NodeType.Text, content: newlines});
						this.nodeCount++;
					}
				}
				this.currentLineIndex += blankLineCount;
				continue;
			}

			// Try to parse block-level elements first
			const blockResult = BlockParsers.parseBlock(
				this,
				this.lines,
				this.currentLineIndex,
				this.parserFlags,
				this.nodeCount,
			);

			if (blockResult.node) {
				ast.push(blockResult.node);
				this.currentLineIndex = blockResult.newLineIndex;
				this.nodeCount = blockResult.newNodeCount;
				continue;
			}

			// If no block elements, parse as inline content
			this.parseInlineLine(ast);
			this.currentLineIndex++;
		}

		// Process the AST to flatten and optimize nodes
		ASTUtils.flattenAST(ast);
		return ast;
	}

	/**
	 * Counts consecutive blank lines starting from the given index
	 *
	 * @param startLine - The line index to start counting from
	 * @returns Number of consecutive blank lines
	 */
	private countBlankLines(startLine: number): number {
		let count = 0;
		let current = startLine;
		while (current < this.totalLineCount && this.lines[current].trim() === '') {
			count++;
			current++;
		}
		return count;
	}

	/**
	 * Parses a line as inline content and adds the resulting nodes to the AST
	 *
	 * @param ast - The AST to add nodes to
	 */
	private parseInlineLine(ast: Array<Node>): void {
		let text = this.lines[this.currentLineIndex];
		if (this.currentLineIndex < this.totalLineCount - 1) {
			const nextLine = this.lines[this.currentLineIndex + 1];

			// Add a newline if the next line isn't a block element
			if (!this.isBlockStart(nextLine.trimStart())) {
				text += '\n';
			}
		}

		// Parse the text into inline nodes
		const inlineNodes = InlineParsers.parseInline(text, this.parserFlags);

		// Add the nodes to the AST
		for (const node of inlineNodes) {
			ast.push(node);
			this.nodeCount++;
			if (this.nodeCount > MAX_AST_NODES) break;
		}
	}

	/**
	 * Checks if a line starts with a block-level element
	 *
	 * @param line - The line to check
	 * @returns Whether the line starts with a block element
	 */
	private isBlockStart(line: string): boolean {
		return (
			line.startsWith('#') ||
			ListParsers.matchListItem(line) !== null ||
			line.startsWith('```') ||
			line.startsWith('>') ||
			line.startsWith('>>> ')
		);
	}
}
