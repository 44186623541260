import Dispatcher from '~/flux/Dispatcher';
import {Logger} from '~/lib/Logger';

// Create a logger for emoji operations
const logger = new Logger('Emoji');

/**
 * Sets the preferred emoji skin tone
 */
export const setSkinTone = (skinTone: string): void => {
	logger.debug(`Setting emoji skin tone: ${skinTone}`);
	Dispatcher.dispatch({type: 'EMOJI_SKIN_TONE', skinTone});
};
