import {type APIErrorCode, APIErrorCodes, ME} from '~/Constants';
import {Endpoints} from '~/Endpoints';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import {TooManyReactionsModal} from '~/components/alerts/TooManyReactionsModal';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {UserPartial} from '~/records/UserRecord';
import AuthenticationStore from '~/stores/AuthenticationStore';
import ConnectionStore from '~/stores/ConnectionStore';
import type {ReactionEmoji} from '~/utils/ReactionUtils';

// Create a logger for reaction operations
const logger = new Logger('MessageReactions');

// Maximum number of retry attempts for reaction operations
const MAX_RETRIES = 3;

/**
 * Check the response from a reaction operation and handle special cases
 */
const checkReactionResponse = (error: any, retry: () => void): boolean => {
	// Check for rate limiting (429)
	if (error.status === 429) {
		const retryAfter = error.data?.retry_after || 1000;
		logger.debug(`Rate limited, retrying after ${retryAfter}ms`);
		setTimeout(retry, retryAfter);
		return false;
	}

	// Check for reaction limits (400)
	if (error.status === 400) {
		const errorCode = error.data?.code as APIErrorCode;
		switch (errorCode) {
			case APIErrorCodes.MAX_USERS_PER_MESSAGE_REACTION:
			case APIErrorCodes.MAX_REACTIONS_PER_MESSAGE:
				logger.debug(`Reaction limit reached: ${errorCode}`);
				ModalActionCreators.push(() => <TooManyReactionsModal />);
				break;
		}
	}

	return true;
};

/**
 * Dispatch an optimistic reaction update
 */
const optimisticDispatch = (
	type:
		| 'MESSAGE_REACTION_ADD'
		| 'MESSAGE_REACTION_REMOVE'
		| 'MESSAGE_REACTION_REMOVE_ALL'
		| 'MESSAGE_REACTION_REMOVE_EMOJI',
	channelId: string,
	messageId: string,
	emoji: ReactionEmoji,
	userId?: string,
): void => {
	const actualUserId = userId || AuthenticationStore.getId();

	Dispatcher.dispatch({
		type,
		channelId,
		messageId,
		userId: actualUserId,
		emoji,
		optimistic: true,
	});

	logger.debug(
		`Optimistically dispatched ${type} for message ${messageId} in channel ${channelId} ` +
			`with emoji ${emoji.name}${emoji.id ? `:${emoji.id}` : ''} by user ${actualUserId}`,
	);
};

/**
 * Generate the URL for a reaction API endpoint
 */
const makeUrl = ({
	channelId,
	messageId,
	emoji,
	userId,
}: {
	channelId: string;
	messageId: string;
	emoji: ReactionEmoji;
	userId?: string;
}): string => {
	const emojiCode = encodeURIComponent(emoji.id ? `${emoji.name}:${emoji.id}` : emoji.name);
	return userId
		? Endpoints.CHANNEL_MESSAGE_REACTION_QUERY(channelId, messageId, emojiCode, userId)
		: Endpoints.CHANNEL_MESSAGE_REACTION(channelId, messageId, emojiCode);
};

/**
 * Retry a function with exponential backoff
 */
const retryWithExponentialBackoff = async (func: () => Promise<any>, attempts = 0): Promise<any> => {
	const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

	try {
		return await func();
	} catch (error) {
		if (attempts < MAX_RETRIES) {
			const backoffTime = 2 ** attempts * 1000;
			logger.debug(`Operation failed, retrying in ${backoffTime}ms (attempt ${attempts + 1}/${MAX_RETRIES})`);
			await delay(backoffTime);
			return retryWithExponentialBackoff(func, attempts + 1);
		}

		logger.error(`Operation failed after ${MAX_RETRIES} attempts:`, error);
		throw error;
	}
};

/**
 * Perform a reaction action with optimistic updates and error handling
 */
const performReactionAction = (
	type: 'MESSAGE_REACTION_ADD' | 'MESSAGE_REACTION_REMOVE',
	apiFunc: () => Promise<any>,
	channelId: string,
	messageId: string,
	emoji: ReactionEmoji,
	userId?: string,
): void => {
	// Optimistically update the UI
	optimisticDispatch(type, channelId, messageId, emoji, userId);

	retryWithExponentialBackoff(apiFunc).catch((error) => {
		// If the error is handled and we should retry, do so
		if (checkReactionResponse(error, () => performReactionAction(type, apiFunc, channelId, messageId, emoji, userId))) {
			// Otherwise, revert the optimistic update
			logger.debug(`Reverting optimistic update for reaction in message ${messageId}`);
			optimisticDispatch(
				type === 'MESSAGE_REACTION_ADD' ? 'MESSAGE_REACTION_REMOVE' : 'MESSAGE_REACTION_ADD',
				channelId,
				messageId,
				emoji,
				userId,
			);
		}
	});
};

/**
 * Get users who reacted with a specific emoji
 */
export const getReactions = async (
	channelId: string,
	messageId: string,
	emoji: ReactionEmoji,
): Promise<Array<UserPartial>> => {
	try {
		logger.debug(`Fetching reactions for message ${messageId} in channel ${channelId} with emoji ${emoji.name}`);

		const {data} = await http.get<Array<UserPartial>>({
			url: makeUrl({channelId, messageId, emoji}),
		});

		Dispatcher.dispatch({
			type: 'MESSAGE_REACTION_ADD_USERS',
			channelId,
			messageId,
			users: data,
			emoji,
		});

		logger.debug(`Retrieved ${data.length} reactions for message ${messageId}`);
		return data;
	} catch (error) {
		logger.error(`Failed to get reactions for message ${messageId}:`, error);
		throw error;
	}
};

/**
 * Add a reaction to a message
 */
export const addReaction = (channelId: string, messageId: string, emoji: ReactionEmoji): void => {
	logger.debug(`Adding reaction ${emoji.name} to message ${messageId}`);

	const apiFunc = () =>
		http.put({
			url: makeUrl({channelId, messageId, emoji, userId: ME}),
			query: {session_id: ConnectionStore.getSessionId()},
		});

	performReactionAction('MESSAGE_REACTION_ADD', apiFunc, channelId, messageId, emoji);
};

/**
 * Remove a reaction from a message
 */
export const removeReaction = (channelId: string, messageId: string, emoji: ReactionEmoji, userId?: string): void => {
	logger.debug(`Removing reaction ${emoji.name} from message ${messageId}`);

	const apiFunc = () =>
		http.delete({
			url: makeUrl({channelId, messageId, emoji, userId: userId || ME}),
			query: {session_id: ConnectionStore.getSessionId()},
		});

	performReactionAction('MESSAGE_REACTION_REMOVE', apiFunc, channelId, messageId, emoji, userId);
};

/**
 * Remove all reactions from a message
 */
export const removeAllReactions = (channelId: string, messageId: string): void => {
	logger.debug(`Removing all reactions from message ${messageId} in channel ${channelId}`);

	const apiFunc = () =>
		http.delete({
			url: Endpoints.CHANNEL_MESSAGE_REACTIONS(channelId, messageId),
		});

	retryWithExponentialBackoff(apiFunc).catch((error) => {
		checkReactionResponse(error, () => removeAllReactions(channelId, messageId));
	});
};
