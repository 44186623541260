import {Parser} from '../parser/parser';
import {
	type AlertNode,
	AlertType,
	type CodeBlockNode,
	type HeadingNode,
	type Node,
	NodeType,
	ParserFlags,
	type SpoilerNode,
	type SubtextNode,
	type TextNode,
} from '../types';
import {MAX_AST_NODES, MAX_LINE_LENGTH} from '../types/constants';
import {flattenChildren} from '../utils/ast-utils';
import * as InlineParsers from './inline-parsers';
import * as ListParsers from './list-parsers';
import * as TableParsers from './table-parsers';

/**
 * Result of parsing a block element
 */
export interface BlockParseResult {
	node: Node | null;
	newLineIndex: number;
	newNodeCount: number;
}

/**
 * Determines which block parser to use and parses the current block
 *
 * @param parser - Not used in this function but matches expected interface
 * @param lines - All lines of the input
 * @param currentLineIndex - The current line being processed
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param nodeCount - Current count of nodes created
 * @returns Parsed node, updated line index, and updated node count
 */
export function parseBlock(
	_parser: Parser,
	lines: Array<string>,
	currentLineIndex: number,
	parserFlags: number,
	nodeCount: number,
): BlockParseResult {
	if (currentLineIndex >= lines.length) {
		return {node: null, newLineIndex: currentLineIndex, newNodeCount: nodeCount};
	}

	const line = lines[currentLineIndex];
	const trimmed = line.trimStart();

	// Check for multiline blockquotes
	if (trimmed.startsWith('>>> ')) {
		if (!(parserFlags & ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES)) {
			return {
				node: parseBlockAsText(lines, currentLineIndex, '>>> '),
				newLineIndex: currentLineIndex + 1,
				newNodeCount: nodeCount + 1,
			};
		}
		return parseMultilineBlockquote(lines, currentLineIndex, parserFlags, nodeCount);
	}

	// Check for single-line blockquotes
	if (trimmed.startsWith('>')) {
		if (!(parserFlags & ParserFlags.ALLOW_BLOCKQUOTES)) {
			return {
				node: parseBlockAsText(lines, currentLineIndex, '>'),
				newLineIndex: currentLineIndex + 1,
				newNodeCount: nodeCount + 1,
			};
		}
		return parseBlockquote(lines, currentLineIndex, parserFlags, nodeCount);
	}

	// Check for lists
	const listMatch = ListParsers.matchListItem(line);
	if (listMatch) {
		const [isOrdered, indentLevel, _content] = listMatch;
		if (parserFlags & ParserFlags.ALLOW_LISTS) {
			const result = ListParsers.parseList(
				lines,
				currentLineIndex,
				isOrdered,
				indentLevel,
				1,
				parserFlags,
				nodeCount,
				(text) => InlineParsers.parseInline(text, parserFlags),
			);
			return {
				node: result.node,
				newLineIndex: result.newLineIndex,
				newNodeCount: result.newNodeCount,
			};
		}
		return {
			node: {type: NodeType.Text, content: line},
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};
	}

	// Check for block spoilers
	if (trimmed.startsWith('||') && !trimmed.slice(2).includes('||')) {
		if (parserFlags & ParserFlags.ALLOW_SPOILERS) {
			const result = parseSpoiler(lines, currentLineIndex, parserFlags);
			return {
				node: result.node,
				newLineIndex: result.newLineIndex,
				newNodeCount: nodeCount + 1,
			};
		}
		return {
			node: {type: NodeType.Text, content: line},
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};
	}

	// Check for code blocks
	if (trimmed.startsWith('```')) {
		if (parserFlags & ParserFlags.ALLOW_CODE_BLOCKS) {
			const result = parseCodeBlock(lines, currentLineIndex);
			return {
				node: result.node,
				newLineIndex: result.newLineIndex,
				newNodeCount: nodeCount + 1,
			};
		}

		// For disabled code blocks, find the end marker and preserve newlines
		let codeBlockText = lines[currentLineIndex];
		let endLineIndex = currentLineIndex + 1;

		// Look for the closing delimiter
		while (endLineIndex < lines.length) {
			const line = lines[endLineIndex];

			if (line.trim() === '```') {
				codeBlockText += `\n${line}`;
				endLineIndex++;
				break;
			}

			codeBlockText += `\n${line}`;
			endLineIndex++;
		}

		return {
			node: {type: NodeType.Text, content: codeBlockText},
			newLineIndex: endLineIndex,
			newNodeCount: nodeCount + 1,
		};
	}

	// Check for subtext
	if (trimmed.startsWith('-#')) {
		if (parserFlags & ParserFlags.ALLOW_SUBTEXT) {
			const subtextNode = parseSubtext(trimmed, (text) => InlineParsers.parseInline(text, parserFlags));
			if (subtextNode) {
				return {
					node: subtextNode,
					newLineIndex: currentLineIndex + 1,
					newNodeCount: nodeCount + 1,
				};
			}
		}
		return {
			node: {type: NodeType.Text, content: handleLineAsText(lines, currentLineIndex)},
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};
	}

	// Check for headings
	if (trimmed.startsWith('#')) {
		if (parserFlags & ParserFlags.ALLOW_HEADINGS) {
			const headingNode = parseHeading(trimmed, (text) => InlineParsers.parseInline(text, parserFlags));
			if (headingNode) {
				return {
					node: headingNode,
					newLineIndex: currentLineIndex + 1,
					newNodeCount: nodeCount + 1,
				};
			}
		}
		return {
			node: {type: NodeType.Text, content: handleLineAsText(lines, currentLineIndex)},
			newLineIndex: currentLineIndex + 1,
			newNodeCount: nodeCount + 1,
		};
	}

	// Check for tables
	if (trimmed.includes('|') && parserFlags & ParserFlags.ALLOW_TABLES) {
		const startIndex = currentLineIndex;
		const tableResult = TableParsers.parseTable(lines, currentLineIndex, parserFlags, (text) =>
			InlineParsers.parseInline(text, parserFlags),
		);

		if (tableResult.node) {
			return {
				node: tableResult.node,
				newLineIndex: tableResult.newLineIndex,
				newNodeCount: nodeCount + 1,
			};
		}

		currentLineIndex = startIndex;
	}

	// No block element was found
	return {node: null, newLineIndex: currentLineIndex, newNodeCount: nodeCount};
}

/**
 * Creates a text node from a line, handling newlines appropriately
 */
function handleLineAsText(lines: Array<string>, currentLineIndex: number): string {
	const isLastLine = currentLineIndex === lines.length - 1;
	return isLastLine ? lines[currentLineIndex] : `${lines[currentLineIndex]}\n`;
}

/**
 * Parses a block element as plain text (when its feature flag is disabled)
 */
function parseBlockAsText(lines: Array<string>, currentLineIndex: number, marker: string): TextNode {
	const originalContent = lines[currentLineIndex];

	// If this is a blockquote marker, we need special handling to avoid duplication
	if (marker === '>' || marker === '>>> ') {
		// For a disabled blockquote, just return the current line and let the parser handle
		// subsequent lines normally without additional processing
		return {
			type: NodeType.Text,
			content: originalContent + (currentLineIndex < lines.length - 1 ? '\n' : ''),
		};
	}

	// For other block markers (like code blocks), just return the text as-is
	// without trying to find the end marker
	return {
		type: NodeType.Text,
		content: originalContent,
	};
}

/**
 * Checks if a line starts with a block-level element
 */
export function isBlockStart(line: string): boolean {
	return (
		line.startsWith('#') ||
		ListParsers.matchListItem(line) !== null ||
		line.startsWith('```') ||
		line.startsWith('>') ||
		line.startsWith('>>> ')
	);
}

/**
 * Checks if a line starts a different block-level element that would break
 * the current block
 */
export function isBlockBreak(trimmed: string): boolean {
	return trimmed.startsWith('#') || trimmed.startsWith('```') || trimmed.startsWith('>') || trimmed.startsWith('>>> ');
}

/**
 * Parses a heading (# Heading)
 */
export function parseHeading(trimmed: string, parseInline: (text: string) => Array<Node>): HeadingNode | null {
	const level = [...trimmed].filter((c) => c === '#').length;

	// Discord Markdown supports headings with 1-3 #s, and requires a space after
	if (level >= 1 && level <= 3 && trimmed[level] === ' ') {
		const content = trimmed.slice(level + 1);
		const inlineNodes = parseInline(content);
		return {type: NodeType.Heading, level, children: inlineNodes};
	}

	return null;
}

/**
 * Parses a subtext (-# Subtext)
 */
export function parseSubtext(trimmed: string, parseInline: (text: string) => Array<Node>): SubtextNode | null {
	if (trimmed.startsWith('-#')) {
		// Valid format: "-# content" (there must be a space after #)
		if ((trimmed.length > 2 && trimmed[2] !== ' ') || (trimmed.length > 3 && trimmed[3] === ' ')) {
			return null;
		}

		const content = trimmed.slice(3);
		const inlineNodes = parseInline(content);
		return {type: NodeType.Subtext, children: inlineNodes};
	}

	return null;
}

/**
 * Parses a single-line blockquote (> Quote)
 * In Discord-style Markdown, blockquotes must have a space after the ">" character.
 */
export function parseBlockquote(
	lines: Array<string>,
	currentLineIndex: number,
	parserFlags: number,
	nodeCount: number,
): BlockParseResult {
	let blockquoteContent = '';
	const startLine = currentLineIndex;
	let newLineIndex = currentLineIndex;

	while (newLineIndex < lines.length) {
		if (nodeCount > MAX_AST_NODES) break;
		const line = lines[newLineIndex];
		const trimmed = line.trimStart();

		// Handle empty blockquote lines (just "> " or ">  ")
		if (trimmed === '> ' || trimmed === '>  ') {
			// Empty quote line should add double newlines
			if (blockquoteContent.length > 0) blockquoteContent += '\n\n';
			newLineIndex++;
		} else if (trimmed.startsWith('> ')) {
			// Regular quote line with content
			const content = trimmed.slice(2);
			if (blockquoteContent.length > 0) blockquoteContent += '\n';
			blockquoteContent += content;
			newLineIndex++;
		} else {
			break;
		}

		if (blockquoteContent.length > MAX_LINE_LENGTH * 100) break;
	}

	if (blockquoteContent === '' && newLineIndex === startLine) {
		return {node: null, newLineIndex, newNodeCount: nodeCount};
	}

	// Check if this should be an alert instead of a regular blockquote
	if (parserFlags & ParserFlags.ALLOW_ALERTS) {
		const alertNode = parseAlert(blockquoteContent, parserFlags);
		if (alertNode) {
			return {
				node: alertNode,
				newLineIndex,
				newNodeCount: nodeCount + 1,
			};
		}
	}

	// Parse as a regular blockquote
	const childFlags = parserFlags & ~ParserFlags.ALLOW_BLOCKQUOTES;
	const childParser = new Parser(blockquoteContent, childFlags);
	const childNodes = childParser.parse();

	// Force blockquote text nodes to merge using a special flag
	flattenChildren(childNodes, true);

	return {
		node: {
			type: NodeType.Blockquote,
			children: childNodes,
		},
		newLineIndex,
		newNodeCount: nodeCount + 1,
	};
}

/**
 * Parses a multiline blockquote (>>> Quote)
 */
export function parseMultilineBlockquote(
	lines: Array<string>,
	currentLineIndex: number,
	parserFlags: number,
	nodeCount: number,
): BlockParseResult {
	const line = lines[currentLineIndex];
	const trimmed = line.trimStart();

	if (!trimmed.startsWith('>>> ')) {
		return {
			node: {type: NodeType.Text, content: ''},
			newLineIndex: currentLineIndex,
			newNodeCount: nodeCount,
		};
	}

	let content = trimmed.slice(4);
	let newLineIndex = currentLineIndex + 1;

	// In multiline blockquotes, everything until the end of the message is included
	while (newLineIndex < lines.length) {
		const current = lines[newLineIndex];
		content += `\n${current}`;
		newLineIndex++;
		if (content.length > MAX_LINE_LENGTH * 100) break;
	}

	// Use modified parser flags for the blockquote content
	const childFlags = (parserFlags & ~ParserFlags.ALLOW_MULTILINE_BLOCKQUOTES) | ParserFlags.ALLOW_BLOCKQUOTES;
	const childParser = new Parser(content, childFlags);
	const childNodes = childParser.parse();

	return {
		node: {
			type: NodeType.Blockquote,
			children: childNodes,
		},
		newLineIndex,
		newNodeCount: nodeCount + 1,
	};
}

/**
 * Parses a code block (```language\ncode\n```)
 */
export function parseCodeBlock(
	lines: Array<string>,
	currentLineIndex: number,
): {node: CodeBlockNode; newLineIndex: number} {
	const line = lines[currentLineIndex];
	const trimmed = line.trimStart();

	// Extract language if provided
	const language = trimmed.length > 3 ? trimmed.slice(3).trim() || undefined : undefined;
	let newLineIndex = currentLineIndex + 1;

	let content = '';
	while (newLineIndex < lines.length) {
		const current = lines[newLineIndex];

		// Check for the ending backticks
		if (current.trimStart().startsWith('```')) {
			newLineIndex++;
			break;
		}

		content += `${current}\n`;
		if (content.length > MAX_LINE_LENGTH * 100) break;
		newLineIndex++;
	}

	return {
		node: {
			type: NodeType.CodeBlock,
			language,
			content,
		},
		newLineIndex,
	};
}

/**
 * Parses a block spoiler (||spoiler content||)
 */
export function parseSpoiler(
	lines: Array<string>,
	currentLineIndex: number,
	parserFlags: number,
): {node: SpoilerNode | TextNode; newLineIndex: number} {
	const startLine = currentLineIndex;
	let foundEnd = false;
	let blockContent = '';
	let newLineIndex = currentLineIndex;

	while (newLineIndex < lines.length) {
		const line = lines[newLineIndex];

		if (newLineIndex === startLine) {
			const startIdx = line.indexOf('||');
			if (startIdx !== -1) {
				blockContent += line.slice(startIdx + 2);
			}
		} else {
			const endIdx = line.indexOf('||');
			if (endIdx !== -1) {
				blockContent += line.slice(0, endIdx);
				foundEnd = true;
				newLineIndex++;
				break;
			}
			blockContent += line;
		}

		blockContent += '\n';
		newLineIndex++;

		if (blockContent.length > MAX_LINE_LENGTH * 10) break;
	}

	if (!foundEnd) {
		return {
			node: {
				type: NodeType.Text,
				content: `||${blockContent.trimEnd()}`,
			},
			newLineIndex,
		};
	}

	// Parse the content inside the spoiler
	const childParser = new Parser(blockContent.trim(), parserFlags);
	const innerNodes = childParser.parse();

	return {
		node: {
			type: NodeType.Spoiler,
			children: innerNodes,
			isBlock: true,
		},
		newLineIndex,
	};
}

/**
 * Parses an alert block ([!NOTE], [!TIP], etc.)
 */
export function parseAlert(blockquoteText: string, parserFlags: number): AlertNode | null {
	const alertMatch = blockquoteText.match(/^\[!([A-Z]+)\]\s*\n?/);
	if (!alertMatch) return null;

	const alertTypeStr = alertMatch[1].toUpperCase();
	let alertType: AlertType;

	// Map the alert type string to the AlertType enum
	switch (alertTypeStr) {
		case 'NOTE':
			alertType = AlertType.Note;
			break;
		case 'TIP':
			alertType = AlertType.Tip;
			break;
		case 'IMPORTANT':
			alertType = AlertType.Important;
			break;
		case 'WARNING':
			alertType = AlertType.Warning;
			break;
		case 'CAUTION':
			alertType = AlertType.Caution;
			break;
		default:
			return null;
	}

	// Extract the content after the alert marker
	const content = blockquoteText.slice(alertMatch[0].length);

	// Use modified parser flags for alert content
	const childFlags =
		(parserFlags & ~ParserFlags.ALLOW_BLOCKQUOTES) | ParserFlags.ALLOW_LISTS | ParserFlags.ALLOW_HEADINGS;

	// Process content for alerts, which have special handling for lists
	const lines = content.split('\n');
	const processedLines = lines.map((line) => {
		const trimmed = line.trim();
		if (trimmed.startsWith('-') || /^\d+\./.test(trimmed)) {
			return line;
		}
		return trimmed;
	});

	const processedContent = processedLines
		.join('\n')
		.replace(/\n{3,}/g, '\n\n')
		.trim();

	// Parse the content inside the alert
	const childParser = new Parser(processedContent, childFlags);
	const childNodes = childParser.parse();

	// Merge adjacent text nodes
	const mergedNodes: Array<Node> = [];
	let currentText = '';

	for (const node of childNodes) {
		if (node.type === NodeType.Text) {
			if (currentText) {
				currentText += node.content;
			} else {
				currentText = node.content;
			}
		} else {
			if (currentText) {
				mergedNodes.push({type: NodeType.Text, content: currentText});
				currentText = '';
			}
			mergedNodes.push(node);
		}
	}

	if (currentText) {
		mergedNodes.push({type: NodeType.Text, content: currentText});
	}

	// Post-process the nodes for alert-specific formatting
	const finalNodes = postProcessAlertNodes(mergedNodes);

	return {
		type: NodeType.Alert,
		alertType,
		children: finalNodes,
	};
}

/**
 * Post-processes alert nodes to handle special formatting cases
 */
export function postProcessAlertNodes(nodes: Array<Node>): Array<Node> {
	const result: Array<Node> = [];
	let i = 0;

	while (i < nodes.length) {
		const node = nodes[i];

		if (node.type === NodeType.Text && i + 1 < nodes.length) {
			if (nodes[i + 1].type === NodeType.List) {
				const trimmedContent = node.content.replace(/\s+$/, '\n');
				if (trimmedContent) {
					result.push({type: NodeType.Text, content: trimmedContent});
				}
			} else {
				result.push(node);
			}
		} else if (node.type === NodeType.List && i + 1 < nodes.length) {
			result.push(node);

			const nextNode = nodes[i + 1];
			if (nextNode.type === NodeType.Text) {
				const content = nextNode.content.trim();
				if (content) {
					result.push({type: NodeType.Text, content: `\n${content}`});
					i++;
				}
			}
		} else {
			result.push(node);
		}

		i++;
	}

	return result;
}
