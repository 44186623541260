import {X} from '@phosphor-icons/react';
import clsx from 'clsx';
import React from 'react';
import {useLocation} from 'react-router-dom';
import * as ModalActionCreators from '~/actions/ModalActionCreators';
import * as UserSettingsModalActionCreators from '~/actions/UserSettingsModalActionCreators';
import {AutoUpdateManager} from '~/components/layout/AutoUpdateManager';
import {SplashScreen} from '~/components/layout/SplashScreen';
import {MfaTotpEnableModal} from '~/components/modals/MfaTotpEnableModal';
import Dispatcher from '~/flux/Dispatcher';
import {i18n} from '~/i18n';
import AuthenticationStore from '~/stores/AuthenticationStore';
import NagbarStore from '~/stores/NagbarStore';
import UserStore from '~/stores/UserStore';
import * as RouterUtils from '~/utils/RouterUtils';

const NON_AUTHED_PATHNAMES = new Set(['/login', '/register']);

export const AppLayout = ({children}: {children: React.ReactNode}) => {
	const {hideMfaNagbar} = NagbarStore.useStore();
	const {token} = AuthenticationStore.useStore();
	const location = useLocation();
	const user = UserStore.useCurrentUser();
	// Email verification takes precedence over MFA
	const shouldShowVerificationNagbar = user && !user.verified;
	const shouldShowMfaNagbar = !shouldShowVerificationNagbar && user && !(user.mfaEnabled || hideMfaNagbar);
	const sessionStartSentRef = React.useRef(false);
	const previousTokenRef = React.useRef(token);

	React.useEffect(() => {
		if (token && (!sessionStartSentRef.current || token !== previousTokenRef.current)) {
			Dispatcher.dispatch({type: 'SESSION_START', token: AuthenticationStore.getToken()});
			sessionStartSentRef.current = true;
			previousTokenRef.current = token;
		}
	}, [token]);

	React.useEffect(() => {
		if (!token && !NON_AUTHED_PATHNAMES.has(location.pathname)) {
			RouterUtils.replaceWith('/login');
		}
	}, [token, location.pathname]);

	React.useEffect(() => {
		if (!token) {
			sessionStartSentRef.current = false;
		}
	}, [token]);

	return (
		<>
			{token && <SplashScreen />}
			<AutoUpdateManager />
			<div
				className={clsx(
					'grid h-[100svh] overflow-hidden bg-background-primary text-text-primary',
					shouldShowVerificationNagbar || shouldShowMfaNagbar
						? 'grid-cols-1 grid-rows-[auto,1fr]'
						: 'grid-cols-1 grid-rows-1',
				)}
			>
				{shouldShowVerificationNagbar && <EmailVerificationNagbar />}
				{!shouldShowVerificationNagbar && shouldShowMfaNagbar && <MfaNagbar />}
				{children}
			</div>
		</>
	);
};

const EmailVerificationNagbar = () => {
	const user = UserStore.useCurrentUser();
	if (!user) {
		return null;
	}

	const openUserSettings = () => {
		UserSettingsModalActionCreators.openUserSettingsTab('my_account');
	};

	return (
		<div className="relative flex h-[36px] items-center justify-center bg-green-600 px-2 py-1 font-semibold text-sm text-white">
			<div className="flex items-center">
				<p>
					{i18n.format(i18n.Messages.EMAIL_NOT_VERIFIED_NAGBAR, {
						username: user.displayName,
					})}
				</p>
				<div className="ml-3">
					<button
						type="button"
						className="rounded-md border-2 border-white px-2 py-0.5 font-bold text-white text-xs transition-colors hover:bg-white hover:text-green-600"
						onClick={openUserSettings}
					>
						{i18n.Messages.OPEN_SETTINGS}
					</button>
				</div>
			</div>
		</div>
	);
};

const MfaNagbar = () => {
	const user = UserStore.useCurrentUser();
	if (!user) {
		return null;
	}

	const enableTwoFactor = () => {
		UserSettingsModalActionCreators.openUserSettingsTab('my_account');
		ModalActionCreators.push(() => <MfaTotpEnableModal />);
	};

	return (
		<div className="relative flex h-[36px] items-center justify-center bg-brand-primary px-2 py-1 font-semibold text-brand-primary-fill text-sm">
			<p>
				{i18n.format(i18n.Messages.MFA_NOT_ENABLED_NAGBAR, {
					username: user.displayName,
					enableTwoFactor: (
						<span
							key="enableTwoFactor"
							role="button"
							tabIndex={0}
							className="cursor-pointer underline"
							onClick={enableTwoFactor}
							onKeyDown={(e) => e.key === 'Enter' && enableTwoFactor()}
						>
							{i18n.Messages.ENABLE_TWO_FACTOR}
						</span>
					),
				})}
			</p>
			<button
				type="button"
				className="absolute top-2 right-2 border-none bg-transparent"
				aria-label={i18n.Messages.CLOSE}
				onClick={() => Dispatcher.dispatch({type: 'HIDE_MFA_NAGBAR'})}
			>
				<X weight="regular" className="h-5 w-5" />
			</button>
		</div>
	);
};
