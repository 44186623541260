import {Endpoints} from '~/Endpoints';
import Dispatcher from '~/flux/Dispatcher';
import http from '~/lib/HttpClient';
import {Logger} from '~/lib/Logger';
import type {FavoriteGif, UserSettings} from '~/stores/UserSettingsStore';
import UserSettingsStore from '~/stores/UserSettingsStore';

// Create a logger for user settings operations
const logger = new Logger('UserSettings');

/**
 * Update user settings with optimistic UI update
 */
export const update = async (settings: Partial<UserSettings>): Promise<void> => {
	const previousSettings = UserSettingsStore.getState();

	// Create new settings object with proper handling for arrays
	const newSettings = {
		...previousSettings,
		...settings,
		...(settings.favorite_gifs ? {favorite_gifs: [...settings.favorite_gifs]} : {}),
	};

	// Optimistically update UI
	Dispatcher.dispatch({
		type: 'USER_SETTINGS_UPDATE',
		userSettings: newSettings,
	});

	try {
		logger.debug('Updating user settings');
		await http.patch({
			url: Endpoints.USER_SETTINGS,
			body: settings,
		});

		logger.debug('Successfully updated user settings');
	} catch (error) {
		// Revert to previous settings on failure
		logger.error('Failed to update user settings:', error);
		Dispatcher.dispatch({
			type: 'USER_SETTINGS_UPDATE',
			userSettings: previousSettings,
		});
		throw error;
	}
};

/**
 * Add a GIF to user's favorites
 */
export const addFavoriteGif = async (gif: Readonly<FavoriteGif>): Promise<void> => {
	try {
		const previousSettings = UserSettingsStore.getState();
		const existingUrls = new Set(previousSettings.favorite_gifs.map((g) => g.url));

		// Only add if the GIF isn't already in favorites
		if (!existingUrls.has(gif.url)) {
			logger.debug(`Adding GIF to favorites: ${gif.url}`);
			const updatedFavoriteGifs = [gif, ...previousSettings.favorite_gifs];

			await update({favorite_gifs: updatedFavoriteGifs});
			logger.debug('Successfully added GIF to favorites');
		} else {
			logger.debug(`GIF already in favorites, skipping: ${gif.url}`);
		}
	} catch (error) {
		logger.error('Failed to add GIF to favorites:', error);
		throw error;
	}
};

/**
 * Remove a GIF from user's favorites
 */
export const removeFavoriteGif = async (url: string): Promise<void> => {
	try {
		const previousSettings = UserSettingsStore.getState();
		const existingUrls = new Set(previousSettings.favorite_gifs.map((g) => g.url));

		// Only remove if the GIF exists in favorites
		if (existingUrls.has(url)) {
			logger.debug(`Removing GIF from favorites: ${url}`);
			const updatedFavoriteGifs = previousSettings.favorite_gifs.filter((gif) => gif.url !== url);

			await update({favorite_gifs: updatedFavoriteGifs});
			logger.debug('Successfully removed GIF from favorites');
		} else {
			logger.debug(`GIF not in favorites, skipping: ${url}`);
		}
	} catch (error) {
		logger.error('Failed to remove GIF from favorites:', error);
		throw error;
	}
};

/**
 * Reorder GIFs in user's favorites
 */
export const reorderFavoriteGifs = async (fromIndex: number, toIndex: number): Promise<void> => {
	try {
		const previousSettings = UserSettingsStore.getState();
		const {favorite_gifs} = previousSettings;

		// Validate indices
		if (fromIndex < 0 || fromIndex >= favorite_gifs.length || toIndex < 0 || toIndex >= favorite_gifs.length) {
			logger.debug(
				`Invalid indices for reordering GIFs: from=${fromIndex}, to=${toIndex}, length=${favorite_gifs.length}`,
			);
			return;
		}

		logger.debug(`Reordering favorite GIFs: from index ${fromIndex} to index ${toIndex}`);

		// Create a new array and move the item
		const updatedFavoriteGifs = [...favorite_gifs];
		const [movedGif] = updatedFavoriteGifs.splice(fromIndex, 1);
		updatedFavoriteGifs.splice(toIndex, 0, movedGif);

		await update({favorite_gifs: updatedFavoriteGifs});
		logger.debug('Successfully reordered favorite GIFs');
	} catch (error) {
		logger.error('Failed to reorder favorite GIFs:', error);
		throw error;
	}
};
