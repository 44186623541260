import {type Node, NodeType, TableAlignment, type TableCellNode, type TableNode, type TableRowNode} from '../types';

/**
 * Result of parsing a table
 */
export interface TableParseResult {
	node: TableNode | null;
	newLineIndex: number;
}

/**
 * Parses a Markdown table structure
 *
 * @param lines - All lines of the input
 * @param currentLineIndex - The current line being processed
 * @param parserFlags - Flags that control which Markdown features are enabled
 * @param parseInline - Function to parse inline content
 * @returns Parsed table node and updated line index
 */
export function parseTable(
	lines: Array<string>,
	currentLineIndex: number,
	_parserFlags: number,
	parseInline: (text: string) => Array<Node>,
): TableParseResult {
	const startIndex = currentLineIndex;

	try {
		// Check if we have at least 3 lines for a valid table (header, separator, data)
		if (startIndex + 2 >= lines.length) {
			return {node: null, newLineIndex: currentLineIndex};
		}

		const potentialTable = lines.slice(startIndex, startIndex + 3);
		if (!isValidTableStructure(potentialTable)) {
			return {node: null, newLineIndex: currentLineIndex};
		}

		// Parse header row
		const headerRow = parseTableRow(lines[currentLineIndex], undefined, parseInline);
		if (!headerRow) {
			return {node: null, newLineIndex: currentLineIndex};
		}

		const columnCount = headerRow.cells.length;
		currentLineIndex++;

		// Parse alignment row
		if (currentLineIndex >= lines.length) {
			return {node: null, newLineIndex: startIndex};
		}

		const alignmentRow = parseTableAlignments(lines[currentLineIndex]);
		if (!alignmentRow || headerRow.cells.length !== alignmentRow.length) {
			return {node: null, newLineIndex: startIndex};
		}

		currentLineIndex++;

		// Parse data rows
		const rows: Array<TableRowNode> = [];
		while (currentLineIndex < lines.length) {
			const line = lines[currentLineIndex];

			// Basic check - must have pipe to be a table row
			if (!line.includes('|')) break;

			// Make sure this isn't the start of another block
			const trimmed = line.trim();
			if (isBlockBreak(trimmed)) break;

			// Parse the row
			const row = parseTableRow(line, columnCount, parseInline);
			if (!row) break;

			rows.push(row);
			currentLineIndex++;
		}

		// Valid table should have at least one data row
		if (rows.length === 0) {
			return {node: null, newLineIndex: startIndex};
		}

		return {
			node: {
				type: NodeType.Table,
				header: headerRow,
				alignments: alignmentRow,
				rows,
			},
			newLineIndex: currentLineIndex,
		};
	} catch (_err) {
		// Reset the line index and return null on any error
		return {node: null, newLineIndex: startIndex};
	}
}

/**
 * Checks if lines form a valid table structure
 */
export function isValidTableStructure(lines: Array<string>): boolean {
	if (lines.length < 2) return false;

	const headerLine = lines[0].trim();
	if (!hasValidTableContent(headerLine)) return false;

	const alignmentLine = lines[1].trim();
	if (!isValidAlignmentRow(alignmentLine)) return false;

	return true;
}

/**
 * Checks if a line has valid table content
 */
export function hasValidTableContent(line: string): boolean {
	if (!line.includes('|')) return false;

	const cells = splitTableCells(line);
	return cells.some((cell) => cell.trim().length > 0);
}

/**
 * Validates that a line is a proper table alignment row
 */
export function isValidAlignmentRow(line: string): boolean {
	if (!line.includes('|') || !line.includes('-')) return false;

	const cells = splitTableCells(line);
	return cells.every((cell) => {
		const trimmed = cell.trim();
		if (trimmed.length === 0) return false;

		return /^[\s:|-]+$/.test(trimmed) && trimmed.includes('-');
	});
}

/**
 * Splits a table row into cell content, handling escaped pipes
 */
export function splitTableCells(line: string): Array<string> {
	// Remove leading/trailing pipes
	const content = line.trim().replace(/^\||\|$/g, '');

	// Handle escaped pipes in the content
	const cells: Array<string> = [];
	let currentCell = '';
	let i = 0;

	while (i < content.length) {
		// Handle escaped pipes - keep them as part of the cell content
		if (content[i] === '\\' && i + 1 < content.length && content[i + 1] === '|') {
			currentCell += '|';
			i += 2;
			continue;
		}

		// Handle pipe as a cell delimiter
		if (content[i] === '|') {
			cells.push(currentCell);
			currentCell = '';
			i++;
			continue;
		}

		// Regular character
		currentCell += content[i];
		i++;
	}

	// Add the last cell
	cells.push(currentCell);

	return cells;
}

/**
 * Parses the alignment row of a table
 */
export function parseTableAlignments(line: string): Array<TableAlignment> | null {
	const cells = splitTableCells(line.trim());
	const alignments: Array<TableAlignment> = [];

	for (const cell of cells) {
		const trimmed = cell.trim();
		if (!trimmed || !trimmed.includes('-')) return null;

		const left = trimmed.startsWith(':');
		const right = trimmed.endsWith(':');

		if (left && right) {
			alignments.push(TableAlignment.Center);
		} else if (left) {
			alignments.push(TableAlignment.Left);
		} else if (right) {
			alignments.push(TableAlignment.Right);
		} else {
			alignments.push(TableAlignment.None);
		}
	}

	return alignments.length > 0 ? alignments : null;
}

/**
 * Parses a table row into an array of cells
 *
 * @param line - The line to parse
 * @param expectedColumns - The expected number of columns (optional)
 * @param parseInline - Function to parse inline content
 * @returns TableRowNode or null if not a valid row
 */
export function parseTableRow(
	line: string,
	expectedColumns: number | undefined,
	parseInline: (text: string) => Array<Node>,
): TableRowNode | null {
	const trimmedLine = line.trim();
	if (!trimmedLine || !hasValidTableContent(trimmedLine)) return null;

	const cells: Array<TableCellNode> = [];
	const cellContents = splitTableCells(trimmedLine);

	// If expected columns is provided, ensure we have the right number
	if (expectedColumns !== undefined && cellContents.length !== expectedColumns) {
		// Try to redistribute cells if we have too many
		if (cellContents.length > expectedColumns) {
			redistributeCells(cellContents, expectedColumns);
		}

		// If we still don't have the right count, add empty cells
		while (cellContents.length < expectedColumns) {
			cellContents.push('');
		}
	}

	for (const cellContent of cellContents) {
		const trimmed = cellContent.trim();
		const inlineNodes = parseInline(trimmed);
		cells.push({
			type: NodeType.TableCell,
			children: inlineNodes.length > 0 ? inlineNodes : [{type: NodeType.Text, content: trimmed}],
		});
	}

	return cells.length === 0 ? null : {type: NodeType.TableRow, cells};
}

/**
 * Redistributes cells when there are too many cells in a row
 * This combines excess cells into the last expected cell
 */
export function redistributeCells(cells: Array<string>, expectedColumns: number): void {
	if (cells.length <= expectedColumns) return;

	// Keep the first expectedColumns-1 cells as they are
	const lastCellContent = cells.slice(expectedColumns - 1).join('|');
	cells.splice(expectedColumns - 1, cells.length - expectedColumns + 1, lastCellContent);
}

/**
 * Checks if a line starts a block element that would break a table
 */
function isBlockBreak(trimmed: string): boolean {
	return (
		trimmed.startsWith('#') ||
		trimmed.startsWith('```') ||
		trimmed.startsWith('>') ||
		trimmed.startsWith('>>> ') ||
		trimmed.startsWith('-#') ||
		/^\d+\.\s/.test(trimmed) ||
		/^- /.test(trimmed) ||
		/^\* /.test(trimmed)
	);
}
